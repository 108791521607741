.support {
    user-select: none;
    @media (max-width: $mq__mobile) {
        .support__btn {
            display: none !important;
        }
    }

    &__row {
        margin: 0 -30px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        position: relative;
        @media (max-width: $mq__mobile) {
            margin: 0;
        }
    }

    &__box {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        @media (max-width: $mq__mobile) {
            padding: 0;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: start;

        @media (max-width: $mq__mobile) {
            transform: none !important;
            flex-direction: column;
        }
    }

    &__item {
        margin: 24px 0;
        width: auto;
        display: flex !important;
        align-items: end;
        position: relative;
        background: #fff;
        transition: $animation__duration_slow;
        &:not(:last-of-type) {
            margin-right: 72px;
        }
        @media (max-width: $mq__mobile) {
            transform: none !important;
            flex-direction: column-reverse;
            margin: 0 0 16px;
            &:not(:last-of-type) {
                margin-right: 0;
            }
        }

        &:hover,
        &:focus {
            .support__caption {
                color: #3055D8;
            }

            .support__li::marker {
                color: #3055D8;
            }

            .support__img {
                &:before {
                    opacity: 0;
                }
                mix-blend-mode: initial;
            }
        }
    }

    &__card {
        width: 100%;
        height: 100%;
        max-width: 520px;
        max-height: 292px;
        flex-shrink: 0;
        padding: 32px;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        overflow: hidden;
        cursor: grab;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            padding: 16px;
            border: 0.5px solid rgba(34, 34, 34, 0.3);
            box-shadow: 0 0 20px #DBDBDB;
            border-radius: 0 0 20px 20px;
            max-height: initial;
            max-width: initial;
        }

        &:active {
           cursor: grabbing;
        }

    }

    &__caption {
        margin-bottom: 24px;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #222222;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            font-size: 18px;
            margin-bottom: 16px;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__li {
        margin-bottom: 16px;
        margin-left: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.7);
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
        }
    }

    &__img {
        margin-left: -20px;
        margin-bottom: 114px;
        flex-shrink: 0;
        position: relative;
        background: #fff;
        mix-blend-mode: luminosity;
        transition: $animation__duration_slow;
        border-radius: 30px;
        overflow: hidden;
        filter: drop-shadow(0px 0px 20px #DBDBDB);

        @media (max-width: $mq__mobile) {
            mix-blend-mode: initial;
            border-radius: 20px 20px 0 0;
            margin: 0;
            width: 100%;
            img {
                object-fit: cover;
                width: 100%;
            }
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(52.99deg, rgba(0, 0, 0, 0.4) 42.64%, rgba(192, 192, 192, 0.4) 93.25%);
            transition: $animation__duration_slow;
        }
    }
}

