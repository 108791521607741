.info {
    background: rgba(48, 85, 216, 0.10);
    padding-top: 24px;
    padding-bottom: 50px;

    @media (max-width: 1279px) {
        padding-top: 24px;
        padding-bottom: 16px;
    }

    &__more {
        max-width: 100%;
        width: 100%;
        background: none;
        border-radius: 8px;
        border: 1px solid #3055D8;
        padding: 6px 0;
        cursor: pointer;
        display: none;
        align-items: center;
        justify-content: center;

        svg {
            width: 24px;
            height: 24px;
        }

        @media (max-width: 1279px) {
            display: flex;
        }
    }

    h2 {
        font-size: 32px;
        font-style: normal;
        font-weight: 800;
        line-height: 130%;
        letter-spacing: 0.32px;
        margin-bottom: 0;

        @media (max-width: 1279px) {
            font-size: 19px;
            line-height: 115%;
            max-width: 260px;

            br {
                display: none;
            }
        }
    }

    .section__body > p {
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        font-style: italic;
        margin-top: 24px;
        margin-bottom: 24px;

        @media (max-width: 1279px) {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 16px;
        }
    }

    &__desk {
        max-width: 476px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 36px;

        .info__desk-list {
            max-width: 370px;
            margin: 0 auto;

            @media (max-width: 1279px) {
                display: none;
            }
        }

        .info__desk-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;
            gap: 30px;
            background: #3055D8;
            border-radius: 30px;

            @media (max-width: 1279px) {
                padding: 16px;
                gap: 20px;
                border-radius: 20px;
                flex-direction: column;
            }

            p {
                font-size: 29px;
                font-weight: 600;
                font-style: italic;
                line-height: 150%; 
                text-align: center;
                margin-bottom: 0;
                color: white;
                max-width: 420px;

                @media (max-width: 1279px) {
                    font-size: 18px;
                    line-height: 120%
                }
            }

            a {
                padding: 18px 0;
                max-width: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                border-radius: 20px;
                background: #EEF2F5;
                display: flex;
                align-items: center;
                border: none;
                color: #3055D8;
                cursor: pointer;
                font-size: 20px;
                font-weight: 500;
                line-height: 149.3%;

                @media (max-width: 1279px) {
                    max-width: 100%;
                    width: 100%;
                    padding: 12px 0;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 150%;
                    border-radius: 10px;
                }

                b {
                    font-weight: 500;

                    @media (max-width: 1279px) {
                        display: none;
                    }
                }

                &:hover {
                    opacity: 0.8;
                }

                svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    &__list {
        display: grid;
        gap: 32px;
        grid-template-columns: repeat(2, 1fr);
        transition: all 0.8s ease;

        @media (max-width: 1279px) { 
            grid-template-columns: repeat(1, 1fr);
            gap: 12px;
            max-height: 175px;
            overflow-y: hidden;
        }

        @media (max-width: 374px) {
            max-height: 225px;
        }
    }

    &__list.open {
        @media (max-width: 1279px) { 
            max-height: initial;
        }
    }

    &__block {
        display: flex;
        gap: 32px;

        @media (max-width: 1279px) {
            flex-direction: column;
            gap: 20px;
        }

        ul {
            max-width: 832px;
            gap: 20px;
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;

            @media (max-width: 1279px) {
                gap: 12px;
                margin-bottom: 0;
            }

            li {
                width: 100%;
                font-size: 18px;
                font-weight: 500;
                line-height: 150%;
                list-style: none;
                position: relative;
                padding-left: 30px;
                color: rgba(0, 0, 0, 0.60);
                margin-bottom: 0;

                @media (max-width: 1279px) {
                    padding-left: 18px;
                    font-size: 14px;
                    line-height: 125%;
                    max-width: 100%;
                }

                &::after {
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                    display: block;
                    width: 14px;
                    height: 14px;
                    border-radius: 100%;
                    background: #3055D8;
                    margin-top: 6px;

                    @media (max-width: 1279px) {
                        width: 8px;
                        height: 8px;
                    }
                }
            }

            .hide-desk {
                display: none;

                @media (max-width: 1279px) {
                    display: block;
                }
            }
        }
    }
}