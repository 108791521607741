.profit {
    user-select: none;

    &__body {
        padding-bottom: 74px;
        position: relative;
    }

    &__link {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 14px 28px 14px 28px;
        display: -ms-inline-flexbox;
        display: inline-flex;
        width: auto;
        -ms-flex-item-align: end;
        align-self: end;
        justify-self: end;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        background: #3055d8;
        color: #eef2f5;
        border: 1.5px solid #3055d8;
        border-radius: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 149.3%;
        letter-spacing: 0.03em;
        transition: 0.6s;
    }

    &__row {
        padding: 24px;
        margin: 0 -24px;
        display: -webkit-flex;
        display: flex;

        gap: 24px;

        @media (max-width: $mq__laptop) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: $mq__tablet) {
            padding: 12px 16px;
            margin: 0 -16px;
            gap: 16px;
        }
    }

    &__item {
        width: 100%;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: $mq__mobile) {
            .profit__card {
                width: 100%;
            }
        }

        &:hover {
            .profit__card {
                background: #3055d8;
                transition: $animation__duration_slow;
            }

            .profit__caption {
                color: #eef2f5;
                transition: $animation__duration_slow;
            }

            .profit__more {
                color: #eef2f5;
                transition: $animation__duration_slow;
            }
        }
    }

    &__card {
        width: 100%;
        flex-shrink: 0;
        padding: 24px;
        background: #ffffff;
        box-shadow: 0 0 20px #dbdbdb;
        border-radius: 30px;
        overflow: hidden;
        cursor: pointer;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            padding: 16px 12px;
            display: -webkit-flex;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }

    &__caption {
        margin-bottom: 32px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 28px;
        line-height: 33px;
        letter-spacing: 0.03em;
        color: #3055d8;

        @media (max-width: $mq__mobile) {
            margin-bottom: 16px;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            flex-direction: column;
        }

        &_icon {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            margin-right: 24px;

            @media (max-width: $mq__mobile) {
                width: 24px;
                height: 24px;
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
    }

    &__text {
        height: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #eef2f5;
        opacity: 0;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
        }
    }

    &__more {
        display: flex;
        align-items: center;
        justify-content: end;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #3055d8;

        @media (max-width: $mq__mobile) {
            justify-content: center;
            font-size: 12px;
        }

        &-svg {
            margin-left: 16px;
            width: 24px;
            height: 24px;

            @media (max-width: $mq__mobile) {
                margin-left: 6px;
                width: 16px;
                height: 16px;
            }
        }
    }

    &-modal {
        position: fixed;
        display: none;
        align-items: stretch;
        border-radius: 30px;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        margin: 0 auto;
        max-width: 593px;
        height: 100%;
        width: 100%;
        max-height: 472px;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        transition: $animation__duration_slow;
        overflow: hidden;

        &.is-display {
            display: block;
            opacity: 1;
            visibility: initial;
            animation: is-display $animation__duration_slow linear;
        }

        @media (max-width: $mq__tablet) {
            height: auto;
            width: 80%;
            max-width: 288px;
            align-items: normal;
            flex-direction: column-reverse;
            max-height: initial;
            border-radius: 0;
        }

        &__title {
            padding: 30px 24px 30px 32px;
            background: #3055d8;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;

            @media (max-width: $mq__tablet) {
                padding: 16px;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }
        }

        &__caption {
            padding-right: 50px;
            font-weight: 600;
            font-size: 30px;
            line-height: 35px;
            letter-spacing: 0.03em;
            color: #ffffff;

            @media (max-width: $mq__tablet) {
                font-size: 18px;
                line-height: 21px;
            }
        }

        &__close {
            position: absolute;
            top: 30px;
            right: 24px;
            width: 34px;
            height: 34px;
            padding: 7px;
            color: #eef2f5;
            background: 0 0;
            border: 0;
            cursor: pointer;

            @media (max-width: $mq__tablet) {
                top: 16px;
                right: 16px;
                width: 24px;
                height: 24px;
                padding: 5px;
            }
        }

        &__body {
            background: #ffffff;
            padding: 24px 15px 8px 32px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            max-height: 355px;
            overflow: auto;

            @media (max-width: $mq__tablet) {
                padding: 12px;
                border-bottom-left-radius: 20px;
                border-bottom-right-radius: 20px;
            }
        }

        &__item {
            display: flex;
        }

        &__icon {
            width: 30px;
            height: 30px;
            flex-shrink: 0;

            @media (max-width: $mq__tablet) {
                width: 20px;
                height: 20px;
            }
        }

        &__text {
            margin-left: 16px;
            margin-bottom: 16px;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #222222;

            @media (max-width: $mq__tablet) {
                font-size: 14px;
                line-height: 140%;
                margin-left: 10px;
            }
        }

        &_row {
            display: flex;
            gap: 12px;

            @media (max-width: $mq__mobile) {
                flex-direction: column;
                align-items: center;
            }
        }

        &_item {
            max-width: 265px;
        }

        &_blue {
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            color: #3055d8;
            text-align: center;
        }

        &_info {
            display: flex;
            align-items: center;
        }

        &_img {
            flex-shrink: 0;
            background: #fff;
            position: relative;
            overflow: hidden;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;

            @media (max-width: $mq__tablet) {
                border-radius: 0;
                max-height: 30%;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(52.99deg,
                        rgba(0, 0, 0, 0.4) 42.64%,
                        rgba(192, 192, 192, 0.4) 93.25%);
            }
        }

        &_close {
            position: absolute;
            top: 24px;
            right: 24px;
            width: 34px;
            height: 34px;
            padding: 7px;
            color: #eef2f5;
            background: transparent;
            border: 0;
            cursor: pointer;

            @media (max-width: $mq__tablet) {
                top: 16px;
                right: 16px;
            }
        }
    }
}