.highlight {
    position: relative;
    background: #d1e1ff;

    &:before {
        position: absolute;
        top: -2px;
        right: -2px;
        bottom: -2px;
        left: -4px;
        content: '';
        display: inline;
        background: #d1e1ff;
        z-index: -1;
    }
}
