.c-label {
    background: rgba(77, 107, 215, 0.21);
    display: inline-block;
    border-radius: 200px;
    padding: 6px 11px;
    color: var(--color__link);
    text-transform: uppercase;
    line-height: 1;
    font-size: 12px;

    &:first-letter {
        font-size: 15px;
    }
}