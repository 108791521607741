.tech {
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        position: relative;
        margin: -30px;

        @media (max-width: $mq__mobile) {
            margin: -16px;
        }
    }

    &__box {
        padding: 30px;

        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__wrapper {
        align-items: flex-start;
    }

    &__card {
        height: 100%;
        margin-right: 32px;
        padding: 35px 24px 24px;
        display: flex;
        flex-direction: column;
        background: #fff;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        font-weight: 600;
        font-size: 28px;
        line-height: 33px;
        letter-spacing: 0.03em;
        color: #3055D8;
        cursor: grab;
        transition: $animation__duration_slow;

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #BEBEBE;

            .tech__icon {
                color: #3055D8;
            }

            .tech__title {
                color: #3055D8;
            }
        }

        @media (max-width: $mq__tablet) {
            padding: 16px;
            font-size: 16px;
            line-height: 19px;
        }

        @media (max-width: $mq__mobile) {
            width: 250px;
            height: 420px;
        }

        &.open {
            .tech__icon {
                color: #3055D8;
            }

            .tech__title {
                color: #3055D8;
            }

            .tech__more {
                margin-top: 32px;
                padding: 14px 18px 14px 28px;
                opacity: 1;
                height: 52px;
            }
        }
    }

    &__icon {
        width: 48px;
        height: 48px;
        margin-bottom: 15px;
        flex-shrink: 0;
        color: #222;
        align-self: center;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            margin-bottom: 10px;
            width: 32px;
            height: 32px;
            color: #3055D8;
        }
    }

    &__title {
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 30px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #222222;
        align-self: center;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.03em;
            color: #3055D8;
        }
    }

    &__more {
        padding: 0;
        display: flex;
        width: auto;
        align-self: center;
        align-items: center;
        flex-shrink: 0;
        background: #3055D8;
        border: none;
        border-radius: 20px;
        height: 0;
        opacity: 0;
        font-weight: 500;
        font-size: 16px;
        line-height: 149.3%;
        letter-spacing: 0.03em;
        color: #EEF2F5;
        transition: $animation__duration_fast;

        svg {
            margin-left: 6px;
            width: 16px;
            height: 16px;
        }

        &:hover {
            box-shadow: 0 0 10px #3055d8;
        }
    }

    &__list {
        margin-bottom: 0;
        transition: $animation__duration_slow;
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(7, auto);

        @media (max-width: $mq__mobile) {
            grid-template-rows: repeat(12, auto);
        }
    }

    &__item {
        width: 180px;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);
        flex-shrink: 0;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
            width: 121px;
        }
    }
}

.seo-page {
    .tech {
        &__box {
            padding-top: 60px;
        }
    }
}