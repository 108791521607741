.propose {
    .section__h_with-pre-content {
        margin-bottom: 62px;
        @media (max-width: $mq__mobile) {
            margin-bottom: 24px;
        }
    }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        position: relative;
        margin: -30px;
        @media (max-width: $mq__mobile) {
            margin: -16px;
        }
    }

    &__box {
        padding: 30px;
        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__wrapper {
        align-items: flex-start;
    }

    &__item {
        margin-right: 16px;

        &:last-child{
            margin-right: 0;
        }
    }

    &__card {
        min-width: 380px;
        max-width: 380px;
        flex-shrink: 0;
        padding: 32px;
        background: #ffffff;
        box-shadow: 0 0 20px #dbdbdb;
        border-radius: 30px;
        overflow: hidden;
        cursor: pointer;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            min-width: 175px;
            padding: 16px 12px;
            display: -webkit-flex;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }

    &__caption {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 28px;
        line-height: 33px;
        letter-spacing: 0.03em;
        color: #3055d8;

        @media (max-width: $mq__mobile) {
            max-width: 150px;
            margin: 0 auto;
            margin-bottom: 16px;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            flex-direction: column;
        }

        &_icon {
            flex-shrink: 0;
            width: 40px;
            height: 40px;
            margin-right: 24px;

            @media (max-width: $mq__mobile) {
                width: 24px;
                height: 24px;
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
    }

    &__text {
        height: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #eef2f5;
        opacity: 0;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
        }
    }

    &__more {
        display: flex;
        align-items: center;
        justify-content: start;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #3055d8;
        @media (max-width: $mq__mobile) {
            justify-content: center;
            font-size: 12px;
        }
        &-svg {
            margin-left: 16px;
            width: 24px;
            height: 24px;
            @media (max-width: $mq__mobile) {
                margin-left: 6px;
                width: 16px;
                height: 16px;
            }
        }
    }
}