@keyframes chat {
    0% {
        opacity: 0;
        max-height: 0;
    }

    100% {
        opacity: 1;
        max-height: 400px;
    }
}

@keyframes waiting {
    0% {
        background: #3055D8;
    }

    50% {
        background: #fff;
    }

    100% {
        background: #3055D8;
    }
}

@keyframes waiting-icons {
    0% {
        color: #fff;
    }

    50% {
        color: #3055D8;
    }

    100% {
        color: #fff;
    }
}

@keyframes playing {
    0% {
        width: 0;
    }

    100% {
        width: 205px;
    }
}

.quiz {
    padding-top: 0;
    display: inline-block;
    width: 100%;
    background-color: rgba(48, 85, 216, 0.1);
    padding-top: 64px;

    @media (max-width: 1024px) {
        padding-top: 16px;
    }

    .unactive {
        .empty-field {
            text-shadow: 0 0 10px white;
        }

        .quiz__step-result-form-input {
            box-shadow: 0 0 10px red;

            &::placeholder {
                color: red;
            }
        }
    }
}

.quiz__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    margin-bottom: 32px;
}

.quiz__body {
    background-color: #fff;
    border-radius: 30px;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 525px;
    width: 954px;
    margin-right: 32px;
    -webkit-box-shadow: 0px 0px 20px #dbdbdb;
    box-shadow: 0px 0px 20px #dbdbdb;
}

.quiz__chat {
    border-radius: 30px;
    width: 354px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 354px;
    flex: 0 0 354px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.15);
}

.quiz__chat-top {
    height: 80px;
    background-color: #3055d8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 16px;
}

.quiz__chat-top-img {
    position: relative;
    z-index: 1;
    width: 40px;
    height: 40px;
}

.quiz__chat-top-img-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-right: 12px;
}

.quiz__chat-top-img-wrapper:before {
    content: "";
    position: absolute;
    top: -3px;
    left: -3px;
    width: 46px;
    height: 46px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 0;
}

.quiz__chat-top-img-wrapper:after {
    content: "";
    position: absolute;
    top: 28px;
    left: 28px;
    width: 14px;
    height: 14px;
    background-color: #38bf4b;
    border-radius: 50%;
    z-index: 2;
}

.quiz__chat-top-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.quiz__chat-top-name {
    font-size: 18px;
    font-weight: 600;
    color: white;
}

.quiz__chat-top-status {
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.7);
}

.quiz__chat-middle {
    background-color: #eef2f5;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.quiz__chat-middle-today {
    color: rgba(0, 0, 0, 0.4);
    font-size: 14px;
    text-align: center;
}

.quiz__chat-middle-message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 16px;
}

.quiz__chat-middle-message.writting .quiz__chat-middle-message-body-writting {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.quiz__chat-middle-message.writting .quiz__chat-middle-message-img {
    display: block;
}

.quiz__chat-middle-message.writting .name {
    margin-right: 4px;
}

.quiz__chat-middle-message-img {
    display: none;
    margin-right: 10px;
    width: 40px;
    height: 40px;
}

.quiz__chat-middle-message-img.show {
    display: block !important;
}

.quiz__chat-middle-message-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
}

.quiz__chat-middle-message-body-writting {
    color: #666;
    font-size: 14px;
    font-weight: 600;
    position: relative;
    padding-left: 25px;
    height: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-ordinal-group: 124;
    -ms-flex-order: 123;
    order: 123;
    display: none;
}

.quiz__chat-middle-message-body-writting:before {
    content: "";
    position: absolute;
    top: 10px;
    left: 0;
    background-image: url("img/quiz-img/edit.png");
    width: 19px;
    height: 19px;
}

.quiz__chat-middle-message-item {
    background-color: #3055d8;
    color: white;
    padding: 10px;
    font-size: 13px;
    font-weight: 500;
    border-radius: 0 16px 16px 16px;
    margin-bottom: 16px;
    transition: $animation__duration_slow;
    animation: chat $animation__duration_slow linear;
}

.quiz__chat-middle-message-audio {
    background-color: #3055d8;
    color: white;
    padding: 10px;
    border-radius: 16px;
    margin-top: 0;
    margin-bottom: 32px;
    position: relative;
    width: 100%;
    height: 62px;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition: $animation__duration_slow;
    animation: waiting 4s infinite linear;

    &:after {
        content: 'Прослушать сообщение';
        position: absolute;
        bottom: -25px;
        left: 17px;
        font-size: 14px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.4);
    }

    &.playing {
        animation: none;

        .quiz__chat-middle-message-audio-play,
        .quiz__chat-middle-message-audio-line {
            animation: none;
        }

        &.audio1 {
            .quiz__chat-audio-box {
                animation: playing 12s linear;
            }
        }

        &.audio2 {
            .quiz__chat-audio-box {
                animation: playing 16s linear;
            }
        }

        &.audio3 {
            .quiz__chat-audio-box {
                animation: playing 12s linear;
            }
        }
    }

    &.pause {
        .quiz__chat-audio-box {
            animation-play-state: paused !important;
        }
    }
}

.quiz__chat-middle-message-audio-play {
    width: 35px;
    height: 35px;
    color: #fff;
    margin-right: 12px;
    transition: $animation__duration_slow;
    animation: waiting-icons 4s infinite linear;
}

.quiz__chat-middle-message-audio-line {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 30px;
    color: #929292;
    width: calc(100% - 35px - 12px);
    transition: $animation__duration_slow;
    animation: waiting-icons 4s infinite linear;
}

.quiz__chat-audio-box {
    position: absolute;
    left: 57px;
    top: 16px;
    height: 30px;
    width: 0;
    overflow: hidden;
}

.quiz__chat-middle-message-audio-line-active {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    height: 30px;
    color: #fff;
    width: 205px;
}

.quiz__chat-bottom {
    height: 64px;
    padding: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: end;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.quiz__chat-bottom-text {
    margin-right: 12px;
    font-weight: 500;
    font-size: 14px;
}

.quiz__chat-bottom-socials {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-right: -16px;
}

.quiz__chat-bottom-social {
    margin-right: 16px;
    width: 32px;
    height: 32px;
}

.quiz__step {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    height: 100%;
    padding: 32px;

    &.modal-open {
        display: flex !important;
    }
}

.quiz__step.active {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.quiz__step-counter {
    font-size: 16px;
    font-weight: 500;
}

.quiz__step-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px;
}

.quiz__step-back {
    margin-right: 10px;
    cursor: pointer;
}

.quiz__step-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 24px;
}

.quiz__step-next {
    position: absolute;
    bottom: 32px;
    right: 32px;
    width: 280px;
    height: 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #3055d8;
    color: white;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    cursor: pointer;
    border: none;

    svg {
        margin-left: 10px;
        color: #fff;
        width: 16px;
        height: 16px;
    }
}

.btn-final {
    bottom: 32px;
    right: 32px;
    width: 280px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    cursor: pointer;
    border: none;
    position: static;
    background: #FFFFFF;
    color: #3055D8;

    svg {
        margin-left: 10px;
        color: #3055D8;
        width: 16px;
        height: 16px;
    }
}

.quiz__modal-content {
    position: relative;
}


.quiz__step-next.unactive {
    pointer-events: none;
    opacity: 0.9;
}

.quiz__step-next.block {
    pointer-events: none;
    opacity: 0.9;
}

.quiz__step-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 550px;
}

.quiz__step-column-item {
    height: 80px;
    width: 550px;
    padding-left: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    background-color: #eef2f5;
    margin-bottom: 16px;
    border-radius: 16px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.quiz__step-column-item:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 24px;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(34, 34, 34, 0.3);
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.quiz__step-column-item:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 28px;
    width: 12px;
    height: 12px;
    background-color: white;
    opacity: 0;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.quiz__step-column-item.active {
    background-color: #3055d8;
    color: white;
    opacity: 1 !important;
}

.quiz__step-column-item.active:before {
    border-color: white;
}

.quiz__step-column-item.active:after {
    opacity: 1;
}

.quiz__step-column-item:hover {
    background-color: #3055d8;
    color: white;
    opacity: 0.8;
}

.quiz__step-column-item:hover:before {
    border-color: white;
}

.quiz__step-columns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.quiz__step-message {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 500;
    padding: 0 50px;
    display: none;
}

.quiz__step-message.active {
    display: block;
}

.quiz__step-message-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.quiz__step-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.quiz__step-flex {
    max-width: 100%;
    width: 100%;
    position: relative;
}

.quiz__step-text {
    max-width: 374px;
    margin: 0;
    font-size: 16px;
    line-height: 140%;
    margin: 0;
    padding: 0;
    margin-left: 16px;
    position: absolute;
    top: -40px;
    right: -15px;

    li {
        margin: 0;
        padding: 0;

        b {
            color: #3055D8;
            font-weight: 600;
        }
    }

    @media (max-width: 1439px) {
        display: none;
    }
}

.quiz__step-four-grid {
    width: calc((100% - 48px) / 4);
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    background-color: #eef2f5;
    margin-bottom: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
}

.quiz__step-four-grid.active {
    background-color: #3055d8;
    color: white;
    opacity: 1 !important;
}

.quiz__step-four-grid:hover {
    background-color: #3055d8;
    color: white;
    opacity: 0.8;
}

.quiz__step-half-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    max-width: 500px;
}

.quiz__step-two-grid {
    width: calc((100% - 16px) / 2);
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    background-color: #eef2f5;
    margin-bottom: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
}

.quiz__step-two-grid.active {
    background-color: #3055d8;
    color: white;
    opacity: 1 !important;
}

.quiz__step-two-grid:hover {
    background-color: #3055d8;
    color: white;
    opacity: 0.8;
}

.quiz__step-three-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    max-width: 758px;
}

.quiz__step-three-grid {
    padding: 12px 24px;
    width: calc((100% - 32px) / 3);
    height: 76px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    background-color: #eef2f5;
    margin-bottom: 16px;
    border-radius: 16px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-align: center;
}

.quiz__step-three-grid.active {
    background-color: #3055d8;
    color: white;
    opacity: 1 !important;
}

.quiz__step-three-grid:hover {
    background-color: #3055d8;
    color: white;
    opacity: 0.8;
}

.quiz__step-one-grid {
    width: 100%;
    height: 76px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    background-color: #eef2f5;
    margin-bottom: 16px;
    border-radius: 16px;
    cursor: pointer;
    transition: 0.3s;
    text-align: center;
}

.quiz__step-one-grid.active {
    background-color: #3055d8;
    color: white;
    opacity: 1 !important;
}

.quiz__step-one-grid:hover {
    background-color: #3055d8;
    color: white;
    opacity: 0.8;
}

.quiz__step-grid-item {
    width: calc((100% - 32px) / 3);
    height: 76px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    background-color: #eef2f5;
    margin-bottom: 16px;
    border-radius: 16px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    text-align: center;
}

.quiz__step-grid-item.active {
    background-color: #3055d8;
    color: white;
    opacity: 1 !important;
}

.quiz__step-grid-item:hover {
    background-color: #3055d8;
    color: white;
    opacity: 0.8;
}

.quiz__step-period {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.quiz__step-period-line {
    width: 264px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: space-evenly;
    -ms-flex-pack: space-evenly;
    justify-content: space-evenly;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    margin-bottom: 48px;
}

.quiz__step-period-line:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    -webkit-transform: translateY(-1px);
    transform: translateY(-1px);
    background-color: #3055d8;
}

.quiz__step-period-line-item {
    width: 30px;
    height: 30px;
    background-color: transparent;
    cursor: pointer;
    position: relative;
    z-index: 1;
}

.quiz__step-period-line-item:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: 0.2s;
    transition: 0.2s;
    width: 9px;
    height: 9px;
    border: 3px solid #3055d8;
    background-color: #3055d8;
    border-radius: 50%;
}

.quiz__step-period-line-item.active:before {
    background-color: #fff;
    width: 25px;
    height: 25px;
}

.quiz__step-period-line-item-caption {
    position: absolute;
    top: 30px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    left: 50%;
    white-space: nowrap;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.quiz__step-description {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.quiz__step-description-input {
    border-radius: 16px;
    outline: none;
    border: 2px solid rgba(34, 34, 34, 0.3);
    padding: 16px;
    font-size: 16px;
    width: 100%;
    font-weight: 500;
    color: rgba(34, 34, 34, 0.8);
    max-width: 100%;
    max-height: 184px;

    &.quiz__step-short-input {
        width: 100%;
        max-width: calc((100% - 10px)/ 2);
        height: 56px;
    }
}

.quiz__step-description-input::-webkit-input-placeholder {
    font-size: 16px;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 500;
}

.quiz__step-description-input:-ms-input-placeholder {
    font-size: 16px;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 500;
}

.quiz__step-description-input::-ms-input-placeholder {
    font-size: 16px;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 500;
}

.quiz__step-description-input::placeholder {
    font-size: 16px;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 500;
}

.quiz__step-description-input:focus {
    border-color: #3055d8;
}

.quiz__step-subtitle {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
}

.quiz__step-speed {
    max-width: 582px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.quiz__step-result {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.quiz__step-result-form {
    width: 400px;
    margin-right: 48px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.quiz__step-result-form-label {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 10px;
}

.quiz__step-result-form-input {
    border-radius: 16px;
    outline: none;
    border: 2px solid rgba(34, 34, 34, 0.3);
    padding: 16px;
    font-size: 16px;
    width: 100%;
    height: 56px;
    font-weight: 500;
    color: rgba(34, 34, 34, 0.8);
    margin-bottom: 16px;
}

.quiz__step-result-form-input::-webkit-input-placeholder {
    font-size: 16px;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 500;
}

.quiz__step-result-form-input:-ms-input-placeholder {
    font-size: 16px;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 500;
}

.quiz__step-result-form-input::-ms-input-placeholder {
    font-size: 16px;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 500;
}

.quiz__step-result-form-input::placeholder {
    font-size: 16px;
    color: rgba(34, 34, 34, 0.5);
    font-weight: 500;
}

.quiz__step-result-form-input:focus {
    border-color: #3055d8;
}

.quiz__modal {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 30px;

    &.active {
        display: block;
    }

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        backdrop-filter: blur(6px);
    }

    .quiz__step-result-form-input {
        border: none;
    }

    .quiz__step-next {
        position: static;
        background: #FFFFFF;
        color: #3055D8;

        svg {
            color: #3055D8;
        }

        &.unactive {
            opacity: 0.8;
        }
    }

    &-window {
        padding: 32px;
        padding-right: 92px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        width: 680px;
        height: auto;
        top: 29px;
        left: 0;
        right: 0;
        margin: 0 auto;
        background: #3055D8;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
    }

    &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        @media (max-width: $mq__mobile) {
            flex-direction: column;
        }

        svg {
            width: 83px;
            height: 83px;
            margin-left: 50px;

            @media (max-width: $mq__mobile) {
                margin-left: 0;
                margin-bottom: 20px;
                width: 53px;
                height: 53px;
            }
        }
    }

    &-messanger {
        display: flex;
        align-items: center;
        gap: 24px;
        margin-bottom: 16px;

        label {
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
            color: #FFFFFF;
        }
    }

    &-polit {
        text-align: center;
        margin-top: 24px;
        font-weight: 500;
        font-size: 12px;
        line-height: 120%;
        color: rgba(238, 242, 245, 0.6);

        @media (max-width: $mq__mobile) {
            margin-top: 16px;
        }
    }

    &-content {
        h3 {
            max-width: 360px;
            font-weight: 700;
            font-size: 36px;
            line-height: 110%;
            letter-spacing: 0.01em;
            color: #FFFFFF;
            margin-bottom: 40px;

            @media (max-width: $mq__mobile) {
                font-size: 20px;
                line-height: 120%;
                text-align: center;
            }
        }

        .empty-field {
            color: #eef2f5;
            opacity: .8;
            margin-bottom: 5px;
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            position: absolute;
            top: -25px;

            @media (max-width: $mq__mobile) {
                font-size: 12px;
                line-height: 150%;
                top: -22px;
            }
        }

        p {
            font-weight: 500;
            font-size: 16px;
            line-height: 130%;
            color: #EEF2F5;
            opacity: 0.8;
            margin-bottom: 16px;

            @media (max-width: $mq__mobile) {
                opacity: 1;
                margin-bottom: 8px;
                font-size: 12px;
                line-height: 150%;
            }
        }

        button {
            width: 100%;

            @media (max-width: $mq__mobile) {
                max-width: calc(100vw - 64px);
            }

            svg {
                margin-left: 10px;
                width: 16px;
                height: 16px;
            }
        }
    }

    &-close {
        position: absolute;
        right: 15px;
        top: 15px;
        margin: 0;
        width: 38px;
        height: 38px;
        padding: 7px;
        color: #EEF2F5;
    }

    &-form {
        display: flex;
        flex-direction: column;
        max-width: 372px;
        position: relative;
    }


}

.quiz__step-result-form-policy {
    display: flex;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    user-select: none;

    a {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #EEF2F5;
    }

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked~.checkmark {
            &:after {
                display: block;
            }
        }
    }

    .checkmark {
        position: absolute;
        top: 0px;
        left: 3px;
        height: 18px;
        width: 18px;
        background-color: transparent;
        border: 2px solid #EEF2F5;
        border-radius: 2px;

        &:after {
            content: "";
            position: absolute;
            display: none;
            left: 5px;
            top: 1px;
            width: 5px;
            height: 9px;
            border: solid #EEF2F5;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

.quiz__step-result-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 450px;
}

.quiz__step-result-right-price {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px;
    font-size: 14px;
}

.quiz__step-result-right-price .form,
.quiz__step-result-right-price .to {
    display: inline-block;
    font-size: 26px;
    font-weight: 600;
    color: #3055d8;
    margin: 0 10px;
    text-shadow: 0px 0px 20px #DBDBDB;
}

.quiz__step-result-right-text {
    font-size: 16px;
    font-weight: 500;
}

.quiz__step-person {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.quiz__step-person-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    user-select: none;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.quiz__step-person-item {
    height: 60px;
    width: 500px;
    padding-left: 64px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    font-size: 20px;
    font-weight: 600;
    background-color: #eef2f5;
    margin-bottom: 16px;
    border-radius: 16px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.quiz__step-person-item:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 24px;
    width: 20px;
    height: 20px;
    border: 1px solid rgba(34, 34, 34, 0.3);
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.quiz__step-person-item:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 28px;
    width: 12px;
    height: 12px;
    background-color: white;
    opacity: 0;
    border-radius: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.quiz__step-person-item.active {
    background-color: #3055d8;
    color: white;
    opacity: 1 !important;
}

.quiz__step-person-item.active:before {
    border-color: white;
}

.quiz__step-person-item.active:after {
    opacity: 1;
}

.quiz__step-person-item:hover {
    background-color: #3055d8;
    color: white;
    opacity: 0.8;
}

.quiz__step-person-item:hover:before {
    border-color: white;
}

.quiz__step-person-col {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc((100% - 80px) / 3);
    flex: 0 0 calc((100% - 80px) / 3);

    &:first-of-type {
        align-items: start;
    }
}

.quiz__step-person-person {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.quiz__step-person-group {
    position: relative;
    margin-bottom: 10px;
    display: none;
}

.quiz__step-person-group.show {
    display: block;

    &:nth-of-type(3) {
        .quiz__step-person-group-content {
            width: 200%;
            z-index: 2;
        }
    }
}

.quiz__step-person-group-top {
    padding: 16px 20px;
    display: inline-flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
    cursor: pointer;
}

.quiz__step-person-group-top:after {
    content: "+";
    margin-left: 18px;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    color: #3055D8;
    font-size: 27px;
    font-weight: 600;
    line-height: 17px;
    text-align: center;
    border: 2px solid #3055D8;
    background-size: contain;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.quiz__step-person-group-img {
    width: 22px;
    height: 22px;
    -o-object-fit: contain;
    object-fit: contain;
    display: block;
    margin-right: 10px;
}

.quiz__step-person-group-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
}

.quiz__step-person-group.active .quiz__step-person-group-top:after {
    background: #3055D8;
    color: #fff;
}

.quiz__step-person-group.active .quiz__step-person-group-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 16px;
    left: 100%;
}

.quiz__step-person-group-content {
    overflow: hidden;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    display: none;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    min-width: 285px;
    padding: 16px 16px 8px;
    border-radius: 16px;
    background: #EEF2F5;
    z-index: 2;
}

.quiz__step-person-group-content-item {
    margin: 0px 4px 8px;
    border-radius: 6px;
    padding: 4px 5px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background: #fff;
}

.quiz__step-person-group-content-item.active {
    background-color: #3055d8;
    color: white;
}

.quiz__step-person-radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.quiz__step-person-radio-item {
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 500;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 32px;
    cursor: pointer;
    position: relative;
}

.quiz__step-person-radio-item:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 2px;
    width: 20px;
    height: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 50%;
    border: 2px solid rgba(34, 34, 34, 0.3);
}

.quiz__step-person-radio-item:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 7px;
    width: 10px;
    height: 10px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 50%;
    background-color: transparent;
}

.quiz__step-person-radio-item.active:before {
    border-color: #3055d8;
}

.quiz__step-person-radio-item.active:after {
    background-color: #3055d8;
}

.quiz__step-person-list {
    display: none;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.quiz__step-person-list.show {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.quiz__step-person-list-top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 16px;
}

.quiz__step-person-list-title {
    font-size: 20px;
    font-weight: 600;
}

.quiz__step-person-list-img {
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 10px;
}

.quiz__step-person-list-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.quiz__step-person-list-item {
    border: 1px solid black;
    margin: 0px 4px 8px;
    border-radius: 6px;
    padding: 4px 5px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background: #fff;
}

.quiz__step-person-list-item.active {
    background-color: #3055d8;
    color: white;
}

.quiz__step-person-selected {
    display: flex;
    padding-left: 16px;

    &.full {
        &>div {
            display: none;
        }

        &>div:nth-of-type(1) {
            display: block;
        }

        &>div:nth-of-type(2) {
            display: block;
        }

        &:after {
            content: '+3';
            margin: 0 4px 8px;
            border-radius: 6px;
            padding: 4px 5px;
            font-weight: 500;
            font-size: 14px;
            cursor: pointer;
            transition: .3s;
            color: #ffffff;
            background: #3055d8;
        }
    }

    .quiz-selected {
        margin: 0 4px 8px;
        border-radius: 6px;
        padding: 4px 5px;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        transition: .3s;
        color: #fff;
        background: #3055d8;
    }
}

.quiz__qna {
    width: 100%;
    border-radius: 30px;
    -webkit-box-shadow: 0px 0px 20px #dbdbdb;
    box-shadow: 0px 0px 20px #dbdbdb;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background-color: #fff;
    padding: 32px;
}

.quiz__qna-title {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 16px;
}

.quiz__qna-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.quiz__qna-list-item {
    cursor: pointer;
    margin-bottom: 16px;
}

.quiz__qna-list-item-question {
    font-weight: 600;
    font-size: 28px;
    color: black;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    padding-left: 50px;
    position: relative;
    margin-bottom: 8px;
}

.quiz__qna-list-item-answer {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    padding-left: 50px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.7);

    p>a {
        color: rgb(23, 55, 170);
    }
}

.quiz__qna-list-item-close {
    position: absolute;
    width: 40px;
    height: 40px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
}

.quiz__qna-list-item-close:before {
    content: "";
    position: absolute;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    top: 50%;
    height: 24px;
    width: 3px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: black;
}

.quiz__qna-list-item-close:after {
    content: "";
    position: absolute;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    top: 50%;
    width: 24px;
    height: 3px;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: black;
}

.quiz__qna-list-item.active .quiz__qna-list-item-question {
    color: #3055d8;
}

.quiz__qna-list-item.active .quiz__qna-list-item-answer {
    max-height: initial;
    opacity: 1;
}

.quiz__qna-list-item.active .quiz__qna-list-item-close {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.quiz__qna-list-item.active .quiz__qna-list-item-close:before,
.quiz__qna-list-item.active .quiz__qna-list-item-close:after {
    background-color: #3055d8;
}

.template {
    .quiz__qna {
        background-color: initial;
        box-shadow: none;
        padding: 0;
    }

    .quiz__qna-list-item {
        margin-bottom: 20px;
        border: 1px solid rgba(48, 85, 216, 0.2);
        border-radius: 6px;
        padding: 32px 42px 32px 42px;
        position: relative;

        @media (max-width: $mq__mobile) {
            padding: 16px 21px;
            padding-right: 36px;
        }

        &.active {
            background: rgba(48, 85, 216, 0.07);
        }
    }

    .quiz__qna-list-item-close {
        right: 42px;
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDEzLjMwNEwwIDMuMzA0MDRMMi4zMzMzMyAwLjk3MDcwM0wxMCA4LjYzNzM3TDE3LjY2NjcgMC45NzA3MDNMMjAgMy4zMDQwNEwxMCAxMy4zMDRaIiBmaWxsPSIjMjIyMjIyIi8+Cjwvc3ZnPgo=);
        background-repeat: no-repeat;
        background-position: center;

        @media (max-width: $mq__mobile) {
            right: 10px;
            top: 20px;
            width: 20px;
            height: 20px;
        }

        &::before {
            content: none;
        }

        &::after {
            content: none;
        }
    }

    .quiz__qna-list-item.active .quiz__qna-list-item-close {
        transform: rotate(180deg);
        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAyMCAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEwIDEzLjMwNEwwIDMuMzA0MDRMMi4zMzMzMyAwLjk3MDcwM0wxMCA4LjYzNzM3TDE3LjY2NjcgMC45NzA3MDNMMjAgMy4zMDQwNEwxMCAxMy4zMDRaIiBmaWxsPSIjMzA1NUQ4Ii8+Cjwvc3ZnPgo=);
    }

    .quiz__qna-list-item-question {
        padding-left: 0;
        font-size: 30px;
        font-weight: 500;

        @media (max-width: $mq__mobile) {
            font-size: 18px;
        }
    }

    .quiz__qna-list-item-answer {
        padding-left: 0;
        max-width: 1200px;
        font-size: 24px;
        font-weight: 400;

        @media (max-width: $mq__mobile) {
            font-size: 16px;
        }
    }
}


.quiz__final-svg {
    margin: 68px auto 24px;
    width: 100px;
    height: 100px;

    @media (max-width: $mq__mobile) {
        width: 73px;
        height: 73px;
    }
}

.quiz__final-title {
    margin: 0 0 10px;
    font-weight: 700;
    font-size: 36px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #222222;
}

.quiz__final-text {
    margin-bottom: 48px;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
    text-align: center;
    color: #222222;
}

.quiz__step-final {
    margin: 0 auto 68px;
    position: relative;
}




@media (max-width: 1439px) {
    .quiz__body {
        min-height: 525px;
        width: 100%;
        height: auto;
        margin-right: 0;
    }

    .quiz__step-title {
        font-size: 20px;
    }

    .quiz__step-next {
        bottom: 16px;
        right: 16px;
        left: 16px;
        width: calc(100% - 32px);
        height: 48px;
        font-size: 14px;
        border-radius: 10px;
    }

    .btn-final {
        bottom: 16px;
        right: 16px;
        left: 16px;
        width: calc(100% - 32px);
        height: 48px;
        font-size: 14px;
        border-radius: 10px;
    }

    .quiz__step-column {
        width: 100%;
    }

    .quiz__step-column-item {
        width: 100%;
        font-size: 14px;
        height: unset;
        border-radius: 10px;
        padding: 14px 14px 14px 42px;
    }

    .quiz__step-columns {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .quiz__step-grid-item {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc((100% - 16px) / 2);
        flex: 0 0 calc((100% - 16px) / 2);
        width: calc((100% - 16px) / 2);
        border-radius: 8px;
        font-size: 13px;
        line-height: 1.2;
        height: 70px;
    }

    .quiz__step-grid-item:last-child {
        margin-bottom: 100px;
        width: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }

    .quiz__step-result {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    .quiz__step-result-form {
        margin: 0 0 20px;
        width: 100%;
        max-width: 500px;
    }

    .quiz__step-result-form-input {
        border-radius: 8px;
    }

    .quiz__step-result-right {
        width: 100%;
    }

    .quiz__step-result-row {
        margin-bottom: 8px;
    }

    .quiz__step-result-right-price {
        line-height: 21px;
        flex-wrap: wrap;
        margin-bottom: 2px;
    }

    .quiz__step-result-right-price .form,
    .quiz__step-result-right-price .to {
        font-size: 18px;
        line-height: 21px;
    }

    .quiz__step-result-right-text {
        font-size: 14px;
        margin-bottom: 20px;
    }

    .quiz__step-person-item {
        width: 100%;
        font-size: 14px;
        padding-left: 42px;
        border-radius: 8px;
    }

    .quiz__step-person-item:before {
        left: 14px;
    }

    .quiz__step-person-item:after {
        left: 18px;
    }

    .quiz__step-person-content {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 20px;
    }

    .quiz__step-person-col {
        width: 100%;
        -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    }

    .quiz__step-description-input {
        border-radius: 8px;
        height: 120px;
    }

    .quiz__chat {
        display: none;
    }

    .quiz__step-column-item:before {
        left: 14px;
    }

    .quiz__step-column-item:after {
        left: 18px;
    }

    .quiz__step-result-right-text {
        padding-bottom: 48px;
    }

    .quiz__step-person-col:last-of-type {
        padding-bottom: 100px;
    }
}

@media (max-width: 1024px) {
    .quiz__qna {
        padding: 16px;
    }

    .quiz__final-title {
        font-size: 20px;
        line-height: 120%;
        font-weight: 500;
        max-width: 250px;
        margin: 0 auto;
    }

    .quiz__final-text {
        font-size: 14px;
        line-height: 140%;
    }

    .quiz__step-result-form-label {
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 150%;
    }

    .quiz__step-subtitle {
        margin-bottom: 6px;
        font-size: 14px;
        line-height: 150%;
    }

    .quiz__step-result-form-input {
        padding: 10px 12px;
        font-size: 12px;
        line-height: 150%;
        height: 38px;
    }

    .quiz__step-result-form-input::placeholder {
        font-size: 12px;
        line-height: 150%;
    }

    .quiz__body {
        border-radius: 20px;
    }

    .quiz__step {
        padding: 16px;
    }

    .quiz__qna-title {
        font-size: 20px;
    }

    .quiz__qna-list-item-question {
        font-size: 18px;
        padding-left: 30px;
    }

    .quiz__qna-list-item-answer {
        font-size: 16px;
        padding-left: 30px;
    }

    .quiz__qna-list-item-close {
        width: 24px;
        height: 24px;
    }

    .quiz__qna-list-item-close:before {
        height: 14px;
        width: 2px;
    }

    .quiz__qna-list-item-close:after {
        width: 14px;
        height: 2px;
    }

    .quiz__step-person-group.show {
        .quiz__step-person-group-content {
            position: absolute;
            top: calc(100% + 16px);
            left: 0;
        }

        &:nth-of-type(3) {
            .quiz__step-person-group-content {
                width: 100%;
            }
        }
    }

    .quiz__step-message {
        padding: 0;
    }

    .quiz__step-result-form-policy {
        padding-left: 32px;
        font-size: 12px;
        line-height: 150%;

        a {
            padding-top: 3px;
            font-size: 12px;
        }
    }

    .quiz__modal {
        border-radius: 20px;

        &:before {
            display: none;
        }

        .quiz__step-next {
            position: absolute;
        }

        .btn-final {
            position: initial;
        }

        .quiz__modal-content button svg {
            margin: 0;
            margin-left: 10px;
        }

        &-window {
            padding: 16px;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            border-radius: 20px;
        }

        &-top {
            width: 100%;
            margin-bottom: 16px;
        }

        &-title {
            font-size: 20px;
            line-height: 120%;
        }

        &-close {
            width: 24px;
            height: 24px;
            padding: 5px;
        }

        &-time {
            max-width: 350px;
            margin-bottom: 16px;
            align-items: start;

            svg {
                width: 32px;
                height: 32px;
            }

            &-text {
                font-size: 12px;
            }
        }

        &-form {
            max-width: 350px;
        }
    }

    .quiz__step-grid {
        margin-bottom: 60px;
    }

    .quiz__step-four-grid {
        width: calc((100% - 10px)/ 2);
        margin-bottom: 10px;
        padding: 12px;
        height: 62px;
        font-size: 13px;
    }

    .quiz__step-two-grid {
        width: calc((100% - 10px)/ 2);
        margin-bottom: 10px;
        padding: 12px;
        height: 62px;
        font-size: 13px;
    }

    .quiz__step-one-grid {
        padding: 12px;
        height: 62px;
        font-size: 13px;
    }

    .quiz__step-half-row {
        max-width: initial;
    }

    .quiz__step-person {
        width: 100%;
    }

    .quiz__step-three-grid {
        width: calc((100% - 10px)/ 2);
        margin-bottom: 10px;
        padding: 12px;
        height: 62px;
        font-size: 13px;
    }

    .quiz__step-description-input.quiz__step-short-input {
        padding: 10px 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        height: 38px;
        border: 1px solid rgba(34, 34, 34, 0.3);
        border-radius: 6px;

        &::placeholder {
            font-weight: 500;
            font-size: 12px;
            line-height: 150%;
        }
    }
}

.quiz-pages {
    background-color: initial;

    .quiz__qna-list-item-answer {
        font-size: 20px;

        @media (max-width: $mq__mobile) {
            font-size: 16px;
        }
    }

    .quiz__qna-list-item.active .quiz__qna-list-item-answer {
        @media (max-width: $mq__mobile) {
            max-height: initial;
        }
    }
}

@media (max-width: 767px) {
    .quiz__modal-window {
        padding-top: 24px;
    }

    .quiz__step-result-form-input {
        height: 46px;
        font-size: 12px;
        line-height: 150%;
    }
}

#quiz-without-chat {
    .quiz__body {
        width: 100%;
        margin-right: 0;
    }

    .quiz__chat {
        display: none;
    }
}