.block-form{
    background: rgba(48, 85, 216, 0.1);
    @media (max-width: $mq__mobile) {
        padding-bottom: 32px;
    }

    &__row{
        display: flex;
        gap: 64px;
        justify-content: space-between;
        @media (max-width: $mq__mobile) {
            flex-direction: column-reverse;
            gap: 16px;
            margin-top: -8px;
            padding-bottom: 61px;
            position: relative;
        }
    }

    &__row-dop{
        display: flex;
        gap: 42px;
        justify-content: space-between;
        @media (max-width: $mq__mobile) {
           flex-direction: column;
           gap: 0; 
        }
    }

    &__text{
        max-width: 550px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 10px;

        p{
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            margin: 0;
            @media (max-width: $mq__mobile) {
               font-size: 14px; 
            }
        }
    }

    &__form {
        padding: 32px;
        width: 100%;
        max-width: 726px;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        align-self: start;
        z-index: 1;
        @media (max-width: $mq__mobile) {
            padding: 24px 16px;
        }
        form{
            display: flex;
            gap: 30px;
            flex-direction: column;
            @media (max-width: $mq__mobile) {
                gap: 0px;
            }
            .form-field{
                font-family: 'TT Hoves',sans-serif,BlinkMacSystemFont,-apple-system,'Segoe UI',Helvetica,Arial,sans-serif;
                height: 36px;
                width: 100%;
                border: none;
                color: black;
                font-family: 'TT Hoves';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                border-bottom: 1px solid #2222224D;
                &::placeholder{
                    color: #2222224D;
                }
                &:focus, &:focus-visible{
                    outline: none;
                }
                @media (max-width: $mq__mobile) {
                    height: 32px;
                    font-size: 12px;
                    line-height: 150%;
                    margin-bottom: 32px;
                }

                &.error {
                    & + .form-error {
                        display: block;
                    }
                }
            }

            .form-error {
                display: none;
                font-size: 16px;
                margin-top: 6px;
                color: red;
            }

            .container-field{
                position: relative;
                max-width: 100%;
                width: 100%;
                &::after{
                    content: '*';
                    position: absolute;
                    right: 17px;
                    top: 8px;
                    font-size: 22px;
                    color: red;
                    display: flex;
                    align-items: center;
                    @media (max-width: $mq__mobile) {
                        right: 5px;
                    }
                }
            }

            button{
                height: 56px;
                border: none;
                max-width: 264px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #FFFFFF;
                background: #d62b2b;
                border-radius: 20px;
                @media (max-width: $mq__mobile) {
                    max-width: 100%;
                    height: 45px;
                    margin-top: 15px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    border-radius: 10px;
                    height: 45px;
                }
                &:hover{
                    opacity: 0.5;
                    transition-duration: 0.6s;
                }
            }

            .quiz__step-result-form-policy{
                pointer-events: initial;
                font-size: 18px;
                @media (max-width: $mq__mobile) {
                    font-size: 12px;
                    margin-top: 15px;
                }
                a{
                    color: #006ff7;
                    border-bottom: 1px solid #006ff7;
                    @media (max-width: $mq__mobile) {
                        border-bottom: none;
                    }
                }
                .checkmark{
                    top: 4px;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(0, 0, 0, 0.5);
                    &::after{
                        top: 1px;
                        left: 5px;
                        width: 5px;
                        height: 10px;
                        border-color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
    }
}