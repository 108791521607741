.logos {
    max-width: 820px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55px;

    @media (max-width: $mq__tablet) {
        margin-bottom: 30px;
    }

    @media (max-width: $mq__mobile) {
        margin-bottom: 20px;
    }

    &__list {
        margin: 0;
        padding: 0;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        justify-content: center;
        align-items: center;
    }

    &__item {
        margin: 20px 18px;

        @media (max-width: $mq__tablet) {
            max-width: 100px;
        }

        @media (max-width: $mq__mobile) {
            max-width: 66px;
        }
    }
}