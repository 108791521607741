.more-link {
    display: inline-flex;
    align-items: center;

    &_space-t {
        margin-top: 50px;
    }

    &__arrow {
        line-height: 3px;
        display: inline-block;
        height: 10px;
        position: relative;
        top: 1px;
        margin-left: 4px;
        font-size: 17px;

        @media (max-width: $mq__mobile) {
            top: 2px;
            font-size: 14px;
        }
    }
}
