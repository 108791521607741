.tools {
    user-select: none;
    &__row {
        padding: 24px;
        margin: 0 -24px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 24px;

        @media (max-width: $mq__laptop) {
            flex-wrap: wrap;
        }

        @media (max-width: $mq__tablet) {
            grid-template-columns: repeat(2, 1fr);
            padding: 12px 16px;
            margin: 0 -16px;
            gap: 16px;
        }
    }

    &__more {
        margin-top: 24px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #3055D8;
        svg {
            margin-left: 16px;
            width: 28px;
            height: 28px;
        }
        @media (max-width: $mq__mobile) {
            font-size: 12px;
            margin-top: 6px;
            justify-content: center;
            svg {
                margin-left: 6px;
                width: 18px;
                height: 18px;
            }
        }
    }

    &__item {
        width: 100%;
        height: 222px;
        display: flex;
        align-items: center;
        position: relative;

        @media (max-width: $mq__mobile) {
            height: 122px;

            .tools__card {
                width: 100%;
            }

            &:last-of-type {
                width: calc(200% + 16px);
            }
        }

        &.open {
            z-index: 10;
            @media (max-width: $mq__mobile) {

                &:nth-of-type(odd) {
                    .tools__card {
                        left: 0;
                    }
                }
                &:nth-of-type(even) {
                    .tools__card {
                        right: 0;
                        left: auto;
                    }
                }

                &:last-of-type {
                    .tools__card {
                        width: 100%;
                    }
                }

                .tools__close {
                    display: block;
                    padding: 0;
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    color: #EEF2F5;
                    background: transparent;
                    border: none;
                    right: 21px;
                    top: 21px;
                    cursor: pointer;
                    z-index: 1;
                }
            }

            .tools__more {
                display: none;
            }

            .tools__card {
                background: #3055D8;
                transition: $animation__duration_slow;

                @media (max-width: $mq__mobile) {
                    width: calc(200% + 16px);
                    justify-content: start;
                    padding: 32px;
                    height: auto;
                }
            }

            .tools__caption {
                transition: $animation__duration_slow;
                color: #EEF2F5;
                align-items: start;
                text-align: left;
                margin-bottom: 24px;

                @media (max-width: $mq__mobile) {
                    font-size: 24px;
                    line-height: 28px;
                }

                &_icon {
                    display: none;
                }
            }

            .tools__text {
                height: auto;
                opacity: 1;
                transition: $animation__duration_slow;
            }
        }
    }

    &__close {
        display: none;
    }

    &__card {
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        flex-shrink: 0;
        padding: 32px;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        overflow: hidden;
        cursor: pointer;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            height: 122px;
            padding: 16px;
            display: flex;
            justify-content: start;
            flex-direction: column;
        }

    }

    &__caption {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-weight: 600;
        font-size: 36px;
        line-height: 38px;
        letter-spacing: 0.03em;
        color: #3055D8;
        text-align: center;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            flex-direction: column;
        }

        &_icon {
            flex-shrink: 0;
            width: 50px;
            height: 50px;
            margin-bottom: 24px;

            @media (max-width: $mq__mobile) {
                width: 24px;
                height: 24px;
                margin-right: 0;
                margin-bottom: 12px;
            }
        }
    }

    &__text {
        height: 0;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #EEF2F5;
        opacity: 0;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
        }
    }
}

