.services {
    user-select: none;

    @media (max-width: $mq__mobile) {
        .services__btn {
            display: none !important;
        }

        .services__wrapper {
            transform: none !important;
            padding: 0;
            display: grid;
            gap: 16px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: start;
        position: relative;
        margin: -30px;

        @media (max-width: $mq__mobile) {
            margin: 0;
        }
    }

    &__box {
        display: flex;
        justify-content: space-between;
        align-items: start;
        z-index: 2;
        padding: 0 30px;

        @media (max-width: $mq__mobile) {
            padding: 0;
            overflow: initial !important;
        }
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: start;
    }

    &__item {
        height: 264px;
        width: 380px;
        margin-right: 16px;
        display: flex !important;
        align-items: center;
        position: relative;

        @media (max-width: $mq__mobile) {
            width: auto;
            margin-right: 0;
            height: auto;

            .services__card {
                width: 100%;
            }
        }

        &.open {
            z-index: 10;

            @media (max-width: $mq__mobile) {
                height: 100px;

                &:nth-of-type(odd) {
                    .services__card {
                        left: 0;
                    }
                }

                &:nth-of-type(even) {
                    .services__card {
                        right: 0;
                    }
                }
            }

            .services__more {
                display: none;
            }

            .services__card {
                position: relative;
                height: auto;
                background: #3055D8;
                transition: $animation__duration_slow;

                @media (max-width: $mq__mobile) {
                    justify-content: start;
                    padding: 16px;
                    width: 288px;
                    height: auto;
                    position: absolute;
                    top: 0;
                }
            }

            .services__caption {
                transition: $animation__duration_slow;
                color: #EEF2F5;

                @media (max-width: $mq__mobile) {
                    padding-right: 34px;
                    text-align: left;
                    font-size: 20px;
                    line-height: 24px;
                }
            }

            .services__text {
                height: auto;
                opacity: 1;
                margin-top: 12px;
                transition: $animation__duration_slow;

                @media (max-width: $mq__mobile) {
                    display: block;
                    margin-top: 16px;
                }
            }
        }
    }

    &__close {
        position: absolute;
        top: 24px;
        right: 24px;
        width: 24px;
        height: 24px;
        padding: 5px;
        color: #EEF2F5;
        background: transparent;
        border: 0;
        cursor: pointer;
        z-index: 10;

        @media (max-width: $mq__mobile) {
            display: none;
        }
    }

    &__card {
        width: 380px;
        height: 178px;
        flex-shrink: 0;
        padding: 32px;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        overflow: hidden;
        cursor: pointer;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            padding: 16px;
            margin-right: 0;
            width: 136px;
            height: 100px;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            border-radius: 20px;
        }
    }

    &__caption {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        letter-spacing: 0.03em;
        color: #3055D8;
        max-width: 240px;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.02em;
            text-align: center;
            word-break: break-word;
        }
    }

    &__text {
        height: 0;
        font-weight: 500;
        font-size: 15px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #EEF2F5;
        opacity: 0;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            display: none;
            font-size: 14px;
        }
    }

    &__more {
        margin-top: 24px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #3055D8;

        svg {
            margin-left: 16px;
            width: 28px;
            height: 28px;
        }

        @media (max-width: $mq__mobile) {
            font-size: 12px;
            margin-top: 6px;
            justify-content: center;

            svg {
                margin-left: 6px;
                width: 18px;
                height: 18px;
            }
        }
    }

    &__link {
        margin-top: 16px;
        display: flex;
        justify-content: end;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #eef2f5;

        svg {
            margin-left: 16px;
            width: 28px;
            height: 28px;
        }

        @media (max-width: $mq__mobile) {
            font-size: 12px;

            svg {
                margin-left: 8px;
                width: 18px;
                height: 18px;
            }
        }
    }

    &__modal {
        position: fixed;
        display: none;
        align-items: stretch;
        border-radius: 30px;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        margin: 0 auto;
        max-width: 288px;
        height: 100%;
        width: 100%;
        max-height: 214px;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        transition: $animation__duration_slow;

        &.is-display {
            display: block;
            opacity: 1;
            visibility: initial;
            transition-duration: $animation__duration_slow;
            animation: is-display $animation__duration_slow linear;
        }

        &_close {
            position: absolute;
            top: 11px;
            right: 11px;
            width: 24px;
            height: 24px;
            padding: 5px;
            color: #EEF2F5;
            background: transparent;
            border: 0;
            cursor: pointer;
            z-index: 10;
        }

        .services__card {
            box-shadow: none;
        }
    }
}

.template {
    .services__item {
        height: 210px;

        @media (max-width: $mq__mobile) {
            height: auto;
        }
    }

    .services__card {
        height: auto;
    }

    .services__more {
        margin-top: 10px;
    }

    .services__row {
        @media (max-width: $mq__mobile) {
            display: block;
        }
    }
}