.breadcrumbs {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0 50px;
    max-width: var(--max_body_width);
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
    font-size: 16px;
    line-height: 19px;

    @media (max-width: $mq__tablet) {
        padding: 0 16px;
        margin-top: 16px;
        margin-bottom: 16px;
        overflow: auto;
        max-width: 100vw;
        padding-bottom: 12px;
    }

    a {
        color: #3055D8;

        @media (max-width: $mq__tablet) {
            white-space: nowrap;
        }
    }

    &__text {
        @media (max-width: $mq__mobile) {
            display: none;
        }
    }

    &__separator-mob {
        height: 19px;
        width: 19px;
        max-height: 19px;
        max-width: 19px;
        display: none;
        transform: rotate(180deg);

        @media (max-width: $mq__mobile) {
            display: block;

            svg {
                min-width: 19px;
            }

            &:first-child {
                display: none;
            }
        }
    }

    &__separator {
        height: 19px;
        width: 19px;
        max-height: 19px;
        max-width: 19px;

        @media (max-width: $mq__mobile) {
            display: none;
        }
    }
}