.header {
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
    //position: relative;
    width: 100%;
    font-size: var(--font-size_nav);
    align-items: center;


    @media (max-width: $mq__tablet) {
        height: 60px;

        &.is-open {
            .header__menu {
                left: 0;
            }
        }
    }

    &_style_cn &__buttons {

        @media (max-width: $mq__tablet) {
            top: 9px;
        }

        @media (max-width: $mq__tablet) {
            top: 4px;
        }
    }

    &_style_fix-height {
        height: 77px;
    }

    &__item {
        &_btn {
            background: none;
            border: none;
            position: relative;
            z-index: 3;

            &:hover {
                opacity: 0.8;
            }


            span {
                background-color: #3055D8;
                color: #EEF2F5;
                margin-left: 16px;
                max-width: 75px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    min-width: 26px;
                }
            }

            @media (max-width: $mq__tablet) {
                span {
                    display: none !important;
                }
            }

            >svg {
                display: none;

                @media (max-width: $mq__tablet) {
                    display: block;
                    width: 32px;
                    height: 32px;
                    background: #EEF2F5;
                    border-radius: 50%;
                }
            }
        }

        &_logo {
            padding-right: 30px;
            display: -webkit-flex;
            display: flex;
            align-items: center;
            flex-grow: 1;

            @media (max-width: $mq_xxs) {
                padding-right: 10px;
            }
        }

        &_content {
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @media (max-width: $mq__tablet) {
                padding-right: 50px;
            }
        }
    }

    &__lang {
        display: -webkit-flex;
        display: flex;
        align-items: center;

        @media (max-width: $mq__tablet) {
            position: absolute;
            top: 85px;
            right: 0;
        }

        @media (max-width: $mq__tablet) {
            top: 69px;
        }
    }

    &__info {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        position: relative;
        top: 26px;
    }

    &__buttons {
        display: -webkit-flex;
        display: flex;
        align-items: center;
    }

    &__menu-button {
        display: none;
        margin-right: 20px;

        @media (max-width: $mq__tablet) {
            display: -webkit-flex;
            display: flex;
        }

        @media (max-width: $mq_xxs) {
            margin-right: 10px;
        }
    }

    &__qr {
        width: 60px;
        height: 60px;
        margin-right: 10px;
    }

    &__info-wrapper {
        margin-left: 8px;
        display: -webkit-flex;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @media (max-width: $mq__tablet) {
            margin-left: 0;
        }
    }

    &__info-link {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #EEF2F5;

        &:first-child {
            margin-bottom: 6px;
        }
    }

    .nav {
        @media (max-width: $mq__tablet) {
            display: none;
        }
    }

    &__socials {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-right: 16px;
        height: 38px;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #EEF2F5;

        @media (max-width: $mq__laptop) and (min-width: $mq__tablet) {
            margin-right: 6px;
        }

        .telegram-contact {
            background: transparent url('../img/telegram.svg') center no-repeat;
            width: 20px;
            height: 24px;
            display: block;

            @media (max-width: $mq__tablet) {
                width: 25px;
                height: 32px;
            }
        }

        .whatsapp-contact {
            background: transparent url('../img/whatsapp.svg') center no-repeat;
            width: 24px;
            height: 24px;
            display: block;

            @media (max-width: $mq__tablet) {
                width: 32px;
                height: 32px;
            }
        }

        &-list {
            display: -webkit-flex;
            display: flex;

            @media (max-width: $mq__tablet) {
                margin-bottom: 24px;
            }
        }

        &-mobile {
            display: none;

            .header__socials {
                margin-right: 0;
                margin-left: 6px;
                background: none;
                background: none;
                border: none;
                padding: 0;
            }

            .telegram-contact {
                width: 32px;
                height: 32px;
            }

            .whatsapp-contact {
                width: 38px;
                height: 38px;
                margin-top: -3px;
            }

            @media (max-width: $mq__tablet) {
                display: -webkit-flex;
                display: flex;
                margin-top: 6px;
            }
        }
    }

    &__menu {
        display: -webkit-flex;
        display: flex;
        justify-content: end;
        width: 100%;
        align-items: center;

        @media (max-width: $mq__tablet) {
            position: fixed;
            left: -100vw;
            top: 1px;
            height: 100vh;
            width: 100vw;
            padding: 16px 0;
            align-items: start;
            flex-direction: column;
            justify-content: start;
            background: #222;
            z-index: 100;
            transition: all .3s ease;
        }

        .menu-mobile {
            display: none;

            @media (max-width: $mq__tablet) {
                display: block;
            }
        }
    }

}

.section_header .section__body {
    @media (max-width: $mq__laptop) {
        padding: 0 16px;
    }
}


.small-mobile-menu {
    display: none;
}

@media (max-width: 767px) {
    .small-mobile-menu {
        display: block;
        position: fixed;
        left: 0;
        bottom: 0;
        background: #28314E;
        padding: 4px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 19px;
        max-width: 100%;
        width: 100%;
        z-index: 103;

        &__icon-v2 {
            display: none;
        }

        .is-open {
            background: #3055D8;

            .small-mobile-menu__icon-v1 {
                display: none;
            }

            .small-mobile-menu__icon-v2 {
                display: block;
            }
        }

        .active {
            background: #3055D8;

            .small-mobile-menu__icon-v1 {
                display: none;
            }

            .small-mobile-menu__icon-v2 {
                display: block;
            }
        }

        button,
        a {
            background: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            outline: none;
            border: none;
            gap: 2px;
            padding: 6px;
            min-width: 83px;
            border-radius: 6px;
            transition: background 0.5s ease;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            color: #EEF2F5;

            svg {
                min-width: 18px;
                min-height: 18px;
                max-width: 18px;
                max-height: 18px;
            }

            p {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                color: #EEF2F5;
            }
        }
    }
}

.social-modal {
    display: none;
    position: fixed;
    bottom: 58px;
    left: 0;
    max-width: 100%;
    width: 100%;
    background: #222;
    padding: 16px;
    z-index: 103;
    opacity: 0;
    animation: opacity-out .5s forwards;

    &__item {
        display: flex;
        flex-direction: column;
        gap: 16px;

        button {
            margin-top: 4px;
            margin-bottom: 20px;
            background: #3055D8;
            font-size: 16px;
            display: none;
        }

        a {
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 10px 8px;

            svg {
                min-width: 30px;
                min-height: 30px;
                max-width: 30px;
                max-height: 30px;
            }

            p {
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0.16px;
                color: white;
            }
        }

    }
}

.social-modal.active {
    display: block;
    animation: opacity-in .5s forwards;
}

@keyframes opacity-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes opacity-out {
    0% {
        opacity: 1;
        display: block;
    }

    100% {
        opacity: 0;
        display: none;
    }
}