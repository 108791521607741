.simple-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    &_inline {
        display: inline-block;
    }

    &_space_right {
        margin-right: 58px;

        @media (max-width: $mq__mobile) {
            margin-right: 30px;
        }
    }

    &__item {
        margin: 0 0 10px 0;
    }

}
