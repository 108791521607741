.team {
    padding-bottom: 0;
    user-select: none;

    &.full-width {
        .team__wrapper {
            display: grid;
            gap: 16px;
            grid-template-columns: repeat(3, 1fr);

            @media (max-width: $mq__mobile) {
                display: flex;
                gap: 0;
            }
        }

        .team__card {
            width: 100%;
            margin-right: 0;

            @media (max-width: $mq__mobile) {
                width: 260px;
                margin-right: 16px;
            }
        }
    }

    &.with-arrow {
        @media (max-width: $mq__mobile) {
            .team__btn {
                display: none !important;
            }

            .team__wrapper {
                transform: none !important;
                flex-direction: column;
            }

            .team__item {
                padding-bottom: 64px;
                height: 100%;
            }

            .team__item:last-child {
                padding-bottom: 0px;
            }

            .team__list {
                padding: 0;
            }

            .team__card {
                width: 100%;
            }

            .team__caption {
                width: 100%;

            }
        }

        .team__item {
            &:not(:last-of-type) {
                .team__card {
                    margin-right: 100px;

                    @media (max-width: $mq__mobile) {
                        margin-right: 0;

                    }
                }
            }
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: start;
        position: relative;
        margin: -30px;

        @media (max-width: $mq__mobile) {
            margin: -16px;
        }

        .swiper-button {
            top: 148px;

            @media (max-width: $mq__mobile) {
                top: 110px;
            }
        }
    }

    &__box {
        padding: 30px;

        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__item {
        width: auto;
        height: 420px;
        display: flex !important;
        align-items: start;

        @media (max-width: $mq__mobile) {
            height: auto;
        }
    }

    &__arrow {
        position: absolute;
        right: 20px;
        top: 45px;
        bottom: 0;
        margin: auto;
        width: 60px;
        height: 60px;
        color: #3055D8;

        @media (max-width: $mq__mobile) {
            transform: rotate(90deg);
            left: 0;
            right: 0;
            bottom: 16px;
            top: auto;
            margin: 0 auto;
            width: 32px;
            height: 32px;
        }
    }

    &__card {
        width: 368px;
        flex-shrink: 0;
        margin-right: 16px;
        border-radius: 30px;
        position: relative;
        transition: $animation__duration_slow;
        cursor: pointer;
        box-sizing: border-box;

        @media (max-width: $mq__mobile) {
            width: 260px;
        }

        &.open {
            @media (max-width: $mq__mobile) {
                margin-right: 0;
                width: 100%;
            }

            .team__caption {
                color: #EEF2F5;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                @media (max-width: $mq__mobile) {
                    margin-top: 24px;
                    height: 100px;
                    padding: 32px 16px 20px;
                    width: 100%;
                }
            }

            .team__more {
                display: none;
            }

            .team__list {
                height: initial;
                opacity: 1;
                box-shadow: 0 13px 15px #bebebe;

                @media (max-width: $mq__mobile) {
                    height: initial;
                    box-shadow: none;
                    padding: 16px 16px 32px;
                }
            }

            .team__show {
                @media (max-width: $mq__mobile) {
                    top: -24px;
                }

                &_icon {
                    display: none;
                }

                span {
                    @media (max-width: $mq__mobile) {
                        display: none;
                    }
                }

                &_close {
                    display: block;
                    cursor: pointer;
                }
            }
        }
    }

    &__more {
        margin-top: 24px;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #EEF2F5;

        svg {
            margin-left: 16px;
            width: 28px;
            height: 28px;
        }

        @media (max-width: $mq__mobile) {
            font-size: 12px;
            margin-top: 6px;

            svg {
                margin-left: 8px;
                width: 18px;
                height: 18px;
            }
        }
    }

    &__show {
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 0;
        padding: 24px;
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: #FFFFFF;
        font-weight: 600;
        font-size: 24px;
        line-height: 150%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #3055D8;
        border: 3px solid #3055D8;

        @media (max-width: $mq__mobile) {
            width: 48px;
            height: 48px;
            padding: 12px;
            border-width: 2px;
        }

        &_icon {
            cursor: pointer;
        }

        &_close {
            display: none;
        }
    }

    &__caption {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        margin-top: 54px;
        font-weight: 600;
        font-size: 26px;
        line-height: 31px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #EEF2F5;
        padding: 32px 24px 24px;
        background: #3055D8;
        border-radius: 30px;
        transition-property: background-color;
        min-height: 160px;

        @media (max-width: $mq__mobile) {
            min-height: initial;
            margin-top: 32px;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            padding: 16px;
            width: 260px;
            height: 122px;
        }
    }

    &__list {
        height: 0;
        margin: 0;
        padding: 16px 24px 32px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: rgba(0, 0, 0, 0.6);
        background: #fff;
        opacity: 0;
        transition-property: opacity, height;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
            padding: 16px 16px 32px;
        }
    }

    &__text {
        margin-bottom: 0;
        margin-left: 20px;
    }

    &__modal {
        position: fixed;
        display: none;
        align-items: stretch;
        border-radius: 30px;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        margin: 0 auto;
        max-width: 288px;
        height: 100%;
        width: 100%;
        max-height: 214px;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        transition: $animation__duration_slow;

        &.is-display {
            display: block;
            opacity: 1;
            visibility: initial;
            transition-duration: $animation__duration_slow;
            animation: is-display $animation__duration_slow linear;
        }

        &_close {
            position: absolute;
            top: 11px;
            right: 11px;
            width: 24px;
            height: 24px;
            padding: 5px;
            color: #EEF2F5;
            background: transparent;
            border: 0;
            cursor: pointer;
            z-index: 10;
        }

        .services__card {
            box-shadow: none;
        }
    }
}

.seo-page {
    .swiper-button {
        top: 0;
        transform: none;
        width: 30px;
        height: 30px;
        background: #3055D8;
        border: 1px solid #3055D8;

        svg {
            color: #fff;
            width: 14px;
            height: 14px;
        }

        &.swiper-button-disabled {
            display: flex;
            background: #fff;

            svg {
                color: #3055D8;
            }
        }
    }

    .swiper-button-prev {
        left: initial;
        right: 42px;

        @media (max-width: $mq__mobile) {
            right: 56px;
        }

        svg {
            transform: rotate(90deg);
        }
    }

    .swiper-button-next {
        right: 0;

        @media (max-width: $mq__mobile) {
            right: 16px;
        }

        svg {
            transform: rotate(-90deg);
        }
    }

    .team {
        &__caption {
            height: 251px;
        }

        &__more {
            width: 100%;
            border: 1.5px solid #EEF2F5;
            border-radius: 20px;
            padding: 16px;
            justify-content: center;
        }

        &__box {
            padding-top: 60px;
        }

        &__card {
            &.open {
                .team__caption {
                    height: 98px;
                    padding-bottom: 0;
                    box-shadow: 0 8px 15px #bebebe;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;
                    color: #3055D8;
                    background: #fff;
                }
            }
        }
    }
}

.template .team {
    padding-bottom: 64px;

    @media (max-width: 1024px) {
        padding-bottom: 16px;
    }

    &__row {
        @media (max-width: $mq__mobile) {
            display: block;
        }
    }

    &__item {
        height: initial;
    }

    &__caption {
        justify-content: center;
        padding: 30px 16px 20px 16px;
    }
}