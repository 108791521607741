.promo {
    padding: 89px 0;
    overflow: hidden;
    position: relative;

    @media (max-width: $mq__tablet) {
        padding: 20px 0 20px;
    }

    &-home {
        padding-top: 30px;
        padding-bottom: 70px;

        @media (max-width: $mq__tablet) {
            padding: 20px 0 20px;
        }
    }

    /*
    &-projects{
        padding: 221.5px 0;
        @media (max-width: $mq__tablet) {
            padding: 64px 0 78px;
        }
    }
    */

    &__title {
        max-width: 1150px;
        color: rgba(255, 255, 255, 0.9);
        text-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
        font-size: 55px;
        line-height: 65px;
        letter-spacing: 0.01em;

        @media (max-width: $mq__tablet) {
            font-size: 32px;
            line-height: 130%;
        }

        &-home {
            font-size: 38px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.38px;
            margin-bottom: 16px;

            @media (max-width: $mq__tablet) {
                font-size: 20px;
            }
        }

        &-seo {
            font-size: 18px;
            font-weight: 400;
            margin-bottom: 10px;
            color: rgba(255, 255, 255, .3);

            @media (max-width: $mq__tablet) {
                font-size: 16px;
            }
        }
    }

    &__text {
        margin: 0 0 64px;
        max-width: 1150px;
        font-weight: 400;
        font-size: 36px;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.9);
        text-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
        letter-spacing: 0.01em;

        @media (max-width: $mq__tablet) {
            margin-bottom: 48px;
            font-size: 16px;
            line-height: 130%;
        }

        &-home {
            font-size: 24px;
            font-style: normal;
            font-weight: 500;

            @media (max-width: $mq__tablet) {
                font-size: 18px;
                margin-bottom: 20px;
            }
        }

        &-small {
            margin: 0 0 32px;
            max-width: 1150px;
            font-weight: 400;
            font-size: 26px;
            line-height: 140%;
            color: rgba(255, 255, 255, 0.9);
            text-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
            letter-spacing: 0.01em;

            @media (max-width: $mq__tablet) {
                margin-bottom: 24px;
                font-size: 16px;
                line-height: 130%;
            }
        }
    }

    &__arrow {
        position: absolute;
        width: 226.584px;
        height: 92.291px;
        bottom: 75px;
        left: 40%;

        &-mob {
            display: none;

            @media (max-width: $mq__mobile) {
                display: block;
            }
        }

        @media (max-width: $mq__mobile) {
            position: initial;
            display: flex;
            flex-direction: column-reverse;
            margin: 0 auto;
            gap: 15px;
        }

        &-desk {
            width: 160px;
            height: 60px;
            margin: 0 auto;

            @media (max-width: $mq__mobile) {
                display: none;
            }
        }

        p {
            text-align: center;
            margin-top: 8px;
            font-size: 20px;
            font-weight: 500;

            @media (max-width: $mq__mobile) {
                margin-top: 0;
            }
        }
    }

    &__block {
        border-radius: 38px;
        background: #FFF;
        box-shadow: 14px 14px 0px 0px rgba(255, 255, 255, 0.20);
        padding: 24px 28px 32px 28px;
        height: fit-content;
        max-width: 577px;
        width: 100%;
        //min-height: 208px;
        display: flex;
        flex-direction: column;

        @media (max-width: $mq__tablet) {
            padding: 10px;
            min-height: initial;
            border-radius: 16px;
            box-shadow: 0px 10px 0px 0px rgba(255, 255, 255, 0.20);
        }

        h3 {
            font-size: 32px;
            font-weight: 500;
            line-height: 150%;
            margin-bottom: 14px;
            color: black;
            text-align: center;

            @media (max-width: $mq__mobile) {
                margin-top: 14px;
                font-size: 22px;
                font-weight: 500;
                line-height: 130%;
            }
        }

        >ul {
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin: 0;
            padding: 0;
            margin-bottom: 30px;

            @media (max-width: $mq__tablet) {
                gap: 10px;
                margin-bottom: 14px;
            }

            li {
                display: flex;
                gap: 12px;
                border-radius: 14px;
                background: #F1F6FF;
                padding: 8px 18px 8px 14px;

                @media (max-width: $mq__tablet) {
                    gap: 8px;
                    padding: 10px 16px 10px 10px;
                }

                p {
                    color: rgba(0, 0, 0, 0.80);
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 140%;
                    margin: 0;
                    text-align: left;

                    @media (max-width: $mq__tablet) {
                        font-size: 14px;
                    }

                    .b-blue {
                        color: #3055D8;
                        font-weight: 500;
                    }
                }

                svg {
                    min-width: 16px;
                    min-height: 12px;
                    max-width: 16px;
                    max-height: 12px;
                    margin-top: 6px;

                    @media (max-width: $mq__tablet) {
                        min-width: 14px;
                        min-height: 10px;
                        max-width: 14px;
                        max-height: 10px;
                        margin-top: 5px;
                    }
                }
            }
        }

        p {
            margin-bottom: 20px;
            font-size: 32px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            color: black;
            text-align: center;

            @media (max-width: $mq__tablet) {
                margin-bottom: 10px;
                font-size: 18px;
            }
        }

        a {
            max-width: 490px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 20px;
            padding: 33px 35px;
            background: linear-gradient(267deg, #672A8D -11.98%, #9742CC 9.04%, #9776E8 58.92%, #3055D8 121.97%);
            box-shadow: 6px 8px 0px 0px #DADDE9;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 149.3%;
            color: white;
            gap: 6px;
            margin: 0 auto;

            @media (max-width: $mq__tablet) {
                font-size: 14px;
                max-width: 100%;
                width: 100%;
                padding: 0;
                border-radius: 16px;
                box-shadow: 0px 4px 0px 0px #DADDE9;
            }

            svg {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__row {
        display: flex;
        gap: 148px;
        position: relative;

        @media (max-width: $mq__tablet) {
            flex-direction: column;
            gap: 20px;
        }

        >svg {
            position: absolute;
            width: 226.584px;
            height: 92.291px;
            bottom: -23px;
            left: 40%;

            @media (max-width: $mq__tablet) {
                display: none;
            }
        }

        >ul {
            padding: 0;
            max-width: 570px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 12px;
            margin: 0;

            @media (max-width: $mq__tablet) {
                padding-top: 0px;
                gap: 10px;
            }


            li {
                display: flex;
                border-radius: 16px;
                background: rgba(255, 255, 255, 1);
                backdrop-filter: blur(30.5px);
                padding: 4px 18px 4px 12px;
                color: rgba(0, 0, 0, 1);
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                max-width: fit-content;
                cursor: pointer;

                @media (max-width: $mq__tablet) {
                    font-size: 16px;

                    br {
                        display: none;
                    }
                }

                svg {
                    min-width: 28px;
                    min-height: 28px;
                    max-width: 28px;
                    max-height: 28px;
                    padding-right: 12px;
                }
            }
        }
    }

    &__btn.button {
        font-weight: 500;
        font-size: 20px;
        height: 62px;
        line-height: 62px;
        padding: 0 36px;

        @media (max-width: $mq__tablet) {
            font-size: 12px;
            height: 38px;
            line-height: 38px;
            padding: 0 20px;
        }

        @media (max-width: 767px) {
            display: table;
        }

        &.promo__btn-blue {
            margin-left: 32px;
            background: #3055d8;

            @media (max-width: 767px) {
                margin-left: 0;
                margin-top: 12px;
            }
        }
    }

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.promo-pages {
    .promo__title {
        margin-bottom: 64px;

        @media (max-width: $mq__tablet) {
            margin-bottom: 104px;
        }
    }
}

.seo-page {
    .section__description {
        max-width: 1200px;
    }

    .promo {
        padding: 103px 0;
        background: rgba(34, 34, 34, 0.95);

        &__icon-top {
            position: absolute;
            top: 0;
            right: 0;
            mix-blend-mode: lighten;
        }

        &__icon-bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            mix-blend-mode: lighten;
        }

        &__btn {
            z-index: 1;
        }

        .breadcrumbs {
            margin-bottom: 64px;
            padding: 0;
            z-index: 1;

            a {
                color: rgba(255, 255, 255, 0.9);
            }

            &__text {
                color: rgba(255, 255, 255, 0.3);
            }
        }
    }
}

.template {
    .promo {
        padding: 84px 0;
        background: rgba(34, 34, 34, 0.95);

        @media (max-width: $mq__mobile) {
            padding: 30px 0;
        }

        &__title {
            margin-bottom: 16px;
        }

        &__desc {
            max-width: 1070px;
            color: rgba(255, 255, 255, 0.90);
            text-shadow: 0px 0px 40px rgba(0, 0, 0, 0.40);
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            letter-spacing: 0.24px;
            margin-bottom: 64px;

            @media (max-width: $mq__mobile) {
                font-size: 16px;
                margin-bottom: 30px;
            }
        }

        &__icon-top {
            position: absolute;
            top: 0;
            right: 0;
            mix-blend-mode: lighten;
        }

        &__icon-bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            mix-blend-mode: lighten;
        }

        &__btn {
            z-index: 1;
        }

        .breadcrumbs {
            margin-bottom: 4px;
            margin-top: 0;
            padding: 0;
            position: relative;
            z-index: 10;

            @media (max-width: $mq__mobile) {
                padding-bottom: 12px;
            }

            a {
                color: rgba(255, 255, 255, 0.9);
            }

            &__text {
                color: rgba(255, 255, 255, 0.3);
            }
        }
    }
}

.promo-v2 {
    padding-bottom: 30px;

    @media (max-width: $mq__mobile) {
        padding-top: 24px;
        padding-bottom: 32px;
    }

    .promo__row {
        justify-content: space-between;
        min-height: 555px;

        @media (max-width: $mq__mobile) {
            gap: 15px;
        }

        >ul {
            gap: 16px;

            @media (max-width: $mq__mobile) {
                gap: 10px;
            }

            li {
                padding: 12px 24px 12px 14px;

                @media (max-width: $mq__mobile) {
                    padding: 10px 16px 10px 10px;
                }
            }
        }
    }

    .promo__block {
        margin-top: -35px;
        max-width: 546px;
        box-shadow: 0px 14px 0px 0px rgba(255, 255, 255, 0.20);

        @media (max-width: $mq__mobile) {
            margin-top: 0;
            padding: 16px 10px 20px 10px;
            box-shadow: 0px 10px 0px 0px rgba(255, 255, 255, 0.20);
        }
    }

    .promo__block .promo__btn-dop {
        padding: 33px 35px;
        box-shadow: 0px 8px 0px 0px #DADDE9;
        display: flex;

        @media (max-width: $mq__mobile) {
            padding: 35px 35px;

            svg {
                min-width: 24px;
                min-height: 24px;
                max-width: 24px;
                max-height: 24px;
                transform: rotate(90deg);
            }
        }

        p {
            font-weight: 500;
            line-height: 149.3%;
            color: #fff;
            margin: 0;
            font-size: 20px;

            @media (max-width: $mq__mobile) {
                max-width: 193px;
                white-space: break-spaces;
                font-size: 18px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }

        }
    }

    .promo__text-home {
        @media (max-width: $mq__mobile) {
            margin-bottom: 24px;
        }
    }

    .promo__title-home {
        @media (max-width: $mq__mobile) {
            font-size: 24px;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.24px;
        }
    }
}

.promo-v3 {
    min-height: 713px;
    position: relative;
    background: rgba(0, 0, 0, 0.8);

    @media (max-width: $mq__mobile) {
        overflow: hidden;
        padding-bottom: 40px;
        min-height: auto;

        &::after {
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.8);
            top: 0;
            left: 0;
            z-index: 2;
        }
    }

    .breadcrumbs {
        margin-bottom: 4px;
        margin-top: 0;
        padding: 0;
        position: relative;
        z-index: 10;

        @media (max-width: $mq__mobile) {
            margin-bottom: 16px;
        }

        a {
            color: rgba(255, 255, 255, 0.9);
        }

        &__text {
            color: rgba(255, 255, 255, 0.3);
        }

        .breadcrumbs__separator,
        .breadcrumbs__text {
            @media (max-width: $mq__mobile) {
                display: block;
            }
        }
    }

    &__block {
        position: relative;

        span {
            display: block;
            margin-bottom: 90px;

            @media (max-width: $mq__mobile) {
                margin-bottom: 54px;
            }
        }
    }

    &__title {
        max-width: 600px;
        font-size: 48px;
        font-weight: bold;
        line-height: 57px;
        color: white;
        z-index: 3;
        position: relative;
        margin-bottom: 0;

        b {
            display: none;
        }

        br {
            display: block;
            content: '';
            height: 10px;

            @media (max-width: $mq__mobile) {
                height: 6px;
            }
        }

        @media (max-width: $mq__mobile) {
            font-size: 26px;
            line-height: 31px;
        }
    }

    .rotate-wrap {
        width: -webkit-fill-available;
        position: absolute;
        left: 0;
        top: 170px;
        z-index: 3;
        margin-top: 10px;

        @media (max-width: 1024px) {
            top: 90px;
        }

        .rotatingtext {
            position: absolute;
            opacity: 0;
            overflow: hidden;
            background: linear-gradient(267deg, rgba(103, 42, 141, 1) -11.98%, rgba(151, 66, 204, 1) 9.04%, rgba(151, 118, 232, 1) 58.92%, rgba(83, 117, 239, 1) 121.97%);
            color: #0B2349;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 48px;
            font-weight: bold;
            line-height: 57px;
            animation: rotate 7s linear infinite 0s;
            transition-timing-function: cubic-bezier(0.2, 0.5, 0.3, 1);

            @media (max-width: $mq__mobile) {
                font-size: 26px;
                line-height: 31px;
            }

            &:nth-of-type(2) {
                animation-delay: 2.5s;
            }

            &:nth-of-type(3) {
                animation-delay: 4.5s;
            }
        }
    }

    &__desc {
        max-width: 523px;
        width: 100%;
        font-weight: 400;
        font-size: 24px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: white;
        margin-bottom: 42px;
        z-index: 3;
        position: relative;

        @media (max-width: $mq__mobile) {
            font-size: 16px;
            margin-bottom: 24px;
        }
    }

    &__btn {
        background: #F64F4F;
        border-radius: 20px;
        max-width: 284px;
        width: 100%;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 66px;
        font-weight: 400;
        font-size: 22px;
        z-index: 3;
        position: relative;

        @media (max-width: $mq__mobile) {
            font-size: 14px;
            margin-bottom: 42px;
            height: 45px;
            border-radius: 10px;
            max-width: 100%;
        }
    }

    &__img {
        position: absolute;
        top: 10px;
        right: 20px;

        @media (max-width: $mq__mobile) {
            display: block;
            position: relative;
            z-index: 3;
            margin: 0 auto;
            top: 0;
            left: 0;
            right: 0;
        }
    }
}

.promo__btn-dop {
    max-width: 490px;
    display: flex !important;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 33px 35px;
    background: linear-gradient(267deg, #672A8D -11.98%, #9742CC 9.04%, #9776E8 58.92%, #3055D8 121.97%);
    box-shadow: 6px 8px 0px 0px #DADDE9;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 149.3%;
    color: white;
    gap: 6px;
    margin: 0 auto;

    @media (max-width: $mq__tablet) {
        font-size: 14px;
        max-width: 100%;
        width: 100%;
        padding: 0;
        border-radius: 16px;
        box-shadow: 0px 4px 0px 0px #DADDE9;
    }

    svg {
        width: 24px;
        height: 24px;
    }
}


@keyframes rotate {
    0% {
        opacity: 0;
    }

    2% {
        opacity: 0;
        -webkit-transform: translateY(-30px);
    }

    8% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    17% {
        opacity: 1;
        -webkit-transform: translateY(0px);
    }

    23% {
        opacity: 0;
        -webkit-transform: translateY(30px);
    }

    80% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}