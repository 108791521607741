.footer {
    background: #282A32;
    padding: 32px 50px;

    @media (max-width: 1279px) {
        padding: 24px 0;
    }

    .section__body {
        display: flex;
        gap: 24px;
        justify-content: space-between;

        @media (max-width: 1279px) {
            flex-direction: column;
            gap: 40px;
        }
    }

    &__menu {
        max-width: 100%;
        width: 100%;

        &-desk {
            display: flex;
            flex-direction: column;
            gap: 36px;

            @media (max-width: 1279px) {
                gap: 20px;
            }
        }

        &-row {
            display: flex;
            gap: 24px;

            @media (max-width: 1279px) {
                flex-direction: column;
                gap: 20px;
            }
        }

        &-col {
            display: flex;
            flex-direction: column;
            gap: 15px;
            max-width: 31%;
            width: 100%;

            @media (max-width: 1279px) {
                max-width: 100%;

                &:hover>p::after,
                &:focus-within>p::after {
                    transform: rotate(0deg);
                    bottom: 3px;
                    transition: all .6s ease;
                }

                &:hover>ul,
                &:focus-within>ul {
                    animation: opacity .6s forwards;
                    display: flex;
                    opacity: 1;
                }
            }

            >p {
                color: #EEF2F5;
                font-size: 18px;
                font-weight: 600;
                position: relative;
                margin: 0;

                @media (max-width: 1279px) {
                    cursor: pointer;

                    &:hover,
                    &:active,
                    &:focus,
                    &:visited:hover {
                        opacity: 1;
                    }

                    &::after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 2px;
                        right: 0px;
                        width: 24px;
                        height: 24px;
                        background-position: 0 0;
                        background-repeat: no-repeat;
                        background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPG1hc2sgaWQ9Im1hc2swXzQ2NDZfNzE5NSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij4KPHJlY3QgeT0iMjQiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdHJhbnNmb3JtPSJyb3RhdGUoLTkwIDAgMjQpIiBmaWxsPSIjRDlEOUQ5Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF80NjQ2XzcxOTUpIj4KPHBhdGggZD0iTTE4IDEwTDEyIDE2TDYgMTBMNy40IDguNkwxMiAxMy4yTDE2LjYgOC42TDE4IDEwWiIgZmlsbD0iI0VFRjJGNSIvPgo8L2c+Cjwvc3ZnPgo=');
                        transform: rotate(-180deg);
                    }
                }
            }

            ul {
                margin: 0;
                padding: 0;
                display: flex;
                flex-direction: column;
                gap: 15px;

                @media (max-width: 1279px) {
                    display: none;
                    margin-top: -4px;
                }
            }

            li {
                list-style: none;
                line-height: initial;
                margin: 0;

                a {
                    color: rgba(238, 242, 245, 0.60);
                    font-size: 14px;
                    font-weight: 400;
                }


            }
        }
    }

    &__logo {
        max-width: 18.65%;
        width: 100%;

        @media (max-width: 1279px) {
            max-width: 100%;
            text-align: center;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 24px;
        max-width: 18.65%;
        width: 100%;

        @media (max-width: 1279px) {
            max-width: 100%;
            align-items: center;
            gap: 16px;
            flex-direction: column-reverse;
        }

        &-social {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 16px;

            a>svg {
                height: auto;
                width: auto;
            }
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            gap: 16px;

            @media (max-width: 1279px) {
                align-items: center;
                flex-direction: column-reverse;
                gap: 8px;
            }

            a {
                display: block;
                font-size: 16px;
                font-weight: 500;
                color: #EEF2F5;
            }
        }

        &-btn {
            color: #EEF2F5;
            padding: 13px 28px;
            border-radius: 20px;
            border: 1px solid #3055D8;
            background: #3055D8;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 149.3%;
            letter-spacing: 0.48px;
            cursor: pointer;
            max-width: fit-content;

            &:hover {
                opacity: .5;
                transition-duration: .6s;
            }
        }
    }
}

.btn-up {
    position: fixed;
    background-color: #3055d8;
    right: 20px;
    bottom: 20px;
    border-radius: 22px;
    cursor: pointer;
    width: 44px;
    height: 44px;
    z-index: 10;

    @media (max-width: 1279px) {
        right: 16px;
    }

    @media (max-width: 767px) {
        bottom: 65px;
    }

    &::before {
        content: "";
        text-align: center;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 12px;
        top: 12px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M10 17V4M3 10l7-7 7 7'/%3E%3C/g%3E%3C/svg%3E");
    }
}

.btn-up__hide {
    display: none;
}

@media (hover: hover) and (pointer: fine) {
    .btn-up:hover {
        opacity: .8;
    }
}