.h-list {
    margin-bottom: $h-list-bottom-space;
    margin-top: 0;
    max-width: $content-max_thin;

    @media (max-width: $mq__tablet) {
        margin-bottom: $h-list-bottom-space_t;
    }

    @media (max-width: $mq__mobile) {
        margin-bottom: $h-list-bottom-space_m;
    }

    &_style_sublist {
        padding-left: 20px;

        @media (max-width: $mq__mobile) {
            padding-left: 15px;
        }
    }

    &_space-b_none {
        margin-bottom: 0;
    }

    &_marked &__item {
        position: relative;
        padding-left: 20px;
    }

    &_marked &__item:before {
        position: absolute;
        content: '';
        top: 8px;
        left: 1px;
        width: 6px;
        height: 6px;
        background-color: var(--color_accent);
        border-radius: 50%;
    }

    &_marked-outline &__item {
        position: relative;
        padding-left: 20px;
    }

    &_marked-outline &__item:before {
        position: absolute;
        content: '';
        top: 8px;
        left: 1px;
        width: 6px;
        height: 6px;
        border: 1px solid;
        background-color: transparent;
        border-radius: 50%;
    }

    &__body {
        margin: 0;
        padding: 0;

        p {
            margin: 0 0 22px 0;
            line-height: 1.25;
        }
    }

    &__list {
        list-style-type: none;
        margin: 0;
        padding: 0;

        @media (max-width: $mq__masonry_auto) {
            display: inline-block;
        }
    }

    &__item {
        padding: 0;
        margin: 0 0 16px 0;
        line-height: 1.25;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 4px 0 0 0;
        }

        ul li {
            padding-left: 20px;
            position: relative;

            &::before {
                content: '—';
                display: block;
                position: absolute;
                top: 1px;
                left: 0;

                @media (max-width: $mq__mobile) {
                    top: 0;
                }
            }
        }
    }



}
