.content-caption {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;

    @media (max-width: $mq__tablet) {
        font-size: 14px;
    }

    @media (max-width: $mq__mobile) {
        font-size: 13px;
    }

    &__second-letter,
    &:first-letter {
        font-size: 20px;

        @media (max-width: $mq__tablet) {
            font-size: 17px;
        }

        @media (max-width: $mq__mobile) {
            font-size: 16px;
        }
    }
}
