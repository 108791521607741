.blockquote {
    $img-dim: 183px;
    $img-gap: 21px;
    $mq__blockquote_vert: 725px;

    position: relative;
    margin: ($space__vside + 20px) 0 52px ($img-dim + $img-gap);
    padding: 1px 0 0 0;
    max-width: $content-max_thin;

    @media (max-width: $mq__blockquote_vert) {
        margin-left: 0;
    }

    &__h {
        position: relative;
        color: var(--color_accent);
        margin-top: 22px;

        &::before {
            content: '„';
            display: block;
            position: absolute;
            bottom: 100%;
            left: 0;
            font-size: 34px;
            margin-bottom: 4px;
        }
    }

    &__aside {
        width: $img-dim;
        position: absolute;
        top: 0;
        left: -($img-dim + $img-gap);

        @media (max-width: $mq__blockquote_vert) {
            position: relative;
            left: 0;

        }
    }

    &__img {
        display: block;
        margin-bottom: 8px;
    }

    &__author {
        font-weight: 600;
        margin-bottom: 4px;
        font-size: var(--font-size);
    }

}
