.section-teaser {
    display: flex;

    @media (max-width: $mq__section-teaser_vert) {
        flex-direction: column;
    }

    &_ratio_smaller-content &__item {
        &:first-child {
            width: 43%;
            @media (max-width: $mq__section-teaser_vert) {
                width: auto;
            }
        }

        &:last-child {
            width: 57%;
            @media (max-width: $mq__section-teaser_vert) {
                width: auto;
            }
        }
    }

    &__item {
        width: 50%;

        @media (max-width: $mq__section-teaser_vert) {
            width: auto;
        }

        &:first-child {
            padding-right: 50px;
            max-width: $content-max;

            @media (max-width: $mq__section-teaser_vert) {
                padding-right: 0;
            }


        }

        &:last-child {
            padding-top: 14px;

            @media (max-width: $mq__section-teaser_vert) {
                padding-top: 0;
            }
        }
    }

    &__body {
        margin-bottom: 40px;
        max-width: $content-max_thin;

        @media (max-width: $mq__mobile) {
            margin-bottom: 30px;
        }
    }
}
