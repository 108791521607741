.table {
    margin-bottom: $space__bottom;

    &_first-col-h td:first-child {
        min-width: 200px;
    }

    &__table {
        margin-bottom: 0;
    }

    &__caption {

    }
}
