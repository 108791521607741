.orbit {

    &__row {
        margin-bottom: 32px;
        display: -webkit-flex;
        display: flex;
        align-items: stretch;
        gap: 32px;
        @media (max-width: $mq__tablet) {
            flex-direction: column-reverse;
            gap: 16px;
            margin-bottom: 16px;
            &:last-of-type {
                flex-direction: column;
            }
        }
    }

    &__card {
        display: flex;
        max-width: 802px;
        flex-direction: row-reverse;
        justify-content: start;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        overflow: hidden;
        transition-duration: $animation__duration_slow;

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #BEBEBE;

            .orbit__img {
                mix-blend-mode: initial;
                &:before {
                    opacity: 0;
                }
            }

            .orbit__title {
                color: #3055D8;
            }

            .orbit__li::marker {
                color: #3055D8;
            }
        }

        @media (max-width: $mq__tablet) {
            width: 100%;
        }

        @media (max-width: $mq__mobile) {
            flex-direction: column;
        }
    }

    &__img {
        position: relative;
        background: #fff;
        mix-blend-mode: luminosity;
        transition: $animation__duration_slow;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(52.99deg, rgba(0, 0, 0, 0.4) 42.64%, rgba(192, 192, 192, 0.4) 93.25%);
            transition: $animation__duration_slow;
        }

        @media (max-width: $mq__mobile) {
            mix-blend-mode: initial;
            img {
                height: 237px;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    &__wrapper {
        padding: 32px;
        max-width: 442px;
        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__title {
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #222222;

        @media (max-width: $mq__mobile) {
            margin-bottom: 6px;
            font-size: 18px;
            color: #3055D8;
        }
    }

    &__posttitle {
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 12px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.5);
        @media (max-width: $mq__mobile) {
            margin-bottom: 16px;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__li {
        margin-left: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.7);
        &:not(:last-of-type) {
            margin-bottom: 16px;
        }

        @media (max-width: $mq__mobile) {
            font-size: 14px;
            &::marker {
                color: #3055D8;
            }
            &:not(:last-of-type) {
                margin-bottom: 8px;
            }
        }
    }
}

