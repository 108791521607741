.products {
    user-select: none;

    &__row {
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        position: relative;
    }

    &__card {
        margin: 40px 10px 40px 40px;
        position: relative;
        padding: 32px;
        width: 360px;
        flex-shrink: 0;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        cursor: grab;
        transition: $animation__duration_slow;

        &:hover,
        &:focus {
            background: #3055D8;

            .products__caption {
                color: #EEF2F5;
            }

            p {
                color: #EEF2F5;
            }

            .products__item {
                color: #EEF2F5;
            }

            .products__icon {
                background: #fff;
                color: #3055D8 !important;

                path {
                    fill: #3055D8;
                }
            }

            .dops {
                path {
                    fill: none;
                    stroke: #3055D8;
                }
            }

            .products__link {
                color: #EEF2F5;

                svg {
                    color: #EEF2F5;

                    path {
                        transition: $animation__duration_slow;
                    }
                }
            }
        }

        &:active {
           cursor: grabbing;
        }

        @media (max-width: $mq__mobile) {
            margin: 30px 8px;
            padding: 32px 16px;
            width: 240px;
        }

        svg {
            color: #3055D8;
        }

        p {
            transition: $animation__duration_slow;
        }
    }

    &__icon {
        position: absolute;
        left: -40px;
        top: -40px;
        width: 80px;
        height: 80px;
        padding: 24px;
        border-radius: 50%;
        border: 3px solid #3055D8;
        background: #3055D8;
        color: #fff !important;
        overflow: initial;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            left: 0;
            right: 0;
            top: -24px;
            margin: 0 auto;
            width: 48px;
            height: 48px;
            padding: 12px;
        }
    }

    &__caption {
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #222222;

        @media (max-width: $mq__mobile) {
            font-size: 20px;
            opacity: 0.9;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__item {
        margin-left: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);

        @media (max-width: $mq__mobile) {
            font-size: 14px;
        }
    }

    &__link {
        margin-top: 16px;
        display: -webkit-flex;
        display: flex;
        justify-content: end;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 150%;
        color: #3055D8;

        svg {
            margin-left: 16px;
            width: 28px;
            height: 28px;
            color: #3055D8;
        }
    }

}

.template .products{
    &__caption {
        margin-bottom: 0;
    }

    p {
        margin-top: 16px;
        color: rgba(0, 0, 0, 0.60);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
    }

    &__card {
        &:hover,
        &:focus {
            .products__icon > path {
                stroke: rgb(48, 85, 216);
            }
            p {
                color: white;
            }
        }
    }
}


.products-check .products__card:focus .products__icon path, .products-check .products__card:hover .products__icon path {
    fill: none;
}