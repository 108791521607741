.video {

    &__body {
        position: relative;
        width: 100%;
        padding-top: 56.25%;
        margin-bottom: 10px;
    }

     & iframe {
         position: absolute;
         top: 0;
         left: 0;
         width: 100%;
         height: 100%;
     }
}
