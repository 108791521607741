.feedback {
    background: rgba(48, 85, 216, 0.1);

    &__body {
        display: -webkit-flex;
        display: flex;

        @media (max-width: $mq__tablet) {
            flex-direction: column;
        }
    }

    &__h {
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 50px;
        line-height: 105.8%;

        @media (max-width: $mq__mobile) {
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 28px;
        }
    }

    &__text {
        margin: 0 0 42px;
        max-width: 850px;
    }

    &__left {
        padding-right: 64px;
        max-width: 575px;

        @media (max-width: $mq__mobile) {
            padding-right: 0;
        }

        h2>b {
            color: #006ff7;
        }

        p {
            margin: 0 0 12px;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            color: #222222;
            opacity: 0.8;

            @media (max-width: $mq__tablet) {
                margin: 0 0 6px;
                font-size: 14px;
                line-height: 130%;
            }
        }

        div {
            @media (max-width: $mq__mobile) {
                margin-bottom: 16px;
            }
        }
    }

    &__form {
        padding: 32px;
        width: 100%;
        max-width: 480px;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        align-self: start;
        z-index: 1;
        min-width: 385px;

        @media (max-width: $mq__mobile) {
            padding: 24px 16px;
            margin-bottom: 85px;
            min-width: auto;
        }

        form {
            display: flex;
            gap: 30px;
            flex-direction: column;

            @media (max-width: $mq__mobile) {
                gap: 32px;
                padding-bottom: 0px;
            }

            .form-field {
                font-family: 'TT Hoves', sans-serif, BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
                height: 36px;
                width: 100%;
                border: none;
                color: black;
                font-family: 'TT Hoves';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                border-bottom: 1px solid #2222224D;

                &::placeholder {
                    color: #2222224D;
                }

                &:focus,
                &:focus-visible {
                    outline: none;
                }

                @media (max-width: $mq__mobile) {
                    height: 32px;
                    font-size: 12px;
                    line-height: 150%;
                }

                &.error {
                    &+.form-error {
                        display: block;
                    }
                }
            }

            .form-error {
                display: none;
                font-size: 16px;
                margin-top: 6px;
                color: red;
            }

            .container-field {
                position: relative;
                display: flex;
                flex-direction: column;
                gap: 10px;

                &::after {
                    content: '*';
                    position: absolute;
                    right: 17px;
                    bottom: -1px;
                    font-size: 22px;
                    color: red;
                    display: flex;
                    align-items: center;

                    @media (max-width: $mq__mobile) {
                        right: 5px;
                    }
                }
            }

            button {
                margin-left: auto;
                height: 51px;
                border: none;
                max-width: 236px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #FFFFFF;
                background: #d62b2b;
                border-radius: 53px;

                @media (max-width: $mq__mobile) {
                    left: -16px;
                    min-width: calc(100vw - 32px);
                    height: 45px;
                    bottom: -90px;
                    border-radius: 10px;
                }

                &:hover {
                    opacity: 0.5;
                    transition-duration: 0.6s;
                }
            }

            .quiz__step-result-form-policy {
                pointer-events: initial;
                font-size: 18px;

                @media (max-width: $mq__mobile) {
                    font-size: 12px;
                }

                a {
                    color: #006ff7;
                }

                .checkmark {
                    top: 4px;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(0, 0, 0, 0.5);

                    &::after {
                        top: 1px;
                        left: 5px;
                        width: 5px;
                        height: 10px;
                        border-color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
    }

    &__right {
        padding-top: 4px;
        padding-left: 64px;

        @media (max-width: $mq__tablet) {
            padding-left: 0;
        }

        p {
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            margin: 0 0 32px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #222222;

            @media (max-width: $mq__tablet) {
                margin: 0 0 16px;
            }
        }

        .chat-to-us {
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            margin: 0 0 32px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #222222;

            @media (max-width: $mq__tablet) {
                margin: 0 0 16px;
                display: block;
            }

            .chat-text {
                display: block;
                margin-bottom: 10px;
            }

            a {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                margin-right: 16px;
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: #222222;

                &:last-child {
                    gap: 10px;

                    @media (max-width: $mq__mobile) {
                        gap: 15px;
                    }
                }
            }

            .telegram-contact {
                background: transparent url('../img/telegram.svg') 0 0;
                width: 36px;
                height: 36px;
                display: block;
                margin-bottom: 15px;
            }

            .whatsapp-contact {
                background: transparent url('../img/whatsapp.svg') 0 0;
                width: 44px;
                height: 44px;
                display: block;

                @media (max-width: $mq__mobile) {
                    background-position-y: 3px;
                    background-repeat: no-repeat;
                }
            }

            &-row {
                display: -webkit-flex;
                display: flex;
                align-items: end;
                justify-content: start;

                @media (max-width: $mq__tablet) {
                    display: block;
                }
            }
        }
    }

    &__info-link {
        display: block;
        font-weight: 700;
        font-size: 24px;
        line-height: 150%;
        letter-spacing: 0.01em;
        color: #3055D8;

        @media (max-width: 413px) {
            font-size: 18px;
        }
    }

    iframe {
        max-width: 480px;
    }
}

.slider-feedback {
    position: fixed;
    display: none;
    align-items: stretch;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    margin: 0 auto;
    max-width: 872px;
    padding: 24px;
    background: #EEF2F5;
    border-radius: 30px;
    height: 100%;
    width: 100%;
    height: fit-content;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    transition: $animation__duration_slow;

    @media (max-width: $mq__mobile) {
        height: auto;
        padding: 12px;
        overflow: scroll;
        max-width: calc(100vw - 32px);
    }

    @media (max-height: 600px) {
        height: inherit;
    }

    &.is-display {
        display: block;
        opacity: 1;
        visibility: initial;
        animation: is-display $animation__duration_slow linear;
    }

    &__row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 64px;
        align-items: center;

        @media (max-width: $mq__mobile) {
            flex-direction: column;
            gap: 10px;
        }
    }

    &__close {
        position: absolute;
        background: none;
        border: none;
        right: -10px;
        top: -45px;
        cursor: pointer;

        @media (max-width: $mq__mobile) {
            right: 10px;
            top: 10px;
        }

        svg {
            width: 32px;
            height: 32px;

            @media (max-width: $mq__mobile) {
                g>path {
                    fill: #3055D8;
                }
            }
        }
    }

    &__right {
        background: #FFFFFF;
        border-radius: 20px;
        max-width: 414px;
        width: 100%;
        padding: 24px;

        @media (max-width: $mq__mobile) {
            padding: 10px;
        }

        h2 {
            font-weight: 700;
            font-size: 36px;
            line-height: 105%;
            margin-bottom: 32px;
            color: #3055D8;

            @media (max-width: $mq__mobile) {
                font-size: 18px;
                line-height: 120%;
                margin-bottom: 12px;
            }

            span {
                color: #222222;
            }

        }

        .slider-feedback__copy {
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: #222222;
            opacity: 0.5;
            margin: 0;

            a {
                display: inline;
            }
        }
    }

    &__form {
        form {
            position: relative;
            display: flex;
            gap: 18px;
            flex-direction: column;

            @media (max-width: $mq__mobile) {
                gap: 10px;
            }

            .form-field {
                padding: 0 17px;
                font-family: 'TT Hoves', sans-serif, BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
                height: 50px;
                width: 100%;
                color: black;
                font-family: 'TT Hoves';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                border: 1px solid #3055D8;
                border-radius: 16px;

                &::placeholder {
                    color: #2222224D;
                }

                &:focus,
                &:focus-visible {
                    outline: none;
                }

                @media (max-width: $mq__mobile) {
                    height: 37px;
                    padding: 0 12px;
                    font-size: 14px;
                    border-radius: 10px;
                }
            }

            .container-field {
                position: relative;

                &::after {
                    content: '*';
                    position: absolute;
                    right: 17px;
                    top: 8px;
                    font-size: 22px;
                    color: red;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                }
            }

            button {
                height: 56px;
                border: none;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #FFFFFF;
                background-color: #ea5757;
                border-radius: 53px;

                &:hover {
                    opacity: 0.5;
                    transition-duration: 0.6s;
                }

                @media (max-width: $mq__mobile) {
                    height: 37px;
                    font-size: 14px;
                    line-height: 150%;
                }
            }
        }

        position: relative;

        iframe {
            height: 100% !important;
            opacity: 1 !important;
        }

        html {
            height: 100% !important;
        }
    }

    &__tel {
        @media (max-width: $mq__mobile) {
            flex-direction: column;

            a {
                font-size: 12px !important;
            }
        }
    }

    &__call {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 12px;
        margin-bottom: 32px;

        @media (max-width: $mq__mobile) {
            margin-bottom: 12px;
            gap: 8px;
        }

        p {
            opacity: 1;
            margin: 0;
            display: flex;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: rgba(34, 34, 34, 0.7);

            @media (max-width: 413px) {
                font-size: 12px;
            }

            a {
                font-weight: 600;
                color: #3055D8;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-left: 10px;

                    @media (max-width: $mq__mobile) {
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }

                @media (max-width: $mq__mobile) {
                    font-size: 0;
                }


                svg {
                    margin-left: 7.5px;
                    display: inline;
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;

                    @media (max-width: $mq__mobile) {
                        width: 16px;
                        height: 16px;
                        margin: 0;
                    }
                }
            }
        }
    }

    &__left {
        max-width: 346px;
        width: 100%;

        h2 {
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            text-align: center;
            letter-spacing: 0.01em;
            color: rgba(34, 34, 34, 0.9);
            margin-bottom: 16px;

            @media (max-width: $mq__mobile) {
                margin-top: 20px;
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 8px;
            }
        }


    }

    &__list {
        display: flex;
        flex-direction: column;
        gap: 20px;

        @media (max-width: $mq__mobile) {
            margin-bottom: 12px;
            gap: 8px;
        }

        p {
            margin: 0;
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #222222;

            @media (max-width: $mq__mobile) {
                font-size: 14px;
                font-weight: 600;
            }

            svg {
                width: 22px;
                height: 16px;

                @media (max-width: $mq__mobile) {
                    width: 14px;
                    height: 11px;
                }
            }
        }
    }

    &__slider {
        max-width: 210px;
        position: relative;
        height: 180px;
        margin: 0 auto;
        margin-bottom: 16px;

        @media (max-width: $mq__mobile) {
            height: auto;
        }
    }

    &__wrapper {
        display: -webkit-flex;
        display: flex;
        position: relative;
    }

    &__item {
        &-text {
            margin-top: 12px;
            font-weight: 700;
            font-size: 20px;
            line-height: 115%;
            text-align: center;
            letter-spacing: 0.01em;
            color: rgba(34, 34, 34, 0.9);

            @media (max-width: $mq__tablet) {
                font-size: 14px;
            }

            @media (max-width: $mq__mobile) {
                font-size: 12px;
                line-height: 115%;
                max-width: 137px;
                margin: 0 auto;
                margin-top: 8px;
            }
        }

        &-info {
            margin-top: 8px;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);

            @media (max-width: $mq__tablet) {
                font-size: 12px;
            }
        }
    }

    &__btn {
        border: none;
        background: transparent;
        width: 32px;
        height: 32px;
        box-shadow: none;
        top: 55px;

        @media (max-width: $mq__tablet) {
            top: 50px;
            width: 24px;
            height: 24px;
        }

        @media (max-width: $mq__mobile) {
            top: 27px;
        }

        &.swiper-button-next {
            right: 0;

            @media (max-width: $mq__mobile) {
                right: 35px;
            }
        }

        &.swiper-button-prev {
            left: 0;

            @media (max-width: $mq__mobile) {
                left: 30px;
            }
        }

        &.swiper-button-disabled {
            display: -webkit-flex;
            display: flex;
            opacity: 0.5;
        }
    }

    &__img {
        width: 180px;

        img {
            width: 120px;
            margin: 0 auto;
        }

        @media (max-width: $mq__tablet) {
            width: 100px;
            height: 100px;

            img {
                width: 100px;
                height: 100px;
                margin: 0 auto;
            }
        }

        @media (max-width: $mq__mobile) {
            img {
                width: 57px;
                height: 57px;
            }
        }
    }

    &__select {
        max-width: 360px;
        margin-bottom: 18px;
        font-weight: 500;
        font-size: 18px;
        line-height: 130%;
        text-align: center;
        letter-spacing: 0.01em;
        color: rgba(34, 34, 34, 0.9);

        @media (max-width: $mq__tablet) {
            font-size: 14px;
        }
    }

    &__svg {
        margin-bottom: 32px;
        width: 120px;
        height: 120px;

        @media (max-width: $mq__tablet) {
            margin-bottom: 24px;
            width: 72px;
            height: 72px;
        }
    }

    &__end {
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 28px;
        line-height: 130%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #222222;

        @media (max-width: $mq__tablet) {
            font-size: 20px;
            line-height: 120%;
        }
    }

    &__blue {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        text-align: center;
        color: #3055D8;

        @media (max-width: $mq__tablet) {
            max-width: 131px;
            font-size: 14px;
            line-height: 140%;
        }
    }
}


.js-feedback-thankyou {
    max-width: 872px;
    height: 293px;
    background: #EEF2F5;
    border-radius: 30px;
    padding: 24px;

    @media (max-width: $mq__tablet) {
        max-width: calc(100vw - 20px);
        height: auto;
        padding: 12px;
    }

    #feedback-step-3 {
        gap: 74px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: $mq__tablet) {
            flex-direction: column;
            gap: 15px;
        }

        .slider-feedback__svg {
            margin-bottom: 0;
            margin-left: 50px;
            width: 100px;
            height: 100px;

            @media (max-width: $mq__tablet) {
                margin-left: 0;
            }

            @media (max-width: $mq__mobile) {
                width: 85px;
                height: 85px;
                margin-top: 40px;
                margin-bottom: 10px;
            }
        }
    }

    .feedback-thankyou__content {
        background: #FFFFFF;
        border-radius: 20px;
        max-width: 600px;
        width: 100%;
        height: 100%;
        padding: 24px;

        @media (max-width: $mq__tablet) {
            padding: 10px;
            padding-top: 18px;
        }


        h3 {
            font-weight: 700;
            font-size: 32px;
            line-height: 105%;
            text-align: center;
            margin-bottom: 24px;

            @media (max-width: $mq__tablet) {
                font-size: 24px;
                margin-bottom: 12px;
            }

            @media (max-width: $mq__mobile) {
                font-size: 18px;
                line-height: 120%;
                margin-bottom: 12px;
            }

            b {
                color: #3055D8;
            }
        }

        .feedback-thankyou__block {
            max-width: fit-content;
            margin: 0 auto;
        }

        .feedback-thankyou__copy {
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: #222222;
            opacity: 0.5;
            margin: 0;
            text-align: center;
            margin-top: 8px;

            a {
                color: #3055D8;
                display: contents;
                font-size: 12px !important;
            }
        }

        .feedback-thankyou__text {
            font-weight: 500;
            font-size: 24px;
            line-height: 130%;
            text-align: center;
            letter-spacing: 0.01em;
            color: rgba(34, 34, 34, 0.6);
            margin-bottom: 24px;

            @media (max-width: $mq__tablet) {
                margin-bottom: 12px;
            }

            @media (max-width: $mq__mobile) {
                font-size: 16px;
                line-height: 150%;
            }
        }

        .feedback-thankyou__tel {
            margin-bottom: 8px;

            a {
                color: #3055D8;
                font-weight: 600;

                @media (max-width: $mq__mobile) {
                    margin-left: 0 !important;
                    font-size: 12px !important;
                }
            }

            @media (max-width: $mq__mobile) {
                flex-direction: column;
                align-items: center;
            }
        }

        p {
            display: flex;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: rgba(34, 34, 34, 0.7);

            @media (max-width: 413px) {
                font-size: 12px;
            }

            a {
                color: #3055D8;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-left: 10px;

                    @media (max-width: $mq__mobile) {
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }

                svg {
                    margin-left: 7.5px;
                    display: inline;
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;

                    @media (max-width: $mq__mobile) {
                        margin: 0;
                    }
                }

                @media (max-width: $mq__mobile) {
                    font-size: 0;
                }
            }
        }
    }
}

.seo-page,
.template {
    .feedback-seo {
        .feedback__left {
            max-width: 482px;
            box-sizing: content-box;
        }

        .quote__photo {
            top: auto;
            right: auto;
            max-width: 250px;
            position: relative;

            @media (max-width: $mq__mobile) {
                margin: 0 auto;
            }

            p {
                font-weight: 500;
                font-size: 20px;
                line-height: 150%;
                text-align: center;
                color: #222222;
                opacity: 0.8;
            }
        }

        .quote__name {
            margin: 14px 0 12px;
            font-weight: 700;
            font-size: 20px;
            line-height: 110%;
            text-align: center;
            color: #3055D8;
        }

        .quote__position {
            font-weight: 700;
            font-size: 20px;
            line-height: 110%;
            text-align: center;
            color: #3055D8;
        }
    }
}

.template {
    .feedback__h {
        font-size: 42px;
        margin-bottom: 24px;

        @media (max-width: $mq__mobile) {
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 28px;
        }
    }
}

@keyframes visible {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.feedback-thankyou__close {
    background: none;
    border: none;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    @media (max-width: $mq__tablet) {
        right: 10px;
        top: 10px;
    }

    svg {
        width: 35px;
        height: 35px;
    }
}

.slider-feedback .contacts-block {
    padding: 24px;
    border-radius: 24px;
    background: white;
    max-width: 414px;
    width: 100%;

    @media (max-width: $mq__mobile) {
        padding: 16px 10px 10px 10px;
        border-radius: 16px;
    }

    h2 {
        margin-bottom: 32px;
        max-width: 325px;

        @media (max-width: $mq__mobile) {
            margin-bottom: 16px;
        }
    }

    div {
        flex-direction: column;
        gap: 10px;

        @media (max-width: $mq__mobile) {
            gap: 8px;
        }

        a {
            max-width: 100%;
        }
    }
}