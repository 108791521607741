.outcome {
    $mq__outcome-vert: 581px;
    display: -webkit-flex;
    display: flex;

    @media (max-width: $mq__outcome-vert) {
        flex-direction: column;
    }

    &_style_wrappable {
        flex-wrap: wrap;
    }

    &_style_wrappable &__item {
        max-width: 250px;
        min-width: 200px;
    }


    &__item {
        max-width: 214px;
        margin-bottom: $space__bottom;
        padding-right: 40px;

        @media (max-width: $mq__tablet) {
            max-width: 190px;
            padding-right: 20px;
        }

        @media (max-width: $mq__outcome-vert) {
            max-width: none;
        }
        
        &_one {
            max-width: 335px;
        }
    }

    &__h {
        white-space: nowrap;
        font-size: calc(var(--font-size) + 4px);
        margin-bottom: 8px;

        @media (max-width: $mq__tablet) {
            font-size: calc(var(--font-size_t) + 4px);
        }

        @media (max-width: $mq__mobile) {
            font-size: calc(var(--font-size_m) + 4px);
        }
    }

    &__icon {
        width: 34px;
        height: 34px;
        position: relative;
        top: 8px;
        display: inline-block;
        color: var(--color_yep);

        @media (max-width: $mq__mobile) {
            top: 6px;
            width: 24px;
            height: 24px;
        }

        svg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    &__legend {
        font-size: calc(var(--font-size) - 2px);

        @media (max-width: $mq__tablet) {
            font-size: calc(var(--font-size_t) - 2px);
        }

        @media (max-width: $mq__mobile) {
            font-size: calc(var(--font-size_m) - 2px);
        }
    }
}
