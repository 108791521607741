.solutions{
    &__btn {
        display: none;

        @media (max-width: 1279px) {
            display: flex;
        }
    }

    &__row{
        position: relative;
    }

    &__wrapper {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;

        @media (max-width: 1279px) {
            flex-wrap: initial;
            gap: 0;
        }
    }

    &__card {
        border-radius: 30px;
        box-shadow: 0px 0px 20px 0px #DBDBDB;
        display: flex;

        @media (max-width: 1279px) {
            margin: 20px 10px 20px 12px;
        }

        &:hover{
            opacity: 1;
        }

        &:first-child {
            max-width: 100%;
            width: 100%;
            background: #3055D8;
            gap: 15px;
            justify-content: center;
            padding-top: 48px;
            padding-bottom: 48px;
            align-items: center;

            @media (max-width: 1279px) {
                margin-left: 0;
                max-width: 280px;
                flex-direction: column;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            .solutions__text{
                max-width: 300px;
                h3 {
                    color: #FFF;
                    font-size: 64px;
                    font-weight: 600;
                    margin-bottom: 20px;

                    @media (max-width: 1279px) {
                        font-size: 24px;
                        text-align: center;
                        margin-bottom: 10px;
                    }
                }

                p{
                    color: #FFF;
                    text-align: center;
                    font-family: TT Hoves;
                    font-size: 20px;
                    font-weight: 500;

                    @media (max-width: 1279px) {
                        font-size: 16px;
                    }
                }
            }
        }

        &:nth-child(5) {
            flex-direction: column;
            gap: 20px;
            background: #3055D8;
            justify-content: center;
            align-items: center;
            padding-top: 40px;
            padding-bottom: 30px;
            max-width: 66%;
            width: 100%;

            @media (max-width: 1279px) {
                padding-left: 20px;
                padding-right: 20px;
                max-width: 280px;   
                padding-bottom: 20px;
                padding-top: 20px;
            }

            h3 {
                color: #FFF;
                font-size: 48px;
                font-weight: 600;
                margin-bottom: 20px;
                max-width: 520px;

                @media (max-width: 1279px) {
                    font-size: 24px;
                    margin-bottom: 10px;
                    text-align: center;
                }
            }

            p{
                max-width: 596px;
                color: #FFF;
                text-align: center;
                font-family: TT Hoves;
                font-size: 20px;
                font-weight: 500;

                @media (max-width: 1279px) {
                    font-size: 16px;
                }
            }
        }

        &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(6) {
            flex-direction: column;
            gap: 25px;
            justify-content: center;
            align-items: center;
            padding-top: 40px;
            padding-bottom: 40px;
            background: #394750;
            max-width: calc(33% - 12px);
            width: 100%;

            @media (max-width: 1279px) {
                padding-left: 20px;
                padding-right: 20px;
                max-width: 280px;
                padding-top: 20px;
                padding-bottom: 20px;
            }

            img{
                border-radius: 10px;
            }

            .solutions__text{
                max-width: 224px;
                h3 {
                    color: #FFF;
                    font-size: 48px;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 0px;

                    @media (max-width: 1279px) {
                        font-size: 24px;
                    }
                }
            }
        }
    }
}