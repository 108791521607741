.layout {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;

    &__header,
    &__footer {
        flex-shrink: 0;
    }

    &__footer {
        background: #282A32;
    }

    &__header {

        &_fixed {
            position: fixed;
            top: 0;
            left: 0;
            background-color: #282A32;
            z-index: 50;
            width: 100%;
        }
    }

    &__body {
        flex: 1 0 auto;
        margin-top: 88px;

        @media (max-width: $mq__tablet) {
            margin-top: 60px;
            overflow: hidden;
        }

        &_centered {
            display: -webkit-flex;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
        }

        &_space-t_none {
            margin-top: 0;
        }
    }

}
