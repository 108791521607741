.blog {
    &__body {
        display: grid;
        gap: 32px;
        grid-template-columns: repeat(3,1fr);

        @media (max-width: $mq__tablet) {
            grid-template-columns: repeat(2,1fr);
        }

        @media (max-width: 767px) {
            grid-template-columns: repeat(1,1fr);
            gap: 16px;
        }
    }

    &__item {
        position: relative;
        border-radius: 30px;
        box-shadow: 0 0 20px #dbdbdb;

        img{
            max-height: 350px;
            width: 100%;
            height: 100%;
            margin-bottom: 0;
            object-fit: cover;
            border-radius: 30px 30px 0 0;

            @media (max-width: 768px) {
                max-height: 190px;
            }
        }

        &:hover {
            a {
                opacity: 1;
            }
            .blog__info h3, .blog__info p {
                color: #3055d8;
                transition-duration: .3s;
            }
        }
    }

    &__info {
        padding: 24px;
        min-height: 250px;

        @media (max-width: 768px) {
            padding: 12px;   
            min-height: auto;    
        }

        h3 {
            font-size: 26px;
            font-weight: 700;
            color: #333;
            margin-bottom: 0;

            @media (max-width: 768px) {
                font-size: 20px;
            }
        }

        p {
            font-size: 18px;
            font-weight: 400;
            padding-top: 10px;
            color: #333;
            margin: 0;

            @media (max-width: 768px) {
                font-size: 16px;
            }
        }
    }

    &__date {
        position: absolute;
        bottom: 24px;
        left: 24px;
        font-size: 14px;
        color: black;

        @media (max-width: 768px) {
            position: initial;
            padding-top: 15px;
            padding-bottom: 10px;
        }
    }
}

.blog > .section__body {
    position: relative;
    padding-bottom: 83px;

    > a{
        padding: 14px 28px 14px 28px;
        display: flex;
        width: auto;
        align-self: flex-start;
        align-items: center;
        flex-shrink: 0;
        background: #3055d8;
        color: #eef2f5;
        border: 1.5px solid #3055d8;
        border-radius: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 149.3%;
        letter-spacing: .03em;
        transition: .6s;
        position: absolute;
        bottom: 0;
        right: 50px;
        
        @media (max-width: 768px) {
            right: 16px;
        }
    }
}