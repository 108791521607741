.banner {
    padding: 64px 0 64px 0;
    background: rgb(48, 85, 216);
    position: relative;

    @media (max-width: $mq__mobile) {
        padding: 32px 0;
        overflow: hidden;
    }

    svg {
        width: auto;
    }


    &__decor-1 {
        z-index: 1;
        position: absolute;
        left: 0;
        bottom: 0;

        svg {
            &:first-child {
                @media (max-width: $mq__mobile) {
                    display: none;
                }
            }


            &:last-child {
                display: none;

                @media (max-width: $mq__mobile) {
                    display: block
                }
            }

        }
    }


    &__decor-2 {
        z-index: 1;
        position: absolute;
        right: 0;
        bottom: 0;

        @media (max-width: $mq__mobile) {
            top: 0;
        }

        svg {
            &:first-child {
                @media (max-width: $mq__mobile) {
                    display: none;
                }
            }

            &:last-child {
                display: none;

                @media (max-width: $mq__mobile) {
                    display: block;
                }
            }

        }

    }


    &__body {
        margin: 0 auto;
        margin-left: -60px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media (max-width: $mq__mobile) {
            flex-direction: column;
            margin: 0 auto;
        }

        >svg {
            margin-right: 16px;

            @media (max-width: $mq__mobile) {
                display: none;
            }
        }


        p {
            margin: 0;
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            max-width: 603px;
            color: white;
            margin-right: 48px;

            b {
                color: white;
                font-weight: 600;
            }

            @media (max-width: $mq__mobile) {
                margin-right: 0;
                margin-bottom: 20px;
                font-size: 20px;
                max-width: calc(100vw - 48px);
                line-height: 28px;
            }
        }


        >div {
            cursor: pointer;
            margin-top: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            border: 0.5px solid rgb(255, 255, 255);
            border-radius: 32px 0px 0px 32px;
            background: rgba(255, 255, 255, 0.3);
            padding: 22px 36px 22px 32px;
            font-size: 20px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.16px;
            color: white;
            z-index: 2;

            @media (max-width: $mq__mobile) {
                padding: 16px 34px 16px 28px;
                font-size: 16px;
                margin-left: -35px;
            }

            div {
                position: absolute;
                right: -65px;
                background: white;
                border-radius: 100%;
                width: 84px;
                height: 84px;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: $mq__mobile) {
                    width: 64px;
                    height: 64px;
                    right: -40px;
                }

                svg {
                    height: auto;

                    @media (max-width: $mq__mobile) {
                        width: 32px;
                        height: 32px;
                    }
                }

            }

        }

    }

}