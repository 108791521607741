.h-list-container {
    margin-bottom: -$h-list-bottom-space;

    @media (max-width: $mq__tablet) {
        margin-bottom: -$h-list-bottom-space_t;
    }

    @media (max-width: $mq__mobile) {
        margin-bottom: -$h-list-bottom-space_m;
    }
}
