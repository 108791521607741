.call-modal {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background: #FFFFFF;
    border-radius: 30px;
    max-width: 790px;
    width: 100%;
    padding: 32px;
    height: fit-content;
    display: none;
    z-index: 103;

    @media (max-width: $mq__mobile) {
        height: fit-content;
        max-width: calc(100vw - 20px);
        padding: 20px 12px 16px 12px;
    }

    iframe {
        height: 100% !important;
        opacity: 1 !important;
    }

    &.is-display {
        display: block;
    }

    &__close {
        padding: 0;
        position: absolute;
        background: none;
        border: none;
        right: 24px;
        top: 24px;
        cursor: pointer;
        opacity: 0.3;

        @media (max-width: $mq__mobile) {
            right: 18px;
            top: 18px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__row {
        position: relative;

        @media (max-width: $mq__mobile) {
            display: flex;
            flex-direction: column-reverse;
            gap: 2px;
        }
    }

    &__amo {
        position: relative;

        form {
            position: relative;
            display: flex;
            gap: 24px;
            flex-direction: column;

            @media (max-width: $mq__mobile) {
                gap: 16px;
            }

            .container-field {
                max-width: 317px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                @media (max-width: $mq__mobile) {
                    margin: 0 auto;
                    width: 100%;
                }
            }

            .form-field {
                padding: 0 17px;
                font-family: 'TT Hoves', sans-serif, BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
                height: 50px;
                width: 100%;
                color: black;
                font-family: 'TT Hoves';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                border: 1px solid #cfcacf;
                border-radius: 16px;
                max-width: 317px;

                @media (max-width: $mq__mobile) {
                    padding: 0 12px;
                    height: 41px;
                    font-size: 14px;
                }

                &::placeholder {
                    color: #2222224D;
                }

                &:focus,
                &:focus-visible {
                    outline: none;
                }
            }

            .container-field {
                position: relative;

                &::after {
                    content: '*';
                    position: absolute;
                    right: 17px;
                    top: 8px;
                    font-size: 22px;
                    color: red;
                    bottom: 0;
                    display: flex;
                    align-items: center;
                }
            }

            button {
                height: 56px;
                border: none;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #FFFFFF;
                background-color: #F64F4F;
                border-radius: 20px;
                max-width: 242px;
                font-size: 20px;
                font-weight: 500;
                line-height: 150%;

                @media (max-width: $mq__mobile) {
                    max-width: 100%;
                    border-radius: 10px;
                    font-size: 16px;
                    font-weight: 400;
                    height: 48px;
                }

                &:hover {
                    opacity: 0.5;
                    transition-duration: 0.6s;
                }
            }
        }


    }

    &__timer {
        text-align: center;
        font-weight: 700;
        font-size: 54px;
        line-height: 64px;
        color: #3055D8;
        margin-top: 2px;
    }

    &__people {
        position: absolute;
        right: 0;
        top: 72px;
        max-width: 143px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: $mq__mobile) {
            margin: 0 auto;
            padding: 0;
            position: initial;
        }

        picture>img {
            margin: 0 auto;

            @media (max-width: $mq__mobile) {
                max-width: 72px;
                max-height: 72px;
            }
        }

        h5 {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            margin-top: 10px;
            letter-spacing: 0.01em;

            @media (max-width: $mq__mobile) {
                margin-top: 6px;
                font-size: 18px;
            }
        }

        p {
            margin: 0;
            opacity: 0.7;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.01em;
            margin-top: 4px;

            @media (max-width: $mq__mobile) {
                font-size: 12px;
            }
        }
    }

    &__form {
        width: 100%;

        h3 {
            font-weight: 700;
            font-size: 32px;
            line-height: 105%;
            margin-bottom: 24px;
            max-width: 545px;

            @media (max-width: $mq__mobile) {
                font-size: 20px;
                margin: 0 auto;
                margin-bottom: 16px;
                line-height: 110%;
                text-align: center;
                max-width: 180px;
            }

            b {
                color: #3055D8;
            }
        }

        p {
            margin-top: 12px;
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            opacity: 0.5;

            @media (max-width: $mq__mobile) {
                margin-top: 10px;
                text-align: center;
            }
        }
    }

    &__block {
        display: flex;
        gap: 10px;

        @media (max-width: $mq__mobile) {
            align-items: flex-end;
            padding-bottom: 10px;
            justify-content: space-between;
        }
    }

    &__dop {
        p {
            font-size: 20px;
            margin: 0;
            margin-bottom: 16px;
            font-weight: 600;
            color: black;
            opacity: 1;

            @media (max-width: $mq__mobile) {
                font-size: 18px;
            }
        }

        .radio-group {
            display: flex;
            align-items: center;
            gap: 20px;

            @media (max-width: $mq__mobile) {
                flex-direction: column;
                gap: 16px;
                align-items: baseline;
            }

            input[type="radio"]:checked,
            input[type="radio"]:not(:checked) {
                position: absolute;
                left: -9999px;
            }

            input[type="radio"]:checked+label,
            input[type="radio"]:not(:checked)+label {
                display: inline-block;
                position: relative;
                padding-left: 32px;
                line-height: 24px;
                cursor: pointer;
                white-space: nowrap;

                @media (max-width: $mq__mobile) {
                    font-size: 14px;
                }
            }

            input[type="radio"]:checked+label:before,
            input[type="radio"]:not(:checked)+label:before {
                content: "";
                position: absolute;
                left: 0px;
                top: 0px;
                width: 24px;
                height: 24px;
                border: 1px solid #D9D9D9;
                background-color: #ffffff;
            }

            input[type="radio"]:checked+label:before,
            input[type="radio"]:not(:checked)+label:before {
                border-radius: 100%;
            }

            input[type="radio"]:checked+label:after,
            input[type="radio"]:not(:checked)+label:after {
                content: "";
                position: absolute;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

            input[type="radio"]:checked+label:after,
            input[type="radio"]:not(:checked)+label:after {
                left: 6px;
                top: 6px;
                width: 12px;
                height: 12px;
                border-radius: 100%;
                background-color: #3055D8;
            }

            input[type="radio"]:checked+label:before {
                border: 1px solid #3055D8;
            }

            input[type="radio"]:not(:checked)+label:after {
                opacity: 0;
            }

            input[type="radio"]:checked+label:after {
                opacity: 1;
            }
        }

        .drop {
            @media (max-width: $mq__mobile) {
                margin-bottom: -9px;
            }

            span.msg,
            span.choose {
                color: #555;
                padding: 5px 0 10px;
                display: inherit
            }

            .dropdown.block {
                opacity: 0.5;
                pointer-events: none;

                .select>svg path {
                    fill: #222222;
                }
            }

            .dropdown {
                width: 143px;
                display: inline-block;
                background-color: #fff;
                transition: all .5s ease;
                position: relative;
                font-size: 14px;
                height: 40px;
                text-align: left;
                border-radius: 12px;
                border: 1px solid rgba(34, 34, 34, 0.30);
                color: rgba(34, 34, 34, 0.50);
                font-size: 16px;
                font-weight: 600;
                line-height: 150%;

                @media (max-width: $mq__mobile) {
                    width: 125px;
                    font-size: 14px;
                }
            }

            .dropdown .select {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 8px 10px 8px 12px;

                @media (max-width: $mq__mobile) {
                    padding: 8px;
                }
            }

            .dropdown .select>svg {
                transition: all .3s ease-in-out;
                float: right;
                max-width: 16px;
                max-height: 16px;
                min-width: 16px;
                min-height: 16px;
            }

            .dropdown.active:hover,
            .dropdown.active {
                border: 1px solid #3055D8;
            }

            .dropdown.active .select>svg {
                transform: rotate(-180deg)
            }

            .dropdown .dropdown-menu {
                position: absolute;
                background-color: #fff;
                width: 100%;
                left: 18px;
                margin-top: 10px;
                border-radius: 12px;
                overflow: hidden;
                display: none;
                max-height: 130px;
                overflow-y: auto;
                z-index: 9;
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.16);

                @media (max-width: $mq__mobile) {
                    max-height: 110px;
                    margin-top: 6px;
                }
            }

            .dropdown .dropdown-menu li {
                padding: 10px;
                transition: all .2s ease-in-out;
                cursor: pointer;
                color: black;
                font-size: 16px;
                font-weight: 600;
                line-height: 150%;
                margin: 0;

                @media (max-width: $mq__mobile) {
                    font-size: 14px;
                    padding: 8px 8px 8px 10px;
                }
            }

            .dropdown .dropdown-menu {
                padding: 0;
                list-style: none
            }

            .dropdown .dropdown-menu li:hover {
                background-color: #f2f2f2
            }

            .dropdown .dropdown-menu li:active {
                background-color: #e2e2e2
            }
        }
    }

    &__btns {
        display: flex;
        align-items: center;
        gap: 20px;

        @media (max-width: $mq__mobile) {
            flex-direction: column;
            gap: 10px;
        }

        p {
            margin: 0;
            color: rgba(34, 34, 34, 0.90);
            font-size: 16px;
            font-weight: 500;
            line-height: 125%;
            opacity: 1;

            @media (max-width: $mq__mobile) {
                font-size: 14px;
            }

            a {
                margin-left: 6px;
                color: #3055D8;
                font-size: 16px;
                font-weight: 600;
                line-height: 120%;
                text-decoration-line: underline;

                @media (max-width: $mq__mobile) {
                    display: block;
                    font-size: 14px;
                }
            }
        }
    }
}