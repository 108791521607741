.like-link {
    cursor: pointer;
    color: var(--color__link);
    transition:
            color $animation__duration_slow,
            border-color $animation__duration_slow,
            background $animation__duration_slow;
    text-decoration: none;
    outline: none !important;

    &:active,
    &:hover,
    &:focus {
        color: var(--color__link_hover);
        transition-duration: $animation__duration_slow;
    }

    &_style_light {
        color: var(--color_light);
        font-style: italic;

        &:visited {
            color: var(--color_light);

            &:active,
            &:hover,
            &:focus {
                color: var(--color__header_link);
                transition-duration: $animation__duration_slow;
            }
        }

        &:active,
        &:hover,
        &:focus {
            color: var(--color__header_link);
            transition-duration: $animation__duration_slow;
        }
    }

    &_no-v {
        &:visited {
            color: var(--color__link);

            &:active,
            &:hover,
            &:focus {
                color: var(--color__link_hover);
                transition-duration: $animation__duration_slow;
            }
        }
    }

    &_decoration {
        text-decoration: underline;
    }

}
