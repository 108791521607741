.ad-projects {

    &__row {
        margin: -30px;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        position: relative;
        @media (max-width: $mq__mobile) {
            margin: -16px;
        }
    }

    &__wrapper {
        padding: 30px !important;
        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__card {
        margin: 30px 32px 30px 0;
        position: relative;
        width: 425px;
        flex-shrink: 0;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        overflow: hidden;
        cursor: grab;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            width: 260px;
            margin-right: 16px;
            .projects__img img {
                height: auto;
            }
        }

        &:active {
            cursor: grabbing;
        }

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #BEBEBE;

            .projects__img {
                mix-blend-mode: initial;
                &:before {
                    opacity: 0;
                }
            }
        }
    }

    &__data {
        padding: 18px;
        min-height: 111px;
    }

    &__name {
        max-width: 160px;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #222222;

        &_small {
            max-width: 300px;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.7);
        }
    }

    &__link {
        position: absolute;
        padding: 0;
        bottom: 18px;
        right: 18px;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        background: transparent;
        border: none;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        text-align: center;
        cursor: pointer;
        color: #3055d8;
        flex-shrink: 0;
        transition: $animation__duration_fast;
        @media (max-width: $mq__mobile) {
            width: 24px;
            height: 24px;
            right: 16px;
            bottom: 16px;
        }
    }

    &__more {
        position: absolute;
        padding: 0;
        bottom: 18px;
        right: 18px;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        background: #3055D8;
        border: none;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        text-align: center;
        cursor: pointer;
        color: #EEF2F5;
        flex-shrink: 0;
        transition: $animation__duration_fast;

        @media (max-width: $mq__mobile) {
            width: 24px;
            height: 24px;
            right: 16px;
            bottom: 16px;
        }

        svg {
            margin: 0 auto;
            width: 11px;
            height: 11px;
            @media (max-width: $mq__mobile) {
                width: 9px;
                height: 9px;
            }
        }

        &:hover {
            box-shadow: 0 0 10px #3055d8;
        }
    }

    &__img {
        flex-shrink: 0;
        position: relative;
        background: #fff;
        mix-blend-mode: luminosity;
        transition: $animation__duration_slow;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(52.99deg, rgba(0, 0, 0, 0.4) 42.64%, rgba(192, 192, 192, 0.4) 93.25%);
            transition: $animation__duration_slow;
        }

        @media (max-width: $mq__mobile) {
            mix-blend-mode: initial;
            img {
                height: 336px;
                width: 100%;
                object-fit: cover;
            }
        }
    }


    &__modal {
        position: fixed;
        display: none;
        align-items: stretch;
        background: #FFFFFF;
        border-radius: 30px;
        z-index: 1000;
        opacity: 0;
        visibility: hidden;
        margin: 0 auto;
        max-width: 860px;
        height: 100%;
        width: 100%;
        max-height: 448px;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        transition: $animation__duration_slow;

        &.is-display {
            display: -webkit-flex;
            display: flex;
            opacity: 1;
            visibility: initial;
            animation: is-display $animation__duration_slow linear;
        }

        @media (max-width: $mq__tablet) {
            top: 0;
            transform: none;
            height: 100vh;
            width: 100vw;
            align-items: normal;
            flex-direction: column-reverse;
            max-width: initial;
            max-height: initial;
            border-radius: 0;
        }

        &_wrapper {
            padding: 24px;
            width: calc(100% - 336px);
            @media (max-width: $mq__tablet) {
                width: 100%;
                height: 100%;
                padding: 16px;
            }
        }

        &_title {
            margin-bottom: 32px;
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #222222;
        }

        &_caption {
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.8);
        }

        &_text {
            margin-bottom: 32px;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.7);
        }

        &_row {
            display: -webkit-flex;
            display: flex;
            gap: 12px;

            @media (max-width: $mq__mobile) {
                flex-direction: column;
                align-items: center;
            }
        }

        &_item {
            max-width: 265px;
        }

        &_blue {
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            color: #3055D8;
            text-align: center;
        }

        &_info {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            color: rgba(0, 0, 0, 0.7);
        }

        &_img {
            flex-shrink: 0;
            background: #fff;
            position: relative;
            overflow: hidden;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;

            @media (max-width: $mq__tablet) {
                border-radius: 0;
                max-height: 30%;
                width: 100%;
                height: 100%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(52.99deg, rgba(0, 0, 0, 0.4) 42.64%, rgba(192, 192, 192, 0.4) 93.25%);
            }
        }

        &_close {
            position: absolute;
            top: 24px;
            right: 24px;
            width: 34px;
            height: 34px;
            padding: 7px;
            color: #EEF2F5;
            background: transparent;
            border: 0;
            cursor: pointer;

            @media (max-width: $mq__tablet) {
                top: 16px;
                right: 16px;
            }
        }
    }
}