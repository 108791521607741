.work {
    &__body {
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex: 1 1 0;
        gap: 32px;
        @media (max-width: $mq__tablet) {
            flex-direction: column;
        }

        @media (max-width: $mq__mobile) {
            gap: 16px;
        }
    }

    &__card {
        width: 50%;
        padding: 32px;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        transition-duration: $animation__duration_slow;

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #BEBEBE;
        }

        @media (max-width: $mq__tablet) {
            width: 100%;
        }

        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__title {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 42px;
        align-items: center;
        font-weight: 600;
        font-size: 28px;
        line-height: 33px;
        letter-spacing: 0.03em;
        color: #3055D8;

        @media (max-width: $mq__mobile) {
            margin-bottom: 24px;
            font-size: 22px;
            line-height: 26px;
        }

        svg {
            width: 33px;
            height: 33px;
            margin-right: 16px;
        }
    }

    &__row {
        display: -webkit-flex;
        display: flex;
        gap: 42px;

        @media (max-width: $mq__mobile) {
            flex-direction: column;
            gap: 24px;
        }
    }

    &__col {
        width: 50%;

        @media (max-width: $mq__mobile) {
            width: 100%;
        }

        p{
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            color: rgba(0,0,0,.6);
            @media (max-width: $mq__mobile) {
                font-size: 16px;
            }
        }
    }

    &__caption {
        margin-bottom: 18px;
        font-weight: 700;
        font-size: 21px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.8);

        @media (max-width: $mq__mobile) {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__item {
        margin-left: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);

        @media (max-width: $mq__mobile) {
            font-size: 14px;
        }
    }

    &__bold {
        text-align: center;
        font-weight: bold;
        margin-bottom: 18px;
    }

    &__text {
        text-align: center;
        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }
}

.seo-page, .template {
  .work {
      &__body {
          flex-wrap: wrap;
      }
      &__card {
          width: 100%;
      }
  }
}

.work-price {
    &__block {
        h3 {
            color: #3055D8;
            margin-bottom: 20px;
        }

        ul {
            margin-bottom: 16px;
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        p {
            margin-bottom: 8px;
        }
    }
}