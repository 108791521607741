.review {
    &__body {
        position: relative;
        padding-bottom: 20px;

        @media (max-width: $mq__tablet) {
            margin-bottom: 40px;
        }

        @media (max-width: $mq__mobile) {
            padding-bottom: 0;
            margin-bottom: 55px;
        }
    }

    &__link {
        position: absolute;
        bottom: -40px;
        right: -50px;
        padding: 14px 28px 14px 28px;
        display: -ms-inline-flexbox;
        display: inline-flex;
        width: auto;
        -ms-flex-item-align: end;
        align-self: end;
        justify-self: end;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        background: #3055d8;
        color: #eef2f5;
        border: 1.5px solid #3055d8;
        border-radius: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 149.3%;
        letter-spacing: 0.03em;
        transition: 0.6s;

        &:hover {
            box-shadow: 0 0 10px #3055d8;
        }

        @media (max-width: 1024px) {
            right: 0;
        }

        @media (max-width: $mq__mobile) {
            margin-bottom: -15px;
        }
    }

    &__row {
        margin: 0 -30px;
        display: flex;
        align-items: start;
        position: relative;

        @media (max-width: $mq__tablet) {
            margin: 0 -16px;
        }
    }

    &__wrapper {
        padding: 30px;

        @media (max-width: $mq__tablet) {
            padding: 16px;
        }
    }

    &__card,
    .review-slider-all__item {
        position: relative;
        padding: 32px;
        width: 360px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-shrink: 0;
        background: #ffffff;
        box-shadow: 0 0 20px #dbdbdb;
        border-radius: 30px;
        cursor: pointer;
        transition: $animation__duration_slow;

        &:not(:last-of-type) {
            margin-right: 32px;
        }

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #bebebe;
        }

        @media (max-width: $mq__mobile) {
            padding: 16px;
            width: 260px;

            &:not(:last-of-type) {
                margin-right: 16px;
            }
        }
    }

    .review-slider-all__item {
        cursor: grab;
    }

    &__name {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.01em;
        color: #222222;
    }

    &__text {
        margin-bottom: 18px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    &__img {
        min-width: 92px;
        height: 55px;
        border-radius: 12px;
        position: relative;
        overflow: hidden;

        @media (max-width: $mq__tablet) {
            min-width: 75px;
            max-width: 85px;
        }

        @media (max-width: 767px) {
            min-width: 60px;
            max-width: 70px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &:before {
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background-color: rgba(57, 57, 57, 0.5);
            background-position: center;
            background-repeat: no-repeat;
        }
    }
}

.review-widget_net {
    margin-top: 60px;
}

review-lab .widget__pag {
    z-index: 2 !important;
}

.review-tabs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 16px;
    max-width: fit-content;
    background-color: rgba(43, 43, 43, 0.05);
    margin-bottom: 20px;
    padding: 3px;

    @media (max-width: 413px) {
        margin-bottom: 10px;
    }

    &__item {
        border-radius: 16px;
        transition: background-color 0.15s;
        padding: 8px 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 5px;

        @media (max-width: 374px) {
            padding: 8px 10px;
        }

        &:hover {
            background-color: rgba(43, 43, 43, 0.05);
        }

        svg {
            max-width: 20px;
            height: 20px;
        }
    }

    .active {
        background: white;
        cursor: default;
    }
}

.review-all {
    display: none;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;

    @media (max-width: 413px) {
        font-size: 14px;
        margin-bottom: 10px;
    }

    &__stars {
        display: flex;
        align-items: center;
        gap: 2px;

        img {
            @media (max-width: 359px) {
                max-width: 16px;
            }
        }
    }

    &.active {
        display: flex;
    }

    P {
        margin: 0;

        b {
            font-weight: 600;
        }
    }
}

.review-slider-all {
    position: relative;
    margin: 0 -30px;

    @media (max-width: $mq__mobile) {
        margin: 0;
    }

    .js-horScroll-review-all>.swiper-wrapper,
    .js-horScroll-review-all-dop>.swiper-wrapper,
    .js-horScroll-review-video>.swiper-wrapper,
    .js-horScroll-review-yandex>.swiper-wrapper,
    .js-horScroll-review-google>.swiper-wrapper {
        padding: 30px;

        @media (max-width: $mq__mobile) {
            padding: 30px 8px;
        }
    }

    .js-horScroll-review-yandex>.swiper-wrapper>.reviews__card {
        height: 362px;
        justify-content: initial;

        &-open {
            height: auto !important;
        }

        @media (max-width: $mq__mobile) {
            height: 411px;
        }
    }

    .js-horScroll-review-google>.swiper-wrapper>.reviews__card {
        height: 305px;
        justify-content: initial;

        &-open {
            height: auto !important;
        }

        @media (max-width: $mq__mobile) {
            height: 328px;
        }
    }

    .js-horScroll-review-all>.swiper-wrapper>.reviews__card,
    .js-horScroll-review-all-dop>.swiper-wrapper>.reviews__card {
        height: 362px;
        justify-content: initial;

        @media (max-width: $mq__mobile) {
            height: 352px;
        }

        &-open {
            height: auto !important;

            @media (max-width: $mq__mobile) {
                height: auto;
            }
        }

        .reviews__card2-stars {
            @media (max-width: $mq__mobile) {
                margin-bottom: 5px;
            }
        }
    }

    .js-horScroll-review-all>.swiper-wrapper>[data-videoid],
    .js-horScroll-review-all-dop>.swiper-wrapper>[data-videoid],
    .js-horScroll-review-video>.swiper-wrapper>[data-videoid] {
        @media (max-width: $mq__mobile) {
            height: max-content;
            justify-content: initial;
        }
    }

    .review-btns-prev,
    .review-btns-next {
        button {
            display: none;
        }

        button.active {
            display: flex;
        }

        button.swiper-button-disabled {
            display: none;
        }
    }

    .js-tab-content {
        display: none;
    }

    .active {
        display: block;

        @media (max-width: $mq__mobile) {
            overflow: initial;
        }
    }
}

@media (max-width: $mq__mobile) {
    .review-sliders {

        .review-slider-all__item,
        .review__card {
            width: 340px !important;
        }

        .review__card {
            justify-content: space-between !important;
        }

        .review__name {
            max-width: 200px;
        }
    }
}

@media (max-width: 359px) {
    .review-sliders {

        .review-slider-all__item,
        .review__card {
            width: 270px !important;
        }
    }
}