.feedback-top {
    padding-bottom: 0;

    p {
        font-size: 24px;

        @media (max-width: $mq__mobile) {
            font-size: 16px;
        }
    }
}

.promo__text-feedback {
    max-width: 1020px !important;
}

.partners__title {
    font-size: 38px;
}

.partners__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;

    @media (max-width: $mq__tablet) {
        flex-direction: column;
    }
}

.partners__card {
    text-align: center;
    padding: 32px;
    background: #fff;
    -webkit-box-shadow: 0 0 20px #dbdbdb;
    box-shadow: 0 0 20px #dbdbdb;
    border-radius: 30px;
    -webkit-transition: 0.6s;
    transition: 0.6s;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 calc((100% - 32px * 3) / 4);
    flex: 0 0 calc((100% - 32px * 3) / 4);

    @media (max-width: $mq__tablet) {
        flex: 0 0 100%;
        margin-bottom: 24px;
    }
}

.partners__card_caption {
    margin: 0 0 12px;
    font-weight: 600;
    font-size: 48px;
    line-height: 57px;
    letter-spacing: 0.03em;
    color: #3055d8;
}

.partners__card_text {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.01em;
    color: #222;
}

.promo__text-feedback {
    max-width: 1020px;
}

.partners {
    &__body {}

    &__title {
        font-size: 38px;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
    }

    &__card {
        text-align: center;
        padding: 32px;
        background: #fff;
        box-shadow: 0 0 20px #dbdbdb;
        border-radius: 30px;
        transition: 0.6s;
        flex: 0 0 calc((100% - 32px * 3) / 4);

        &_caption {
            margin: 0 0 12px;
            font-weight: 600;
            font-size: 48px;
            line-height: 57px;
            letter-spacing: 0.03em;
            color: #3055d8;
        }

        &_text {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            text-align: center;
            letter-spacing: 0.01em;
            color: #222;
        }
    }
}

.reviews {
    &__body {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: -60px;
    }

    &__row {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin-bottom: 60px;

        @media (max-width: 767px) {
            &:last-child {
                margin-bottom: 75px;
            }
        }
    }

    &__headline {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        &-title {
            font-size: 38px;
            margin-bottom: 10px;
            display: flex;
            align-items: center;

            @media (max-width: $mq__mobile) {
                font-size: 24px;
            }
        }

        &-counter {
            font-family: "TT Hoves";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: rgba(6, 37, 38, 0.7);
        }

        &-logo {
            margin-left: 15px;

            @media (max-width: $mq__mobile) {
                max-width: 90px;
            }
        }

        &-rate {
            display: flex;
            align-items: center;
            margin-bottom: 6px;

            &-text {
                font-family: TT Hoves;
                font-size: 32px;
                font-weight: 700;
                letter-spacing: 0px;
                text-align: left;
                margin-right: 16px;

                @media (max-width: $mq__mobile) {
                    font-size: 22px;
                    margin-right: 10px;
                }
            }

            &-line {
                display: flex;
                align-items: center;
            }

            &-img {
                margin-right: 5px;
            }
        }
    }

    &__slider {
        position: relative;
        margin: -30px;
    }

    &__box {
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-flex-align: start;
        align-items: start;
        z-index: 2;
        padding: 30px;
    }

    &__item {
        // height: 244px;
        // width: 460px;
        margin-right: 16px;
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-align: center;
        align-items: center;
        position: relative;
    }

    &__wrapper--2 {
        align-items: flex-start;
    }

    &__card {
        width: 460px;
        // height: 178px;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        padding: 32px;
        background: #fff;
        box-shadow: 0 0 20px #dbdbdb;
        border-radius: 30px;
        overflow: hidden;
        cursor: -webkit-grab;
        cursor: grab;
        transition: 0.6s;
        display: flex;
        flex-direction: column;

        @media (max-width: $mq__mobile) {
            max-width: calc(100vw - 60px);
            padding: 16px;
        }
    }

    &__card1 {
        &-text {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            // opacity: 0.9;
            margin-bottom: 10px;
        }

        &-bottom {
            display: flex;
            justify-content: space-between;
        }

        &-name {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0.01em;
            text-align: left;
        }

        &-img {
            display: block;
        }
    }

    &__card2 {
        height: 338px;

        &-top {
            display: flex;
            margin-bottom: 16px;

            &-col {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            &-name {
                font-family: TT Hoves;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 6px;
            }

            &-date {
                font-family: TT Hoves;
                font-size: 16px;
                font-weight: 400;
                line-height: 19px;
                letter-spacing: 0.01em;
                text-align: left;
                opacity: 0.4;
            }

            &-img {
                margin-right: 16px;
                width: 52px;
                height: 52px;
                border-radius: 50%;
            }

            &-ballon {
                min-width: 52px;
                width: 52px;
                height: 52px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                color: white;
                margin-right: 16px;
                background-color: #6ca632;
            }
        }

        &-stars {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            &-img {
                margin-right: 6px;
            }
        }

        &-text {
            position: relative;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            // opacity: 0.9;
            overflow: hidden;
            min-height: 144px;

            &.unshowed {
                max-height: 144px;
                position: relative;

                &:after {
                    content: "...еще";
                    display: block;
                    position: absolute;
                    color: lightgrey;
                    bottom: 0px;
                    right: 0px;
                    padding: 0 20px 0 5px;
                    background-color: white;
                    z-index: 2;
                    opacity: 1;
                }
            }

            &.show {
                max-height: none;

                &:after {
                    content: none;
                }
            }
        }

        &-name {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 700;
            line-height: 19px;
            letter-spacing: 0.01em;
            text-align: left;
        }

        &-img {
            display: block;
        }
    }

    &__card3 {
        position: relative;
        height: 305px;

        &-date {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.01em;
            text-align: left;
            opacity: 0.4;
            margin-bottom: 8px;
        }

        &-stars {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            &-img {
                margin-right: 6px;
            }
        }

        &-text {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 16px;
            overflow: hidden;
            min-height: 144px;
            position: relative;

            // opacity: 0.8;
            &.unshowed {
                max-height: 144px;
                position: relative;

                &:after {
                    content: "...еще";
                    display: block;
                    position: absolute;
                    color: lightgrey;
                    bottom: 0px;
                    right: 0px;
                    padding: 0 20px 0 5px;
                    background-color: white;
                    z-index: 2;
                    opacity: 1;
                }
            }

            &.show {
                max-height: none;

                &:after {
                    content: none;
                }
            }
        }

        &-name {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            opacity: 0.6;
        }

        &-icon {
            display: block;
            position: absolute;
            top: 32px;
            right: 32px;
        }
    }

    &__card4 {
        &-rate {
            display: flex;
            margin-bottom: 16px;

            &-text {
                font-family: TT Hoves;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0em;
                text-align: left;
                margin-right: 8px;
            }
        }

        &-date {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.01em;
            text-align: left;
            opacity: 0.4;
            margin-bottom: 8px;
        }

        &-stars {
            display: flex;
            align-items: center;

            &-img {
                margin-right: 6px;
            }
        }

        &-text {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 32px;
            // opacity: 0.8;
        }

        &-name {
            font-family: TT Hoves;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.01em;
            text-align: left;
            margin-bottom: 8px;
            opacity: 0.6;
        }

        &-under {
            display: flex;
            align-items: center;
            font-family: TT Hoves;
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;

            &-img {
                margin-right: 8px;
            }
        }
    }

    &__card5 {
        &-top {
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            &-col {
                display: flex;
                flex-direction: column;
            }

            &-firm {
                font-family: TT Hoves;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 4px;
            }

            &-line {
                display: flex;
                align-items: center;
                margin-bottom: 4px;
            }

            &-stars {
                display: flex;
                align-items: center;

                &-img {
                    width: 16px;
                    height: 16px;
                    margin-right: 5px;
                }
            }

            &-date {
                font-family: TT Hoves;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                opacity: 0.6;

                span {
                    color: rgba(58, 122, 243, 1);
                }
            }

            &-rang {
                font-family: TT Hoves;
                font-size: 14px;
                font-weight: 500;
                line-height: 21px;
                letter-spacing: 0em;
                text-align: left;
                opacity: 0.6;
            }
        }

        &-img {
            margin-right: 8px;
            border-radius: 100%;
            max-width: 70px;
            height: 70px;
        }

        &-rate {
            display: flex;
            margin-bottom: 16px;

            &-text {
                font-family: TT Hoves;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0em;
                text-align: left;
                margin-right: 8px;
            }
        }

        &-date {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0.01em;
            text-align: left;
            opacity: 0.4;
            margin-bottom: 8px;
        }

        &-stars {
            display: flex;
            align-items: center;

            &-img {
                margin-right: 6px;
            }
        }

        &-text {
            font-family: TT Hoves;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 32px;
            // opacity: 0.8;
        }

        &-name {
            font-family: TT Hoves;
            font-size: 14px;
            font-weight: 500;
            line-height: 16px;
            letter-spacing: 0.01em;
            text-align: left;
            margin-bottom: 8px;
            opacity: 0.6;
        }

        &-bottom {
            display: flex;
            justify-content: flex-end;
        }

        &-icon {
            width: 108px;
            object-fit: contain;
        }
    }

    &__btn {
        background-color: rgba(48, 85, 216, 1);
        padding: 14px 18px 14px 28px;
        border-radius: 20px;
        color: #eef2f5;
        font-family: TT Hoves;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.03em;
        text-align: left;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        &-icon {
            margin-left: 8px;
            display: inline-block;
        }

        &-wrap {
            margin-top: 32px;
            display: flex;
            justify-content: flex-end;
        }
    }
}

.reviews .section__body {
    @media (max-width: $mq__tablet) {
        overflow: hidden;
    }
}

.reviews__row:last-child {
    @media (max-width: $mq__tablet) {
        .reviews__headline-logo {
            max-width: 50%;
        }
    }
}

.reviews__card5-top-line {
    @media (max-width: $mq__tablet) {
        flex-direction: column;
        align-items: baseline;

        .reviews__card5-top-date {
            display: block;
        }
    }
}

.reviews__card {
    overflow: hidden;
    position: relative;

    .show-all-container {
        position: absolute;
        right: 0;
        bottom: 0;

        button {
            background: white;
            border: none;
            color: #00000066;
            cursor: pointer;
            padding: 0;
        }
    }

    .show-all-container-inline {
        width: fit-content;
        display: contents;
    }
}

.reviews__card-open {
    height: auto;
}