@keyframes t-arrow-bottom {
    0% {
        transform: translateY(0)
    }
    50% {
        transform: translateY(-7px)
    }
    55% {
        transform: translateY(-7px)
    }
    100% {
        transform: translateY(0)
    }
}

.to-top {
    $topAttowWidth: 30px;
    $topAttowHeight: 20px;
    position: fixed;
    top: 16px;
    right: -100px;
    color: var(--color_primary);
    cursor: pointer;
    transform: rotate(180deg);
    width: $topAttowWidth;
    height: $topAttowHeight;
    display: block;
    border: none;
    outline: none;
    visibility: hidden;
    opacity: 0;
    z-index: 2;


    @media (max-width: $mq__mobile) {
        top: 14px;
        right: 10px;
    }

    &__item {
        position: absolute;
        right: 0;
        left: 0;
        height: 2px;
        background: #fff;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4);
        transition-property: background-color;
        transition-duration: $animation__duration_fast;
        //border-radius: 2px;

        &_top {
            top: 0;
        }
        &_center {
            top: 50%;
            margin-top: -1px;
        }

        &_bottom {
            bottom: 0;
        }

    }

    &__link {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
        width: 100px;
        height: 100px;
        z-index: 100;
        display: block;
        border: none !important;
        text-indent: -999px;
        overflow: hidden;

        @media (max-width: $mq__mobile) {
            width: 50px;
            height: 50px;
            margin-top: -25px;
            margin-left: -25px;
        }
    }

    &__link:focus ~ &__item {
        background: var(--color_focus);
    }

    &__svg {
        animation: t-arrow-bottom 3s infinite ease;
    }

    svg {
        width: ($topAttowWidth);
        height: ((14/28) * $topAttowWidth );
        -webkit-filter: drop-shadow(5px 5px 3px rgba(0,0,0,0.4));
        filter: drop-shadow(-5px -5px 3px rgba(0,0,0,0.4));
    }

    .is-to-top & {
        right: 16px;
        visibility: visible;
        opacity: 1;
        transition:
                right $animation__duration_slowest $ease__opacity,
                visibility $animation__duration $ease__opacity,
                opacity $animation__duration $ease__opacity;
        transition-delay: 300ms;

        @media (max-width: $mq__mobile) {
            right: 14px;
        }
    }


}
