.menu-button {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 12px;
    width: 18px;
    cursor: pointer;

    &.is-open {
        height: 14px;
        //z-index: 101;
    }

    &__line {
        display: block;
        height: 2px;
        width: 100%;
        background: #EEF2F5;

        &_1 {
            transform-origin: 0 0;
            transition: transform 0.2s ease-in-out;
        }

        &_2 {
            transition: transform 0.1s ease-in-out;
        }

        &_3 {
            transform-origin: 0 100%;
            transition: transform 0.2s ease-in-out;
        }
    }

    &.is-open &__line {

        &_1 {
            transform: rotate(45deg);
        }

        &_2 {
            transform: scaleY(0);
        }

        &_3 {
            transform: rotate(-45deg);
        }
    }
}