.contacts {
    padding-top: 0;
    padding-bottom: 120px;

    @media (max-width: 768px) {
        padding-bottom: 100px;
    }

    .breadcrumbs {
        padding: 0;

        &__separator {
            svg>g>path {
                fill: #3055D8;
            }

            &-mob {
                @media (max-width: 768px) {
                    svg>g>path {
                        fill: #3055D8;
                    }
                }
            }
        }

    }

    h1 {
        margin-top: 64px;
        margin-bottom: 62px;
        color: #222;
        font-size: 55px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.55px;
        text-shadow: none;

        @media (max-width: 768px) {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 32px;
            line-height: 130%;
        }
    }

    &__row {
        display: flex;
        gap: 32px;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 20px;
        }

        >div {
            max-width: 33%;
            width: 100%;

            @media (max-width: 768px) {
                max-width: 100%;
            }
        }
    }

    &__form {
        h3 {
            color: #222;
            font-size: 24px;
            font-weight: 600;
            line-height: normal;
            letter-spacing: 0.24px;
            margin-bottom: 32px;

            @media (max-width: 768px) {
                font-size: 20px;
                margin-bottom: 20px;
            }
        }

        form {
            padding-top: 48px;
            padding-bottom: 48px;
            padding-left: 40px;
            padding-right: 40px;
            width: 100%;
            max-width: 480px;
            background: rgba(48, 85, 216, 0.10);
            box-shadow: 0 0 20px #DBDBDB;
            border-radius: 30px;
            align-self: start;
            z-index: 1;
            position: relative;
            display: flex;
            gap: 30px;
            flex-direction: column;

            @media (max-width: 768px) {
                max-width: 382px;
                padding: 24px 16px;
                padding-bottom: 20px;
            }

            @media (max-width: $mq__mobile) {
                gap: 32px;
                padding-bottom: 0px;
            }

            .form-field {
                font-family: 'TT Hoves', sans-serif, BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
                height: 36px;
                width: 100%;
                border: none;
                color: rgba(0, 0, 0, 0.50);
                ;
                font-family: 'TT Hoves';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 150%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.50);
                ;
                background: initial;

                &::placeholder {
                    color: #2222224D;
                }

                &:focus,
                &:focus-visible {
                    outline: none;
                }

                @media (max-width: $mq__mobile) {
                    height: 32px;
                    font-size: 12px;
                    line-height: 150%;
                }

                &.error {
                    &+.form-error {
                        display: block;
                    }
                }
            }

            .form-error {
                display: none;
                font-size: 16px;
                margin-top: 6px;
                color: red;
            }

            .container-field {
                position: relative;

                &::after {
                    content: '*';
                    position: absolute;
                    right: 17px;
                    top: 8px;
                    font-size: 22px;
                    color: red;
                    display: flex;
                    align-items: center;

                    @media (max-width: $mq__mobile) {
                        right: 5px;
                    }
                }
            }

            button {
                position: absolute;
                right: -5px;
                bottom: -88px;
                height: 51px;
                border: none;
                max-width: 236px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #FFFFFF;
                background: #F64F4F;
                border-radius: 53px;

                @media (max-width: $mq__mobile) {
                    left: 0;
                    min-width: calc(100vw - 32px);
                    height: 45px;
                    bottom: -65px;
                    border-radius: 10px;
                }

                &:hover {
                    opacity: 0.5;
                    transition-duration: 0.6s;
                }
            }

            .quiz__step-result-form-policy {
                pointer-events: initial;

                @media (max-width: $mq__mobile) {
                    font-size: 12px;
                }

                a {
                    color: #006ff7;
                    font-size: 15px;
                }

                .checkmark {
                    top: 4px;
                    width: 18px;
                    height: 18px;
                    border: 2px solid rgba(0, 0, 0, 0.5);

                    &::after {
                        top: 1px;
                        left: 5px;
                        width: 5px;
                        height: 10px;
                        border-color: rgba(0, 0, 0, 0.5);
                    }
                }
            }
        }
    }

    &__col-1 {
        display: flex;
        flex-direction: column;
        gap: 32px;

        @media (max-width: 768px) {
            gap: 20px;
        }

        svg {
            width: 36px;
            height: 36px;
        }

        p {
            margin: 0;
        }

        p:first-child {
            a:first-child {
                margin-top: 8px;
                margin-bottom: 8px;
            }
        }

        .contacts__grafik {
            span {
                margin-top: 8px;
                margin-bottom: 4px;
            }

            p {
                color: rgba(34, 34, 34, 0.60);
                font-size: 14px;
                font-weight: 400;
                line-height: normal;
                letter-spacing: 0.14px;
            }
        }

        .contacts__social {
            p {
                font-size: 16px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.16px;
                margin-bottom: 10px;
            }

            svg {
                display: inline;

                &:first-child {
                    margin-right: 24px;
                }
            }
        }
    }

    &__col-2 {
        p {
            margin: 0;

            &:first-child {
                margin-bottom: 76px;

                @media (max-width: 768px) {
                    margin-bottom: 20px;
                }
            }
        }

        .chat-to-us {
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            margin-top: 52px;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.01em;
            color: #222222;

            @media (max-width: $mq__tablet) {
                margin: 0 0 16px;
                display: block;
                margin-top: 20px;
            }

            .chat-text {
                display: block;
                margin-bottom: 10px;
            }

            a {
                display: inline-flex;
                flex-direction: column;
                align-items: center;
                margin-right: 16px;
                font-weight: 400;
                font-size: 10px;
                line-height: 12px;
                color: #222222;

                &:last-child {
                    gap: 10px;

                    @media (max-width: $mq__mobile) {
                        gap: 15px;
                    }
                }
            }

            .telegram-contact {
                background: transparent url('../img/telegram.svg') 0 0;
                width: 36px;
                height: 36px;
                display: block;
                margin-bottom: 15px;
            }

            .whatsapp-contact {
                background: transparent url('../img/whatsapp.svg') 0 0;
                width: 44px;
                height: 44px;
                display: block;

                @media (max-width: $mq__mobile) {
                    background-position-y: 3px;
                    background-repeat: no-repeat;
                }
            }

            &-row {
                display: -webkit-flex;
                display: flex;
                align-items: end;
                justify-content: start;

                @media (max-width: $mq__tablet) {
                    display: block;
                }
            }
        }
    }
}


.contacts-modal {
    border-radius: 30px;
    background: white;
    padding: 32px;
    padding-bottom: 24px;
    max-width: 720px;
    width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 103;
    position: fixed;
    display: none;
    height: fit-content;

    @media (max-width: $mq__mobile) {
        max-width: calc(100vw - 30px);
        padding: 32px 12px 12px 12px;
        border-radius: 16px;
    }

    &__close {
        padding: 0;
        position: absolute;
        background: none;
        border: none;
        right: 20px;
        top: 20px;
        cursor: pointer;
        opacity: 1;

        @media (max-width: $mq__mobile) {
            top: 14px;
            right: 14px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }
}

.contacts-modal.is-display {
    display: block;
}

.contacts-block {
    h2 {
        font-size: 32px;
        font-weight: 700;
        line-height: 120%;
        margin: 0 auto;
        margin-bottom: 21px;
        text-align: center;
        max-width: 445px;

        @media (max-width: $mq__mobile) {
            font-size: 24px;
            margin-bottom: 14px;
        }

        b {
            background: linear-gradient(267deg,#672a8d -11.98%,#9742cc 9.04%,#9776e8 58.92%,#3055d8 121.97%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    &__text-1 {
        font-size: 20px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0%;
        margin-bottom: 12px;
        position: relative;
        padding-left: 38px;

        @media (max-width: $mq__mobile) {
            margin-bottom: 10px;
            font-size: 16px;
        }

        &::after {
            position: absolute;
            top: 7px;
            left: 6px;
            content: '';
            display: block;
            width: 14px;
            height: 14px;
            border-radius: 100%;
            background: rgb(48, 85, 216);
        }

        &::before {
            position: absolute;
            left: 11.5px;
            width: 1px;
            content: '';
            display: block;
            border: 2px solid rgba(48, 85, 216, 0.2);
            height: 127px;
            top: -6px;

            @media (max-width: $mq__mobile) {
                height: 220px;
            }

            @media (max-width: 413px) {
                height: 230px;
            }
        }
    }

    &__text-2 {
        font-size: 20px;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: 0%;
        margin-top: 24px;
        display: flex;
        align-items: center;

        @media (max-width: $mq__mobile) {
            font-size: 16px;
            margin-top: 19px;
            position: relative;
            padding-left: 38px;
            display: block;
        }


        b {
            font-weight: 600;
            color: rgb(48, 85, 216);
        }

        svg {
            margin-right: 14px;
            width: 25px;
            height: 25px;

            @media (max-width: $mq__mobile) {
                position: absolute;
                left: 0;
            }
        }
    }

    div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 8px;
        padding-left: 38px;

        @media (max-width: $mq__mobile) {
            flex-direction: column;
            gap: 6px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            max-width: 195px;
            height: 48px;
            width: 100%;
            border-radius: 12px;

            @media (max-width: $mq__mobile) {
                max-width: 100%;
                height: 42px;
                border-radius: 10px;
            }

            &:nth-child(1) {
                background: #28A9EB;
            }

            &:nth-child(2) {
                background: #25D366;
            }

            &:nth-child(3) {
                background: #3055D8;
                max-width: 210px;

                @media (max-width: $mq__mobile) {
                    max-width: 100%;
                }
            }

            p {
                margin: 0;
                font-size: 14px;
                font-weight: 500;
                line-height: 150%;
                letter-spacing: 0.16px;
                color: white;
            }

            svg {
                max-width: 18px;
                max-height: 18px;
                min-height: 18px;
                min-width: 18px;
            }
        }
    }
}