.stages{
    &__row{
        position: relative;
        margin: -30px;
    }

    &__box{
        padding: 30px;
    }

    &__item{
        max-width: 363px;
        width: 100%;
        background: #3055D8;
        box-shadow: 0px 0px 20px #DBDBDB;
        border-radius: 30px;
        height: 396px;
        padding-top: 40px;
        padding-bottom: 24px;
        padding-left: 24px;
        padding-right: 24px;
        position: relative;
        margin-right: 32px;
        cursor: grab;
        @media (max-width: $mq__mobile) {
            max-width: calc(100vw - 32px - 38px);
            padding-top: 32px;
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 16px;
            height: 315px;
            margin-right: 16px;
        }

        &:last-child{
            margin-right: 0;
        }

        h3{
            font-weight: 600;
            font-size: 28px;
            line-height: 110%;
            text-align: center;
            color: #FFFFFF;
            @media (max-width: $mq__mobile) {
                font-size: 20px;
            }
        }

        button{
            max-width: 320px;
            width: 100%;
            position: absolute;
            bottom: 24px;
            left: 21.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            background: #EEF2F5;
            border-radius: 20px;
            height: 59px;
            cursor: pointer;
            border: none;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #3055D8;
            @media (max-width: $mq__mobile) {
                left: 16px;
                bottom: 16px;
                max-width: calc(100% - 32px);
                font-size: 14px;
            }
            
            svg{
                width: 20px;
            }
        }
    }

    &__number{
        margin: 0 auto;
        width: 87px;
        height: 87px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 100%;
        margin-bottom: 30px;
        font-weight: 700;
        font-size: 54.375px;
        line-height: 64px;
        text-align: center;
        color: #3055D8;
        @media (max-width: $mq__mobile) {
            width: 64px;
            height: 64px;
            font-size: 40px;
            line-height: 47px;
            margin-bottom: 16px;
        }
    }
}


.stages-modal{
    max-width: 720px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px #DBDBDB;
    border-radius: 30px;
    padding: 24px;
    position: fixed;
    display: none;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    margin: 0 auto;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    transition: $animation__duration_slow;
    @media (max-width: $mq__mobile) {
        max-width: calc(100vw - 32px);
        padding-top: 48px;
        max-height: 450px;
        overflow: hidden;
    }

    &.is-display {
        display: block;
        opacity: 1;
        visibility: initial;
        animation: is-display $animation__duration_slow linear;
    }

    &__close{
        position: absolute;
        right: 30px;
        top: 34px;
        background: none;
        border: none;
        width: 31px;
        height: 21px;
        opacity: 0.3;
        cursor: pointer;
        @media (max-width: $mq__mobile) {
            right: 20px;
            top: 20px;
        }
    }

    &__title{
        display: flex;
        align-items: center;
        gap: 14px;
        margin-bottom: 24px;
        @media (max-width: $mq__mobile) {
            gap: 12px;
            margin-bottom: 10px;
        }

        h3{
            font-weight: 700;
            font-size: 28px;
            line-height: 33px;
            color: #3055D8;
            margin: 0;
            max-width: 570px;
            @media (max-width: $mq__mobile) {
                font-size: 22px;
                line-height: 108%;
            }
        }
    }

    &__number{
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        min-width: 40px;
        height: 40px;
        border: 2.5px solid #3055D8;
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;
        text-align: center;
        color: #3055D8;
        @media (max-width: $mq__mobile) {
            min-width: 32px;
            height: 32px;
            max-width: 32px;
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__text{
        display: flex;
        flex-direction: column;
        gap: 24px;
        @media (max-width: $mq__mobile) {
            gap: 10px;
            overflow-y: scroll;
            max-height: 290px;
        }

        h4{
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            color: rgba(0, 0, 0, 0.8);
            margin: 0;
            margin-bottom: 10px;
            @media (max-width: $mq__mobile) {
                font-size: 16px;
                line-height: 19px;
                margin-bottom: 6px;
            }
        }

        p{
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.6);
            margin: 0;
            @media (max-width: $mq__mobile) {
                font-size: 13px;
                line-height: 130%;
            }
        }
    }
}

.template .stages{
    &__item {
        height: auto;

        h3 {
            min-height: 92px;
        }

        button {
            position: initial;
            margin-top: 50px;

            @media (max-width: $mq__mobile) {
                margin: 0 auto;
                margin-top: 53px;
            }
        }
    }
}