a.logo {
    cursor: pointer;
}

a.logo,
a.logo:visited {
    color: #EEF2F5;
}

a.logo {

    &:active,
    &:focus,
    &:hover {
        opacity: 0.5;
    }
}

.logo {
    display: inline-block;
    cursor: default;


    &_style_simple {

        &,
        &:visited {
            color: var(--color__text) !important;
        }
    }

    &__title,
    &__caption {
        display: block;
        line-height: 1;
        white-space: nowrap;
    }

    &__title {
        font-size: 32px;
        position: relative;
        z-index: 3;
        transition: all 0.6 ease;

        @media (max-width: $mq__tablet) {
            font-size: 27px;
        }
    }

    &__caption {
        padding-top: 12px;
        font-size: var(--font-size_nav);
        text-align: center;
        top: 100%;

        @media (max-width: $mq__header-vert) {
            position: relative;
            top: auto;


        }

    }

}