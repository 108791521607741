.examples {
    &__row {
        margin: -30px;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        position: relative;
        @media (max-width: $mq__mobile) {
            margin: -16px;
        }
    }

    &__box {
        padding: 30px;
        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__card {
        margin: 30px 32px 30px 0;
        position: relative;
        max-width: 940px;
        height: 420px;
        width: 100%;
        display: -webkit-flex;
        display: flex;
        align-items: stretch;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        overflow: hidden;
        cursor: grab;
        transition: $animation__duration_slow;

        @media (max-width: $mq__mobile) {
            flex-direction: column;
            margin: 16px 16px 16px 0;
            padding: 0;
            width: 250px;
            height: 400px;
        }

        &:active {
            cursor: grabbing;
        }

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #BEBEBE;

            .examples__img {
                mix-blend-mode: initial;
                &:before {
                    opacity: 0;
                }
            }

            .examples__modal_blue {
                color: #3055D8;
            }
        }
    }

    &__data {
        display: none;

        @media (max-width: $mq__mobile) {
            display: block;
            padding: 16px;
        }
    }

    &__name {
        max-width: 180px;
        font-weight: 700;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.01em;
        color: #222222;
    }

    &__more {
        position: absolute;
        padding: 0;
        bottom: 18px;
        right: 18px;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        background: #3055D8;
        border: none;
        border-radius: 50%;
        width: 36px;
        height: 36px;
        text-align: center;
        cursor: pointer;
        color: #EEF2F5;
        flex-shrink: 0;
        transition: $animation__duration_fast;

        @media (max-width: $mq__mobile) {
            width: 24px;
            height: 24px;
            right: 16px;
            bottom: 16px;
        }

        svg {
            margin: 0 auto;
            width: 11px;
            height: 11px;
            @media (max-width: $mq__mobile) {
                width: 9px;
                height: 9px;
            }
        }

        &:hover {
            box-shadow: 0 0 10px #3055d8;
        }
    }

    &__img {
        position: relative;
        background: #fff;
        mix-blend-mode: luminosity;
        transition: $animation__duration_slow;
        width: 100%;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(52.99deg, rgba(0, 0, 0, 0.4) 42.64%, rgba(192, 192, 192, 0.4) 93.25%);
            transition: $animation__duration_slow;

            @media (max-width: $mq__mobile) {
                background: linear-gradient(20.27deg, rgba(10, 26, 2, 0.4) 6.09%, rgba(41, 163, 141, 0.4) 70.57%);
            }
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        @media (max-width: $mq__mobile) {
            height: 280px;
            mix-blend-mode: initial;
        }
    }


    &__modal {
        position: relative;
        padding: 32px;
        max-width: 500px;

        &.is-display {
            opacity: 1;
            visibility: initial;
            animation: is-display $animation__duration_slow linear;
        }

        @media (max-width: $mq__mobile) {
            display: -webkit-flex;
            display: flex;
            position: absolute;
            padding: 56px 16px;
            background: #FFFFFF;
            border-radius: 20px;
            z-index: 10;
            opacity: 0;
            visibility: hidden;
            margin: 0 auto;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            transition: $animation__duration_slow;
            align-items: normal;
        }

        &_wrapper {
            width: 100%;
            height: 100%;
        }

        &_title {
            margin-bottom: 24px;
            font-weight: 700;
            font-size: 24px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #222222;

            @media (max-width: $mq__mobile) {
                font-size: 18px;
                line-height: 130%;
            }
        }

        &_row {
            display: -webkit-flex;
            display: flex;
            flex-wrap: wrap;
            gap: 32px;

            @media (max-width: $mq__mobile) {
                flex-direction: column;
                align-items: center;
                gap: 16px;
            }
        }

        &_item {
            width: calc(50% - 16px);
            @media (max-width: $mq__mobile) {
                width: 100%;
            }
        }

        &_blue {
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            color: #222222;
            @media (max-width: $mq__mobile) {
                margin-bottom: 4px;
                font-size: 16px;
                line-height: 19px;
                color: #3055D8;
            }
        }

        &_info {
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.7);
        }

        &_link {
            position: absolute;
            width: 48px;
            height: 48px;
            bottom: 32px;
            right: 32px;
            color: #3055D8;
            @media (max-width: $mq__mobile) {
                width: 24px;
                height: 24px;
                bottom: 16px;
                right: 16px;
            }
        }

        &_close {
            display: none;

            @media (max-width: $mq__mobile) {
                display: block;
                position: absolute;
                padding: 0;
                width: 14px;
                height: 14px;
                color: #3055D8;
                background: transparent;
                border: 0;
                cursor: pointer;
                top: 21px;
                right: 21px;
            }
        }
    }
}