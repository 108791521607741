.lang {
    &__list {
        padding: 36px 20px;
        display: -webkit-flex;
        display: flex;
    }

    & &__link {
        color: #EEF2F5;
        line-height: 16px;
        opacity: 0.5;

        &:active,
        &:focus,
        &:hover {
            opacity: 0.8;
        }
    }
   & &__item {
        color: #EEF2F5;
        line-height: 16px;
    }
}