@keyframes opacity {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.nav {
    $item-gap: 16px;
    $item-gap_mobile: 10px;

    font-variant: small-caps;
    font-size: var(--font-size_nav);

    & &__list {
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: flex;
        gap: 4px;

        &::after {
            display: none;
            opacity: 0;
            position: absolute;
            left: 0;
            top: 0;
            background: white;
            content: '';
            max-width: 100%;
            width: 100%;
            height: 100px;
            z-index: 1;
        }

        &:hover {
            &::after {
                display: block;
                animation: opacity .6s forwards;
            }

            >li>a,
            >li>div {
                color: black;
                z-index: 3;

                svg {
                    path {
                        fill: black;
                    }
                }
            }
        }
    }

    li .drop {
        position: relative;
        padding-right: 32px;
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 3px;
        padding: 4px 6px 4px 16px;
        transition: all 0.6s ease;
        border-radius: 10000px;
        background: none;
        border: none;
        outline: none;
        cursor: pointer;

        svg {
            width: 24px;
            height: 24px;
            transition: all 0.6s ease;

            path {
                transition: all 0.6s ease;
            }
        }
    }

    & &__item {
        display: flex;
        align-items: center;
        height: 88px;

        &.active>div {
            background: #EEF2F5;

            svg {
                path {
                    fill: #3055D8;
                }
            }
        }

        &:hover>a,
        &:focus-within>a,
        &:focus>a,
        &:hover>div,
        &:focus-within>div,
        &:focus>div {
            background: #EEF2F5;

            svg {
                transform: rotate(180deg);

                path {
                    fill: black;
                }
            }
        }

        &:hover>ul,
        &:focus-within>ul,
        &:focus>ul {
            animation: opacity .6s forwards;
            display: flex;
            flex-direction: column;
            opacity: 1;
            gap: 12px;
            z-index: 2;
        }

        &:hover>.nav__big,
        &:focus-within>.nav__big {
            animation: opacity .6s forwards;
            opacity: 1;
            display: flex;
            z-index: 2;
        }

        .nav__big {
            position: absolute;
            width: max-content;
            margin: 0;
            background: #FFFFFF !important;
            padding-left: calc((100vw - 1440px) / 2 + 50px);
            padding-right: calc((100vw - 1440px) / 2 + 50px);
            padding-bottom: 50px;
            display: none;
            gap: 40px;
            opacity: 0;
            top: 0;
            left: 0;
            padding-top: 110px;
            max-width: 100%;
            width: 100%;

            @media (max-width: 1439px) {
                padding-left: 50px;
                padding-right: 50px;
            }

            @media (max-width: 1359px) {
                padding-left: 16px;
                padding-right: 16px;
            }

            ul {
                opacity: 1;
                position: initial;
                flex-direction: column;
                gap: 12px;
                padding: 0;
                box-shadow: initial;
                max-width: 375px;
                width: 100%;

                li:hover>a {
                    opacity: 1;
                    color: #3055D8;
                }
            }

            .nav__big-right {
                max-width: initial;
                gap: 0;

                &.active {
                    display: flex;
                }

                .nav__big-right__title {
                    margin-bottom: 24px;

                    a {
                        font-size: 32px;
                        font-weight: 400;
                        line-height: 130%;
                        font-variant: normal;
                    }
                }

                .nav__big-right__block {
                    display: flex;
                    gap: 20px;
                    font-variant: normal;
                    padding-left: 0;

                    &::after {
                        content: none;
                    }

                    .nav__big-right__col {
                        max-width: fit-content;
                        min-width: 200px;
                        margin: 0;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;

                        &:last-child {
                            overflow: auto;
                        }

                        li {
                            padding-left: 26px;
                            position: relative;
                            margin-bottom: 0;
                            font-size: initial;

                            &::after {
                                content: '';
                                display: block;
                                background: #3055D8;
                                border-radius: 100%;
                                position: absolute;
                                left: 6px;
                                top: 6.5px;
                                width: 8px;
                                height: 8px;
                            }

                            ul {
                                margin-top: 10px;
                                display: flex;
                            }

                            p {
                                margin: 0;
                                padding: 0;
                                color: initial;

                            }

                            a {
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 130%;
                                color: black;
                                transition: all 0.6s ease;

                                &:hover {
                                    color: #3055D8;
                                }
                            }
                        }

                        .nav__big-right__subtitle {
                            padding-left: 0;
                            font-size: 24px;
                            font-weight: 400;
                            line-height: 130%;

                            &::after {
                                content: none;
                            }

                            a {
                                font-size: 24px;
                                font-weight: 400;
                                line-height: 130%;
                            }
                        }
                    }
                }
            }

            .nav__big-left {
                gap: 10px;
                display: flex;
                flex-direction: column;

                li {
                    width: 100%;
                    padding-left: 0;

                    &::after {
                        content: none;
                    }

                    >div {
                        width: 100%;

                        &:hover {
                            svg {
                                transform: rotate(0deg);
                            }
                        }
                    }

                    ul {
                        display: none;
                    }
                }
            }

            #menuSubItem1 {
                display: flex;
            }

            #menuSubItem2,
            #menuSubItem3,
            #menuSubItem4 {
                display: none;
            }

            &-left {
                li {
                    height: initial;
                    margin-bottom: 0 !important;

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        min-width: 280px;
                        gap: 10px;
                        padding-left: 18px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                        padding-right: 10px;
                        border-radius: 14px;
                        font-variant: normal;

                        &:hover {
                            opacity: 1;
                            background: #EEF2F5;

                            .link {
                                color: black;
                            }

                            svg path {
                                fill: #3055D8;
                            }
                        }

                        .link {
                            color: #222;
                            font-size: 20px;
                            font-weight: 500;
                            line-height: 130%;
                            transition: color .6s;
                        }

                        p {
                            color: rgba(51, 51, 51, 0.60);
                            font-size: 12px;
                        }

                        svg {
                            width: 32px;
                            height: 32px;
                            transition: all .6s;
                            transform: rotate(0);

                            path {
                                transition: all .6s;

                                @media (max-width: $mq__tablet) {
                                    fill: white;
                                }
                            }
                        }
                    }
                }
            }
        }

        ul {
            position: absolute;
            width: max-content;
            margin: 0;
            background: #FFFFFF;
            padding: 24px;
            display: none;
            opacity: 0;
            left: 0;
            top: 0;
            max-width: 100%;
            width: 100%;
            padding-top: 110px;
            padding-left: calc((100vw - 1440px) / 2 + 50px);
            padding-right: calc((100vw - 1440px) / 2 + 50px);
            padding-bottom: 50px;

            @media (max-width: 1439px) {
                padding-left: 50px;
                padding-right: 50px;
            }

            @media (max-width: 1359px) {
                padding-left: 16px;
                padding-right: 16px;
            }

            .nav__big-right__block {
                display: flex;
                gap: 20px;
                font-variant: normal;
                padding-left: 0;

                &::after {
                    content: none;
                }

                .nav__big-right__col {
                    display: flex;
                    gap: 12px;
                    flex-direction: column;
                    opacity: 1;
                    position: initial;
                    max-width: fit-content;
                    padding: 0;
                    min-width: 200px;
                    margin: 0;

                    li {
                        padding-left: 26px;
                        font-size: initial;
                        margin-bottom: 0;

                        &::after {
                            content: '';
                        }

                        a {
                            font-size: initial;
                        }
                    }
                }
            }

            li {
                padding-left: 26px;
                position: relative;
                list-style: none;
                font-variant: normal;
                margin-bottom: 0;

                &::after {
                    content: '';
                    display: block;
                    background: #3055D8;
                    border-radius: 100%;
                    position: absolute;
                    left: 6px;
                    top: 6.5px;
                    width: 8px;
                    height: 8px;
                }

                &:first-child {
                    padding-left: 0;
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 130%;
                    margin-bottom: 10px;

                    a {
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 130%;
                    }

                    &::after {
                        content: none;
                    }
                }

                a {
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 130%;
                    color: black;
                    transition: all 0.6s ease;

                    &:hover {
                        color: #3055D8;
                    }
                }
            }
        }
    }

    & &__item.is-no-active &__link {
        color: var(--color_disabled);

        &:hover {
            color: var(--color__link_hover);
        }
    }

    & &__link {
        margin: 0;
        padding: 36px 8px;
        display: block;
        border-bottom: none;
        color: #eef2f5;
        line-height: 16px;
        font-size: 16px;

        &:active,
        &:focus,
        &:hover {
            opacity: 1;
        }

        @media (max-width: $mq__laptop) and (min-width: $mq__tablet) {
            padding: 36px 8px;

            &:last-child {
                padding: 36px 8px;
            }
        }

        @media (max-width: $mq__tablet) {
            padding: 0;
            margin: 10px 0;
            font-size: 16px;
            height: 24px;
            align-items: center;
            display: flex;
            font-weight: 500;

            &:last-child {
                padding: 0;
            }
        }
    }
}

.footer-nav {
    &__list {
        max-width: 445px;
        padding: 0;
        margin: 0;
        list-style-type: none;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media (max-width: $mq__tablet) {
            justify-content: center;
            gap: 16px;
        }
    }

    &__item {
        display: block;
        margin: 0;
        padding: 0;
    }

    &__link {
        margin: 0;
        padding: 8px 8px;
        display: block;
        border-bottom: none;
        color: #eef2f5;
        line-height: 16px;
        white-space: nowrap;

        @media (max-width: $mq__laptop) and (min-width: $mq__tablet) {
            padding: 8px 8px;
        }

        @media (max-width: $mq__tablet) {
            padding: 0;
        }
    }

    &__lang {
        display: none;

        @media (max-width: $mq__tablet) {
            display: -webkit-flex;
            display: flex;
            align-items: center;
            color: #eef2f5;

            .lang__link {
                color: #eef2f5;
                opacity: 0.5;
            }
        }
    }
}