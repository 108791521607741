.profi {
    &__body {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        position: relative;
        @media (max-width: $mq__tablet) {
            flex-direction: column;
        }
    }

    &__card {
        width: calc(50% - 16px);
        margin-bottom: 32px;
        padding: 32px;
        display: flex;
        align-items: center;
        background: #fff;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        font-weight: 600;
        font-size: 28px;
        line-height: 33px;
        letter-spacing: 0.03em;
        color: #3055D8;
        cursor: pointer;
        transition: $animation__duration_slow;
        &:nth-of-type(odd) {
            margin-right: 16px;
        }
        &:nth-of-type(even) {
            margin-left: 16px;
        }

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #BEBEBE;
            .tech__icon {
                color: #3055D8;
            }
        }

        @media (max-width: $mq__tablet) {
            border-radius: 10px;
            padding: 16px;
            font-size: 16px;
            line-height: 19px;
            width: 100%;
            margin: 0 0 16px;
            &:nth-of-type(odd) {
                margin-right: 0;
            }
            &:nth-of-type(even) {
                margin-left: 0;
            }
        }
    }

    &__icon {
        margin-right: 24px;
        width: 33px;
        height: 33px;
        flex-shrink: 0;
        transition: $animation__duration_slow;
        @media (max-width: $mq__tablet) {
            width: 24px;
            height: 24px;
            margin-right: 15px;
        }
    }
}