.form {
    $form-line-side-gap: 8px;
    $form-line-gap: 13px;

    &__line {
        margin-bottom: $form-line-gap;

        &_has-items {
            display: -webkit-flex;
            display: flex;
            margin-bottom: 0;
            margin-left: -($form-line-side-gap/2);
            margin-right: -($form-line-side-gap/2);

            @media (max-width: $mq__mobile) {
                flex-direction: column;
                margin-left: 0;
                margin-right: 0;
            }

        }
    }

    &__line-item {
        margin-bottom: $form-line-gap;
        margin-left: ($form-line-side-gap/2);
        margin-right: ($form-line-side-gap/2);
        flex-grow: 1;
        flex-basis: 0;

        @media (max-width: $mq__mobile) {
            margin-left: 0;
            margin-right: 0;
        }
    }

    &__footer {
        padding-top: 16px;
    }
}