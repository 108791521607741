.img-fixed {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;

    &__img {
        width: 100%;
        height: 100%;
        max-width: 500px;
        max-height: 500px;

        @media (max-width: $mq__mobile) {
            max-width: 90%;
            max-height: 90%;
        }
    }

    &.is-display {
        display: -webkit-flex;
        display: flex;
    }
}
