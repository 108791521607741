.overlay {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 102;

    transition:
            visibility 0s,
            opacity 0.2s;

    &.is-display {
        opacity: 1;
        visibility: visible;
    }
}
