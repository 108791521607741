.menu-mob {
    max-width: 100%;
    width: 100%;
    padding: 0 16px;

    @media (min-width: 1024px) {
        display: none;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        a {
            font-size: 14px;
            font-weight: 500;
            line-height: 31px;
        }

        button {
            background: none;
            margin: 0;
            outline: none;
            border: none;
            padding: 0;
        }
    }

    &__main {
        margin: 0;
        padding: 0;

        li {
            margin: 0;
            padding: 12px 0;
            border-bottom: 1px solid #8080802e;

            &.active {
                border-bottom: 0;
                padding: 0;

                .level {

                    li {

                        &:first-child,
                        &:last-child {
                            border-bottom: 0;
                        }
                    }
                }
            }

            a {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 16px;
                line-height: 20px;
                font-weight: 400;
                color: #fff;
                position: relative;

                &.drop {
                    &::after {
                        position: absolute;
                        line-height: 50px;
                        content: '';
                        width: 24px;
                        height: 24px;
                        background-position: 0 0;
                        background-repeat: no-repeat;
                        background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgaWQ9Imljb24iPgo8bWFzayBpZD0ibWFzazBfNjQ1M180NjAwMSIgc3R5bGU9Im1hc2stdHlwZTphbHBoYSIgbWFza1VuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeD0iMCIgeT0iMCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0Ij4KPHJlY3QgaWQ9IkJvdW5kaW5nIGJveCIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiBmaWxsPSIjRDlEOUQ5Ii8+CjwvbWFzaz4KPGcgbWFzaz0idXJsKCNtYXNrMF82NDUzXzQ2MDAxKSI+CjxwYXRoIGlkPSJhcnJvd19mb3J3YXJkX2lvcyIgZD0iTTkuMTIxNDEgMTlMNy44Nzg5MSAxNy43NTc1TDEzLjYzNjQgMTJMNy44Nzg5MSA2LjI0MjVMOS4xMjE0MSA1TDE2LjEyMTQgMTJMOS4xMjE0MSAxOVoiIGZpbGw9IndoaXRlIi8+CjwvZz4KPC9nPgo8L3N2Zz4K);
                        right: 0;
                    }
                }

                svg {
                    max-width: 20px;
                    max-height: 20px;
                    min-width: 20px;
                    min-height: 20px;
                }
            }

            .back-li {
                position: absolute;
                top: 0;
                background: #222;
                z-index: 3;
                width: 110px;

                button {
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: none;
                    border: none;
                    outline: none;
                    gap: 10px;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 31px;
                    color: white;
                }
            }
        }

        ul {
            max-height: calc(100vh - 140px);
            overflow: auto;
            margin: 0;
            padding: 0;
            display: none;

            &.active {
                display: block;
            }
        }
    }

    &__social {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;
        bottom: 145px;
        left: 0;
        right: 0;
        margin: auto;

        @media (max-width: 413px) {
            bottom: 110px;
        }

        @media (max-width: 359px) {
            bottom: 70px;
        }

        a {
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 6px;
            padding: 15px;
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            max-width: 190px;
            width: 100%;

            @media (max-width: 413px) {
                max-width: 155px;
            }

            svg {
                max-width: 40px;
                max-height: 40px;
                min-width: 40px;
                min-height: 40px;
                margin: 0;
            }
        }
    }
}