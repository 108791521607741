.predictor {
    &__row {
        width: 100%;
        margin: -30px;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        position: relative;
        @media (max-width: $mq__mobile) {
            margin: -16px;
        }
    }

    &__box {
        width: 100%;
        padding: 30px;
        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__wrapper {
        display: -webkit-flex;
        display: flex;
        align-items: start;
    }

    &__card {
        margin: 30px 0;
        position: relative;
        max-width: 622px;
        height: 320px;
        width: 100%;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        overflow: hidden;
        cursor: grab;
        transition: $animation__duration_slow;

        &:not(:last-child) {
            margin-right: 32px;
        }

        @media (max-width: $mq__mobile) {
            flex-direction: column;
            margin: 16px 16px 16px 0;
            padding: 0;
            width: 250px;
            height: 400px;
        }

        &:active {
            cursor: grabbing;
        }

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #BEBEBE;

            .predictor__img {
                mix-blend-mode: initial;
                &:before {
                    opacity: 0;
                }
            }

            .predictor__modal_blue {
                color: #3055D8;
            }
        }

        &.open {
            height: 404px;

            .predictor__img {
                height: 240px;
            }

            .predictor__data {
                display: block;
                opacity: 1;
            }
        }
    }

    &__data {
        display: none;
        padding: 32px;
        opacity: 0;

        @media (max-width: $mq__mobile) {
            opacity: 1;
            display: block;
            padding: 16px;
        }
    }

    &__text {
        max-width: 500px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
        &:not(:last-child) {
            margin-bottom: 4px;
        }

        @media (max-width: $mq__mobile) {
            font-size: 14px;
        }
    }


    &__img {
        position: relative;
        background: #fff;
        mix-blend-mode: luminosity;
        width: 100%;
        height: 100%;
        max-height: 320px;
        transition: $animation__duration_slow;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(52.99deg, rgba(0, 0, 0, 0.4) 42.64%, rgba(192, 192, 192, 0.4) 93.25%);
            transition: $animation__duration_slow;

            @media (max-width: $mq__mobile) {
                background: linear-gradient(20.27deg, rgba(10, 26, 2, 0.4) 6.09%, rgba(41, 163, 141, 0.4) 70.57%);
            }
        }

        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        @media (max-width: $mq__mobile) {
            height: 240px;
            mix-blend-mode: initial;
        }

        &_data {
            position: absolute;
            left: 32px;
            bottom: 32px;
            @media (max-width: $mq__mobile) {
                left: 16px;
                bottom: 16px;
            }
        }

        &_caption {
            margin-bottom: 10px;
            font-weight: 600;
            font-size: 28px;
            line-height: 33px;
            letter-spacing: 0.03em;
            color: #EEF2F5;
            @media (max-width: $mq__mobile) {
                font-size: 16px;
                line-height: 19px;
            }
        }

        &_text {
            font-weight: 500;
            font-size: 18px;
            line-height: 150%;
            letter-spacing: 0.01em;
            color: #EEF2F5;
            opacity: 0.6;
            @media (max-width: $mq__mobile) {
                font-size: 14px;
            }
        }
    }



}