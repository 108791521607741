.cards {
    $cards-item-gap: 20px;
    $mq__cards-vert: 870px;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;

    margin-left: -($cards-item-gap/2);
    margin-right: -($cards-item-gap/2);

    @media (max-width: $mq__cards-vert) {
        flex-direction: column;
    }

    &_col_3 &__item {
        width: (100% / 3);

        @media (max-width: $mq__cards-vert) {
            width: auto;
        }
    }

    &__card {
        height: 100%;
    }

    &__item {
        width: 50%;
        padding: 0 ($cards-item-gap/2);
        margin-bottom: $cards-item-gap;

        &_full {
            width: 100%;
        }

        @media (max-width: $mq__cards-vert) {
            width: auto;
        }

        &:first-child {
            @media (max-width: $mq__cards-vert) {
                margin-bottom: $cards-item-gap;
            }
        }
    }

}
