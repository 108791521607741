.custom-list {
    list-style-type: none;
    margin: 0 0 40px 0;
    padding: 0;

    @media (max-width: $mq__mobile) {
        margin-bottom: 30px;
    }

    &_styl_2 &__item {
        &::before {
            background: var(--color_light);
        }
    }

    &__item {
        position: relative;
        padding-left: 36px;
        margin-bottom: 24px;

        @media (max-width: $mq__mobile) {
            padding-left: 26px;
            margin-bottom: 20px;
        }

        &::before {
            content: '';
            display: block;
            width: 15px;
            height: 3px;
            background: var(--color__text);
            position: absolute;
            top: 11px;
            left: 0;

            @media (max-width: $mq__mobile) {
                width: 11px;
                height: 2px;
            }
        }
    }
}
