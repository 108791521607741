.step-list {

    &__item {
        position: relative;
        padding-left: 25px;
        border-left: 1px solid var(--color_accent_light);
        padding-bottom: 20px;

        @media (max-width: $mq__mobile) {
            padding-left: 15px;
        }

        &:before {
            position: absolute;
            content: '';
            top: -1px;
            left: -9px;
            width: 17px;
            height: 17px;
            border-radius: 50%;
            background-color: var(--color_accent);

            @media (max-width: $mq__mobile) {
                top: -1px;
                left: -8px;
                width: 15px;
                height: 15px;
            }
        }

        &:last-child {
            border-left: 0;
        }
    }

    &__title {
        position: relative;
        margin-top: 0;
        margin-bottom: 10px;
        top: -7px;

        @media (max-width: $mq__tablet) {
            top: -5px;
        }

        @media (max-width: $mq__mobile) {
            top: -3px;
        }
    }


}
