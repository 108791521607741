.possibilities {
    &__row {
        display: flex;
        flex-wrap: wrap;
        gap: 48px;

        @media (max-width: $mq__mobile) {
            gap: 24px;
        }
    }

    &__item {
        max-width: calc((100% - 96px) / 3);
        width: 100%;

        @media (max-width: $mq__mobile) {
            max-width: 100%;
        }


        &:nth-child(3)>h4,
        &:nth-child(6)>h4,
        &:nth-child(9)>h4 {
            &::before {
                display: none;
            }

        }

        h4 {
            font-size: 22px;
            font-weight: 500;
            line-height: 130%;
            letter-spacing: 0%;
            position: relative;
            margin-bottom: 42px;

            @media (max-width: $mq__mobile) {
                margin-bottom: 24px;
                font-size: 20px;
            }


            &::before {
                display: block;
                position: absolute;
                content: '';
                height: 2px;
                width: 100%;
                right: -48px;
                bottom: -22px;
                background: rgb(48, 85, 216);

                @media (max-width: $mq__mobile) {
                    display: none;
                }
            }


            &::after {
                display: block;
                position: absolute;
                content: '';
                height: 2px;
                width: 100%;
                left: 0;
                bottom: -22px;
                background: rgb(48, 85, 216);

                @media (max-width: $mq__mobile) {
                    bottom: -12px;
                }
            }
        }


        p {
            color: rgba(0, 0, 0, 0.6);
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            letter-spacing: 0%;
            margin-top: 10px;

            @media (max-width: $mq__mobile) {
                font-size: 16px;
                line-height: 140%;
            }
        }
    }
}