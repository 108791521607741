.message-404 {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 90px;

    @media (max-width: 1279px) {
        padding-top: 60px;
    }

    &__icon {
        width: 235px;
        height: 90px;
        margin-bottom: 20px;
    }
}
