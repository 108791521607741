@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;

    user-select: none;
    appearance: none;
    position: relative;
    display: inline-block;
    border: none !important;
    background-color: #d62b2b;
    color: #fff;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    vertical-align: middle;
    outline: none;
    padding: 0 24px;
    white-space: nowrap;
    height: 50px;
    line-height: 50px;
    border-radius: 20px;
    font-weight: normal;
    font-size: 16px;
    transition-property: all;
    transition-duration: $animation__duration_slow;

    @media (max-width: $mq__tablet) {
        font-size: var(--font-size_t);
        border-radius: 10px;
    }

    @media (max-width: $mq__mobile) {
        font-size: var(--font-size_m);
        height: $height__input_m;
        line-height: $height__input_m;
        padding: 0 26px;
    }

    &:hover {
        opacity: 0.8;
    }

    &_style_request-header {
        background-color: #3055D8;
        color: #EEF2F5;
        margin-left: 16px;
        &:hover {
            opacity: 1;
            color: #222222;
            background: #EEF2F5;
        }
    }

    &_space_right {
        margin-right: 15px;
    }

    &_space_bottom {
        margin-bottom: 15px;
    }

    &:active {
        opacity: 0.8;
    }

    &:focus,
    &:focus:active,
    &[disabled]:focus {
        opacity: 0.8;
    }

    &[disabled]:hover,
    &[disabled]:active,
    &[disabled]:focus,
    &[disabled],
    &_stl_spinner:hover,
    &_stl_spinner:active,
    &_stl_spinner:focus,
    &_stl_spinner {
        cursor: default;
        background: #c1c1c1 !important;
        box-shadow: none;
        color: #E1E0E0;
        line-height: 40px;
    }

    &_stl_spinner {
        position: relative;

        span {
            color: var(--color_disabled);
        }

        &:before {
            border-top: $spinner-border solid rgba(255, 255, 255, $spinner-opacity);
            border-right: $spinner-border solid rgba(255, 255, 255, $spinner-opacity);
            border-bottom: $spinner-border solid rgba(255, 255, 255, $spinner-opacity);
            border-left: $spinner-border solid #ffffff;
            transform: translateZ(0);
            animation: load8 1.1s infinite linear;
            position: absolute;
            content: '';
            display: block;
            top: 50%;
            left: 50%;
            margin-left: -($spinner-dim / 2);
            margin-top: -($spinner-dim / 2);
            width: $spinner-dim;
            height: $spinner-dim;
            border-radius: 50%;
        }
    }

}
