.clients {
    user-select: none;
    &__row {
        position: relative;
        padding: 24px 0;
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
    }

    &__img {
        display: inline-flex;
        align-items: center;
        width: auto;
        margin-right: 52px;
        flex-shrink: 0;
        cursor: grab;
        @media (max-width: $mq__mobile) {
            margin-right: 16px;

            img {
                height: 49px;
                width: 100%;
            }
        }

        &:active {
           cursor: grabbing;
        }
    }
}