.input {
    box-sizing: border-box;
    height: $height__input;
    border: none;
    background: var(--color__input);
    font-size: var(--font-size);
    padding: 0 $space__input-side;
    outline: none;
    border-radius: $border-radius;
    display: block;
    width: 100%;

    transition-property: border, box-shadow, background-color;
    transition-duration: $animation__duration_fast;

    &_fluid {
        width: 100%;
    }



    &.is-error,
    &.is-error:focus {
        color: var(--color_no);
    }

    &.is-valid,
    &.is-valid:focus {
        //border-color: $color__valid;
    }


    &:disabled,
    &.is-disabled,
    &[disabled="disabled"] {
        background: var(--color__input_disabled);
        color: var(--color__text_disabled);
    }

    &:focus,
    &:focus:active,
    &[disabled]:focus {
        box-shadow: 0 0 5px var(--color_focus);
    }

}

textarea.input {
    height: 128px;
    min-height: 128px;
    padding: $space__input-side;
    line-height: var(--line-height);
    resize: vertical;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--color__text_disabled);
}
::-moz-placeholder { /* Firefox 19+ */
    color: var(--color__text_disabled);
}
:-ms-input-placeholder { /* IE 10+ */
    color: var(--color__text_disabled);
}
:-moz-placeholder { /* Firefox 18- */
    color: var(--color__text_disabled);
}