.request-call-form {
    display: none;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;

    opacity: 0;
    visibility: hidden;

    transition:
            visibility 0s,
            opacity 0.3s;

    &.is-display {
        display: flex;
        opacity: 1;
        visibility: visible;
    }

    & iframe {
        display: flex;
        justify-content: center;
        right: auto !important;
        bottom: auto !important;
        width: 100% !important;
        max-width: 516px;
    }
}
