.img {
    margin-bottom: $space__bottom;

    &__img {
        margin-bottom: 0;
    }

    &__caption {

    }
}
