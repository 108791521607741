.cols {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $space__vside;

    @media (max-width: $mq__tablet) {
        margin-bottom: $space__vside_t;
    }

    @media (max-width: $mq__mobile) {
        margin-bottom: $space__vside_m;
    }

    @media (max-width: $mq__cols-vert) {
        flex-direction: column;
    }

    &_space-b_none {
        margin-bottom: 0;
    }

    &__item {
        width: 50%;
        padding-right: 9%;

        @media (max-width: $mq__cols-vert) {
            width: auto;
            max-width: $content-max;
            margin-bottom: $space__bottom;
        }

        &:last-child {
            @media (max-width: $mq__cols-vert) {
                margin-bottom: 0;
            }
        }
    }

    &_3 &__item {
        width: (100%/3);
        padding-right: 6%;
        margin-bottom: $space__bottom_s;

        @media (max-width: $mq__cols-vert) {
            width: 100%;
            max-width: $content-max;
            padding-right: 0;
            margin-bottom: $space__bottom;
        }

        &:last-child {
            @media (max-width: $mq__cols-vert) {
                margin-bottom: 0;
            }
        }
    }

    &_style_img-col &__item:first-child {
        width: 40%;

        @media (max-width: $mq__cols-vert) {
            width: 100%;
            padding-right: 0;
        }
    }
    &_style_img-col &__item:last-child {
        width: 60%;
        padding-right: 0;

        @media (max-width: $mq__cols-vert) {
            width: 100%;
        }
    }

    &_item_small-gap &__item {
        padding-right: 3%;
    }
}
