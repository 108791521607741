.service-description{
    &__content{
        position: relative;
        padding: 32px;
        box-shadow: 0px 0px 20px #DBDBDB;
        border-radius: 30px;
        @media (max-width: $mq__mobile) {
            padding: 16px;
            border-radius: 20px;
            padding-bottom: 52px;
        }

        h2{
            position: absolute;
            font-weight: 700;
            font-size: 50px;
            line-height: 59px;
            letter-spacing: 0.01em;
            left: 32px;
            top: 32px;
            @media (max-width: $mq__mobile) {
                font-size: 24px;
                line-height: 28px;
                position: static;
                margin-bottom: 16px;
            }
        }
    }

    &__row{
        display: flex;
        justify-content: space-between;
        gap: 64px;
        @media (max-width: $mq__mobile) {
            flex-direction: column-reverse;
            gap: 16px;
        }
    }

    .open{
        @media (max-width: $mq__mobile) {
            max-height: 100%;
            -webkit-line-clamp: initial;

            > button > svg{
                transform: rotate(-180deg);
            }
        } 
    }

    &__text{
        max-width: 750px;
        width: 100%;
        @media (max-width: $mq__mobile) {
            margin-top: 0;
            max-height: 180px;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 6;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            transition: all 2s;
        }

        

        > p{
            font-size: 20px;
            line-height: 150%;
            margin: 0;
            @media (max-width: $mq__mobile) {
                font-size: 16px;
            }
        }

        button{
            display: none;
            background: none;
            border: none;
            align-items: center;
            justify-content: center;
            gap: 6px;
            margin-top: 17px;
            margin-left: auto;
            cursor: pointer;
            @media (max-width: $mq__mobile) {
                display: flex;
                position: absolute;
                right: 16px;
                bottom: 16px;
            }

            p{
                margin: 0;
                font-weight: 600;
                font-size: 12px;
                line-height: 150%;
                color: #3055D8;
            }

            svg{
                width: 20px;
            }
        }
    }
}

.fw-description {
    margin-top: 32px;
    background: #3055d8;
    &__content {
        text-align: center;
    }
    &__title {
        color: #fff;
        margin-bottom: 24px;
    }
    &__text {
        font-size: 20px;
        color: #fff;
        @media (max-width: $mq__mobile) {
            font-size: 16px;
        }
    }
}

.about-description {
    background: #3055d8;
    position: relative;
    &:after {
        content: '';
        background: url('../img/seo-promo-sol.png');
        width: 136px;
        height: 133px;
        position: absolute;
        right: 0;
        top: 0;
    }
    &__content {
        display: flex;
        align-items: stretch;
        justify-content: center;
        @media (max-width: $mq__mobile) {
            flex-direction: column;
        }
    }
    &__title {
        color: #fff;
        margin-bottom: 24px;
    }
    &__text {
        margin: 0;
        width: 33%;
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        color: #EEF2F5;
        flex-grow: 1;
        @media (max-width: $mq__mobile) {
            font-size: 16px;
            width: 100%;
        }
        &:nth-child(1) {
            padding-right: 24px;
            border-right: 1px solid #FFFFFF;
            @media (max-width: $mq__mobile) {
                padding-right: 0;
                border-right: 0;
                padding-bottom: 12px;
                border-bottom: 1px solid #FFFFFF;
            }
        }
        &:nth-child(2) {
            width: calc(33% + 24px);
            padding-left: 24px;
            border-left: 1px solid #FFFFFF;
            padding-right: 24px;
            border-right: 1px solid #FFFFFF;
            @media (max-width: $mq__mobile) {
                width: 100%;
                padding-right: 0;
                padding-left: 0;
                border-right: 0;
                border-left: 0;
                padding-top: 12px;
                padding-bottom: 12px;
                border-top: 1px solid #FFFFFF;
                border-bottom: 1px solid #FFFFFF;
            }
        }
        &:nth-child(3) {
            padding-left: 24px;
            border-left: 1px solid #FFFFFF;
            @media (max-width: $mq__mobile) {
                padding-left: 0;
                border-left: 0;
                padding-top: 12px;
                border-top: 1px solid #FFFFFF;
            }
        }
    }
}