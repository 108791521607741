.masonry {
    position: relative;
    margin-bottom: -$h-list-bottom-space;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;

    @media (max-width: $mq__tablet) {
        margin-bottom: -$h-list-bottom-space_t;
    }

    @media (max-width: $mq__mobile) {
        margin-bottom: -$h-list-bottom-space_m;
    }

    &__item {
        position: relative;
        display: block;
        width: (100%/4);
        padding-right: 50px;

        @media (max-width: $mq__tablet) {
            width: (100%/2);
            padding-right: 40px;
        }

        @media (max-width: $mq__mobile) {
            width: (100%/2);
            padding-right: 40px;
        }

        @media (max-width: $mq__masonry_auto) {
            width: 100%;
            padding-right: 0;
        }

    }

    &_section-teaser &__item {
        width: (100%/2);
        padding-right: 14px;

        @media (max-width: $mq__section-teaser_vert) {
            width: (100%/3);
            padding-right: 50px;
        }

        @media (max-width: 790px) {
            width: (100%/2);
            padding-right: 40px;
        }

        @media (max-width: $mq__masonry_auto) {
            width: 100%;
            padding-right: 0;
        }
    }


    &_three &__item {
        width: (100%/3);
        padding-right: 60px;

        @media (max-width: 800px) {
            width: (100%/2);
            padding-right: 40px;
        }

        @media (max-width: $mq__masonry_auto) {
            width: 100%;
            padding-right: 0;
        }
    }

    &_four-thin &__item {
        width: (100%/4);
        padding-right: 60px;

        @media (max-width: 800px) {
            width: (100%/3);
            padding-right: 40px;
        }

        @media (max-width: 570px) {
            width: (100%/2);
            padding-right: 24px;
        }

        @media (max-width: 350px) {
            width: 100%;
            padding-right: 0;
        }
    }
}
