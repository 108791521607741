.card {
    $card-content-items-gap: 28px;
    $mq__card-content-items-vert: 1120px;
    $mq__card-with-img-vert: 1265px;

    background: var(--color_panel);
    padding: 36px 20px 36px 20px;

    &__cols {
        display: flex;
        height: 100%;

        @media (max-width: $mq__card-with-img-vert) {
            flex-direction: column;
        }
    }

    &__h {
        margin-top: 0;
    }

    &__photo {
        width: 52%;

        @media (max-width: $mq__card-with-img-vert) {
            width: auto;
            max-width: 500px;
            margin-bottom: 20px;
        }

        &_analytics {
            @media (max-width: $mq__card-with-img-vert) {
                margin-left: -1.5%;
                margin-top: -16px;
                max-width: 666px;
                margin-bottom: 8px;
            }
        }

        &_vpn {
            margin-bottom: 30px;
            max-width: 430px;

            @media (max-width: $mq__card-with-img-vert) {
                max-width: 300px;
                margin-top: -10px;
            }
        }

        &_calendar {
            @media (max-width: $mq__card-with-img-vert) {
                max-width: 500px;
                margin-top: -16px;
                margin-left: -1.5%;
                margin-bottom: 8px;
            }
        }

        &_posiflora-web {
            @media (max-width: $mq__card-with-img-vert) {
                max-width: 500px;
                margin-left: -1.5%;
                margin-bottom: 10px;
                margin-top: -16px;
            }
        }

        &_orbit-ad-s {
            @media (max-width: $mq__card-with-img-vert) {
                max-width: 500px;
                margin-left: -1.5%;
                margin-bottom: 10px;
                margin-top: -16px;
            }
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        width: 48%;

        @media (max-width: $mq__card-with-img-vert) {
            width: auto;
            order: 1;
        }
    }

    &__cols_reverse &__body {
        order: 1;
        padding-left: 2.5%;

        @media (max-width: $mq__card-with-img-vert) {
            order: 1;
            padding-left: 0;
        }
    }
    &__cols_reverse &__photo {

        @media (max-width: $mq__card-with-img-vert) {

        }
    }

    &__cols_ratio-img-sm &__body {
        width: 60%;

        @media (max-width: $mq__card-with-img-vert) {
            width: auto;
            order: 1;
        }
    }
    &__cols_ratio-img-sm &__photo {
        width: 40%;

        @media (max-width: $mq__card-with-img-vert) {
            width: auto;
        }
    }
    &__cols_ratio_half &__body {
        width: 50%;

        @media (max-width: $mq__card-with-img-vert) {
            width: auto;
            order: 1;
        }
    }
    &__cols_ratio_half &__photo {
        width: 50%;

        @media (max-width: $mq__card-with-img-vert) {
            width: auto;
        }
    }

    &__content {
        flex: 1 0 auto;

        &_has-items {
            display: flex;
            margin-left: -($card-content-items-gap/2);
            margin-right: -($card-content-items-gap/2);

            @media (max-width: $mq__card-content-items-vert) {
                display: block;
            }
        }
    }

    &__footer {
        flex-shrink: 0;
    }

    &__content-item {
        margin-left: ($card-content-items-gap/2);
        margin-right: ($card-content-items-gap/2);

        &:first-child {
            @media (max-width: $mq__card-content-items-vert) {
                margin-bottom: $h-list-bottom-space;
            }

            @media (max-width: $mq__tablet) {
                margin-bottom: $h-list-bottom-space_t;
            }

            @media (max-width: $mq__mobile) {
                margin-bottom: $h-list-bottom-space_m;
            }
        }
    }

    & &__h-list {
        margin-bottom: 0;
    }

    &__label {
        margin-bottom: 10px;
    }

    &__info-item {
        margin-bottom: 10px;
        max-width: $content-max_thin;
    }
    
    &__info-item-h {
        font-weight: 700;
        margin-bottom: 2px;
    }

    &__outcome {
        margin-bottom: 40px;
        padding-top: 18px;

        @media (max-width: $mq__tablet) {
            margin-bottom: 30px;
            padding-top: 10px;
        }

        @media (max-width: $mq__mobile) {
            margin-bottom: 20px;
            padding-top: 6px;
        }
    }

}
