.chat-hidden {
    position: fixed;
    display: none;
    left: 16px;
    bottom: 110px;
    z-index: 10;
    max-width: 280px;
    width: 100%;

    @media (max-width: $mq__mobile) {
        left: 16px;
        bottom: 115px;
    }

    @media (max-width: 374px) {
        max-width: calc(100vw - 32px);
    }

    &.is-display {
        display: block;
    }

    &__close {
        position: absolute;
        background: none;
        border: none;
        right: 12px;
        top: 12px;
        cursor: pointer;

        svg {
            width: 12px;
            height: 12px;
        }
    }

    &__send {
        padding-right: 20px;
        padding-left: 16px;
        border: none;
        width: 100%;
        background: #ffffff;
        box-shadow: 0px 0px 20px #dbdbdb;
        border-radius: 10px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        &:hover {
            opacity: 1;
        }

        p {
            color: black;
        }

        div {
            margin: 0;
            font-size: 14px;
            line-height: 100%;

            span {
                color: #22222280;
            }
        }

        svg {
            width: 19px;
            height: 16px;
        }
    }

    &__text {
        background: #ffffff;
        box-shadow: 0px 0px 20px #dbdbdb;
        border-radius: 10px;
        padding: 10px 16px;
        margin-bottom: 10px;

        p {
            font-size: 14px;
            line-height: 100%;
            color: rgba(34, 34, 34, 0.8);
        }
    }

    &__people {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        gap: 10px;

        img {
            border-radius: 100%;
        }

        p {
            margin: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 100%;
            color: #222222;
        }

        picture {
            border-radius: 100%;
        }
    }
}

.chat-showed {
    height: fit-content;
    position: fixed;
    display: none;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    box-shadow: 0px 0px 20px #dbdbdb;
    max-width: 856px;
    width: 100%;
    padding: 24px;
    z-index: 103;
    background: #EEF2F5;
    border-radius: 30px;

    @media (max-width: $mq__mobile) {
        padding: 12px;
        max-width: calc(100vw - 32px);
        max-height: 100vh;
        overflow: auto;
        justify-content: start;
    }

    &.is-display {
        display: flex;
    }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 48px;

        @media (max-width: $mq__mobile) {
            flex-direction: column;
            gap: 12px;
        }
    }

    &__block {
        max-width: 346px;
        width: 100%;

        picture img {
            margin: 0 auto;
            margin-bottom: 10px;

            @media (max-width: $mq__mobile) {
                max-width: 64px;
                margin-bottom: 6px;
            }
        }

        h3 {
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.01em;
            margin-bottom: 6px;
            color: #222222;

            @media (max-width: $mq__mobile) {
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 4px;
            }
        }

        .chat-showed__subname {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #222222;
            opacity: 0.7;
            margin-bottom: 16px;

            @media (max-width: $mq__mobile) {
                font-size: 12px;
                line-height: 14px;
                margin-bottom: 8px;
            }
        }

        .chat-showed__list {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 16px;

            @media (max-width: $mq__mobile) {
                gap: 8px;
                margin-bottom: 0;
            }

            p {
                margin: 0;
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.01em;
                color: #222222;

                @media (max-width: $mq__mobile) {
                    font-size: 14px;
                    line-height: 16px;
                    gap: 4px;
                }

                svg {
                    width: 22px;
                    height: 16px;

                    @media (max-width: $mq__mobile) {
                        width: 14px;
                        height: 11px;
                    }
                }
            }
        }

        .chat-showed__text {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #222222;
            opacity: 0.7;

            @media (max-width: $mq__mobile) {
                display: none;
            }
        }
    }

    &__content {
        background: #FFFFFF;
        border-radius: 20px;
        max-width: 414px;
        width: 100%;
        padding: 24px;

        @media (max-width: $mq__mobile) {
            padding: 10px;
        }

        h2 {
            font-weight: 700;
            font-size: 36px;
            line-height: 105%;
            color: #222222;
            margin-bottom: 24px;

            @media (max-width: $mq__mobile) {
                font-size: 18px;
                line-height: 120%;
                margin-bottom: 12px;
            }

            b {
                color: #3055D8;
            }
        }
    }


    &__call {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 24px;

        @media (max-width: $mq__mobile) {
            margin-bottom: 12px;
            gap: 8px;
        }

        p {
            margin: 0;
            display: flex;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: rgba(34, 34, 34, 0.7);

            @media (max-width: $mq__mobile) {
                font-size: 12px;
                line-height: 140%;
            }

            a {
                font-weight: 600;
                color: #3055D8;
                display: flex;
                align-items: center;

                &:first-child {
                    margin-left: 10px;

                    @media (max-width: $mq__mobile) {
                        margin-left: 5px;
                        margin-right: 5px;
                    }
                }

                @media (max-width: $mq__mobile) {
                    font-size: 0;
                }

                svg {
                    margin-left: 7.5px;
                    display: inline;
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;

                    @media (max-width: $mq__mobile) {
                        margin: 0;
                    }
                }
            }
        }
    }

    &__tel {
        @media (max-width: $mq__mobile) {
            flex-direction: column;
        }

        a {
            @media (max-width: $mq__mobile) {
                font-size: 14px !important;
            }
        }

    }

    &__close {
        position: absolute;
        background: none;
        border: none;
        right: -10px;
        top: -45px;
        cursor: pointer;

        @media (max-width: $mq__mobile) {
            right: 10px;
            top: 10px;
        }

        svg {
            width: 32px;
            height: 32px;

            @media (max-width: $mq__mobile) {
                g>path {
                    fill: #3055D8;
                }
            }
        }
    }

    .chat-showed__text-mobile {
        display: none;
        margin-bottom: 12px;
        font-weight: 500;
        font-size: 12px;
        line-height: 124%;
        color: #222222;
        opacity: 0.7;

        @media (max-width: $mq__mobile) {
            display: block;
        }
    }

    form {
        .chat-showed__copy {
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: #222222;
            opacity: 0.5;

            a {
                display: inline;
            }
        }

        button {
            width: 100%;
            background: #F64F4F;
            border-radius: 20px;
            height: 56px;
            border: none;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            color: white;
            cursor: pointer;
            transition: all 1.5s ease;
            margin-bottom: 12px;

            &:hover {
                opacity: 0.8;
                transition-duration: 0.6s;
            }

            @media (max-width: $mq__mobile) {
                margin-bottom: 8px;
                height: 45px;
            }
        }

        input {
            background: #ffffff;
            border: 1px solid #3055D8;
            border-radius: 16px;
            height: 52px;
            width: 100%;
            padding-left: 16px;
            font-family: "TT Hoves";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #22222280;
            margin-bottom: 24px;

            @media (max-width: $mq__mobile) {
                margin-bottom: 12px;
                height: 45px;
            }
        }
    }
}

.chat-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 64px;
    height: 64px;
    background: #ffffff;
    box-shadow: 0 0 20px #dbdbdb;
    border-radius: 100px;
    z-index: 10;
    cursor: pointer;
    border: none;
    margin: 0;
    padding: 17px;

    svg {
        width: 30px;
        height: 30px;
    }
}

.chat-hidden-btn {
    position: fixed;
    left: 30px;
    bottom: 20px;
    z-index: 3;
    cursor: pointer;
    border-radius: 100%;
    left: 27px;
    bottom: 22px;
    background: white;
    background-position: 18px 18px;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJtSURBVHgBvVe/b9NAFP7OTUEtqQgLG5JZSiuGOhLMuCsDBFEqxEIZ2AGxJ5kZKAsLA2VupQT+gTgzQhwDtJ1IhUBILEENFJHG13dnp67826nTT7Js3727z++7d++eGVLCqHwsYXLqPhhMCBiAKAGs5HZ33KuJvnjLm/OdpPlYkoFxd1OHzZ4TWQXpYQHaY74+yzEKsbG8XYUQNYyONVKgHqZAKLEra4MeTRwfHSJf9JMHiJW0A9aiRx35IUCuBUwG7HXOpFDzTbKWUjKMWK1pPvKGk5+aqg5fDqV2Jf6KcUOQ5BvzVuGwYR+1qBif1U9j5oyGrPjweS/YyJj02vI8vrMlvdXDJnhVvYArl6eRFeXl7fCOvrio3DBuf6kg/4CKRoFVXKk1M87uybPvJPUEcgOzTYdYw0Kc3e5fW135QVsopDG7d/0cFq8WkRUP69+iuvQhsY4YXKKoHiW44uAQC3TjjgvrfQ8/fvWRJxSdsbTVoKcsx95xwV2p7TYteCTxzLSGYjE5gfR6aYPQ3nGJGY8ze7pyHjfMs0jCO+s3qi9/JtqBTTSUGzJ30kJ3cVL4b7e9lLm0WXPzaABS6jQJZPfPII3Ua3x97oG3jwUVbhGRnWsCoVJI3ryIYbiGcUN49ZeznU7mLOYkcXn44ng8GFvVMYSsuW4dbXCIBbuJcUFQjd3fK/urTCe4mDBT1PZZGbvkUJ1vzK2G9RZoG5lHfkV8Y+UfgWiriNcgVdGRihAvsP9vlTfLkblBerzi+0qLnJfyvPENfOR8pIwHJs9vuf10dcDINRT2DuWBJhHyOMIhGNVaLdj2pyyD8sABun/jS0ojs3YAAAAASUVORK5CYII=");
    width: 67px;
    height: 67px;

    @media (max-width: 1279px) {
        left: 16px;
        width: 44px;
        height: 44px;
        background-position: center;
        background-size: 20px;
    }

    @media (max-width: 767px) {
        bottom: 65px;
    }
}

.chat {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 11;
    background: white;
    padding: 15px;
    border-radius: 0 15px 0 0;
    box-shadow: 0 0 20px #dbdbdb;
    max-width: 350px;
    width: 100%;
    display: none;

    @media (max-width: 1279px) {
        padding: 10px;
    }

    @media (max-width: 767px) {
        bottom: 58px;
    }

    &__header {
        position: relative;
        margin-bottom: 20px;

        &::before {
            position: absolute;
            left: -15px;
            bottom: -5px;
            display: block;
            content: '';
            height: 1px;
            width: 100%;
            max-width: 100%;
            min-width: 350px;
            background: gray;
            opacity: 0.4;
        }

        &-title {
            font-size: 18px;
        }

        &-time {
            opacity: 0.8;
            font-size: 14px;
            position: relative;
            padding-left: 15px;

            &::after {
                display: block;
                content: '';
                position: absolute;
                left: 0;
                top: 3.5px;
                border-radius: 100%;
                width: 9px;
                height: 9px;
                background: rgb(28, 189, 28);
            }
        }

        button {
            position: absolute;
            background: none;
            width: 30px;
            height: 30px;
            cursor: pointer;
            border: none;
            right: 0;
            top: 5px;
            opacity: 0.6;
        }
    }

    &__messages {
        display: flex;
        flex-direction: column;
        gap: 15px;
        margin-bottom: 10px;
    }

    .message-client {
        padding: 10px;
        font-size: 14px;
        font-weight: 500;
        max-width: 280px;
        width: 100%;
        margin-left: auto;
        background-color: #3055d8;
        color: #fff;
        border-radius: 0 16px 16px 16px;
        transition: .6s;
        position: relative;

        @media (max-width: 1279px) {
            max-width: 260px;
        }

        img {
            position: absolute;
            left: -40px;
            top: 10px;
            border-radius: 100%;
        }
    }

    >p {
        margin: 0;
        text-align: center;
        font-size: 14px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.4);
    }

    form {
        display: flex;
        flex-direction: column;
        gap: 10px;

        input {
            height: 36px;
            width: 100%;
            border-top: none;
            border-right: none;
            border-left: none;
            border-image: initial;
            color: rgb(0, 0, 0);
            font-family: "TT Hoves";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            border-bottom: 1px solid rgba(34, 34, 34, 0.3);

            &::placeholder {
                color: #2222224D;
            }

            &:focus,
            &:focus-visible {
                outline: none;
            }
        }

        button {
            margin-top: 15px;
            padding: 10px 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            max-width: 100%;
            width: 100%;
            background: #3055d8;
            color: #eef2f5;
            border: 1.5px solid #3055d8;
            border-radius: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 149.3%;
            letter-spacing: .03em;
            transition: .6s;

            &:hover {
                opacity: 0.6;
            }
        }
    }
}

.is-display {
    display: block !important;
}