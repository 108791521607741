.success {
    user-select: none;
    @media (max-width: $mq__mobile) {
        .success__btn {
            display: none !important;
        }
    }

    &__row {
        padding: 24px 0;
        position: relative;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        position: relative;
        @media (max-width: $mq__mobile) {
            transform: none !important;
            flex-wrap: wrap;
            gap: 24px;
            justify-content: center;
        }
    }

    &__img {
        margin-right: 64px;
        flex-shrink: 0;
        cursor: grab;

        &:active {
            cursor: grabbing;
        }

        @media (max-width: $mq__mobile) {
            margin-right: 0;
            width: 100px;
            margin-bottom: 24px;
        }
    }
}