:root {
    // --- Colors
    --color_primary: #222222;
    --color_accent: #3055D8;
    --color_accent_light: rgba(48, 85, 216, 0.3);
    --color_select: #9118e8;
    --color_light: rgb(255, 255, 255);
    --color_red: rgb(247, 78, 78);
    --color_secondary: #333;
    --color_panel: #eef2f5;
    --color_panel2: #e0e5f9;
    --color_focus: rgb(28, 121, 220, 0.9);
    --color_yep: #27AE60;

    --color__input: #EBEEF4;
    --color_disabled: #BDBDBD;

    --color__link: rgb(48, 85, 216); // #3055D8;
    --color__link_hover: var(--color_select); // #3055D8;
    --color__link_visited: rgb(184, 94, 207);
    --color__link_active: rgb(207, 73, 43);

    --color__bg: rgb(255, 255, 255);
    --color__bg_dark: rgb(229, 229, 229);

    --color__text: #222222;
    --color__text_disabled: #A2B5C2;
    --color__text_light: rgb(255, 255, 255);
    --color__border: #D9D9D9;
    --color__header_link: #F8DFB9;

    // Font size
    --font-size: 19px;
    --font-size_t: 17px;
    --font-size_m: 15px;
    --font-size__table: 16px;
    --font-size__table_t: 14px;
    --font-size__table_m: 12px;

    --font-size__caption: 14px;
    --font-size__caption_m: 12px;

    --font-size__h1: 74px;
    --font-size__h1_t: 36px;
    --font-size__h1_m: 24px;

    --font-size__h2: 50px;
    --font-size__h2_t: 32px;
    --font-size__h2_m: 24px;

    --font-size__h3: 26px;
    --font-size__h3_t: 20px;
    --font-size__h3_m: 17px;

    --font-size_nav: 14px;

    // Line height
    --line-height: 1.4;
    --line-height__h: 1.17;
    --max_body_width: #{$mq__page-max};
}

/* apply a natural box layout model to all elements, but allowing components to change https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
html {
    box-sizing: border-box;
}
*, *:before, *:after {
    box-sizing: inherit;
}

html, body {
    height: 100%;
}

body {
    padding: 0;
    margin: 0;
    background-color: var(--color_light);
    font-family: 'TT Hoves', 'sans-serif', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: var(--color__text);
    font-size: var(--font-size);
    line-height: var(--line-height);

    @media (max-width: $mq__tablet) {
        font-size: var(--font-size_t);
    }

    @media (max-width: $mq__mobile) {
        font-size: var(--font-size_m);
    }
}

.demibold {
    font-weight: 600;
}

.demibold_italic {
    font-weight: 600;
    font-style: italic;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    &.is-no-loaded {
        background: rgba(220, 226, 250, 0);
        background-image:
            linear-gradient(
                            to right, rgba(220, 226, 250, 0) 0%,
                    rgba(195, 206, 238, 0.3) 20%,
                    rgba(220, 226, 250, 0) 40%,
                    rgba(220, 226, 250, 0) 100%
            );
        background-repeat: no-repeat;
        background-size: 100vh 100vw;
        display: inline-block;
        position: relative;

        -webkit-animation-duration: 1s;
        -webkit-animation-fill-mode: forwards;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
    }
}


p {
    margin: 0 0 ($space__bottom) 0;

    &:last-child {
        margin-bottom: 0;
    }
}

ul, ol {
    margin: 0 0 $space__bottom -17px;

    li {
        margin-bottom: 4px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
    padding: 0;
    margin: 0;
    font-size: var(--font-size__h1);
    line-height: var(--line-height__h);
    font-family: 'TT Hoves', 'sans-serif', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
    font-weight: 700;
}

h1, .h1 {
    font-size: var(--font-size__h1);
    margin-bottom: 32px;

    @media (max-width: $mq__tablet) {
        font-size: var(--font-size__h1_t);
        margin-bottom: 16px;
    }

    @media (max-width: $mq__mobile) {
        font-size: var(--font-size__h1_m);
        margin-bottom: 16px;
    }
}

h2, .h2 {
    font-size: var(--font-size__h2);
    margin-bottom: 58px;

    @media (max-width: $mq__tablet) {
        font-size: var(--font-size__h2_t);
        margin-bottom: 36px;
    }

    @media (max-width: $mq__mobile) {
        font-size: var(--font-size__h2_m);
        margin-bottom: 20px;
    }
}

h3, .h3 {
    font-size: var(--font-size__h3);
    margin-bottom: 32px;

    @media (max-width: $mq__tablet) {
        font-size: var(--font-size__h3_t);
        margin-bottom: $space__bottom;
    }

    @media (max-width: $mq__mobile) {
        font-size: var(--font-size__h3_m);
        margin-bottom: 20px;
    }
}

h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5,
h5 + h6,
.h1 + .h2,
.h2 + .h3,
.h3 + .h4,
.h4 + .h5,
.h5 + .h6 {
    margin-top: 0;
}


a {
    color: var(--color__link);
    transition: all $animation__duration_slow;
    text-decoration: none;
    outline: none !important;

    &:active,
    &:hover,
    &:visited:hover,
    &:focus {
        opacity: 0.5;
        transition-duration: $animation__duration_slow;
    }

    &:visited {
        opacity: 0.8;
    }
}

table {
    margin-bottom: $space__bottom;
    border-collapse: collapse;
    display: block;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    font-size: var(--font-size__table);

    &:last-child {
        margin-bottom: 0;
    }

    tr {
        td {
            padding: 20px;
            border: 1px solid var(--color__border);

            @media (max-width: $mq__mobile) {
                padding: 10px;
            }
        }
        &:first-child td {
            font-weight: bold;
        }
    }

    @media (max-width: $mq__tablet) {
        font-size: var(--font-size__table_t);
    }

    @media (max-width: $mq__mobile) {
        font-size: var(--font-size__table_m);
    }

}

figure {
    margin: 0;
    padding: 0;
}

.svg-sprite {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -100;
}

svg {
    display: block;
    width: 100%;
    height: 100%;
}

.rouble {
    font-family: Rouble, BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
    font-weight: normal;
}

::-moz-selection {
    background: var(--color_select);
    color: var(--color_light);
}
::selection {
    background: var(--color_select);
    color: var(--color_light);
}

#request-a-call > iframe {
    max-width : 510px;
    position  : initial !important;
    margin    : 0 auto !important;
}

.h3, .h4, h3, h4 {
    font-weight : 400;
}

.page_link {
    text-decoration: underline;
}

.telegram-menu {
    background: transparent url('../img/telegram.svg') 0 0;
    width: 50px;
    height: 50px;
    display: block;
    margin-left: 30px;
    @media (max-width: $mq__tablet) {
        width: 36px;
        height: 36px;
    }
}

.whatsapp-menu {
    background: transparent url('../img/whatsapp.svg') 0 0;
    width: 55px;
    height: 55px;
    display: block;
    margin-left: 10px;
    @media (max-width: $mq__tablet) {
        width: 36px;
        height: 36px;
    }
}

@keyframes is-display {
    0% {
        top: 200%;
    }
    100% {
        top: 50%;
    }
}