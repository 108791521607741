.video-modal {
    position: fixed;
    display: none;
    align-items: stretch;
    background: #FFFFFF;
    border-radius: 30px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    margin: 0 auto;
    max-width: 860px;
    height: 100%;
    width: 100%;
    max-height: 484px;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    transition: $animation__duration_slow;

    &.is-display {
        display: flex;
        opacity: 1;
        visibility: initial;
        animation: is-display $animation__duration_slow linear;
    }

    @media (max-width: $mq__tablet) {
        top: 0;
        transform: none;
        height: 100vh;
        width: 100vw;
        align-items: normal;
        flex-direction: column-reverse;
        max-width: initial;
        max-height: initial;
        border-radius: 0;
    }

    &__body {
        width: 100%;
        height: 100%;
    }

    &__close {
        position: absolute;
        top: -34px;
        right: -34px;
        width: 34px;
        height: 34px;
        padding: 7px;
        color: #EEF2F5;
        background: transparent;
        border: 0;
        cursor: pointer;

        @media (max-width: $mq__tablet) {
            top: 0;
            right: 0;
        }
    }

}