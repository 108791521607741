.caption {
    padding-top: ($space__bottom / 2);
    padding-left: 20px;
    font-size: var(--font-size__caption);
    font-style: italic;

    @media (max-width: $mq__mobile) {
        font-size: var(--font-size__caption_m);
    }
}
