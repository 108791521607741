.toggle-content {

    &__item:not(:last-child) {
        margin-bottom: 25px;
    }

    &__title {
        position: relative;
        font-size: 18px;
        padding-left: 30px;
        cursor: pointer;

        &:before {
            position: absolute;
            content: '';
            top: 11px;
            left: 0;
            width: 16px;
            height: 2px;
            background-color: var(--color_accent);
        }

        &:after {
            position: absolute;
            content: '';
            top: 4px;
            left: 7px;
            width: 2px;
            height: 16px;
            background-color: var(--color_accent);

            transition: transform $animation__duration;
        }
    }

    &__title.is-open {

        &:after {
            transform: rotate(90deg);
        }
    }

    &__content {
        display: none;
        padding-top: 10px;
    }
}
