/* PT Serif */
@font-face {
    font-family: "PTSerif";
    src: url('../fonts/serif/ptserif-regular.woff2') format('woff2'),
    url('../fonts/serif/ptserif-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PTSerif";
    src: url('../fonts/serif/ptserif-bold.woff2') format('woff2'),
    url('../fonts/serif/ptserif-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PTSerif";
    src: url('../fonts/serif/ptserif-italic.woff2') format('woff2'),
    url('../fonts/serif/ptserif-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "PTSerif";
    src: url('../fonts/serif/ptserif-bolditalic.woff2') format('woff2'),
    url('../fonts/serif/ptserif-bolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


/* PT Sans */
@font-face {
    font-family: "PTSans";
    src: url('../fonts/sans/ptsans-regular.woff2') format('woff2'),
    url('../fonts/sans/ptsans-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PTSans";
    src: url('../fonts/sans/ptsans-bold.woff2') format('woff2'),
    url('../fonts/sans/ptsans-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "PTSans";
    src: url('../fonts/sans/ptsans-italic.woff2') format('woff2'),
    url('../fonts/sans/ptsans-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "PTSans";
    src: url('../fonts/sans/ptsans-bolditalic.woff2') format('woff2'),
    url('../fonts/sans/ptsans-bolditalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}


/* TT Hoves */
@font-face {
    font-family: "TT Hoves";
    src: url('../fonts/tt/tt-hoves-regular.woff2') format('woff2'),
    url('../fonts/tt/tt-hoves-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Hoves";
    src: url('../fonts/tt/tt-hoves-bold.woff2') format('woff2'),
    url('../fonts/tt/tt-hoves-bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Hoves";
    src: url('../fonts/tt/TTHoves-ExtraBold.woff2') format('woff2'),
    url('../fonts/tt/TTHoves-ExtraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Hoves";
    src: url('../fonts/tt/tt-hoves-italic.woff2') format('woff2'),
    url('../fonts/tt/tt-hoves-italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "TT Hoves";
    src: url('../fonts/tt/tt-hoves-bold-italic.woff2') format('woff2'),
    url('../fonts/tt/tt-hoves-bold-italic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "TT Hoves";
    src: url('../fonts/tt/TTHoves-Medium.woff2') format('woff2'),
    url('../fonts/tt/TTHoves-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Hoves";
    src: url('../fonts/tt/tt-hoves-demibold.woff2') format('woff2'),
    url('../fonts/tt/tt-hoves-demibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "TT Hoves";
    src: url('../fonts/tt/tt-hoves-demibold-italic.woff2') format('woff2'),
    url('../fonts/tt/tt-hoves-demibold-italic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

/* Rouble */
@font-face {
    font-family: "Rouble";
    src: url('../fonts/ruble.woff2') format('woff2'),
    url('../fonts/ruble.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}