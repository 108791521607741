.map {
    $mq__map-first: 1195px;
    $mq__map-second: 1068px;
    $mq__map-third: 1065px;

    position: relative;

    @media (max-width: $mq__map-third) {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
    }

    &__h {
        max-width: 450px;
        position: relative;

        @media (max-width: $mq__map-third) {


        }
    }

    &__body {
        width: 50%;
        position: absolute;
        left: 0;
        z-index: 1;

        @media (max-width: $mq__map-first) {
            width: 70%;
        }

        @media (max-width: $mq__map-third) {
            position: relative;
            width: auto;
            margin-bottom: 30px;
        }

        @media (max-width: $mq__mobile) {
            margin-bottom: 20px;
        }
    }

    &__img {
        position: relative;
        padding-left: 32%;
        top: 16px;

        @media (max-width: $mq__map-first) {
            padding-left: 40%;
        }

        @media (max-width: $mq__map-second) {
            padding-left: 43%;
        }

        @media (max-width: $mq__map-third) {
            padding-left: 0;
            max-width: 550px;
            top: 0;

        }

        @media (max-width: $mq__mobile) {
            margin-left: -($space__side_m - 4px);
            margin-right: -($space__side_m - 4px);
        }
    }

    &__content {
        max-width: 366px;
        padding-bottom: $space__bottom;
        padding-top: 10px;

        @media (max-width: $mq__map-third) {
            max-width: 100%;

        }

        @media (max-width: $mq__mobile) {
            padding-top: 0;
        }
    }

    &__list {
        margin: 0;
        padding: 10px 0 0 0;
        max-width: 400px;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $mq__map-first) {
            max-width: 500px;
        }

        @media (max-width: $mq__map-third) {
            max-width: 900px;
        }
    }

    &__list-item {
        width: 150px;
        padding-right: 40px;
        margin-bottom: 10px;

        @media (max-width: $mq__tablet) {
            width: 160px;
            padding-right: 30px;
        }

        @media (max-width: $mq__mobile) {
            width: 130px;
            padding-right: 20px;
        }
    }
}
