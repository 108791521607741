.development-prices {
    &__row {
        margin: -30px;
        position: relative;

        @media (max-width: $mq__mobile) {
            margin: 0;
            overflow: initial;
        }
    }

    &__btn {
        display: none;

        @media (max-width: $mq__mobile) {
            display: block;
        }
    }

    &__wrapper {
        padding: 30px;
        display: flex;
        justify-content: space-between;
        gap: 32px;

        @media (max-width: $mq__mobile) {
            gap: 16px;
            padding: 0;
        }
    }

    &__item {
        padding: 24px;
        padding-top: 32px;
        max-width: calc((100% / 3) - 32px);
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px #DBDBDB;
        border-radius: 30px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        min-height: 290px;
        transition: all 0.6s ease;
        justify-content: space-between;

        @media (max-width: $mq__mobile) {
            max-width: calc((100vw - 32px) - 38px);
            padding: 16px;
            min-height: 200px;
        }

        &:last-child {
            @media (max-width: $mq__mobile) {
                margin-right: 32px;
            }
        }

        &:hover {
            box-shadow: 0px 0px 30px #DBDBDB;
        }

        h3 {
            font-weight: 700;
            font-size: 30px;
            line-height: 35px;
            text-align: center;
            letter-spacing: 0.03em;
            margin-bottom: 24px;
            min-height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $mq__mobile) {
                font-size: 18px;
                line-height: 150%;
                margin-bottom: 10px;
            }
        }

        ul {
            display: flex;
            flex-direction: column;
            gap: 16px;
            margin: 0;
            padding: 0;

            @media (max-width: $mq__mobile) {
                gap: 6px;
            }

            li {
                display: flex;
                gap: 12px;
                list-style: none;

                @media (max-width: $mq__mobile) {
                    gap: 8px;
                }

                svg {
                    width: 20px;
                    height: 20px;

                    @media (max-width: $mq__mobile) {
                        width: 14px;
                        height: 14px;
                    }
                }

                p {
                    margin: 0;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 140%;
                    color: rgba(0, 0, 0, 0.6);

                    @media (max-width: $mq__mobile) {
                        font-size: 14px;
                        line-height: 130%;
                    }
                }
            }
        }
    }

    &__bottom {
        p {
            font-weight: 700;
            font-size: 30px;
            line-height: 35px;
            text-align: center;
            color: rgba(0, 0, 0, 0.8);
            margin-bottom: 24px;

            @media (max-width: $mq__mobile) {
                font-size: 20px;
                line-height: 150%;
                margin-bottom: 10px;
            }
        }

        a {
            border: 1.5px solid #3055D8;
            border-radius: 16px;
            background: none;
            height: 64px;
            max-width: 100%;
            width: 100%;
            font-weight: 500;
            font-size: 18px;
            line-height: 149.3%;
            text-align: center;
            letter-spacing: 0.03em;
            color: #3055D8;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            transition: all 0.6s ease;

            @media (max-width: $mq__mobile) {
                height: 38px;
                border: 1px solid #3055D8;
                border-radius: 10px;
                font-weight: 500;
                font-size: 12px;
                line-height: 149.3%;
            }

            &:hover {
                background: #3055D8;
                color: #EEF2F5;
                opacity: 1;
            }
        }
    }

    &__item.active {
        h3 {
            color: #3055D8;
        }

        .development-prices__bottom>a {
            background: #3055D8;
            color: #EEF2F5;

            &:hover {
                background: none;
                color: #3055D8;
                opacity: 1;
            }
        }
    }
}

.seo-page {
    .development-prices {
        background: #EBEEFC;

        &__wrapper {
            padding-top: 60px;
        }

        .swiper-button-disabled {
            display: none;

            @media (max-width: $mq__mobile) {
                display: flex;
            }
        }

        &__item {
            &.active {
                background: #3055d8;

                h3 {
                    color: #fff;
                }

                p {
                    color: #fff;
                }

                .development-prices__bottom>a {
                    color: #3055d8;
                    background: #FFFFFF;
                    border: 1px solid #3055D8;
                }
            }
        }
    }
}