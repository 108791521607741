.modal-close {
    height: fit-content;
    position: fixed;
    display: none;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 999;
    @media (max-width: $mq__mobile) {
        height: auto;
        overflow: scroll;
    }
    &.is-display {
        display: flex;
        &:before {
            content: '';
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            z-index: -1;
            backdrop-filter: blur(20px);
        }
    }

    &__wrapper {
        position: relative;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background: #ffffff;
        max-width: 856px;
        height: 520px;
        width: 100%;
        padding: 24px;
        background: rgba(48, 85, 216, 0.5);
        border-radius: 30px;
        @media (max-width: $mq__tablet) {
            padding: 12px;
            max-width: calc(100vw - 20px);
            height: auto;
        }
    }

    &__close {
        position: absolute;
        background: none;
        border: none;
        right: -10px;
        top: -45px;
        cursor: pointer;
        @media (max-width: $mq__mobile) {
            right: 10px;
            top: 10px;
        }
        svg {
            width: 32px;
            height: 32px;
        }
    }

    &__blocks {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        gap: 48px;
        @media (max-width: $mq__mobile) {
            flex-direction: column-reverse;
            gap: 10px;
        }
    }

    &__right {
        max-width: 346px;
        width: 100%;
        picture img{
            margin: 0 auto;
            margin-bottom: 10px;
        }
        h3{
            font-weight: 600;
            font-size: 22px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 0.01em;
            margin-bottom: 6px;
            color: #FFFFFF;
        }
        .modal-close__subname{
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            text-align: center;
            letter-spacing: 0.01em;
            color: #EEF2F5;
            opacity: 0.7;
            margin-bottom: 16px;
            @media (max-width: $mq__mobile) {
                margin-bottom: 12px;
            }
        }
        .modal-close__list{
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-bottom: 16px;
            @media (max-width: $mq__mobile) {
                margin-bottom: 12px;
            }
            p{
                margin: 0;
                display: flex;
                align-items: center;
                gap: 10px;
                font-weight: 600;
                font-size: 16px;
                line-height: 19px;
                letter-spacing: 0.01em;
                color: #FFFFFF;
                @media (max-width: $mq__mobile) {
                    font-size: 14px;
                }
                svg{
                    width: 22px;
                    height: 16px;
                }
            }
        }
        .modal-close__text{
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #EEF2F5;
            opacity: 0.7;
            @media (max-width: $mq__mobile) {
                font-size: 14px;
            }
        }
    }

    

    form {
        button {
            width: 100%;
            background: #F64F4F;
            border-radius: 20px;
            height: 56px;
            border: none;
            font-weight: 500;
            font-size: 20px;
            line-height: 150%;
            color: white;
            cursor: pointer;
            transition: all 1.5s ease;
            margin-bottom: 12px;
            &:hover {
                opacity: 0.8;
                transition-duration: 0.6s;
            }
        }
        input {
            background: #ffffff;
            border: 1px solid #3055D8;
            border-radius: 16px;
            height: 52px;
            width: 100%;
            padding-left: 16px;
            font-family: "TT Hoves";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #22222280;
            margin-bottom: 24px;
            @media (max-width: $mq__mobile) {
                margin-bottom: 12px;
            }
        }
    }

    &__left {
        max-width: 414px;
        width: 100%;
        background: #FFFFFF;
        border-radius: 20px;
        padding: 24px;
        @media (max-width: $mq__mobile) {
            padding: 12px;
        }
        h2{
            font-weight: 700;
            font-size: 36px;
            line-height: 105%;
            color: #222222;
            margin-bottom: 8px;
            @media (max-width: $mq__mobile) {
                font-size: 24px;
            }
            span{
                color: #3055D8;
            } 
        }

        p{
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: #222222;
            opacity: 0.7;
            margin-bottom: 24px;
        }

        .modal-close__copy{
            font-weight: 500;
            font-size: 12px;
            line-height: 130%;
            color: #222222;
            opacity: 0.5;
            margin: 0;
            a{
                display: inline;
            }
        }
    }

    &__call{
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-bottom: 24px;
        @media (max-width: $mq__mobile) {
            margin-bottom: 12px;
        }
        p{
            opacity: 1;
            margin: 0;
            display: flex;
            margin: 0;
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: rgba(34, 34, 34, 0.7);
            @media (max-width: 413px) {
                font-size: 12px;
            }
            a{
                font-weight: 600;
                color: #3055D8;
                display: flex;
                align-items: center;
                &:first-child{
                    margin-left: 10px;
                    @media (max-width: $mq__mobile) {
                        margin-left: 5px;
                    }
                }
                
                
                svg{
                    margin-left: 7.5px;
                    display: inline;
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                    @media (max-width: $mq__mobile) {
                        margin-right: 5px;
                        margin-left: 5px;
                        width: 16px;
                        height: 16px;
                    }
                }
            }
        }
    }
}
