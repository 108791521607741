.custom-h {
    font-weight: 600;
    font-size: 16px;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 8px;
    margin-bottom: 20px;
    margin-top: 0;

    @media (max-width: $mq__tablet) {
        font-size: 14px;
    }

    @media (max-width: $mq__mobile) {
        font-size: 13px;
        margin-bottom: 14px;
    }

    &_simple {
        font-weight: 400;
    }

    &__text {
        display: inline-block;
    }

    &__up,
    &::first-letter {
        font-size: 20px;

        @media (max-width: $mq__tablet) {
            font-size: 17px;
        }

        @media (max-width: $mq__mobile) {
            font-size: 16px;
        }
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 40px;
        height: 2px;
        background: var(--color_accent);
    }
}
