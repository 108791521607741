.custom {
    &__body {
        display: -webkit-flex;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        gap: 24px;
        @media (max-width: $mq__tablet) {
            flex-direction: column;
            gap: 16px;
        }
    }

    &__arrow {
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        align-self: center;
        color: #3055D8;

        @media (max-width: $mq__tablet) {
            transform: rotate(90deg);
            width: 24px;
            height: 24px;
        }
    }

    &__card {
        width: 100%;
        padding: 32px;
        background: #FFFFFF;
        box-shadow: 0 0 20px #DBDBDB;
        border-radius: 30px;
        transition-duration: $animation__duration_slow;
        color: #3055D8;

        &:hover,
        &:focus {
            box-shadow: 0 0 30px #BEBEBE;
        }

        @media (max-width: $mq__tablet) {
            width: 100%;
        }

        @media (max-width: $mq__mobile) {
            padding: 16px;
        }
    }

    &__title {
        margin-bottom: 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.01em;
        color: #222222;

        @media (max-width: $mq__mobile) {
            margin-bottom: 24px;
            font-size: 22px;
            line-height: 26px;
        }
    }

    &__item {
        display: -webkit-flex;
        display: flex;
        align-items: start;
        margin-bottom: 16px;

        &_icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 14px;
        }

        &_text {
            font-weight: 500;
            font-size: 16px;
            line-height: 150%;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    &__list {
        margin: 0;
        padding: 0;
    }

    &__li {
        margin-left: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.6);

        @media (max-width: $mq__mobile) {
            font-size: 14px;
            list-style: none;
            margin-left: 0;
        }
    }
}

