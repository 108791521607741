.gallery {
    $gallery-items-gap: 20px;
    $mq__gallery-vert: 1170px;

    display: flex;
    margin-left: -($gallery-items-gap/2);
    margin-right: -($gallery-items-gap/2);
    margin-bottom: 40px;

    @media (max-width: $mq__gallery-vert) {
        flex-direction: column;
    }

    @media (max-width: $mq__tablet) {
        margin-bottom: 20px;
    }

    @media (max-width: $mq__mobile) {
        margin-bottom: 0;
    }

    &_direction_column {
        flex-direction: column;
    }
    &_direction_column &__item {
        width: 100%;
        margin-bottom: 40px;

        &:last-child {
            @media (max-width: $mq__gallery-vert) {
                align-self: auto;
            }
        }
    }

    &__item {
        width: 50%;
        margin: 0 ($gallery-items-gap/2);

        @media (max-width: $mq__gallery-vert) {
            width: auto;
            max-width: 548px;
        }

        &:first-child {
            @media (max-width: $mq__gallery-vert) {
                margin-bottom: 40px;
            }
        }

        &:last-child {
            @media (max-width: $mq__gallery-vert) {
                align-self: flex-end;
            }
        }
    }

    &__img {
        display: block;
        margin-bottom: 20px;
        @media (max-width: $mq__gallery-vert) {

        }
    }

    &__content {
        padding-right: 50px;
    }
}
