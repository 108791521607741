.tile {
    display: flex;
    align-items: center;
    padding: 20px 25px 20px 20px;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    @media (max-width: $mq_xs) {
        flex-direction: column;
    }

    &_space-b {
        margin-bottom: 15px;
    }

    &__img {
        width: 150px;
        margin-right: 25px;

        @media (max-width: $mq__mobile) {
            width: 120px;
        }

        @media (max-width: $mq_xs) {
            width: 100%;
            max-width: 200px;
            margin-right: 0;
            margin-bottom: 10px;
        }
    }

    &__body {
        display: flex;
        align-items: center;

        @media (max-width: $mq__tablet) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__content {
        padding-right: 20px;
    }

    &__description {

        @media (max-width: $mq__tablet) {
            margin-bottom: 20px;
        }
    }
}
