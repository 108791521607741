.projects {
    overflow: hidden;

    .projects-btn {
        display: flex;
        padding: 14px 73.5px 14px 73.5px;
        justify-content: center;
        align-items: center;
        background: #3055d8;
        color: #eef2f5;
        border: 1.5px solid #3055d8;
        border-radius: 20px;
        font-weight: 500;
        font-size: 16px;
        line-height: 149.3%;
        letter-spacing: 0.03em;
        transition: 0.6s;
        position: absolute;
        right: 0;
        bottom: 0;

        @media (max-width: $mq__tablet) {
            right: 0;
            padding: 14px 28px 14px 28px;
        }
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;

        @media (max-width: $mq__tablet) {
            gap: 0;
            flex-wrap: nowrap;
            justify-content: initial;
        }

        .projects__card {
            @media (max-width: $mq__tablet) {
                margin: 0 12px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__row {
        position: relative;
        padding-bottom: 83px;
    }

    &__box {
        overflow: initial;
    }

    &__btn {
        display: none !important;

        @media (max-width: $mq__tablet) {
            display: flex !important;
        }
    }

    .swiper-button-disabled {
        @media (max-width: $mq__tablet) {
            display: none !important;
        }
    }

    &__text {
        p {
            margin: 0 0 5px;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
        }

        ul {
            display: flex;
            align-items: center;
            gap: 10px;
            margin: 0;
            padding: 0;

            li {
                padding: 4px 8px;
                border-radius: 37px;
                list-style: none;
                font-size: 14px;
                font-weight: 400;
                margin: 0;
                border: 1px solid;
                //background: rgba(255, 255, 255, 0.15);
                backdrop-filter: blur(5px);

                @media (max-width: $mq__mobile) {
                    text-wrap: nowrap;
                }
            }
        }

        h3 {
            margin: 0;
            font-weight: 600;
            font-size: 28px;
            line-height: 33px;
        }
    }

    &__decor {

        &-item-1,
        &-item-2,
        &-item-3,
        &-item-4,
        &-item-5,
        &-item-6,
        &-item-7,
        &-item-8,
        &-item-9,
        &-item-10,
        &-item-11,
        &-item-12,
        &-item-13,
        &-item-14,
        &-item-15,
        &-item-16,
        &-item-17,
        &-item-18,
        &-item-19,
        &-item-20,
        &-item-21,
        &-item-22,
        &-item-23,
        &-item-24,
        &-item-25,
        &-item-26,
        &-item-27,
        &-item-28,
        &-item-29,
        &-item-30,
        &-item-31,
        &-item-32,
        &-item-33,
        &-item-34,
        &-item-35,
        &-item-36,
        &-item-37,
        &-item-38,
        &-item-39 {
            position: absolute;
            transition-duration: 1s;
        }
    }

    &__card {
        box-shadow: 0px 0px 20px #dbdbdb;
        border-radius: 30px;
        height: 400px;
        max-width: calc((100% / 4) - 18px);
        width: 100%;
        color: initial;
        position: relative;

        @media (max-width: $mq__tablet) {
            max-width: 288px !important;
            height: 360px;
        }

        img {
            display: initial;
        }

        h3 {
            margin-bottom: 0;
        }

        &:hover,
        &:visited,
        &:focus {
            opacity: 1;
        }
    }

    &__text {
        position: absolute;
        padding-right: 12px;
        bottom: 0px;
        left: 0px;
        z-index: 5;
        transition: all 1s ease;
        padding: 15px;
        display: flex;
        flex-direction: column-reverse;
        gap: 5px;
        max-width: 100%;
        background: rgba(255, 255, 255, .4);
        width: 100%;

        p {
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            margin-bottom: 5px;
        }

        h3 {
            font-weight: 600;
            font-size: 28px;
            line-height: 33px;

            @media (max-width: $mq__tablet) {
                font-size: 20px;
                line-height: 24px;
            }
        }
    }

    .b1 {
        background: #272727;

        &::after {
            opacity: 0;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(180deg, #200065 0%, #4d004f 100%);
            transition-duration: 1s;
            z-index: 1;
            border-radius: 30px;
        }

        .projects__img picture {
            z-index: 2;
            position: absolute;
            top: 101px;
            text-align: center;
            left: 0;
            right: 0;
            transition-duration: 1s;

            &::after {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: -10px;
                content: "";
                max-width: 270px;
                width: 100%;
                height: 156px;
                background: none;
                border-radius: 16px;
                border: 1px solid white;
            }
        }

        .projects__decor {
            &-item-1 {
                z-index: 2;
                left: 7px;
                top: 2px;

                svg {
                    border-radius: 100%;

                    path {
                        transition-duration: 1s;
                    }
                }
            }

            &-item-2 {
                z-index: 2;
                top: 19px;
                right: 15px;

                svg {
                    border-radius: 100%;

                    path {
                        transition-duration: 1s;
                    }
                }
            }

            &-item-3 {
                z-index: 2;
                left: 13px;
                bottom: 100px;

                svg {
                    border-radius: 100%;

                    path {
                        transition-duration: 1s;
                    }
                }
            }

            &-item-4 {
                z-index: 2;
                right: 22px;
                bottom: 70px;
            }

            &-bg {
                z-index: 2;

                picture {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;

                    &::after {
                        content: none;
                    }
                }
            }
        }

        .projects__text {
            color: white;
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                &::after {
                    opacity: 1;
                }

                .projects__img picture {
                    transform: scale(1.05);
                }

                .projects__decor {
                    &-item-1 {
                        transform: scale(1.3) rotate(10deg) translateX(-10px);

                        svg {
                            border-radius: 100%;

                            path {
                                filter: drop-shadow(0px 0px 5px #f7931a);
                            }
                        }
                    }

                    &-item-2 {
                        transform: scale(1.25) rotate(10deg) translateX(20px) translateY(-20px);

                        svg {
                            border-radius: 100%;

                            path {
                                filter: drop-shadow(0px 0px 5px #627eea);
                            }
                        }
                    }

                    &-item-3 {
                        transform: scale(1.3) rotate(-5deg) translateX(-15px);

                        svg {
                            border-radius: 100%;

                            path {
                                filter: drop-shadow(0px 0px 5px #53ae94);
                            }
                        }
                    }
                }
            }
        }
    }

    .b2 {
        background: #e1faff;

        .projects__img-img {
            position: absolute;
            top: 26px;
            text-align: center;
            left: 0;
            right: 0;
            z-index: 4;
            transition-duration: 1s;
        }

        .projects__decor {
            &-item-1 {
                width: 100%;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-2 {
                width: 278px;
                top: 26px;
                left: 20px;
                z-index: 2;
            }

            &-item-3 {
                width: 67px;
                height: 67px;
                top: 54px;
                right: 0;
                background: #62F8FF;
                border-radius: 50%;
                z-index: 1;
            }

            &-item-4 {
                width: 92px;
                height: 92px;
                top: 263px;
                left: 21px;
                background: #53E6C9;
                border-radius: 50%;
                z-index: 1;
            }

            &-item-5 {
                width: 129px;
                height: 195px;
                top: 2px;
                left: 15px;
                background: rgba(83, 230, 201, 0.50);
                filter: blur(27px);
                border-radius: 50%;
                z-index: 1;
            }

            &-item-6 {
                width: 129px;
                height: 195px;
                top: 167px;
                left: 180px;
                background: rgba(98, 248, 255, 0.50);
                filter: blur(27px);
                border-radius: 50%;
                z-index: 1;
            }

            &-item-7 {
                width: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                opacity: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                transition: all 1s ease;
                background: #234074;

                .projects__text {
                    transition: color 1s ease;
                    color: white;
                }

                .projects__decor {
                    &-item-1 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        width: 319px;
                        transform: translateX(-19px) translateY(-14px);
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 82px;
                        height: 82px;
                        transform: translateX(16px) translateY(-14px);
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 117px;
                        height: 117px;
                        transform: translateX(-20px) translateY(-22px);
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 150px;
                        height: 304px;
                        transform: rotate(-9deg);
                        top: 9px;
                        left: 19px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 140px;
                        height: 289px;
                        transform: rotate(8deg);
                        top: 60px;
                        left: 159px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }
    }

    .b3 {
        max-width: calc((100% / 2) - 12px);
        background: #d3cfff;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        @media (max-width: $mq__tablet) {
            max-width: 288px;
        }

        .projects__img picture {
            position: absolute;
            top: 24px;
            text-align: center;
            left: 0;
            right: 0;
            z-index: 2;
            transition-duration: 1s;

            @media (max-width: $mq__tablet) {
                top: 55px;

                img {
                    max-width: 244px;
                }
            }

            &::after {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: -10px;
                content: "";
                max-width: 390px;
                width: 100%;
                height: 283px;
                background: none;
                border-radius: 16px;
                border: 1px solid white;

                @media (max-width: $mq__tablet) {
                    max-width: 258px;
                    height: 187px;
                    top: -6.6px;
                }
            }
        }

        .projects__decor {
            &-item-1 {
                z-index: 1;
                top: 11px;
                left: 10px;

                @media (max-width: $mq__tablet) {
                    top: 4px;
                    left: 18px;

                    svg {
                        transform: rotate(100deg);
                        width: 80px;
                        height: 80px;
                    }
                }
            }

            &-item-2 {
                top: 90px;
                right: 3px;

                @media (max-width: $mq__tablet) {
                    right: 0;
                    top: 223px;

                    svg {
                        width: 83px;
                    }
                }
            }

            &-bg {
                picture {
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;

                    &::after {
                        content: none;
                    }

                    @media (max-width: $mq__tablet) {
                        max-width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                transition: all 1s ease;
                background: #2f005e;

                .projects__text {
                    transition: color 1s ease;
                    color: white;
                }

                .projects__img picture {
                    transform: scale(1.1) translateY(10px);
                }

                .projects__decor {
                    &-bg picture {
                        transform: scale(1);
                    }

                    &-item-1 {
                        z-index: 2;
                        transform: scale(0.25) translateY(-80px) translateX(115px);
                        opacity: 0;
                    }

                    &-item-2 {
                        z-index: 2;
                        transform: scale(0.65) translateY(30px) translateX(-270px) rotate(-8deg);
                        opacity: 0;
                    }
                }
            }
        }
    }

    .b4 {
        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1) {
                    display: block;
                }
            }
        }

        .projects__img picture {
            position: absolute;
            top: 15px;
            left: 14px;
            z-index: 2;
            transition-duration: 1s;

            @media (max-width: $mq__tablet) {
                left: 0;
            }
        }

        .projects__decor {
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 100%;

            &-item-1 {
                left: -39px;
                top: -26px;
                background: none;
                width: 141px;
                height: 141px;
                border-radius: 100%;
                border: 15px solid #404578;
            }

            &-item-2 {
                left: -108px;
                top: -95px;
                background: none;
                width: 280px;
                height: 280px;
                border-radius: 100%;
                border: 45px solid #ff8c00;
            }

            &-item-3 {
                top: 45px;
                right: 15px;
            }

            &-item-4 {
                right: 40px;
                bottom: 106px;
                background: #404578;
                width: 58px;
                height: 58px;
                border-radius: 100%;

                @media (max-width: $mq__tablet) {
                    right: 30px;
                    bottom: 125px;
                }
            }

            &-item-5 {
                left: -15px;
                top: 144px;
                z-index: 2;
                opacity: 0;
            }

            &-item-6 {
                top: 0;
                right: -2px;
                opacity: 0;
            }

            &-item-7 {
                bottom: 58px;
                right: -2px;
                opacity: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                transition: all 1s ease;
                background: #f7f7f7;

                .projects__img picture {
                    transform: rotate(4.7deg) scale(1.1) translateX(20px);
                }

                .projects__decor {
                    &-item-1 {
                        border: none;
                        background: #404578;
                        transform: scale(0.4) translateY(-5px) translateX(30px);
                    }

                    &-item-2 {
                        transform: translateY(130px) translateX(125px);
                        opacity: 0;
                    }

                    &-item-3 {
                        opacity: 0;
                    }

                    &-item-4 {
                        transform: translateY(20px) translateX(25px);
                    }

                    &-item-5,
                    &-item-6,
                    &-item-7 {
                        transition: all 1s ease;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .b5 {
        max-width: calc((100% / 2) - 12px);
        background: #d97b95;
        overflow: hidden;

        @media (max-width: $mq__tablet) {
            max-width: 288px;

            li {
                display: none;

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4),
                &:nth-child(5) {
                    display: block;
                }
            }
        }

        .projects__img>.projects__img-1 {
            position: absolute;
            top: 48px;
            text-align: center;
            left: 0;
            right: 0;
            z-index: 2;
            transition-duration: 1s;

            &::after {
                position: absolute;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: -10px;
                content: "";
                max-width: 415px;
                width: 100%;
                height: 244px;
                background: none;
                border-radius: 16px;
                border: 1px solid white;

                @media (max-width: $mq__tablet) {
                    width: 230px;
                    height: 135px;
                    left: -6px;
                    top: -6px;
                    border-radius: 5px;
                }
            }

            @media (max-width: $mq__tablet) {
                top: 95px;
                left: 21px;
                right: initial;

                img {
                    border-radius: 5px;
                    max-width: 219px;
                    height: 124px;
                }
            }
        }

        .projects__img>.projects__img-2 {
            position: absolute;
            top: 86px;
            text-align: center;
            right: 70px;
            z-index: 2;
            transition-duration: 1s;

            @media (max-width: $mq__tablet) {
                right: 15px;
                top: 118px;

                img {
                    max-width: 59px;
                }
            }
        }

        .projects__img>.projects__img-3 {
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0;
            transition-duration: 1s;
        }

        .projects__decor {
            &-item-1 {
                z-index: 1;
                width: 365px;
                height: 364px;
                border-radius: 100%;
                background: rgba(255, 255, 255, 0.4);
                top: -22px;
                left: 0;
                right: 0;
                margin: 0 auto;
                transition: all 1s ease;

                @media (max-width: $mq__tablet) {
                    width: 219px;
                    height: 219px;
                    top: 47px;
                }
            }

            &-item-2 {
                z-index: 1;
                width: 424px;
                height: 424px;
                border-radius: 100%;
                background: rgba(255, 255, 255, 0.2);
                top: -52px;
                left: 0;
                right: 0;
                margin: 0 auto;
                transition: all 1s ease;

                @media (max-width: $mq__tablet) {
                    width: 255px;
                    height: 255px;
                    top: 29px;
                }
            }

            &-item-3 {
                z-index: 1;
                top: 14px;
                left: 0;
                right: 0;
                margin: 0 auto;
                text-align: center;

                @media (max-width: $mq__tablet) {
                    display: none;
                }
            }

            &-item-4 {
                display: none;
                left: 0;
                top: -18px;
                z-index: 3;

                img {
                    height: 400px;
                    object-fit: contain;
                }

                @media (max-width: $mq__tablet) {
                    display: block;
                }
            }
        }

        .projects__text {
            color: white;
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__img>.projects__img-1 {
                    transform: scale(1.1);
                }

                .projects__img>.projects__img-2 {
                    transform: rotate(10deg) translateX(23px);
                }

                .projects__img>.projects__img-3 {
                    opacity: 1;
                    transition: all 1s ease;
                }

                .projects__decor {
                    &-item-1 {
                        transition: all 1s ease;
                        background: rgba(188, 63, 255, 0.3);
                    }

                    &-item-2 {
                        transition: all 1s ease;
                        background: rgba(188, 63, 255, 0.15);
                    }

                    &-item-3 {
                        transition: all 1s ease;
                        opacity: 0;
                    }
                }
            }
        }
    }

    .b6 {
        background: linear-gradient(0deg,
                #000000 0%,
                #230051 19.58%,
                #450696 51.35%,
                #2b0062 85.21%,
                #000000 99%);
        position: relative;

        &::after {
            opacity: 0;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(0deg,
                    rgba(71, 0, 163, 0.4) 0%,
                    rgba(99, 0, 226, 0.4) 51.35%,
                    rgba(71, 0, 163, 0.4) 100%),
                #ffffff;
            transition-duration: 1s;
            z-index: 1;
            border-radius: 30px;
        }

        .projects__img>.projects__img-1 {
            position: absolute;
            top: 90px;
            text-align: center;
            left: 0;
            right: 0;
            z-index: 3;
            transition-duration: 1s;

            @media (max-width: $mq__tablet) {
                top: 75px;

                img {
                    max-width: 270px;
                }
            }
        }

        .projects__img>.projects__img-2 {
            position: absolute;
            top: 100px;
            text-align: center;
            left: 0;
            right: 0;
            z-index: 3;
            transition-duration: 1s;
            opacity: 0;
        }

        .projects__decor {
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;

            &-bg {
                picture {
                    z-index: 2;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                    max-width: 99%;

                    img {
                        width: 100%;
                        max-width: 100%;
                        border-radius: 30px;
                    }
                }
            }

            &-item-1 {
                right: 10px;
                bottom: 10px;
                z-index: 2;

                svg path {
                    transition: all 1s ease;
                }
            }

            &-item-2 {
                z-index: 2;
                top: 10px;
                left: -34px;
                transform: scale(0.8);
            }

            &-item-3 {
                right: 3px;
                top: 21px;
                z-index: 2;
            }

            &-item-4 {
                left: 0;
                bottom: 93px;
                z-index: 2;

                @media (max-width: $mq__tablet) {
                    bottom: 115px;
                }
            }

            &-item-5 {
                left: 70px;
                top: 12px;
                z-index: 2;
            }
        }

        .projects__text {
            color: white;
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                &::after {
                    opacity: 1;
                }

                .projects__img>.projects__img-1 {
                    transform: scale(2);
                    opacity: 0;
                    transition: all 1s ease;
                }

                .projects__img>.projects__img-2 {
                    opacity: 1;
                    transition: all 1s ease;
                }

                .projects__decor {
                    overflow: inherit;

                    &-item-1 svg path {
                        fill: black;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        transform: scale(1);
                    }

                    &-item-3 {
                        transform: scale(1.4) translateX(20px);
                    }
                }

                .projects__text {
                    transition: color 1s ease;
                    color: black;
                }
            }
        }
    }

    .b7 {
        overflow: hidden;
        background: #0d0036;

        .projects__img {
            &-block {
                border: 2px solid #ffffff;
                border-radius: 10px;
                max-width: 282px;
                width: 100%;
                height: 158px;
                overflow: hidden;
                position: relative;
                z-index: 3;
                top: 100px;
                left: 0;
                right: 0;
                margin: 0 auto;

                @media (max-width: $mq__tablet) {
                    text-align: center;
                    max-width: 265px;
                    height: 152px;
                }
            }
        }

        .projects__img .projects__img-1 {
            &::after {
                content: "";
                position: absolute;
                bottom: 15px;
                left: 0;
                right: 0;
                margin: 0 auto;
                height: 3px;
                width: 100%;
                max-width: calc(100% - 55px);
                background: #ffffff;
                box-shadow: inset 0px -1px 2px rgba(31, 31, 31, 0.5);
                border-radius: 6px;
            }

            &::before {
                content: "";
                z-index: 3;
                position: absolute;
                bottom: 10px;
                left: 50px;
                background: #9600ff;
                width: 15px;
                height: 15px;
                border-radius: 100%;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25),
                    inset 0px -2px 2px rgba(19, 19, 19, 0.4);
                transition-duration: 1s;
            }

            img {
                transition-duration: 1s;
            }
        }

        .projects__decor {
            &-item-1 {
                left: 0;
                top: 0;
                z-index: 2;
                opacity: 0.5;
            }

            &-item-2 {
                filter: blur(15.5px);
                left: 0;
                bottom: -20px;
                z-index: 1;
            }

            &-item-3 {
                filter: blur(15.5px);
                left: 0;
                bottom: -20px;
                z-index: 1;
                opacity: 0;
            }

            &-bg {
                picture {
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .projects__text {
            color: white;
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-1 {
                        opacity: 1;
                        transform: translateY(-80px);
                    }

                    &-item-2 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        opacity: 1;
                        transition: all 1s ease;
                        transform: translateY(-230px);
                    }
                }

                .projects__img .projects__img-1 {
                    img {
                        transform: scale(1.3);
                    }

                    &::before {
                        transform: translateX(165px);
                    }
                }
            }
        }
    }

    .b8 {
        overflow: hidden;
        background: linear-gradient(0deg,
                rgba(255, 255, 255, 0.8),
                rgba(255, 255, 255, 0.8)),
            #eee1c0;

        &::after {
            opacity: 0;
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            background: linear-gradient(180deg,
                    #3f1c5c 0%,
                    #391a53 50.31%,
                    #331748 100%);
            transition-duration: 1s;
            z-index: 1;
            border-radius: 30px;
        }

        .projects__img>.projects__img-1 {
            position: absolute;
            top: 50px;
            right: 26px;
            z-index: 4;
            transition-duration: 1s;
        }

        .projects__decor {
            &-item-1 {
                top: 100px;
                left: 0;
                z-index: 3;

                svg>circle {
                    transition-duration: 1s;
                }
            }

            &-item-2 {
                top: 0;
                left: 0;
                z-index: 3;
            }

            &-item-3 {
                bottom: 0;
                left: 0;
                z-index: 3;
                opacity: 0.1;
            }

            &-item-4 {
                width: 218px;
                height: 218px;
                z-index: 2;
                border-radius: 100%;
                opacity: 0.1;
                bottom: 4px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background: #e24100;
            }

            &-item-5 {
                top: 35px;
                left: 0;
                opacity: 0;
                z-index: 3;
            }

            &-item-6 {
                top: 11px;
                left: 10px;
                opacity: 0;
                z-index: 3;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                &::after {
                    opacity: 1;
                }

                .projects__img>.projects__img-1 {
                    transform: rotate(15deg);
                }

                .projects__decor {
                    &-item-1 {
                        svg>circle {
                            filter: blur(3px);
                            opacity: 0.5;
                            transition: all 1s ease;
                        }
                    }

                    &-item-2 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        transform: translateY(100px);
                    }

                    &-item-4 {
                        opacity: 1;
                        transform: translateY(-300px);
                        filter: drop-shadow(0px 0px 20px #e24100) blur(3px);
                    }

                    &-item-5,
                    &-item-6 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }

                .projects__text {
                    transition: color 1s ease;
                    color: white;
                }
            }
        }
    }

    .b9 {
        max-width: calc((100% / 2) - 12px);
        overflow: hidden;

        @media (max-width: $mq__tablet) {
            max-width: 288px;
        }

        .projects__img>.projects__img-1 {
            position: absolute;
            top: 20px;
            right: 0;
            left: 0;
            text-align: center;
            z-index: 4;

            @media (max-width: $mq__tablet) {
                top: 40px;
            }

            img {
                max-width: 314px;
                width: 100%;

                @media (max-width: $mq__tablet) {
                    max-width: 248px;
                }
            }
        }

        .projects__decor {
            svg {
                width: initial;
                height: initial;
            }

            &-item-1 {
                z-index: 2;
                left: 35px;
                top: 113px;

                @media (max-width: $mq__tablet) {
                    max-width: 90px;
                    left: 0;
                    top: 155px;
                }
            }

            &-item-2 {
                z-index: 2;
                bottom: 80px;
                right: 78px;

                @media (max-width: $mq__tablet) {
                    bottom: 165px;
                    right: 10px;
                    max-width: 65px;
                }
            }

            &-item-3 {
                z-index: 5;
                top: 45px;
                right: 170px;

                @media (max-width: $mq__tablet) {
                    max-width: 100px;
                    right: 55px;
                    top: 70px;
                }
            }

            &-item-4 {
                z-index: 2;
                top: 15px;
                right: 155px;

                @media (max-width: $mq__tablet) {
                    max-width: 70px;
                    right: 40px;
                    top: 55px;
                }
            }

            &-item-5 {
                z-index: 5;
                top: 123px;
                right: 211px;

                @media (max-width: $mq__tablet) {
                    max-width: 160px;
                    right: 70px;
                    top: 100px;
                }
            }

            &-item-6 {
                z-index: 2;
                top: 17px;
                left: 14px;

                @media (max-width: $mq__tablet) {
                    left: 15px;
                    top: 12px;

                    img {
                        width: 45px;
                        height: 45px;
                    }
                }
            }

            &-item-7 {
                z-index: 2;
                left: 94px;
                top: 72px;

                @media (max-width: $mq__tablet) {
                    left: 50px;
                    top: 63px;

                    img {
                        width: 30px;
                        height: 30px;
                    }
                }
            }

            &-item-8 {
                z-index: 2;
                bottom: 130px;
                left: 14px;

                @media (max-width: $mq__tablet) {
                    left: 2px;
                    bottom: 100px;

                    img {
                        width: 42px;
                        height: 42px;
                    }
                }
            }

            &-item-9 {
                z-index: 2;
                bottom: 110px;
                left: 172px;

                @media (max-width: $mq__tablet) {
                    display: none;
                }
            }

            &-item-10 {
                z-index: 2;
                right: 119px;
                top: 105px;

                @media (max-width: $mq__tablet) {
                    display: none;
                }
            }

            &-item-11 {
                z-index: 2;
                top: 28px;
                right: 30px;

                @media (max-width: $mq__tablet) {
                    right: 18px;
                    top: 12px;

                    img {
                        width: 35px;
                        height: 35px;
                    }
                }
            }

            &-item-12 {
                z-index: 2;
                right: 52px;
                top: 170px;

                @media (max-width: $mq__tablet) {
                    right: 40px;
                    top: 250px;

                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }

            &-item-13 {
                z-index: 2;
                bottom: 85px;
                right: 15px;

                @media (max-width: $mq__tablet) {
                    right: 5px;
                    bottom: 85px;

                    img {
                        width: 35px;
                        height: 35px;
                    }
                }
            }

            &-item-14 {
                z-index: 2;
                top: 27px;
                left: 145px;
                opacity: 0;
            }

            &-item-15 {
                z-index: 2;
                top: 0;
                right: 50px;
                opacity: 0;
            }

            &-bg {
                picture {
                    z-index: 1;
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;

                    img {
                        @media (max-width: $mq__tablet) {
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        .projects__text {
            color: white;
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {

                    &-item-14,
                    &-item-15 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-1 {
                        transform: scale(1.2) rotate(-15deg) translateX(-10px);
                    }

                    &-item-2 {
                        transform: scale(1.1);
                    }

                    &-item-6 {
                        transform: translateY(-10px) translateX(-5px);
                    }

                    &-item-7 {
                        transform: translateY(-10px) translateX(-5px);
                    }

                    &-item-8 {
                        transform: translateY(15px);
                    }

                    &-item-9 {
                        transform: translateY(15px) translateX(-10px);
                    }

                    &-item-10 {
                        transform: translateY(-2px) translateX(10px);
                    }

                    &-item-11 {
                        transform: translateY(-10px) translateX(10px);
                    }

                    &-item-12 {
                        transform: translateY(5px) translateX(5px);
                    }

                    &-item-13 {
                        transform: translateY(35px);
                    }
                }
            }
        }
    }

    .b10 {
        overflow: hidden;
        background: radial-gradient(76.61% 114.91% at 54.57% -1.54%, #B7FFFE 0%, #ABF5F9 9%, #8ADAED 27%, #55AED8 49%, #45A1D2 56%, #045391 97%, #004E8D 100%);

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 44px;
                height: 44px;
                bottom: 120px;
                left: 123px;
                z-index: 3;
            }

            &-item-2 {
                width: 22px;
                height: 22px;
                top: 20px;
                right: 41px;
                z-index: 3;
            }

            &-item-3 {
                width: 186px;
                height: 180px;
                bottom: 0;
                right: 0;
                z-index: 1;
            }

            &-item-4 {
                width: 147px;
                height: 121px;
                top: 0;
                left: 0;
                z-index: 1;
            }

            &-item-5 {
                width: 92px;
                height: 92px;
                top: 207px;
                left: 178px;
                z-index: 3;
            }

            &-item-6 {
                width: 291px;
                height: 121px;
                top: 12px;
                left: 10px;
                z-index: 3;
            }

            &-item-7 {
                width: 90px;
                height: 121px;
                bottom: 94px;
                left: 15px;
                z-index: 3;
            }

            &-item-8 {
                width: 213px;
                height: 54px;
                top: 124px;
                left: 52px;
                z-index: 3;
                transform: matrix(0.99, 0.11, -0.19, 0.98, 0, 0);
            }

            &-item-9 {
                top: 5px;
                right: 10px;
                z-index: 2;
            }

            &-item-10 {
                top: 136px;
                right: 10px;
                z-index: 2;
            }

            &-item-11 {
                top: 199px;
                left: 79px;
                z-index: 2;
            }

            &-item-12 {
                top: 141px;
                left: -4px;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-5 {
                        width: 110px;
                        height: 110px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        top: -3px;
                        right: -6px;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        right: -2px;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        top: 219px;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        top: 115px;
                        left: -12px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b11 {
        overflow: hidden;
        background: linear-gradient(90deg, #DA5457 0%, #EDB204 100%);
        max-width: calc((100% / 2) - 12px);

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 78px;
                bottom: 60px;
                left: 15px;
                z-index: 4;
            }

            &-item-2 {
                width: 78px;
                bottom: 60px;
                right: 15px;
                transform: scaleX(-1);
                z-index: 4;
            }

            &-item-3 {
                width: calc(100% - 70px);
                height: 18px;
                background: #564232;
                bottom: 63px;
                margin: 0 auto;
                right: 0;
                left: 0;
                z-index: 3;

                &:after {
                    content: '';
                    position: absolute;
                    width: calc(100% + 70px);
                    height: 1px;
                    left: -35px;
                    right: -35px;
                    top: 18px;
                    background: #263238;
                }
            }

            &-item-4 {
                width: 415px;
                top: 64px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 3;
            }

            &-item-5 {
                width: 170px;
                top: 30px;
                left: 50px;
                z-index: 2;
                opacity: 0;
            }

            &-item-6 {
                width: 160px;
                top: 30px;
                left: 172px;
                z-index: 2;
                opacity: 0;
            }

            &-item-7 {
                width: 160px;
                top: 30px;
                right: 172px;
                transform: scaleX(-1);
                z-index: 2;
                opacity: 0;
            }

            &-item-8 {
                width: 170px;
                top: 30px;
                right: 50px;
                transform: scaleX(-1);
                z-index: 2;
                opacity: 0;
            }

            &-item-9 {
                width: 64px;
                height: 42px;
                top: 15px;
                left: 30px;
                z-index: 1;
            }

            &-item-10 {
                width: 64px;
                height: 42px;
                top: 15px;
                left: 152px;
                z-index: 1;
            }

            &-item-11 {
                width: 64px;
                height: 42px;
                top: 15px;
                right: 152px;
                transform: scaleX(-1);
                z-index: 1;
            }

            &-item-12 {
                width: 64px;
                height: 42px;
                top: 15px;
                right: 30px;
                transform: scaleX(-1);
                z-index: 1;
            }

            &-item-13 {
                width: 526px;
                bottom: 81px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 0;
            }

            &-item-14 {
                width: 217px;
                height: 218px;
                top: 77px;
                left: 15px;
                transform: rotate(-13deg);
                z-index: 2;
            }

            &-item-15 {
                width: 96px;
                height: 91px;
                top: 18px;
                left: 228px;
                z-index: 2;
            }

            &-item-16 {
                width: 72px;
                height: 70px;
                top: 50px;
                right: 50px;
                transform: rotate(-3deg);
                z-index: 2;
            }

            &-item-17 {
                width: 36px;
                height: 34px;
                top: 150px;
                right: 31px;
                transform: rotate(2deg);
                z-index: 2;
            }

            &-item-18 {
                width: 45px;
                height: 45px;
                top: 119px;
                right: 113px;
                transform: rotate(-4deg);
                z-index: 1;
            }

            &-item-19 {
                width: 38px;
                height: 39px;
                top: 255px;
                right: 90px;
                transform: rotate(-9deg);
                z-index: 1;
            }

            &-item-20 {
                width: 77px;
                height: 45px;
                bottom: 174px;
                left: 84px;
                opacity: 0;
                z-index: 1;
            }

            &-item-21 {
                width: 77px;
                height: 45px;
                bottom: 174px;
                right: 84px;
                opacity: 0;
                z-index: 1;
                transform: scaleX(-1);
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-5 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-14 {
                        transform: rotate(-113deg);
                        opacity: .3;
                        transition: all 1s ease;
                    }

                    &-item-15 {
                        transform: rotate(-90deg);
                        opacity: .3;
                        transition: all 1s ease;
                    }

                    &-item-16 {
                        transform: rotate(-93deg);
                        opacity: .3;
                        transition: all 1s ease;
                    }

                    &-item-17 {
                        transform: rotate(-88deg);
                        opacity: .3;
                        transition: all 1s ease;
                    }

                    &-item-18 {
                        transform: rotate(-94deg);
                        opacity: .3;
                        transition: all 1s ease;
                    }

                    &-item-19 {
                        transform: rotate(-99deg);
                        opacity: .3;
                        transition: all 1s ease;
                    }

                    &-item-20 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-21 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b12 {
        background: #111011;

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 100%;
                height: 100%;
                z-index: 1;
                mix-blend-mode: lighten;
                border-radius: 30px;
            }

            &-item-2 {
                width: 197px;
                height: 151px;
                top: 0;
                left: 0;
                z-index: 0;
                opacity: 0;
                border-radius: 30px;
            }

            &-item-3 {
                width: 191px;
                height: 175px;
                bottom: 0;
                right: 0;
                z-index: 0;
                opacity: 0;
                border-radius: 30px;
            }

            &-item-4 {
                width: 250px;
                height: 136px;
                top: 132px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 4;
            }

            &-item-5 {
                width: 37px;
                height: 35px;
                top: 293px;
                left: 24px;
                z-index: 5;
            }

            &-item-6 {
                width: 48px;
                height: 48px;
                left: 25px;
                top: 220px;
                z-index: 5;
                opacity: 0;
            }

            &-item-7 {
                width: 48px;
                height: 48px;
                right: 7px;
                top: 99px;
                z-index: 5;
                opacity: 0;
            }

            &-item-8 {
                width: 48px;
                height: 48px;
                left: 103px;
                top: 45px;
                z-index: 5;
                opacity: 0;
            }

            &-item-9 {
                width: 330px;
                height: 330px;
                top: 35px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 2;
            }

            &-item-10 {
                width: 64px;
                height: 42px;
                top: 42px;
                right: 29px;
                z-index: 5;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #53389E;

                .projects__decor {
                    &-item-2 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 56px;
                        height: 54px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        top: 255px;
                        left: -14px;
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 84px;
                        right: -15px;
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        top: -14px;
                        left: 90px;
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        transform: rotate(180deg);
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        top: 23px;
                        right: 28px;
                        width: 68px;
                        height: 53px;
                        transform: rotate(-15deg);
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b13 {
        overflow: hidden;
        background: linear-gradient(180deg, #A296FA 0%, #7457FA 100%);
        max-width: calc((100% / 2) - 12px);

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 366px;
                height: 269px;
                margin: 0 auto;
                top: 85px;
                right: 0;
                left: 0;
                z-index: 1;
            }

            &-item-2 {
                width: 55px;
                top: 224px;
                left: 154px;
                opacity: 0;
                z-index: 2;
            }

            &-item-3 {
                width: 51px;
                height: 51px;
                top: 237px;
                left: 57px;
                z-index: 2;
            }

            &-item-4 {
                width: 86px;
                height: 75px;
                top: 103px;
                left: 81px;
                z-index: 2;
            }

            &-item-5 {
                width: 66px;
                height: 66px;
                top: 250px;
                right: 64px;
                z-index: 2;
            }

            &-item-6 {
                width: 84px;
                height: 65px;
                top: 115px;
                right: 25px;
                z-index: 2;
            }

            &-item-7 {
                width: 113px;
                height: 39px;
                top: 27px;
                left: 223px;
                z-index: 2;
            }

            &-item-8 {
                width: 65px;
                height: 65px;
                top: 38px;
                right: 115px;
                transform: rotate(13deg);
                z-index: 2;
            }

            &-item-9 {
                width: 14px;
                height: 14px;
                top: 265px;
                left: 34px;
                opacity: 0;
                z-index: 0;
            }

            &-item-10 {
                width: 14px;
                height: 14px;
                top: 18px;
                left: 44px;
                opacity: 0;
                z-index: 0;
            }

            &-item-11 {
                width: 14px;
                height: 14px;
                top: 13px;
                right: 257px;
                opacity: 0;
                z-index: 0;
            }

            &-item-12 {
                width: 14px;
                height: 14px;
                top: 226px;
                right: 34px;
                opacity: 0;
                z-index: 0;
            }

            &-item-13 {
                width: 10px;
                height: 10px;
                top: 170px;
                left: 10px;
                border-radius: 2px;
                background: #fff;
                opacity: 0;
                z-index: 0;
            }

            &-item-14 {
                width: 10px;
                height: 10px;
                top: 24px;
                right: 92px;
                border-radius: 2px;
                background: #fff;
                opacity: 0;
                z-index: 0;
            }

            &-item-15 {
                width: 10px;
                height: 10px;
                bottom: 54px;
                right: 67px;
                border-radius: 2px;
                background: #fff;
                opacity: 0;
                z-index: 0;
            }

            &-item-16 {
                width: 11px;
                height: 11px;
                top: 20px;
                left: 125px;
                border-radius: 50%;
                border: 1px solid #fff;
                z-index: 0;
            }

            &-item-17 {
                width: 11px;
                height: 11px;
                top: 286px;
                right: 37px;
                border-radius: 50%;
                border: 1px solid #fff;
                z-index: 0;
            }

            &-item-18 {
                width: 54px;
                height: 4px;
                top: 12px;
                left: 162px;
                border-radius: 2px;
                background: linear-gradient(90deg, #D86E7E 0%, #DA3A8C 97.02%);
                z-index: 0;
            }

            &-item-19 {
                width: 37px;
                height: 1px;
                top: 24px;
                right: 179px;
                background: #fff;
                z-index: 0;
            }

            &-item-20 {
                width: 29px;
                height: 1px;
                top: 48px;
                right: 240px;
                background: #fff;
                z-index: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: linear-gradient(180deg, #4838A8 0%, #190347 100%);

                .projects__decor {
                    &-item-2 {
                        opacity: 1;
                        width: 177px;
                        top: 234px;
                        left: 45px;
                        transform: rotate(-16deg);
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 56px;
                        height: 56px;
                        top: 145px;
                        left: 46px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 86px;
                        height: 75px;
                        top: 46px;
                        left: 81px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 75px;
                        height: 75px;
                        top: 250px;
                        right: 56px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 92px;
                        height: 71px;
                        top: 110px;
                        right: 14px;
                        transform: rotate(7deg);
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 75px;
                        height: 75px;
                        top: 29px;
                        right: 104px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-13 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-14 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-15 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b14 {
        background: #BEE0C8;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        .projects__decor {
            &-item-1 {
                width: 100%;
                height: 100%;
                z-index: 0;
            }

            &-item-2 {
                width: 155px;
                top: 75px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 2;

                img {
                    display: block;
                    margin: 0 auto;
                    width: 155px;
                    transition-duration: 1s;
                }
            }

            &-item-3 {
                width: 298px;
                height: 298px;
                top: 25px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 1;
            }

            &-item-4 {
                width: 38px;
                height: 38px;
                top: 1px;
                left: 78px;
                z-index: 2;
            }

            &-item-5 {
                width: 60px;
                height: 60px;
                top: 52px;
                left: 41px;
                z-index: 2;
            }

            &-item-6 {
                width: 64px;
                height: 64px;
                top: 253px;
                left: 79px;
                z-index: 2;

                svg {
                    width: 64px;
                    height: 64px;
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 2px;
                    top: 2px;
                    width: 64px;
                    height: 64px;
                    border-radius: 50%;
                    background: #9FFBA3;
                    opacity: .35;
                    z-index: 1;
                }
            }

            &-item-7 {
                width: 26px;
                height: 25px;
                top: 253px;
                left: 121px;
                z-index: 3;
            }

            &-item-8 {
                width: 67px;
                top: 163px;
                left: 6px;
                z-index: 2;
            }

            &-item-9 {
                width: 64px;
                top: 29px;
                right: 6px;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #141715;

                .projects__text {
                    color: #fff;
                    transition: all 1s ease;
                }

                .projects__decor {
                    &-item-2 {
                        width: 176px;
                        transition: all 0s ease;

                        img {
                            width: 176px;
                            transition: all 1s ease;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            top: 10px;
                            left: -28px;
                            width: 73px;
                            height: 73px;
                            border-radius: 50%;
                            background: #4A61D9;
                            z-index: -1;
                            filter: blur(30px);
                        }

                        &:before {
                            content: '';
                            position: absolute;
                            top: 10px;
                            right: -28px;
                            width: 73px;
                            height: 73px;
                            border-radius: 50%;
                            background: #4A61D9;
                            z-index: -1;
                            filter: blur(30px);
                        }
                    }

                    &-item-4 {
                        width: 42px;
                        height: 42px;
                        top: -17px;
                        left: 85px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        top: 41px;
                        left: 32px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 71px;
                        height: 71px;
                        top: 243px;
                        left: 69px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 241px;
                        left: 114px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        -webkit-filter: invert(100%);
                        filter: invert(100%);
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        -webkit-filter: invert(100%);
                        filter: invert(100%);
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b15 {
        overflow: hidden;
        background: #d67b76;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 100%;
                height: 100%;
                z-index: 0;
                opacity: 0;
                border-radius: 30px;
            }

            &-item-2 {
                width: 266px;
                top: 109px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 2;
            }

            &-item-3 {
                width: 266px;
                top: 109px;
                left: 0;
                right: 0;
                transform: rotate(-180deg);
                margin: 0 auto;
                z-index: 2;
                opacity: 0;
            }

            &-item-4 {
                width: 296px;
                top: 112px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 2;
            }

            &-item-5 {
                width: 187px;
                height: 143px;
                top: 165px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 2;
            }

            &-item-6 {
                width: 52px;
                height: 52px;
                top: 32px;
                right: 79px;
                z-index: 1;
            }

            &-item-7 {
                width: 29px;
                height: 68px;
                top: 109px;
                left: -3px;
                z-index: 1;
            }

            &-item-8 {
                width: 35px;
                height: 80px;
                top: 33px;
                left: 64px;
                z-index: 1;
            }

            &-item-9 {
                width: 41px;
                height: 120px;
                bottom: -14px;
                right: 1px;
                z-index: 1;
            }

            &-item-10 {
                width: 70px;
                height: 70px;
                bottom: 56px;
                left: -35px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.3);
                z-index: 1;
            }

            &-item-11 {
                width: 70px;
                height: 70px;
                top: 29px;
                right: -35px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.3);
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #373737;

                .projects__decor {
                    &-item-1 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        opacity: 1;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        width: 432px;
                        height: 432px;
                        bottom: 56px;
                        opacity: 0;
                        z-index: 4;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        width: 432px;
                        height: 432px;
                        opacity: 0;
                        z-index: 4;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b16 {
        overflow: hidden;
        background: #EBDD97;

        .projects__decor {
            &-item-1 {
                width: 100%;
                height: 100%;
                z-index: 0;
            }

            &-item-2 {
                width: 147px;
                top: 29px;
                left: 71px;
                z-index: 1;
            }

            &-item-3 {
                width: 52px;
                top: 132px;
                right: 13px;
                z-index: 2;
            }

            &-item-4 {
                width: 38px;
                top: 259px;
                left: 179px;
                z-index: 2;
            }

            &-item-5 {
                width: 58px;
                top: 147px;
                left: 187px;
                z-index: 2;
            }

            &-item-6 {
                width: 39px;
                top: 12px;
                left: 27px;
                z-index: 2;
            }

            &-item-7 {
                width: 69px;
                height: 61px;
                top: 234px;
                right: 18px;
                z-index: 2;
            }

            &-item-8 {
                width: 49px;
                height: 54px;
                top: 245px;
                left: 10px;
                z-index: 2;
            }

            &-item-9 {
                width: 46px;
                height: 55px;
                top: 58px;
                right: 17px;
                z-index: 2;
            }

            &-item-10 {
                width: 88px;
                height: 70px;
                top: 13px;
                right: 64px;
                z-index: 2;
            }

            &-item-11 {
                width: 118px;
                height: 71px;
                top: 13px;
                right: 48px;
                z-index: 2;
                opacity: 0;
            }

            &-item-12 {
                width: 37px;
                top: 158px;
                left: 19px;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #97D7EB;

                .projects__decor {
                    &-item-3 {
                        top: 57px;
                        right: 128px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        top: 228px;
                        left: 137px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 67px;
                        top: 131px;
                        left: 188px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 38px;
                        height: 36px;
                        top: 13px;
                        left: 58px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 25px;
                        height: 25px;
                        top: 209px;
                        left: 109px;
                        transform: rotate(-28deg);
                        filter: invert(100%) sepia(33%) saturate(983%) hue-rotate(245deg) brightness(104%) contrast(101%);
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        width: 68px;
                        height: 82px;
                        top: 66px;
                        right: 3px;
                        transform: rotate(16.17deg);
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        top: 131px;
                        left: 51px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b17 {
        overflow: hidden;
        background: #80D9FF;
        max-width: calc((100% / 2) - 12px);

        .projects__decor {
            &-item-1 {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0.15;
                z-index: 0;
            }

            &-item-2 {
                width: 445px;
                bottom: -50px;
                right: 8px;
                z-index: 2;
            }

            &-item-3 {
                width: 188px;
                top: 121px;
                left: 89px;
                z-index: 1;
            }

            &-item-4 {
                width: 112px;
                height: 118px;
                top: 66px;
                left: -38px;
                z-index: 1;

                img {
                    width: 100%;
                }
            }

            &-item-5 {
                width: 149px;
                height: 155px;
                top: -32px;
                right: -53px;
                z-index: 1;

                img {
                    width: 100%;
                }
            }

            &-item-6 {
                width: 85px;
                top: 181px;
                left: 292px;
                opacity: 0;
                z-index: 1;
            }

            &-item-7 {
                width: 81px;
                top: 212px;
                left: 311px;
                opacity: 0;
                z-index: 1;
            }

            &-item-8 {
                width: 94px;
                height: 99px;
                bottom: -38px;
                right: 33px;
                opacity: 0;
                background: #00FF94;
                filter: blur(75px);
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #16082D;

                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-1 {
                        opacity: 0.03;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        width: 355px;
                        bottom: 38px;
                        right: 151px;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 110px;
                        top: 41px;
                        left: 109px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        transition: all 1s ease;
                        width: 135px;
                        height: 142px;

                        img {
                            opacity: 0;
                            transition: all 1s ease;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: #00FF94;
                            filter: blur(75px);
                        }
                    }

                    &-item-5 {
                        transition: all 1s ease;
                        width: 169px;
                        height: 176px;

                        img {
                            opacity: 0;
                            transition: all 1s ease;
                        }

                        &:after {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: #00FF94;
                            filter: blur(75px);
                        }
                    }

                    &-item-6 {
                        width: 185px;
                        top: 131px;
                        left: 40px;
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 284px;
                        top: 165px;
                        left: 347px;
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b18 {
        background: #6626CF;

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: 0;
            }

            &-item-2 {
                width: 82px;
                height: 68px;
                top: 16px;
                right: 16px;
                z-index: 1;
                transition: all 1s ease, filter 1ms;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }

        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        width: 115px;
                        height: 95px;
                        top: -17px;
                        right: -10px;
                        filter: invert(43%) sepia(71%) saturate(2448%) hue-rotate(221deg) brightness(110%) contrast(103%);
                        transition: all 1s ease, filter 1ms;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b19 {
        background: #8F76E8;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 310px;
                left: 0;
                top: 19px;
                z-index: 0;
            }

            &-item-2 {
                width: 41px;
                top: 120px;
                left: 3px;
                opacity: 0;
                z-index: 1;
            }

            &-item-3 {
                width: 86px;
                top: 11px;
                right: 0;
                opacity: 0;
                z-index: 1;
            }

            &-item-4 {
                width: 185px;
                top: 59px;
                left: 66px;
                opacity: 0;
                z-index: 1;
            }

            &-item-5 {
                width: 61px;
                height: 61px;
                top: 80px;
                left: 128px;
                z-index: 1;
            }

            &-item-6 {
                width: 79px;
                height: 38px;
                left: 10px;
                top: 19px;
                padding: 5px 15px;
                font-weight: 600;
                font-size: 24px;
                line-height: 28px;
                color: #FFFFFF;
                background: #FF4608;
                border-radius: 13px;
                transform: rotate(-8.71deg);
                z-index: 1;
            }

            &-item-7 {
                width: 14px;
                height: 14px;
                top: 187px;
                left: 65px;
                background: #fff;
                border-radius: 50%;
                border: 3px solid #8F76E8;
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        left: 141px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b20 {
        background: #fff;
        overflow: hidden;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2) {
                    display: block;
                }
            }
        }

        .projects__decor {
            &:after {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 86px;
                margin: auto;
                width: 220px;
                height: 155px;
                box-shadow: 0px 4px 50px #FC501E;
                opacity: 0;
                transition-duration: 1s;
            }

            &-item-1 {
                width: 238px;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 41px;
                z-index: 1;
            }

            &-item-2 {
                width: 64px;
                top: 186px;
                right: 7px;
                z-index: 2;
            }

            &-item-3 {
                width: 43px;
                top: 96px;
                left: 23px;
                z-index: 2;
            }

            &-item-4 {
                width: 59px;
                top: 208px;
                left: 11px;
                z-index: 2;
            }

            &-item-5 {
                width: 159px;
                top: 113px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 2;
            }

            &-item-6 {
                width: 24px;
                top: 55px;
                left: 68px;
                z-index: 3;
            }

            &-item-7 {
                width: 22px;
                top: 85px;
                left: 160px;
                z-index: 3;
            }

            &-item-8 {
                width: 32px;
                top: 19px;
                left: 165px;
                z-index: 3;
            }

            &-item-9 {
                width: 18px;
                top: 51px;
                left: 234px;
                z-index: 3;
            }

            &-item-10 {
                width: 32px;
                top: 13px;
                left: 214px;
                z-index: 3;
            }

            &-item-11 {
                width: 23px;
                top: 10px;
                left: 125px;
                z-index: 3;
            }

            &-item-12 {
                width: 43px;
                top: 48px;
                left: 116px;
                z-index: 3;
            }

            &-item-13 {
                width: 53px;
                top: 11px;
                left: 66px;
                z-index: 3;
            }

            &-item-14 {
                width: 40px;
                top: 51px;
                left: 184px;
                z-index: 3;
            }

            &-item-15 {
                width: 46px;
                height: 46px;
                top: 142px;
                left: 58px;
                z-index: 3;
            }

            &-item-16 {
                width: 28px;
                height: 28px;
                top: 95px;
                left: 228px;
                transform: rotate(125deg);
                transform-origin: 50%;
                z-index: 3;
            }

            &-item-17 {
                width: 15px;
                height: 15px;
                top: 136px;
                right: 13px;
                z-index: 3;
            }

            &-item-18 {
                width: 28px;
                height: 28px;
                top: 146px;
                right: 22px;
                z-index: 3;
            }

            &-item-19 {
                width: 100%;
                height: 249px;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-20 {
                width: 123px;
                height: 79px;
                bottom: 0;
                left: 0;
                z-index: 3;
            }

            &-item-21 {
                width: 126px;
                height: 57px;
                bottom: 0;
                right: 0;
                z-index: 3;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }

        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #110230;

                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &:after {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        width: 79px;
                        top: 178px;
                        right: 7px;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 55px;
                        top: 83px;
                        left: 11px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 59px;
                        top: 208px;
                        left: 24px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 164px;
                        top: 111px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 18px;
                        top: 75px;
                        left: 109px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 16px;
                        top: 92px;
                        left: 163px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 24px;
                        top: 47px;
                        left: 160px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        width: 13px;
                        top: 80px;
                        left: 192px;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        width: 23px;
                        top: 50px;
                        left: 185px;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        width: 16px;
                        top: 45px;
                        left: 140px;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        width: 32px;
                        top: 72px;
                        left: 129px;
                        transition: all 1s ease;
                    }

                    &-item-13 {
                        width: 40px;
                        top: 39px;
                        left: 99px;
                        transition: all 1s ease;
                    }

                    &-item-14 {
                        width: 30px;
                        top: 69px;
                        left: 163px;
                        transition: all 1s ease;
                    }

                    &-item-15 {
                        transform: rotate(72deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-16 {
                        transform: rotate(0);
                        transition: all 1s ease;
                    }

                    &-item-17 {
                        transform: rotate(45deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-18 {
                        transform: rotate(-45deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-20 {
                        bottom: -10px;
                        left: -18px;
                        transition: all 1s ease;
                    }

                    &-item-21 {
                        bottom: -4px;
                        right: -30px;
                        transform: rotate(-36deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b21 {
        background: #C9DEEB;
        max-width: calc((100% / 2) - 12px);
        overflow: hidden;

        .projects__decor {
            &-item-1 {
                width: 658px;
                height: 400px;
                top: 0;
                left: 0;
                opacity: .3;
                z-index: 0;
            }

            &-item-2 {
                width: 658px;
                height: 400px;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 0;
            }

            &-item-3 {
                width: 486px;
                height: 286px;
                top: 33px;
                left: 0;
                right: 0;
                margin: auto;
                border: 1px solid #FFF;
                padding: 12px;
                border-radius: 18px;
                z-index: 1;

                img {
                    overflow: hidden;
                    width: 100%;
                    border-radius: 12px;
                }
            }

            &-item-4 {
                width: 57px;
                height: 57px;
                top: 45px;
                right: 97px;
                opacity: 0;
                z-index: 2;
            }

            &-item-5 {
                width: 36px;
                height: 36px;
                top: 258px;
                right: 107px;
                opacity: 0;
                z-index: 2;
            }

            &-item-6 {
                width: 30px;
                height: 30px;
                top: 171px;
                left: 108px;
                opacity: 0;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #32678F;

                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-1 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 99px;
                        height: 99px;
                        top: 1px;
                        right: 14px;
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 65px;
                        height: 65px;
                        top: 280px;
                        right: 15px;
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 50px;
                        height: 50px;
                        top: 168px;
                        left: 20px;
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b22 {
        background: #FFF9E9;
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                width: 252px;
                left: 38px;
                top: 104px;
                z-index: 1;
            }

            &-item-2 {
                width: 279px;
                top: 98px;
                left: 19px;
                z-index: 0;
            }

            &-item-3 {
                width: 279px;
                top: 98px;
                left: 19px;
                opacity: 0;
                z-index: 0;
            }

            &-item-4 {
                width: 30px;
                height: 30px;
                top: 30px;
                right: 50px;
                color: #31313A;
                z-index: 1;
            }

            &-item-5 {
                width: 20px;
                height: 20px;
                top: 28px;
                right: 32px;
                color: #31313A;
                z-index: 1;
            }

            &-item-6 {
                width: 70px;
                left: 128px;
                top: 8px;
                z-index: 1;
            }

            &-item-7 {
                width: 28px;
                height: 11px;
                top: 301px;
                left: 46px;
                z-index: 1;
            }

            &-item-8 {
                width: 85px;
                height: 96px;
                top: 146px;
                left: 92px;
                z-index: 1;
            }

            &-item-9 {
                width: 47px;
                top: 56px;
                left: 48px;
                z-index: 1;
            }

            &-item-10 {
                width: 47px;
                top: 56px;
                left: 48px;
                opacity: 0;
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #20203D;
                transition: all 1s ease;

                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-3 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        color: #FFD47D;
                        transform: rotate(45deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        color: #FFFFFF;
                        transform: rotate(-45deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        transform: rotate(13deg);
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 38px;
                        height: 15px;
                        left: 33px;
                        top: 302px;
                        transform: rotate(-11deg);
                        color: #fff;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 110px;
                        height: 125px;
                        left: 80px;
                        top: 129px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b23 {
        max-width: calc((100% / 2) - 12px);
        overflow: hidden;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    display: block;
                }
            }
        }

        .projects__decor {
            &-item-1 {
                width: 658px;
                height: 400px;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-2 {
                width: 283px;
                top: 141px;
                left: 117px;
                z-index: 3;
            }

            &-item-3 {
                width: 95px;
                top: 128px;
                left: 57px;
                z-index: 3;
            }

            &-item-4 {
                width: 320px;
                top: 117px;
                right: 8px;
                z-index: 1;
            }

            &-item-5 {
                width: 155px;
                top: 153px;
                right: 94px;
                z-index: 2;
            }

            &-item-6 {
                width: 155px;
                height: 190px;
                top: 11px;
                left: 138px;
                z-index: 4;
                transition: all .5s ease;
                transition-delay: 0.5s;
            }

            &-item-7 {
                width: 0;
                height: 190px;
                top: 11px;
                right: 480px;
                z-index: 4;
                transition: all .5s ease;
                transition-delay: 0s;
            }

            &-item-8 {
                width: 52px;
                top: 160px;
                left: 239px;
                z-index: 3;
            }

            &-item-9 {
                width: 658px;
                height: 400px;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-1 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 171px;
                        top: 148px;
                        right: 84px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        left: 178px;
                        width: 0;
                        transition: all .5s ease;
                        transition-delay: 0s;
                    }

                    &-item-7 {
                        width: 155px;
                        right: 440px;
                        transition: all .5s ease;
                        transition-delay: .5s;
                    }

                    &-item-8 {
                        width: 56px;
                        top: 173px;
                        left: 241px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b24 {
        background: #263238;
        overflow: hidden;
        transition: all 1s ease;

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 281px;
                left: 18px;
                top: 76px;
                z-index: 1;
            }

            &-item-2 {
                width: 205px;
                top: 79px;
                left: 30px;
                z-index: 2;
            }

            &-item-3 {
                width: 222px;
                top: 96px;
                left: 186px;
                opacity: 0;
                z-index: 3;
            }

            &-item-4 {
                width: 168px;
                top: 235px;
                left: 47px;
                z-index: 2;
            }

            &-item-5 {
                width: 222px;
                top: 235px;
                left: 47px;
                opacity: 0;
                z-index: 2;
            }

            &-item-6 {
                width: 102px;
                top: 96px;
                left: 186px;
                z-index: 3;
            }

            &-item-7 {
                width: 13px;
                height: 13px;
                top: 157px;
                left: 278px;
                opacity: 0;
                z-index: 1;
            }

            &-item-8 {
                width: 276px;
                height: 240px;
                top: 48px;
                left: 20px;
                z-index: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #dbf7e6;

                .projects__text {
                    color: #232323;
                }

                .projects__decor {
                    &-item-2 {
                        top: 100px;
                        left: 50px;
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        top: 103px;
                        left: 47px;
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        top: 103px;
                        left: 47px;
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 774px;
                        height: 681px;
                        top: -150px;
                        left: -170px;
                        transform: rotate(156.9deg);
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b25 {
        background: #1D0F43;
        overflow: hidden;
        transition: all 1s ease;

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 272px;
                left: 30px;
                top: 45px;
                z-index: 2;
            }

            &-item-2 {
                width: 49px;
                top: 83px;
                left: 134px;
                z-index: 2;
            }

            &-item-3 {
                width: 59px;
                top: 64px;
                left: 229px;
                z-index: 1;
            }

            &-item-4 {
                width: 64px;
                height: 0;
                bottom: 164px;
                left: 19px;
                z-index: 1;
            }

            &-item-5 {
                width: 35px;
                top: 240px;
                left: 38px;
                z-index: 1;
            }

            &-item-6 {
                width: 35px;
                top: 229px;
                left: 236px;
                z-index: 1;
            }

            &-item-7 {
                width: 22px;
                top: 206px;
                left: 48px;
                z-index: 2;
            }

            &-item-8 {
                width: 22px;
                top: 197px;
                left: 246px;
                z-index: 2;
            }

            &-item-9 {
                width: 57px;
                height: 65px;
                top: 12px;
                left: 82px;
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        transform: translateY(20px);
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        top: 17px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        height: 152px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        transform: translateY(-4px);
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        transform: translateY(-5px);
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        transform: translateY(7px);
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        top: 202px;
                        transform: translateY(5px);
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        width: 66px;
                        height: 75px;
                        transform: rotate(-16deg) translateX(-19px) translateY(-12px);
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b26 {
        background: #EBF3FA;
        overflow: hidden;
        transition: all 1s ease;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3),
                &:nth-child(4) {
                    display: block;
                }
            }
        }

        .projects__decor {
            &-item-1 {
                width: 316px;
                left: 0;
                top: 0;
                z-index: 1;
            }

            &-item-2 {
                width: 279px;
                top: 156px;
                left: 19px;
                z-index: 4;
            }

            &-item-3 {
                width: 98px;
                top: 93px;
                left: 105px;
                z-index: 3;
            }

            &-item-4 {
                width: 102px;
                top: 237px;
                left: 105px;
                z-index: 1;
            }

            &-item-5 {
                width: 93px;
                top: 10px;
                right: 38px;
                transform: rotate(-5deg);
                z-index: 4;
            }

            &-item-6 {
                width: 45px;
                top: 115px;
                left: 62px;
                transform: rotate(11.4deg);
                z-index: 4;
            }

            &-item-7 {
                width: 35px;
                top: 180px;
                left: 193px;
                transform: rotate(-12.11deg);
                z-index: 4;
            }

            &-item-8 {
                width: 138px;
                height: 162px;
                top: 77px;
                left: 83px;
                z-index: 2;
            }

            &-item-9 {
                width: 138px;
                height: 162px;
                top: 77px;
                left: 83px;
                z-index: 2;
            }

            &-item-10 {
                width: 41px;
                height: 40px;
                top: 62px;
                left: 85px;
                color: #D5DFFC;
                z-index: 4;
            }

            &-item-11 {
                width: 56px;
                height: 55px;
                top: 86px;
                left: 209px;
                color: #D5DFFC;
                z-index: 4;
            }

            &-item-12 {
                width: 21px;
                height: 20px;
                top: 156px;
                left: 251px;
                color: #8C9DF4;
                z-index: 4;
            }

            &-item-13 {
                width: 100%;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-14 {
                width: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-3 {
                        transform: rotate(6.41deg);
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 106px;
                        top: -2px;
                        right: 11px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 59px;
                        top: 108px;
                        left: 42px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 46px;
                        top: 155px;
                        left: 192px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        opacity: 1;
                    }

                    &-item-10 {
                        transform: rotate(90deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        transform: rotate(90deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        transform: rotate(90deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-13 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-14 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b27 {
        max-width: calc((100% / 2) - 12px);
        background: #1E0C4D;
        overflow: hidden;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 658px;
                max-width: initial;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-2 {
                width: 338px;
                top: 20px;
                left: 155px;
                z-index: 2;
            }

            &-item-3 {
                width: 132px;
                top: 213px;
                left: 175px;
                transform: rotate(6.1deg);
                z-index: 3;
            }

            &-item-4 {
                width: 155px;
                top: 200px;
                left: 323px;
                transform: rotate(-6.19deg);
                z-index: 3;
            }

            &-item-5 {
                width: 98px;
                top: 105px;
                left: 32px;
                transform: rotate(3.24deg);
                z-index: 2;
            }

            &-item-6 {
                width: 74px;
                top: 62px;
                left: 516px;
                transform: rotate(5.23deg);
                z-index: 2;
            }

            &-item-7 {
                width: 347px;
                height: 243px;
                top: 48px;
                left: 155px;
                z-index: 1;
            }

            &-item-8 {
                width: 203px;
                height: 142px;
                top: -28px;
                left: -100px;
                z-index: 1;
            }

            &-item-9 {
                width: 203px;
                height: 142px;
                top: -73px;
                right: -111px;
                z-index: 1;
            }

            &-item-10 {
                width: 203px;
                height: 142px;
                top: 184px;
                right: -111px;
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-1 {
                        width: 900px;
                        transform: rotate(13.1deg);
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 149px;
                        transform: rotate(-3deg) translateX(-40px) translateY(0px);
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 182px;
                        transform: none;
                        transform: rotate(-6.19deg) translateX(-5px) translateY(10px);
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 127px;
                        transform: none;
                        transform: rotate(3.24deg) translateX(-25px) translateY(13px);
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 99px;
                        transform: rotate(-5deg) translateX(-10px) translateY(-15px);
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        left: -38px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        right: -148px;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        right: -141px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b28 {
        background: #FFF9F9;
        overflow: hidden;

        .projects__decor {
            &-item-1 {
                width: 278px;
                top: 59px;
                left: 20px;
                z-index: 1;
            }

            &-item-2 {
                width: 87px;
                top: 244px;
                left: 209px;
                z-index: 2;
            }

            &-item-3 {
                width: 72px;
                height: 73px;
                bottom: 5px;
                right: 17px;
                z-index: 3;

                img {
                    width: 72px;
                }

                &:before {
                    content: '';
                    opacity: 0;
                    position: absolute;
                    width: 53px;
                    height: 53px;
                    bottom: 12px;
                    right: 10px;
                    border-radius: 50%;
                    background: #BA2BD2;
                    filter: blur(30px);
                    transition: all 1s ease;
                }
            }

            &-item-4 {
                width: 155px;
                top: 64px;
                left: 114px;
                z-index: 0;
            }

            &-item-5 {
                width: 72px;
                top: 88px;
                left: 30px;
                z-index: 0;
            }

            &-item-6 {
                width: 22px;
                top: 127px;
                right: 24px;
                z-index: 2;
            }

            &-item-7 {
                width: 56px;
                height: 60px;
                top: 10px;
                left: 72px;
                z-index: 2;

                svg {
                    width: 56px;
                    height: 60px;
                }

                &:before {
                    content: '';
                    opacity: 0;
                    position: absolute;
                    width: 53px;
                    height: 53px;
                    top: 3px;
                    left: 1px;
                    border-radius: 50%;
                    background: #DD0031;
                    filter: blur(30px);
                    transition: all 1s ease;
                }
            }

            &-item-8 {
                width: 20px;
                height: 22px;
                top: 93px;
                left: 111px;
                z-index: 1;
            }

            &-item-9 {
                width: 26px;
                height: 26px;
                top: 152px;
                left: 12px;
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        width: 103px;
                        top: 228px;
                        left: 192px;
                        transform: rotate(16.63deg);
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        transition: all 1s ease;

                        &:before {
                            opacity: 1;
                            transition: all 1s ease;
                        }
                    }

                    &-item-4 {
                        top: 23px;
                        left: 149px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        transform: rotate(-180deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 33px;
                        top: 120px;
                        right: 14px;
                        transform: rotate(9.33deg);
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        transition: all 1s ease;

                        &:before {
                            opacity: 1;
                            transition: all 1s ease;
                        }
                    }

                    &-item-8 {
                        width: 31px;
                        height: 33px;
                        top: 83px;
                        left: 105px;
                        transform: rotate(18.71deg);
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        width: 36px;
                        height: 36px;
                        top: 151px;
                        left: 5px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b29 {
        max-width: calc((100% / 2) - 12px);
        background: #FFFAE9;
        overflow: hidden;
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                width: 413px;
                top: 57px;
                left: 114px;
                z-index: 0;
            }

            &-item-2 {
                width: 413px;
                top: 57px;
                left: 114px;
                opacity: 0;
                z-index: 0;
            }

            &-item-3 {
                width: 125px;
                top: 20px;
                left: 20px;
                z-index: 1;
            }

            &-item-4 {
                width: 112px;
                top: 20px;
                right: 20px;
                z-index: 1;
            }

            &-item-5 {
                width: 108px;
                top: 279px;
                right: 20px;
                z-index: 1;
            }

            &-item-6 {
                width: 44px;
                top: 18px;
                left: 228px;
                z-index: 1;
            }

            &-item-7 {
                width: 63px;
                top: 138px;
                left: 242px;
                z-index: 1;
            }

            &-item-8 {
                width: 55px;
                top: 110px;
                left: 366px;
                z-index: 1;
            }

            &-item-9 {
                width: 42px;
                height: 79px;
                top: 22px;
                left: 332px;
                z-index: 1;
                overflow: hidden;

                img {
                    width: 42px;
                    height: 79px;
                }
            }

            &-item-10 {
                width: 37px;
                top: 163px;
                left: 268px;
                z-index: 2;
            }

            &-item-11 {
                width: 17px;
                height: 14px;
                top: 100px;
                left: 319px;
                z-index: 1;
            }

            &-item-12 {
                width: 32px;
                height: 37px;
                top: 42px;
                left: 144px;
                z-index: 1;
            }

            &-item-13 {
                width: 32px;
                height: 37px;
                top: 42px;
                left: 466px;
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #E9FFFF;
                transition: all 1s ease;

                .projects__decor {
                    &-item-1 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 153px;
                        top: 23px;
                        left: 8px;
                        transform: rotate(-11.77deg);
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 123px;
                        right: 4px;
                        transform: rotate(10.83deg);
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 130px;
                        right: 13px;
                        transform: rotate(5.31deg);
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 60px;
                        top: 22px;
                        left: 217px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 214px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        top: 128px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        height: 62px;
                        top: 12px;
                        left: 376px;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        top: 149px;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        top: 72px;
                        left: 363px;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        width: 37px;
                        height: 43px;
                        top: 36px;
                        left: 139px;
                        transition: all 1s ease;
                    }

                    &-item-13 {
                        width: 37px;
                        height: 43px;
                        top: 36px;
                        left: 466px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b30 {
        background: #ECE3FF;
        overflow: hidden;

        .projects__decor {
            &-item-1 {
                width: 145px;
                top: 98px;
                left: 69px;
                z-index: 2;
            }

            &-item-2 {
                width: 70px;
                top: 197px;
                left: 42px;
                z-index: 1;
            }

            &-item-3 {
                width: 65px;
                top: 152px;
                left: 209px;
                z-index: 1;
            }

            &-item-4 {
                width: 40px;
                top: 182px;
                left: 13px;
                z-index: 1;
            }

            &-item-5 {
                width: 36px;
                top: 59px;
                left: 11px;
                z-index: 1;
            }

            &-item-6 {
                width: 60px;
                top: 14px;
                left: 71px;
                z-index: 1;
            }

            &-item-7 {
                width: 80px;
                top: 17px;
                right: 16px;
                z-index: 1;
            }

            &-item-8 {
                width: 36px;
                top: 250px;
                right: 25px;
                z-index: 1;
            }

            &-item-9 {
                width: 225px;
                top: 50px;
                left: 58px;
                max-width: initial;
                z-index: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-1 {
                        width: 286px;
                        top: 41px;
                        left: 16px;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        width: 93px;
                        top: 0;
                        left: 0;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 65px;
                        top: 147px;
                        left: 252px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        top: 193px;
                        left: 150px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        top: 144px;
                        left: 147px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        top: 109px;
                        left: 144px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 100px;
                        right: 84px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        top: 187px;
                        right: 105px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        width: 700px;
                        top: -200px;
                        left: -100px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b31 {
        max-width: calc((100% / 2) - 12px);
        background: linear-gradient(177.06deg, #393591 57%, #59D9FA 94.2%);
        overflow: hidden;
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                width: 207px;
                top: 110px;
                left: 207px;
                z-index: 3;
            }

            &-item-2 {
                width: 168px;
                top: 121px;
                left: 218px;
                z-index: 5;
            }

            &-item-3 {
                width: 658px;
                height: 381px;
                bottom: 0;
                right: 0;
                z-index: 2;
            }

            &-item-4 {
                width: 461px;
                height: 213px;
                top: 73px;
                left: 89px;
                z-index: 1;
            }

            &-item-5 {
                width: 658px;
                height: 400px;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-6 {
                width: 72px;
                height: 72px;
                top: 135px;
                left: 275px;
                z-index: 4;
            }

            &-item-7 {
                width: 24px;
                height: 24px;
                top: 255px;
                left: 241px;
                z-index: 2;
            }

            &-item-8 {
                width: 88px;
                height: 95px;
                top: 24px;
                right: 33px;
                z-index: 3;
            }

            &-item-9 {
                width: 49px;
                height: 41px;
                top: 264px;
                right: 127px;
                z-index: 2;
            }

            &-item-10 {
                width: 38px;
                height: 22px;
                top: 74px;
                left: 276px;
                z-index: 2;
            }

            &-item-11 {
                width: 77px;
                height: 77px;
                top: 18px;
                left: 62px;
                z-index: 2;
            }

            &-item-12 {
                width: 75px;
                height: 37px;
                top: 133px;
                left: 166px;
                z-index: 5;
            }

            &-item-13 {
                width: 67px;
                height: 52px;
                top: 53px;
                left: 336px;
                z-index: 5;
            }

            &-item-14 {
                width: 31px;
                height: 52px;
                top: 198px;
                left: 428px;
                z-index: 2;
            }

            &-item-15 {
                width: 43px;
                height: 26px;
                top: 140px;
                right: 53px;
                z-index: 2;
            }

            &-item-16 {
                width: 64px;
                height: 60px;
                top: 206px;
                left: 12px;
                z-index: 2;
            }

            &-item-17 {
                width: 43px;
                height: 16px;
                top: 290px;
                left: 170px;
                z-index: 2;
            }

            &-item-18 {
                width: 64px;
                height: 65px;
                top: 21px;
                left: 177px;
                z-index: 2;
            }

            &-item-19 {
                width: 102px;
                height: 70px;
                top: 174px;
                left: 93px;
                z-index: 2;
            }

            &-item-20 {
                width: 94px;
                height: 67px;
                top: 144px;
                right: 114px;
                z-index: 2;
            }

            &-item-21 {
                width: 112px;
                height: 74px;
                top: 11px;
                right: 148px;
                z-index: 2;
            }

            &-item-22 {
                width: 16px;
                height: 16px;
                top: 82px;
                left: 183px;
                z-index: 2;
            }

            &-item-23 {
                width: 10px;
                height: 10px;
                top: 97px;
                left: 200px;
                z-index: 2;
            }

            &-item-24 {
                width: 6px;
                height: 6px;
                top: 113px;
                left: 186px;
                z-index: 2;
            }

            &-item-25 {
                width: 13px;
                height: 13px;
                top: 12px;
                left: 287px;
                z-index: 2;
            }

            &-item-26 {
                width: 8px;
                height: 8px;
                top: 28px;
                left: 300px;
                z-index: 2;
            }

            &-item-27 {
                width: 8px;
                height: 8px;
                top: 45px;
                left: 290px;
                z-index: 2;
            }

            &-item-28 {
                width: 10px;
                height: 10px;
                top: 74px;
                right: 208px;
                z-index: 2;
            }

            &-item-29 {
                width: 6px;
                height: 6px;
                top: 91px;
                right: 225px;
                z-index: 2;
            }

            &-item-30 {
                width: 13px;
                height: 13px;
                top: 268px;
                right: 227px;
                z-index: 2;
            }

            &-item-31 {
                width: 8px;
                height: 8px;
                top: 278px;
                right: 213px;
                z-index: 2;
            }

            &-item-32 {
                width: 8px;
                height: 8px;
                top: 291px;
                right: 234px;
                z-index: 2;
            }

            &-item-33 {
                width: 658px;
                height: 304px;
                top: 0;
                left: 0;
                z-index: 1;
                opacity: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: linear-gradient(185.71deg, #7190FF 15.19%, #142D5E 87.85%);

                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-3 {
                        opacity: 0;
                        right: 20px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        opacity: 0;
                        right: 20px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 777px;
                        height: 450px;
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 85px;
                        height: 86px;
                        top: 130px;
                        left: 268px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 204px;
                        left: 273px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 110px;
                        height: 118px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        top: 265px;
                        right: 48px;
                        transform: rotate(-16.41deg);
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        width: 48px;
                        height: 27px;
                        top: 72px;
                        left: 259px;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        width: 88px;
                        height: 88px;
                        top: 0;
                        left: 20px;
                        transform: rotate(-8.87deg);
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        width: 94px;
                        height: 46px;
                        top: 113px;
                        left: 171px;
                        transition: all 1s ease;
                    }

                    &-item-13 {
                        width: 79px;
                        height: 62px;
                        top: 53px;
                        left: 324px;
                        transition: all 1s ease;
                    }

                    &-item-14 {
                        top: 185px;
                        right: 253px;
                        transition: all 1s ease;
                    }

                    &-item-15 {
                        top: 75px;
                        right: 57px;
                        transition: all 1s ease;
                    }

                    &-item-16 {
                        width: 68px;
                        height: 63px;
                        top: 193px;
                        left: 10px;
                        transform: rotate(7.22deg);
                        transition: all 1s ease;
                    }

                    &-item-17 {
                        top: 291px;
                        left: 213px;
                        transform: rotate(20.9deg);
                        transition: all 1s ease;
                    }

                    &-item-18 {
                        width: 75px;
                        height: 76px;
                        top: 17px;
                        left: 158px;
                        transform: rotate(10.56deg);
                        transition: all 1s ease;
                    }

                    &-item-20 {
                        top: 150px;
                        right: 39px;
                        transform: rotate(20.18deg);
                        transition: all 1s ease;
                    }

                    &-item-22 {
                        top: 67px;
                        left: 135px;
                        transform: rotate(120.16deg);
                        transition: all 1s ease;
                    }

                    &-item-23 {
                        top: 78px;
                        left: 121px;
                        transform: rotate(120.16deg);
                        transition: all 1s ease;
                    }

                    &-item-24 {
                        top: 59px;
                        left: 118px;
                        transform: rotate(120.16deg);
                        transition: all 1s ease;
                    }

                    &-item-25 {
                        top: 24px;
                        left: 319px;
                        transform: rotate(117.75deg);
                        transition: all 1s ease;
                    }

                    &-item-26 {
                        top: 28px;
                        left: 303px;
                        transform: rotate(117.75deg);
                        transition: all 1s ease;
                    }

                    &-item-27 {
                        top: 11px;
                        left: 293px;
                        transform: rotate(117.75deg);
                        transition: all 1s ease;
                    }

                    &-item-28 {
                        top: 91px;
                        right: 187px;
                        transform: rotate(107.53deg);
                        transition: all 1s ease;
                    }

                    &-item-29 {
                        top: 73px;
                        right: 197px;
                        transform: rotate(107.53deg);
                        transition: all 1s ease;
                    }

                    &-item-30 {
                        top: 288px;
                        right: 198px;
                        transform: rotate(109.78deg);
                        transition: all 1s ease;
                    }

                    &-item-31 {
                        top: 303px;
                        right: 213px;
                        transform: rotate(109.78deg);
                        transition: all 1s ease;
                    }

                    &-item-32 {
                        top: 279px;
                        right: 217px;
                        transform: rotate(109.78deg);
                        transition: all 1s ease;
                    }

                    &-item-33 {
                        width: 461px;
                        height: 213px;
                        top: 73px;
                        left: 89px;
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b32 {
        background: #DFF1FF;
        overflow: hidden;
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                width: 307px;
                top: 8px;
                left: 0;
                z-index: 3;
            }

            &-item-2 {
                width: 54px;
                top: 30px;
                left: 226px;
                z-index: 3;
            }

            &-item-3 {
                width: 256px;
                height: 146px;
                top: 134px;
                left: 22px;
                z-index: 1;
            }

            &-item-4 {
                width: 256px;
                height: 146px;
                top: 134px;
                left: 22px;
                opacity: 0;
                z-index: 0;
            }

            &-item-5 {
                width: 257px;
                height: 77px;
                top: 130px;
                left: 21px;
                z-index: 2;
            }

            &-item-6 {
                width: 257px;
                height: 77px;
                top: 130px;
                left: 21px;
                z-index: 2;
                opacity: 0;
            }

            &-item-7 {
                width: 17px;
                height: 22px;
                top: 120px;
                left: 146px;
                z-index: 4;
            }

            &-item-8 {
                width: 52px;
                height: 52px;
                left: -26px;
                top: 42px;
                background: #00ECF8;
                filter: blur(25px);
                border-radius: 50%;
                opacity: 0;
                z-index: 5;
            }

            &-item-9 {
                width: 52px;
                height: 52px;
                left: 281px;
                top: 14px;
                background: #8B80FF;
                filter: blur(25px);
                border-radius: 50%;
                opacity: 0;
                z-index: 5;
            }

            &-item-10 {
                width: 52px;
                height: 52px;
                left: 283px;
                top: 290px;
                background: #569CFF;
                filter: blur(25px);
                border-radius: 50%;
                opacity: 0;
                z-index: 5;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #0C1540;

                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-2 {
                        width: 67px;
                        top: 22px;
                        left: 218px;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        height: 80px;
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 112px;
                        left: 146px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b33 {
        background: linear-gradient(147.87deg, #06274E 0%, #1C0659 52.4%, #37108A 100%);
        overflow: hidden;

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 0;
            }

            &-item-2 {
                width: 298px;
                top: 38px;
                left: 10px;
                z-index: 1;
            }

            &-item-3 {
                width: 51px;
                top: 168px;
                left: 23px;
                z-index: 2;
            }

            &-item-4 {
                width: 67px;
                top: 76px;
                left: 109px;
                z-index: 2;
            }

            &-item-5 {
                width: 18px;
                top: 205px;
                left: 218px;
                z-index: 2;
            }

            &-item-6 {
                width: 61px;
                top: 192px;
                left: 192px;
                z-index: 2;
                opacity: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        opacity: 1;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 66px;
                        top: 150px;
                        left: 8px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 88px;
                        top: 58px;
                        left: 109px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b34 {
        background: #EAE5FF;
        overflow: hidden;

        .projects__decor {
            &-item-1 {
                width: 233px;
                top: 131px;
                left: 42px;
                z-index: 1;
            }

            &-item-2 {
                width: 280px;
                top: 46px;
                left: 19px;
                z-index: 0;
            }

            &-item-3 {
                width: 149px;
                top: 83px;
                left: 90px;
            }

            &-item-4 {
                width: 131px;
                top: 7px;
                left: 7px;
            }

            &-item-5 {
                width: 104px;
                top: 10px;
                right: 17px;
            }

            &-item-6 {
                width: 51px;
                top: 293px;
                right: 12px;
            }

            &-item-7 {
                width: 61px;
                top: 80px;
                left: 15px;
            }

            &-item-8 {
                width: 39px;
                top: 69px;
                left: 240px;
            }

            &-item-9 {
                width: 57px;
                top: 216px;
                left: 257px;
            }

            &-item-10 {
                width: 43px;
                top: 275px;
                left: 53px;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        transform: rotate(180deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 110px;
                        transform: rotate(-12deg) translateX(-3px) translateY(-2px);
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 104px;
                        transform: rotate(6deg) translateX(11px) translateY(11px);
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 56px;
                        transform: translateX(-5px) translateY(16px);
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        transform: translateX(23px) translateY(-28px);
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        transform: translateX(21px) translateY(31px);
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        transform: translateX(-14px) translateY(36px);
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        transform: translateX(-25px) translateY(-25px);
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b35 {
        max-width: calc((100% / 2) - 12px);
        background: #002B38;
        overflow: hidden;
        transition: all 1s ease;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2) {
                    display: block;
                }
            }
        }

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 634px;
                top: 36px;
                left: 13px;
                z-index: 1;
            }

            &-item-2 {
                width: 44px;
                top: 190px;
                left: 465px;
                z-index: 5;
            }

            &-item-3 {
                width: 101px;
                top: 134px;
                left: 394px;
                z-index: 3;
            }

            &-item-4 {
                width: 0;
                top: 226px;
                left: 595px;
                z-index: 2;
            }

            &-item-5 {
                width: 53px;
                height: 16px;
                top: 88px;
                left: 19px;
                z-index: 2;
            }

            &-item-6 {
                width: 53px;
                height: 16px;
                top: 118px;
                left: 82px;
                transform: rotate(30deg);
                z-index: 2;
            }

            &-item-7 {
                width: 53px;
                height: 16px;
                top: 155px;
                left: 11px;
                transform: rotate(-106deg);
                z-index: 2;
            }

            &-item-8 {
                width: 53px;
                height: 16px;
                top: 172px;
                left: 63px;
                transform: rotate(-53deg);
                z-index: 2;
            }

            &-item-9 {
                width: 139px;
                height: 139px;
                top: 121px;
                left: 379px;
                background: #08C6A2;
                border-radius: 5px;
                z-index: 2;
            }

            &-item-10 {
                width: 0;
                height: 0;
                top: 191px;
                left: 465px;
                border-radius: 50%;
                background: #18E6BF;
                z-index: 4;
            }

            &-item-11 {
                width: 205px;
                height: 205px;
                top: 130px;
                left: -33px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.32);
                filter: blur(55px);
                z-index: 0;
            }

            &-item-12 {
                width: 205px;
                height: 205px;
                top: -66px;
                right: -41px;
                border-radius: 50%;
                background: rgba(255, 255, 255, 0.32);
                filter: blur(55px);
                z-index: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                transition: all 1s ease;

                .projects__decor {
                    &-item-2 {
                        width: 33px;
                        top: 184px;
                        left: 466px;
                        transform: rotate(-11.2deg);
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 33px;
                        top: 196px;
                        left: 577px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        top: 171px;
                        left: 56px;
                        transform: rotate(-18.58deg);
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        top: 180px;
                        left: 91px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 193px;
                        left: 33px;
                        transform: rotate(-33deg);
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        top: 196px;
                        left: 76px;
                        transform: rotate(-5deg);
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        background: #18E6BF;
                        transition: all .7s ease;
                        transition-delay: .3s;
                    }

                    &-item-10 {
                        width: 235px;
                        height: 235px;
                        top: 72px;
                        left: 347px;
                        opacity: 0;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b36 {
        background: #D9EBFF;
        overflow: hidden;

        .projects__decor {
            &-item-1 {
                width: 210px;
                top: 110px;
                left: 45px;
                z-index: 1;
            }

            &-item-2 {
                width: 97px;
                top: 136px;
                left: 132px;
                z-index: 2;
            }

            &-item-3 {
                width: 31px;
                top: 155px;
                left: 237px;
                z-index: 2;
            }

            &-item-4 {
                width: 36px;
                top: 68px;
                left: 25px;
                z-index: 2;
            }

            &-item-5 {
                width: 44px;
                top: 16px;
                left: 240px;
                z-index: 2;
            }

            &-item-6 {
                width: 106px;
                top: 81px;
                left: 203px;
                z-index: 2;
            }

            &-item-7 {
                width: 64px;
                top: 272px;
                left: 236px;
                z-index: 2;
            }

            &-item-8 {
                width: 36px;
                top: 146px;
                left: 6px;
                z-index: 2;
            }

            &-item-9 {
                width: 271px;
                top: 60px;
                left: 24px;
                z-index: 0;
            }

            &-item-10 {
                width: 29px;
                top: 28px;
                left: 85px;
                z-index: 1;
            }

            &-item-11 {
                width: 55px;
                top: 30px;
                left: 117px;
                z-index: 1;
            }

            &-item-12 {
                width: 35px;
                top: 30px;
                left: 176px;
                z-index: 1;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        width: 163px;
                        top: 126px;
                        left: 108px;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 38px;
                        top: 140px;
                        left: 241px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        top: 133px;
                        left: 69px;
                        z-index: 0;
                        transition-timing-function: ease;
                        transition-duration: 1s;
                        transition-property: left, top;
                    }

                    &-item-5 {
                        top: 142px;
                        left: 166px;
                        z-index: 0;
                        transition-timing-function: ease;
                        transition-duration: 1s;
                        transition-property: left, top;
                    }

                    &-item-6 {
                        top: 134px;
                        left: 118px;
                        z-index: 0;
                        transition-timing-function: ease;
                        transition-duration: 1s;
                        transition-property: left, top;
                    }

                    &-item-7 {
                        top: 165px;
                        left: 142px;
                        z-index: 0;
                        transition-timing-function: ease;
                        transition-duration: 1s;
                        transition-property: left, top;
                    }

                    &-item-8 {
                        top: 172px;
                        left: 51px;
                        z-index: 0;
                        transition-timing-function: ease;
                        transition-duration: 1s;
                        transition-property: left, top;
                    }

                    &-item-9 {
                        width: 94px;
                        top: 137px;
                        left: 122px;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        transform: rotate(-157.48deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        transform: rotate(-180deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        transform: rotate(-180deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b37 {
        background: #FFFFFF;
        overflow: hidden;
        transition: all 1s ease;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2) {
                    display: block;
                }
            }
        }

        .projects__decor {
            &-item-1 {
                width: 267px;
                top: 71px;
                left: 23px;
                z-index: 1;
            }

            &-item-2 {
                width: 111px;
                top: 119px;
                left: 85px;
                z-index: 3;
            }

            &-item-3 {
                width: 139px;
                top: 161px;
                left: 72px;
                z-index: 4;
            }

            &-item-4 {
                width: 73px;
                top: 152px;
                left: 108px;
                z-index: 5;
            }

            &-item-5 {
                width: 282px;
                height: 212px;
                top: 119px;
                left: 21px;
                color: #E0E0E0;
                z-index: 0;
            }

            &-item-6 {
                width: 50px;
                height: 65px;
                top: 39px;
                left: 13px;
                color: #E0E0E0;
                z-index: 1;
            }

            &-item-7 {
                width: 38px;
                height: 49px;
                top: 40px;
                right: 16px;
                color: #E0E0E0;
                z-index: 1;
            }

            &-item-8 {
                width: 43px;
                height: 42px;
                top: 15px;
                left: 91px;
                z-index: 1;
            }

            &-item-9 {
                width: 82px;
                height: 29px;
                top: 22px;
                left: 146px;
                color: #000;
                z-index: 1;
            }

            &-item-10 {
                width: 124px;
                height: 262px;
                top: 80px;
                left: 82px;
                color: #fff;
                z-index: 2;
            }

            &-item-11 {
                width: 124px;
                height: 262px;
                top: 80px;
                left: 82px;
                color: #fff;
                z-index: 2;
                opacity: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #1B1B1B;

                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-3 {
                        top: 190px;
                        left: 96px;
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 55px;
                        top: 181px;
                        left: 117px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        color: #fff;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 38px;
                        height: 50px;
                        top: 45px;
                        left: 21px;
                        transform: rotate(-8deg);
                        color: #fff;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 38px;
                        height: 49px;
                        top: 32px;
                        right: 5px;
                        transform: rotate(10deg);
                        color: #fff;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        color: #fff;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b38 {
        background: #DDE7FE;
        overflow: hidden;
        transition: all 1s ease;

        .projects__text {
            padding-right: 100px;
        }

        .projects__decor {
            &-item-1 {
                width: 204px;
                top: 115px;
                left: 60px;
                z-index: 0;
            }

            &-item-2 {
                width: 174px;
                top: 65px;
                left: 72px;
            }

            &-item-3 {
                width: 66px;
                top: 139px;
                left: 12px;
            }

            &-item-4 {
                width: 68px;
                top: 27px;
                left: 22px;
            }

            &-item-5 {
                width: 55px;
                top: 11px;
                left: 71px;
            }

            &-item-6 {
                width: 33px;
                top: 100px;
                left: 249px;
            }

            &-item-7 {
                width: 60px;
                top: 181px;
                left: 249px;
            }

            &-item-8 {
                width: 68px;
                top: 323px;
                left: 200px;
                transform: rotate(-8deg);
            }

            &-item-9 {
                width: 36px;
                top: 274px;
                left: 269px;
            }

            &-item-10 {
                width: 36px;
                top: 274px;
                left: 269px;
                transform: rotate(17.83deg);
                opacity: 0;
            }

            &-item-11 {
                width: 44px;
                top: 17px;
                left: 222px;
            }

            &-item-12 {
                width: 36px;
                height: 36px;
                top: 37px;
                left: 186px;
                color: #4851EA;
            }

            &-item-13 {
                width: 24px;
                height: 24px;
                top: 65px;
                left: 220px;
                color: #0C0755;
            }

            &-item-14 {
                width: 39px;
                height: 40px;
                top: 245px;
                left: 27px;
            }

            &-item-15 {
                width: 49px;
                height: 50px;
                top: 215px;
                left: 71px;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        width: 223px;
                        top: 15px;
                        left: 36px;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 71px;
                        top: 149px;
                        left: 12px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 34px;
                        top: 18px;
                        left: 22px;
                        transform: rotate(157deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 27px;
                        top: 11px;
                        left: 47px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 37px;
                        top: 94px;
                        left: 249px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 71px;
                        top: 145px;
                        left: 234px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 100px;
                        top: 305px;
                        left: 197px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        width: 59px;
                        top: 257px;
                        left: 260px;
                        opacity: 0;
                        transform: rotate(-17.83deg);
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        width: 62px;
                        top: 249px;
                        left: 256px;
                        opacity: 1;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        transform: rotate(90deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        transform: rotate(-180deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-13 {
                        transform: rotate(-90deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b39 {
        max-width: calc((100% / 2) - 12px);
        overflow: hidden;
        transition: all 1s ease;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        .projects__decor {
            &-item-1 {
                width: 100%;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-2 {
                width: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                opacity: 0;
            }

            &-item-3 {
                width: 481px;
                top: 25px;
                left: 92px;
                z-index: 1;
            }

            &-item-4 {
                width: 83px;
                top: 28px;
                left: 312px;
                z-index: 2;
            }

            &-item-5 {
                width: 68px;
                top: 22px;
                left: 413px;
                z-index: 2;
            }

            &-item-6 {
                width: 100px;
                top: 186px;
                left: 135px;
                z-index: 5;
            }

            &-item-7 {
                width: 109px;
                top: 19px;
                left: 189px;
                z-index: 2;
            }

            &-item-8 {
                width: 73px;
                top: 257px;
                left: 336px;
                z-index: 2;
            }

            &-item-9 {
                width: 160px;
                top: 89px;
                left: 254px;
                z-index: 3;
            }

            &-item-10 {
                width: 125px;
                top: 121px;
                left: 432px;
                z-index: 2;
            }

            &-item-11 {
                width: 76px;
                top: 227px;
                left: 486px;
                z-index: 2;
            }

            &-item-12 {
                width: 83px;
                top: 80px;
                left: 119px;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__text {
                    color: #fff;
                }

                .projects__decor {
                    &-item-1 {
                        opacity: 0;
                        transition: all 1s ease;
                    }

                    &-item-2 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b40 {
        max-width: calc((100% / 2) - 12px);
        background: #8AC1F2;
        transition: all 1s ease;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2) {
                    display: block;
                }
            }
        }

        .projects__decor {
            &-item-1 {
                width: 95px;
                top: 161px;
                left: 75px;
                z-index: 5;
            }

            &-item-2 {
                width: 122px;
                top: 134px;
                left: 430px;
                z-index: 5;
            }

            &-item-3 {
                width: 324px;
                top: 12px;
                left: 144px;
                z-index: 1;
            }

            &-item-4 {
                width: 80px;
                top: 204px;
                left: 144px;
                z-index: 4;
            }

            &-item-5 {
                width: 103px;
                top: 34px;
                left: 161px;
                z-index: 2;
            }

            &-item-6 {
                width: 83px;
                top: 28px;
                left: 23px;
                z-index: 2;
            }

            &-item-7 {
                width: 92px;
                top: 91px;
                right: 5px;
                z-index: 2;
            }

            &-item-8 {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
                overflow: hidden;

                img {
                    position: absolute;
                    width: 968px;
                    max-width: initial;
                    top: -134px;
                    left: -108px;
                }
            }

            &-item-9 {
                width: 39px;
                top: 107px;
                left: 26px;
                transform: rotate(10deg);
                z-index: 3;
            }

            &-item-10 {
                width: 17px;
                top: 125px;
                left: 63px;
                transform: rotate(10deg);
                z-index: 3;
            }

            &-item-11 {
                width: 61px;
                top: 22px;
                right: 99px;
                transform: rotate(25deg);
                z-index: 2;
            }

            &-item-12 {
                width: 26px;
                top: 51px;
                right: 76px;
                transform: rotate(25deg);
                z-index: 2;
            }

            &-item-13 {
                width: 24px;
                height: 98px;
                bottom: 118px;
                left: 165px;
                background: #7371FC;
                z-index: 2;
            }

            &-item-14 {
                width: 24px;
                height: 110px;
                bottom: 118px;
                left: 200px;
                background: #7371FC;
                z-index: 2;
            }

            &-item-15 {
                width: 24px;
                height: 122px;
                bottom: 118px;
                left: 237px;
                background: #7371FC;
                z-index: 2;
            }

            &-item-16 {
                width: 24px;
                height: 136px;
                bottom: 118px;
                left: 273px;
                background: #7371FC;
                z-index: 2;
            }

            &-item-17 {
                width: 24px;
                height: 151px;
                bottom: 118px;
                left: 310px;
                background: #7371FC;
                z-index: 2;
            }

            &-item-18 {
                width: 24px;
                height: 169px;
                bottom: 118px;
                left: 345px;
                background: #7371FC;
                z-index: 2;
            }

            &-item-19 {
                width: 24px;
                height: 210px;
                bottom: 118px;
                left: 382px;
                background: #7371FC;
                z-index: 2;
            }

            &-item-20 {
                width: 24px;
                height: 245px;
                bottom: 118px;
                left: 418px;
                background: #7371FC;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #B0AFFF;

                .projects__decor {
                    &-item-5 {
                        transform: rotate(-88deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 102px;
                        top: 17px;
                        left: -10px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 114px;
                        top: 91px;
                        right: -17px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        transition: all 1s ease;

                        img {
                            top: -79px;
                            left: -113px;
                            transition: all 1s ease;
                        }
                    }

                    &-item-9 {
                        transform: rotate(-80deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        transform: rotate(50deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        transform: rotate(-65deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        transform: rotate(63deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-13 {
                        height: 127px;
                        transition: all 1s ease;
                    }

                    &-item-14 {
                        height: 78px;
                        transition: all 1s ease;
                    }

                    &-item-15 {
                        height: 101px;
                        transition: all 1s ease;
                    }

                    &-item-16 {
                        height: 43px;
                        transition: all 1s ease;
                    }

                    &-item-17 {
                        height: 167px;
                        transition: all 1s ease;
                    }

                    &-item-18 {
                        height: 117px;
                        transition: all 1s ease;
                    }

                    &-item-19 {
                        height: 161px;
                        transition: all 1s ease;
                    }

                    &-item-20 {
                        height: 196px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b41 {
        overflow: hidden;
        background: #fff;
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                width: 420px;
                max-width: initial;
                top: -27px;
                left: -38px;
                z-index: 0;
            }

            &-item-2 {
                width: 280px;
                top: 1px;
                left: 35px;
                z-index: 1;
            }

            &-item-3 {
                width: 106px;
                top: 166px;
                left: 106px;
                z-index: 3;
            }

            &-item-4 {
                width: 310px;
                top: 7px;
                left: 0;
                z-index: 2;
            }

            &-item-5 {
                width: 146px;
                top: 135px;
                left: 171px;
                z-index: 2;
            }

            &-item-6 {
                width: 35px;
                top: 273px;
                left: 74px;
                z-index: 3;
            }

            &-item-7 {
                width: 73px;
                top: 210px;
                left: 8px;
                z-index: 3;
            }

            &-item-8 {
                width: 49px;
                top: 4px;
                left: 21px;
                z-index: 3;
            }

            &-item-9 {
                width: 73px;
                top: 245px;
                left: 261px;
                z-index: 3;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-1 {
                        width: 430px;
                        top: -22px;
                        left: -42px;
                        transform: rotate(180deg);
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        transform: rotate(6deg);
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        transform: rotate(-6deg);
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        top: 234px;
                        left: 15px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 220px;
                        left: 72px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        top: 141px;
                        left: 186px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        top: 219px;
                        left: 175px;
                        transform: rotate(136deg);
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b42 {
        max-width: calc((100% / 2) - 12px);
        background: #97A9EB;
        transition: all 1s ease;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        .projects__text {
            left: 0;
            bottom: 0;
            backdrop-filter: blur(10px);
            padding-bottom: 15px;
            padding-left: 15px;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
        }

        .projects__decor {
            &-item-1 {
                width: 392px;
                top: 81px;
                left: 78px;
                z-index: 2;
            }

            &-item-2 {
                width: 580px;
                top: 87px;
                left: 45px;
                z-index: 1;
            }

            &-item-3 {
                width: 135px;
                top: 137px;
                left: 402px;
                z-index: 1;
            }

            &-item-4 {
                width: 27px;
                top: 145px;
                left: 539px;
                z-index: 4;
            }

            &-item-5 {
                width: 35px;
                top: 38px;
                left: 410px;
                z-index: 2;
            }

            &-item-6 {
                width: 32px;
                top: 66px;
                left: 447px;
                transform: scaleX(-1);
                z-index: 2;
            }

            &-item-7 {
                width: 28px;
                top: 97px;
                left: 422px;
                transform: rotate(-25deg);
                z-index: 2;
            }

            &-item-8 {
                width: 21px;
                height: 5px;
                top: 64px;
                left: 261px;
                color: #fff;
                z-index: 3;
            }

            &-item-9 {
                width: 28px;
                height: 7px;
                top: 35px;
                left: 530px;
                color: #fff;
                z-index: 3;
            }

            &-item-10 {
                width: 28px;
                height: 7px;
                top: 71px;
                left: 567px;
                color: #fff;
                z-index: 3;
            }

            &-item-11 {
                width: 49px;
                height: 50px;
                top: 13px;
                left: 343px;
                color: #543864;
                z-index: 3;
            }

            &-item-12 {
                width: 55px;
                height: 55px;
                top: 127px;
                left: 524px;
                color: #202040;
                z-index: 2;
            }

            &-item-13 {
                width: 49px;
                height: 38px;
                top: 49px;
                left: 246px;
                color: #202040;
                transform: scaleX(-1);
                z-index: 2;
            }

            &-item-14 {
                width: 68px;
                height: 53px;
                top: 14px;
                left: 510px;
                color: #202040;
                z-index: 1;
            }

            &-item-15 {
                width: 68px;
                height: 53px;
                top: 51px;
                left: 547px;
                color: #543864;
                transform: scaleX(-1);
                z-index: 2;
            }

            &-item-16 {
                width: 185px;
                top: 24px;
                left: 22px;
                z-index: 2;
            }

            &-item-17 {
                width: 159px;
                top: 75px;
                left: 62px;
                z-index: 3;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #0C1947;

                .projects__text {
                    color: #fff;
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b43 {
        background: linear-gradient(151deg, #E008E0 2.00%, #AB08C0 20.00%, #7709A1 40.00%, #52098A 57.00%, #3B097C 71.00%, #330977 80.00%, #2D0869 87.00%, #1F0547 99.00%);
        overflow: hidden;
        transition: all 1s ease;

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 0;
            }

            &-item-2 {
                width: 260px;
                top: 45px;
                left: 54px;
                z-index: 1;
            }

            &-item-3 {
                width: 165px;
                top: 20px;
                left: 20px;
                z-index: 3;
            }

            &-item-4 {
                width: 59px;
                top: 221px;
                left: 7px;
                z-index: 2;
            }

            &-item-5 {
                width: 81px;
                top: 271px;
                left: 226px;
                z-index: 2;
            }

            &-item-6 {
                width: 72px;
                top: 76px;
                left: 5px;
                z-index: 2;
            }

            &-item-7 {
                width: 72px;
                top: 4px;
                left: 239px;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-1 {
                        opacity: 1;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b44 {
        overflow: hidden;
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-2 {
                width: 124px;
                top: 126px;
                left: 92px;
                z-index: 5;
            }

            &-item-3 {
                width: 177px;
                top: 18px;
                left: 77px;
                transform: rotate(-13deg);
                z-index: 1;
            }

            &-item-4 {
                width: 200px;
                top: 170px;
                left: -12px;
                transform: rotate(10deg);
                z-index: 2;
            }

            &-item-5 {
                width: 85px;
                top: 115px;
                left: 234px;
                transform: rotate(7deg);
                z-index: 2;
            }

            &-item-6 {
                width: 123px;
                top: 175px;
                left: 205px;
                z-index: 3;
            }

            &-item-7 {
                width: 49px;
                top: 233px;
                left: 174px;
                transform: rotate(-15deg);
                z-index: 2;
            }

            &-item-8 {
                width: 72px;
                top: 94px;
                left: 15px;
                transform: rotate(-13deg);
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        width: 172px;
                        top: 88px;
                        left: 71px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        left: -30px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        top: 93px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 131px;
                        top: 136px;
                        left: 187px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 75px;
                        top: 236px;
                        left: 130px;
                        transform: none;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 80px;
                        top: 68px;
                        left: 6px;
                        transform: none;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b45 {
        background: #E4EBF9;
        overflow: hidden;
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                width: 295px;
                top: 24px;
                left: 9px;
                z-index: 0;
            }

            &-item-2 {
                width: 172px;
                height: 125px;
                top: 187px;
                left: 119px;
                overflow: hidden;
                border-radius: 10px;
                border: 2px solid #70A2F9;
                z-index: 3;
            }

            &-item-3 {
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                top: -2px;
                left: -2px;
                z-index: 1;
                max-width: initial;
            }

            &-item-4 {
                width: 67px;
                top: 27px;
                left: 53px;
                z-index: 3;
            }

            &-item-5 {
                width: 41px;
                height: 40px;
                top: 27px;
                left: 8px;
                color: #F3F7FF;
                z-index: 2;
            }

            &-item-6 {
                width: 115px;
                height: 116px;
                bottom: -45px;
                right: -46px;
                color: #F3F7FF;
                z-index: 2;
            }

            &-item-7 {
                width: 68px;
                top: 247px;
                left: 23px;
                z-index: 2;
            }

            &-item-8 {
                width: 172px;
                top: 25px;
                left: 26px;
                z-index: 2;
            }

            &-item-9 {
                width: 74px;
                top: 153px;
                left: 50px;
                z-index: 1;
            }

            &-item-10 {
                width: 74px;
                top: 120px;
                left: 197px;
                transform: rotate(180deg);
                z-index: 1;
            }

            &-item-11 {
                width: 36px;
                height: 35px;
                top: 73px;
                left: 217px;
                color: #477FF7;
                z-index: 4;
            }

            &-item-12 {
                width: 25px;
                height: 24px;
                top: 101px;
                left: 252px;
                color: #6F80B5;
                transform: rotate(10deg);
                z-index: 4;
            }

            &-item-13 {
                width: 44px;
                height: 45px;
                top: 52px;
                left: 254px;
                color: #69D7E4;
                z-index: 4;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        width: 253px;
                        height: 184px;
                        top: 79px;
                        left: 24px;
                        border-radius: 14px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        width: 98px;
                        top: 38px;
                        left: 76px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        width: 60px;
                        height: 59px;
                        top: 35px;
                        left: 8px;
                        transform: rotate(168deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        width: 168px;
                        height: 171px;
                        bottom: -79px;
                        right: -78px;
                        transform: rotate(171deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        width: 68px;
                        top: 178px;
                        left: 58px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        width: 102px;
                        top: 149px;
                        left: 127px;
                        transition: all 1s ease;
                    }

                    &-item-9 {
                        top: 102px;
                        left: 164px;
                        transform: rotate(-90deg);
                        transition: all 1s ease;
                    }

                    &-item-10 {
                        top: 79px;
                        left: 90px;
                        transform: rotate(90deg);
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        top: 59px;
                        left: 216px;
                        transform: rotate(111deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-12 {
                        top: 88px;
                        left: 252px;
                        transform: rotate(-22deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }

                    &-item-13 {
                        top: 34px;
                        left: 249px;
                        transform: rotate(143deg);
                        transform-origin: 50%;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b46 {
        background: #EAF0FE;
        overflow: hidden;
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                top: 0;
                left: 0;
                z-index: 0;
            }

            &-item-2 {
                top: 75px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 2;
            }

            &-item-3 {
                top: 20px;
                left: 20px;
                z-index: 1;
                width: 55px;
                height: 55px;
            }

            &-item-4 {
                top: 4px;
                right: 0;
                left: 0;
                margin: 0 auto;
                z-index: 1;
                width: 44px;
                height: 72px;
            }

            &-item-5 {
                top: 20px;
                right: 20px;
                z-index: 1;
                width: 55px;
                height: 55px;
            }

            &-item-6 {
                top: 79px;
                left: 33px;
                z-index: 1;
                width: 25px;
                height: 32px;
            }

            &-item-7 {
                top: 79px;
                right: 0;
                left: 0;
                margin: 0 auto;
                z-index: 1;
                width: 13px;
                height: 12px;
            }

            &-item-8 {
                top: 79px;
                right: 33px;
                z-index: 1;
                width: 25px;
                height: 32px;
            }

            &-item-9 {
                top: 200px;
                left: 30px;
                z-index: 1;
                width: 36px;
                height: 76px;
            }

            &-item-10 {
                top: 200px;
                right: 30px;
                z-index: 1;
                width: 36px;
                height: 76px;
            }

            &-item-11 {
                top: 240px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 3;
                width: 75px;
                height: 75px;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        top: 40px;
                        transform: scale(1.45);
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        top: 126px;
                        left: 95px;
                        transition: all 1s ease;
                    }

                    &-item-4 {
                        top: 117px;
                        transition: all 1s ease;
                    }

                    &-item-5 {
                        top: 123px;
                        right: 112px;
                        transition: all 1s ease;
                    }

                    &-item-6 {
                        left: 60px;
                        transition: all 1s ease;
                    }

                    &-item-7 {
                        top: 79px;
                        transition: all 1s ease;
                    }

                    &-item-8 {
                        right: 67px;
                        transition: all 1s ease;
                    }

                    &-item-11 {
                        top: 185px;
                        width: 130px;
                        height: 130px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b47 {
        background: #EAF0FE;
        transition: all 1s ease;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    display: block;
                }
            }
        }

        .projects__text {
            color: #fff;
        }

        .projects__decor {
            &-item-1 {
                top: 0;
                left: 0;
                z-index: 0;
                height: -webkit-fill-available;
            }

            &-item-2 {
                top: 50px;
                right: -5px;
                z-index: 0;
            }

            &-item-3 {
                top: 16px;
                left: 22px;
                width: 144px;
                height: 99px;
                z-index: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-2 {
                        transform: scale(1.15) rotate(6.3deg);
                        transition: all 1s ease;
                    }

                    &-item-3 {
                        width: 164px;
                        height: 113px;
                        transition: all 1s ease;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b48 {
        background: #FFFFFF;
        max-width: calc((100% / 2) - 12px);
        transition: all 1s ease;

        @media (max-width: $mq__tablet) {
            li {
                display: none;

                &:nth-child(1),
                &:nth-child(2) {
                    display: block;
                }
            }
        }

        .projects__decor-dop {
            height: 400px;
            position: relative;
            overflow: hidden;
        }

        .projects__decor {
            &-item-1 {
                width: 190px;
                height: 190px;
                top: 6px;
                left: 18px;
                z-index: 0;
            }

            &-item-2 {
                width: 402px;
                height: 402px;
                bottom: -125px;
                right: -75px;
                z-index: 0;
            }

            &-item-3 {
                width: 75px;
                height: 45px;
                top: 60px;
                left: 34px;
                z-index: 0;
            }

            &-item-4 {
                width: 32px;
                height: 30px;
                top: 23px;
                left: 74px;
                z-index: 0;
            }

            &-item-5 {
                width: 93px;
                height: 16px;
                top: 140px;
                left: 173px;
                z-index: 0;

                g path {
                    transition: all 1s ease;
                }
            }

            &-item-6 {
                width: 29px;
                height: 38px;
                top: 62px;
                left: 263px;
                z-index: 0;
            }

            &-item-7 {
                width: 90px;
                height: 40px;
                top: 261px;
                left: 244px;
                z-index: 0;
            }

            &-item-8 {
                width: 112px;
                height: 17px;
                top: 38px;
                right: 203px;
                z-index: 0;

                g path {
                    transition: all 1s ease;
                }
            }

            &-item-9 {
                width: 385px;
                height: 206px;
                top: 49px;
                left: 127px;
                z-index: 0;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-10 {
                width: 67px;
                height: 87px;
                top: 99px;
                left: 289px;
                z-index: 0;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-11 {
                width: 65px;
                height: 40px;
                top: 80px;
                right: 223px;
                z-index: 0;
                transition: all 1s ease;
            }

            &-item-11-1 {
                position: absolute;
                width: 80px;
                height: 49px;
                top: 70px;
                right: 213px;
                z-index: 0;
                opacity: 0;
                transition: all 1s ease;
            }

            &-item-12 {
                width: 87px;
                height: 20px;
                top: 169px;
                right: 210px;
                z-index: 0;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-13 {
                width: 88px;
                height: 38px;
                top: 205px;
                right: 205px;
                z-index: 0;
            }

            &-item-14 {
                width: 42px;
                height: 30px;
                top: 11px;
                right: 73px;
                z-index: 0;
            }

            &-item-15 {
                width: 70px;
                height: 35px;
                top: 69px;
                right: 33px;
                z-index: 0;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-16 {
                width: 21px;
                height: 17px;
                top: 128px;
                right: 20px;
                z-index: 0;
            }

            &-item-17 {
                width: 100px;
                height: 21px;
                top: 164px;
                right: 71px;
                z-index: 0;

                g path {
                    transition: all 1s ease;
                }
            }

            &-item-18 {
                width: 113px;
                height: 82px;
                top: 12px;
                left: 127px;
                z-index: 0;
            }

            &-item-19 {
                width: 223px;
                height: 133px;
                top: 171px;
                left: 22px;
                z-index: 0;
            }

            &-item-20 {
                width: 115px;
                height: 115px;
                top: 5px;
                right: 104px;
                z-index: 0;
            }

            &-item-21 {
                width: 187px;
                height: 114px;
                top: 191px;
                right: 25px;
                z-index: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #012F58;

                .projects__text {
                    color: #fff;
                    transition: all 1s ease;
                }

                .projects__decor {
                    &-item-1 {
                        transition: all 1s ease;
                        transform: rotate(90deg);
                        transform-origin: 50%;
                    }

                    &-item-2 {
                        width: 402px;
                        height: 402px;
                        transition: all 1s ease;
                        transform: rotate(-90deg);
                        transform-origin: 50%;
                    }

                    &-item-3 {
                        transition: all 1s ease;
                        width: 84px;
                        height: 50px;
                        transform: rotate(-6.349deg);
                    }

                    &-item-4 {
                        transition: all 1s ease;
                        width: 43px;
                        height: 40px;
                        top: -8px;
                    }

                    &-item-5 {
                        transition: all 1s ease;
                        width: 105px;
                        height: 18px;

                        g path {
                            fill: white;
                        }
                    }

                    &-item-6 {
                        transition: all 1s ease;
                        top: 73px;
                    }

                    &-item-7 {
                        transition: all 1s ease;
                        left: 255px;
                    }

                    &-item-8 {
                        transition: all 1s ease;

                        g path {
                            &:nth-child(1) {
                                fill: white;
                            }
                        }
                    }

                    &-item-9 {
                        transition: all 1s ease;

                        path {
                            transition: all 1s ease;
                            stroke: #FFFFFF;
                            fill: #FFFFFF;

                            &:nth-child(1),
                            &:nth-child(4),
                            &:nth-child(7),
                            &:nth-child(9) {
                                fill: none;
                            }
                        }
                    }

                    &-item-10 {
                        transition: all 1s ease;
                        filter: drop-shadow(0px 0px 50px #0078D4);

                        path {
                            transition: all 1s ease;

                            &:nth-child(1) {
                                fill: #FFFFFF;
                            }

                            &:nth-child(2) {
                                fill: #012F58;
                            }
                        }
                    }

                    &-item-11 {
                        transition: all 1s ease;
                        opacity: 0;
                    }

                    &-item-11-1 {
                        transition: all 1s ease;
                        opacity: 1;
                    }

                    &-item-12 {
                        transition: all 1s ease;

                        path {
                            fill: white;
                        }
                    }

                    &-item-13 {
                        transition: all 1s ease;
                        right: 215px;
                    }

                    &-item-14 {
                        transition: all 1s ease;
                        top: -10px;
                    }

                    &-item-15 {
                        transition: all 1s ease;
                        width: 70px;
                        height: 35px;
                        transform: rotate(7deg);

                        path {
                            fill: white;
                        }
                    }

                    &-item-16 {
                        transition: all 1s ease;
                        width: 40px;
                        height: 33px;
                        transform: rotate(9.491deg);
                        right: -10px;
                    }

                    &-item-17 {
                        transition: all 1s ease;
                        width: 138px;
                        height: 29px;
                        right: 44px;

                        g path {
                            fill: white;
                        }
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor-dop {
                display: none;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b49 {
        background: #D2D5DA;
        max-width: calc((100% / 2) - 12px);
        transition: all 1s ease;
        overflow: hidden;

        .projects__decor {
            &-item-1 {
                left: 25px;
                top: 100px;
                width: 49px;
                height: 49px;
                z-index: 2;
            }

            &-item-2 {
                bottom: 26px;
                right: 34px;
                width: 49px;
                height: 49px;
                z-index: 3;
            }

            &-item-3 {
                left: 137px;
                bottom: 35px;
                z-index: 2;
                transition: all 1s ease;

                defs>linearGradient>stop:first-child {
                    transition: all 1s ease;
                }
            }

            &-item-4 {
                opacity: 0;
                z-index: 3;
                transition: all 1s ease;
                top: 50px;
                right: 50px;
                width: 248px;
                height: 211px;
            }

            &-item-5 {
                width: 54px;
                height: 71px;
                z-index: 4;
                left: 137px;
                bottom: 161px;
            }

            &-item-6 {
                right: 0;
                z-index: 2;
                width: 360px;
                top: 0px;
            }

            &-item-7 {
                left: 15px;
                top: 28px;
                z-index: 3;
            }

            &-item-8 {
                border-radius: 30px;
                z-index: 1;
            }

            &-item-9 {
                background: #C29875;
                color: black;
                left: 25px;
                top: 37px;
            }

            &-item-10 {
                background: #72716F;
                color: white;
                left: 250px;
                top: 61px;
            }

            &-item-11 {
                background: #A794BE;
                color: white;
                top: 12px;
                right: 160px;
            }

            &-item-12 {
                background: #CC7C56;
                color: black;
                bottom: 112px;
                left: 275px;
            }

            &-item-13 {
                background: #A7B4E3;
                color: black;
                bottom: 87px;
                right: 9px;
            }

            &-item-9,
            &-item-10,
            &-item-11,
            &-item-12,
            &-item-13 {
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 99px;
                height: 26px;
                width: 100%;
                font-size: 12px;
                font-weight: 600;
                border-radius: 8px;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                .projects__decor {
                    &-item-1 {
                        width: 66px;
                        height: 66px;
                        transform: rotate(-11.289deg);
                        top: 55px;
                        left: 10px;
                    }

                    &-item-2 {
                        width: 66px;
                        height: 66px;
                        transform: rotate(13.727deg);
                        bottom: 36px;
                        right: 18px;
                    }

                    &-item-3 {
                        defs>linearGradient>stop:first-child {
                            transition: all 1s ease;
                            stop-color: #B4B586;
                        }
                    }

                    &-item-4 {
                        opacity: 1;
                    }

                    &-item-5 {
                        transform: scaleY(1.6) scaleX(1.15);
                        bottom: 176px;
                    }

                    &-item-9 {
                        left: 250px;
                        top: 129px;
                    }

                    &-item-10 {
                        left: 312px;
                        top: 62px;
                    }

                    &-item-11 {
                        top: 13px;
                        right: 37px;
                    }

                    &-item-12 {
                        bottom: 139px;
                        left: 329px;
                    }

                    &-item-13 {
                        bottom: 158px;
                        right: 13px;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__text h3 {
                max-width: 225px;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b50 {
        background: #AEDEDE;
        transition: all 1s ease;

        .projects__text {
            color: #232323;
        }

        .projects__decor {
            &-item-1 {
                left: 24px;
                top: 44px;
                width: 50.026px;
                height: 66.592px;
            }

            &-item-2 {
                width: 30.614px;
                height: 22.632px;
                top: 21px;
                left: 83px;
            }

            &-item-3 {
                top: 18px;
                right: 41px;
                z-index: 2;
            }

            &-item-4 {
                width: 32px;
                height: 28px;
                top: 13px;
                right: 43px;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-5 {
                width: 16px;
                height: 13px;
                top: 38px;
                right: 19px;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-6 {
                left: 13px;
                bottom: 84px;
                z-index: 2;
            }

            &-item-7 {
                width: 40px;
                height: 37px;
                bottom: 73px;
                right: 105px;
            }

            &-item-8 {
                width: 55px;
                height: 55px;
                right: 8px;
                bottom: 100px;
                z-index: 2;
            }

            &-item-9 {
                width: 47px;
                height: 47px;
                right: 14px;
                bottom: 40px;
            }

            &-item-10 {
                top: 131px;
                margin: 0 auto;
                left: 0;
                right: 0;
                z-index: 1;
                border: 1px solid white;
                border-radius: 7px;
                padding: 5px;
            }

            &-item-11 {
                width: 81.5px;
                height: 55.5px;
                top: 31px;
                left: 80px;
                z-index: 2;
                opacity: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: #0E3030;

                .projects__text {
                    color: white;
                }

                .projects__decor {
                    &-item-1 {
                        width: 38.909px;
                        height: 51.794px;
                        transform: translateX(-27px) translateY(-31px);
                    }

                    &-item-2 {
                        transform: translateY(29px);
                        opacity: 0;
                    }

                    &-item-3 {
                        transform: translateX(-80px);
                    }

                    &-item-4 {
                        transform: translateX(-62px) translateY(-19px);

                        path {
                            fill: white;
                        }
                    }

                    &-item-5 {
                        transform: translateX(-55px) translateY(-15px);

                        path {
                            fill: white;
                        }
                    }

                    &-item-9 {
                        width: 60px;
                        height: 60px;
                        transform: rotate(20deg);

                        g path {
                            fill: white;
                        }
                    }

                    &-item-11 {
                        transform: translateX(-80px);
                        opacity: 1;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b51 {
        background: #BAEBFF;
        transition: all 1s ease;
        overflow: hidden;

        .projects__text {
            color: #232323;
        }

        .projects__decor {
            &-item-1 {
                left: -19px;
                top: 25px;
                width: 104px;
                height: 78px;
            }

            &-item-2 {
                top: 5px;
                left: 97px;
                width: 40px;
                height: 40px;
            }

            &-item-3 {
                top: 11px;
                right: 63px;
                width: 88px;
                height: 30px;
            }

            &-item-4 {
                top: 49px;
                right: 20px;
            }

            &-item-5 {
                left: 23px;
                top: 126px;
                width: 57px;
                height: 16px;
            }

            &-item-6 {
                right: 30px;
                top: 114px;
                width: 43px;
                height: 12px;
            }

            &-item-7 {
                left: 17px;
                top: 176px;
                width: 40px;
                height: 40px;
            }

            &-item-8 {
                right: -13px;
                top: 146px;
                width: 104px;
                height: 78px;
            }

            &-item-9 {
                left: -16px;
                bottom: 98px;
                width: 104px;
                height: 78px;
            }

            &-item-10 {
                right: 42px;
                bottom: 132px;
                width: 40px;
                height: 40px;
            }

            &-item-11 {
                right: -31px;
                bottom: 45px;
                width: 104px;
                height: 78px;
            }

            &-item-12 {
                top: 41px;
                margin: 0 auto;
                left: 0;
                right: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                overflow: visible;

                .projects__decor {
                    &-item-1 {
                        width: 77px;
                        height: 57px;
                        left: -17px;
                        top: 17px;
                    }

                    &-item-2 {
                        width: 55px;
                        height: 55px;
                        top: -10px;
                        left: 92px;
                    }

                    &-item-3 {
                        width: 88px;
                        height: 30px;
                        top: 11px;
                        right: 42px;
                    }

                    &-item-4 {
                        width: 75px;
                        right: 6px;
                        top: 43px;
                        transform: rotate(-6deg);
                    }

                    &-item-5 {
                        width: 57px;
                        height: 16px;
                        left: 44px;
                        z-index: 1;
                    }

                    &-item-6 {
                        right: 9px;
                    }

                    &-item-7 {
                        width: 55px;
                        height: 55px;
                        left: 20px;
                        top: 163px;
                    }

                    &-item-8 {
                        width: 84px;
                        height: 63px;
                        right: -14px;
                        top: 137px;
                    }

                    &-item-9 {
                        width: 84px;
                        height: 63px;
                        left: -16px;
                        bottom: 107px;
                    }

                    &-item-10 {
                        width: 55px;
                        height: 55px;
                        right: 29px;
                        bottom: 124px;
                    }

                    &-item-11 {
                        width: 75px;
                        height: 56.25px;
                        right: -14px;
                        bottom: 60px;
                    }

                    &-item-12 {
                        top: 32px;
                        width: 298px;
                        z-index: 2;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__text h3 {
                max-width: 225px;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b52 {
        background: rgba(35, 35, 35, 1);
        transition: all 1s ease;
        overflow: hidden;

        .projects__text {
            color: white;
        }

        .projects__decor {
            &-item-1 {
                margin: 0 auto;
                left: 0;
                right: 0;
                top: 47px;
                z-index: 2;
            }

            &-item-2 {
                top: 0;
                left: 0;
            }

            &-item-3 {
                bottom: 0;
                left: 0;
                z-index: 1;
            }

            &-item-4 {
                left: 45px;
                top: 6px;
                width: 27px;
                height: 27px;
            }

            &-item-5 {
                top: 18px;
                right: 20px;
                width: 27px;
                height: 27px;
            }

            &-item-6 {
                top: 213px;
                right: 6px;
                width: 48px;
                height: 48px;
                z-index: 2;
            }

            &-item-7 {
                opacity: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
            }

            &-item-8 {
                top: 0;
                left: 0;
                opacity: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                overflow: visible;

                .projects__decor {
                    &-item-2 {
                        opacity: 0;
                    }

                    &-item-3 {
                        opacity: 0;
                    }

                    &-item-4 {
                        width: 41px;
                        height: 41px;
                        left: 15px;
                        top: -22px;
                    }

                    &-item-5 {
                        width: 41px;
                        height: 41px;
                        right: -15px;
                        top: -13px;
                    }

                    &-item-6 {
                        top: 218px;
                        right: -30px;
                        width: 65px;
                        height: 65px;
                    }

                    &-item-7 {
                        opacity: 1;
                    }

                    &-item-8 {
                        opacity: 1;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__text h3 {
                max-width: 225px;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b53 {
        background: rgba(207, 252, 245, 1);
        transition: all 1s ease;
        max-width: calc((100% / 2) - 12px);
        overflow: hidden;

        .projects__decor {
            &-item-2 {
                top: 0;
                right: 0;
            }

            &-item-3 {
                z-index: 1;
                left: 0;
                right: 0;
                margin: 0 auto;
                top: 34px;
                width: 539.09px;
                height: 264px;
                padding: 12px;
                border: 1.3px solid rgb(255, 255, 255);
                border-radius: 20.8px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &-item-4 {
                z-index: 2;
                bottom: 33px;
                right: 17px;
            }

            &-item-5 {
                z-index: 2;
                left: 10px;
                top: 110px;
            }

            &-item-6 {
                z-index: 2;
                top: 71px;
                right: 14px;
            }

            &-item-7 {
                z-index: 1;
                left: -7px;
                bottom: 50px;
                opacity: 0;
            }

            &-item-8 {
                z-index: 1;
                top: -12px;
                right: 207px;
                opacity: 0;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                overflow: visible;
                background: rgba(31, 57, 53, 1);

                .projects__decor {
                    &-item-3 {
                        width: 593px;
                        height: 290px;
                        transform: rotate(1.80deg);

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &-item-4 {
                        transform: scale(1.1) translateX(25px) translateY(-4px);
                    }

                    &-item-5 {
                        transform: scale(1.4) rotate(-11.93deg) translateX(-5px) translateY(-20px);
                    }

                    &-item-6 {
                        transform: scale(1.3) translateX(15px) translateY(-4px);
                    }

                    &-item-7 {
                        opacity: 1;
                    }

                    &-item-8 {
                        opacity: 1;
                    }
                }

                .projects__text {
                    color: white;
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__text h3 {
                max-width: 225px;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b54 {
        background: rgb(255, 250, 233);
        transition: all 1s ease;

        .projects__decor {
            &-item-1 {
                left: 27px;
                top: -140px;
                max-width: 102px;

                path {
                    transition: all 1s ease;
                }
            }

            >div {
                position: relative;
            }

            &-item-2 {
                left: 61px;
                top: 18px;
                z-index: 2;
            }

            &-item-3 {
                left: 80px;
                top: 40px;
                z-index: 3;
            }

            &-item-4 {
                right: 62px;
                top: 40px;
                z-index: 4;
            }

            &-item-5 {
                right: 57px;
                top: 111px;
                z-index: 5;
            }

            &-item-6 {
                right: 32px;
                top: 40px;
            }

            &-item-7 {
                right: 16px;
                top: 155px;
                z-index: 3;
            }

            &-item-8 {
                top: 95px;
                left: 20px;
                z-index: 4;
            }

            &-item-9 {
                top: 229px;
                left: 25px;
                z-index: 3;
            }

            &-item-10 {
                top: 80px;
                left: 105px;
                max-width: 81px;
                z-index: 2;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-11 {
                top: 20px;
                left: 0;
                right: 0;
                margin: 0 auto;
                max-width: 287px;
                z-index: 1;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-12 {
                top: 60px;
                right: 50px;
                max-width: 71px;
                z-index: 2;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-13 {
                top: 78px;
                left: 30px;
                max-width: 31px;
                z-index: 2;

                path {
                    transition: all 1s ease;
                }
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                overflow: visible;
                background: rgb(233, 251, 255);

                .projects__decor {
                    &-item-1 {
                        transform: scale(1.4);

                        path {
                            fill: rgb(69, 90, 100);

                            &:nth-child(4) {
                                fill: #BA68C8;
                            }
                        }
                    }

                    &-item-4 {
                        transform: scale(1.27) translateX(20px) translateY(-20px);
                    }

                    &-item-5 {
                        transform: translateX(16px);
                    }

                    &-item-6 {
                        transform: scale(1.3) translateX(32px);
                    }

                    &-item-8 {
                        transform: scale(1.5) translateX(20px) translateY(10px);
                    }

                    &-item-10 {
                        path {

                            &:nth-child(1),
                            &:nth-child(9) {
                                fill: rgb(209, 229, 244);
                            }

                            &:nth-child(8),
                            &:nth-child(16) {
                                fill: rgb(189, 209, 225);
                            }
                        }
                    }

                    &-item-11 {
                        path {
                            fill: rgb(209, 229, 244);
                        }
                    }

                    &-item-12 {
                        transform: translateX(16px);

                        path {
                            fill: rgb(189, 209, 225);
                        }
                    }

                    &-item-13 {
                        path {
                            fill: rgb(189, 209, 225);
                        }
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__text h3 {
                max-width: 225px;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b55 {
        background: rgb(35, 35, 35);
        transition: all 1s ease;

        .projects__text {
            color: white;
        }

        .projects__decor {
            &-item-1 {
                left: 16px;
                top: 38px;
                z-index: 3;
            }

            &-item-2 {
                top: 30px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 3;
            }

            &-item-3 {
                top: 37px;
                right: 20px;
                z-index: 3;
            }

            &-item-4 {
                right: 16px;
                top: 183px;
                z-index: 3;
            }

            &-item-5 {
                left: 16px;
                top: 183px;
                z-index: 3;
            }

            &-item-6 {
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 1;
            }

            &-item-7 {
                top: 10px;
                left: 0;
                right: 0;
                margin: 0 auto;
                z-index: 2;
                width: 176px;
                height: 176px;
                border-radius: 100%;
                filter: blur(50px);
                background: rgba(253, 216, 60, 0.6);
                opacity: 0;
            }

            &-item-block {
                width: 100%;
                height: 400px;
                position: relative;
                overflow: hidden;
            }


            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                overflow: visible;

                .projects__decor {
                    &-item-1 {
                        transform: rotate(-8.62deg) scale(1.5);
                    }

                    &-item-2 {}

                    &-item-3 {
                        transform: rotate(7.52deg) scale(1.5);
                    }

                    &-item-4 {
                        transform: scale(1.3) translateY(10px) translateX(12px);
                    }

                    &-item-5 {
                        transform: scale(1.3) translateY(10px) translateX(-12px);
                    }

                    &-item-6 {
                        transform: rotate(150deg) translateX(-80px);
                    }

                    &-item-7 {
                        opacity: 1;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__text h3 {
                max-width: 225px;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b56 {
        background: linear-gradient(109.81deg, rgba(183, 106, 215, 0.7) 0.33%, rgba(91, 77, 249, 0.7) 99.67%);
        transition: all 1s ease;
        max-width: calc((100% / 2) - 12px);

        .projects__text {
            color: white;
        }

        .projects__decor {
            &-item-1 {
                display: flex;
                left: 0px;
                right: 0px;
                margin: 0px auto;
                top: 34px;
                max-width: fit-content;
                padding: 12px;
                border: 1.3px solid rgb(255, 255, 255);
                border-radius: 20.8px;
                z-index: 3;
            }

            &-item-2 {
                z-index: 1;
            }

            &-item-4 {
                z-index: 1;
                opacity: 0;
            }

            &-item-3 {
                z-index: 2;
                opacity: 0;
                top: 10px;
                left: 0px;
                right: 0px;
                margin: 0px auto;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                overflow: hidden;
                background: linear-gradient(109.81deg, #8B3FAB 0.33%, #2D20BD 99.67%);

                .projects__decor {
                    &-item-1 {
                        border: 0px solid rgb(255, 255, 255);
                        padding: 0;
                        transform: rotate(4.6deg) scale(0.6) translateY(55px) translateX(10px);
                    }

                    &-item-2 {
                        opacity: 0;
                    }

                    &-item-3 {
                        opacity: 1;
                    }

                    &-item-4 {
                        opacity: 1;
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__text h3 {
                max-width: 225px;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }

    .b57 {
        background: linear-gradient(1.23deg, rgb(179, 222, 255) 1.974%, rgb(129, 200, 253) 88.245%);
        transition: all 1s ease;

        .projects__text {
            color: black;
        }

        .projects__decor {
            &-item-1 {
                display: flex;
                left: 0px;
                right: 0px;
                margin: 0px auto;
                top: 42px;
                z-index: 1;
            }

            &-item-2 {
                border-radius: 100%;
                width: 27px;
                height: 27px;
                background: rgb(160, 214, 255);
                left: 8px;
                top: 12px;
            }

            &-item-3 {
                border-radius: 100%;
                width: 13px;
                height: 13px;
                background: rgb(160, 214, 255);
                top: 9px;
                right: 94px;
            }

            &-item-4 {
                border-radius: 100%;
                width: 32px;
                height: 32px;
                background: rgb(149, 210, 255);
                bottom: 150px;
                right: 6px;
            }


            &-item-5 {
                border-radius: 100%;
                width: 9.3px;
                height: 9.3px;
                background: rgb(94, 185, 255);
                top: 170px;
                right: 72px;
            }

            &-item-6 {
                border-radius: 100%;
                width: 9.3px;
                height: 9.3px;
                background: rgb(94, 185, 255);
                top: 170px;
                right: 58px;
            }

            &-item-7 {
                border-radius: 100%;
                width: 9.3px;
                height: 9.3px;
                background: rgb(94, 185, 255);
                top: 170px;
                right: 45px;
            }


            &-item-8 {
                border-radius: 100%;
                width: 6.1px;
                height: 6.1px;
                background: rgb(94, 185, 255);
                top: 255px;
                left: 14px;
            }

            &-item-9 {
                border-radius: 100%;
                width: 6.1px;
                height: 6.1px;
                background: rgb(94, 185, 255);
                top: 255px;
                left: 22px;
            }

            &-item-10 {
                border-radius: 100%;
                width: 6.1px;
                height: 6.1px;
                background: rgb(94, 185, 255);
                top: 255px;
                left: 30px;
            }

            &-item-11 {
                border-radius: 100%;
                width: 6.1px;
                height: 6.1px;
                background: rgb(94, 185, 255);
                top: 255px;
                left: 39px;
            }


            &-item-12 {
                left: 13px;
                top: 86px;
                z-index: 2;
            }

            &-item-13 {
                left: 45px;
                top: 30px;
            }

            &-item-14 {
                left: 105px;
                top: 10px;
            }

            &-item-15 {
                right: 62px;
                top: 25px;
            }

            &-item-16 {
                left: 17px;
                top: 140px;
            }

            &-item-17 {
                right: 14px;
                top: 53px;
            }

            &-item-18 {
                right: 14px;
                top: 110px;
            }

            &-item-19 {
                right: 50px;
                bottom: 165px;
                z-index: 2;
            }

            &-item-20 {
                display: flex;
                left: 0px;
                right: 0px;
                margin: 0px auto;
                width: 18px;
                height: 18px;
                top: 193px;
                z-index: 2;
            }

            &-item-mobile {
                display: none;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
            }
        }

        @media (min-width: $mq__tablet) {
            &:hover {
                background: linear-gradient(0.00deg, rgb(179, 222, 255) 85.875%, rgb(129, 200, 253) 99.216%);

                .projects__decor {
                    &-item-2 {
                        transform: translateX(30px);
                    }

                    &-item-4 {
                        transform: translateX(-5px);
                    }

                    &-item-6 {
                        transform: translateX(17px);
                    }

                    &-item-7 {
                        transform: translateX(34px);
                    }

                    &-item-9 {
                        transform: translateX(10px);
                    }

                    &-item-10 {
                        transform: translateX(20px);
                    }

                    &-item-11 {
                        transform: translateX(30px);
                    }

                    &-item-12 {
                        transform: translateX(30px) translateY(-30px);
                    }

                    &-item-13 {
                        transform: translateX(50px);
                    }

                    &-item-14 {
                        transform: translateX(50px);
                    }

                    &-item-15 {
                        transform: translateY(-15px);
                    }

                    &-item-16 {
                        transform: scale(1.35) rotate(-15deg) translateX(-5px);
                    }

                    &-item-17 {
                        transform: scale(1.15) rotate(14deg) translateX(10px) translateY(-7px);
                    }

                    &-item-18 {
                        transform: scale(1.15) rotate(14deg);
                    }

                    &-item-19 {
                        transform: scale(1.5) rotate(8deg) translateX(25px) translateY(3px);
                    }
                }
            }
        }

        @media (max-width: $mq__tablet) {
            .projects__text h3 {
                max-width: 225px;
            }

            .projects__decor {
                *:not(.projects__decor-item-mobile) {
                    display: none;
                }

                &-item-mobile {
                    display: block;
                }
            }
        }
    }
}

.project-amo {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    height: 290px;
    position: fixed;
    display: none;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9999;
    max-width: 361px;
    width: 100%;
    border-radius: 30px;
    background: white;

    &.is-display {
        display: block;
    }

    &__wrapper {
        position: relative;
        height: 100%;
    }

    &__close {
        position: absolute;
        background: none;
        border: none;
        right: 0px;
        top: 8px;
        cursor: pointer;

        svg {
            width: 32px;
            height: 32px;
        }
    }
}

.projects-page .projects-btn {
    display: none;
}

@media (max-width: $mq__tablet) {
    .projects-page .projects__wrapper {
        justify-content: center;
    }
}


@media (max-width: $mq__tablet) {
    .projects-v2 {
        .projects__row {
            padding-bottom: 35px;
        }

        .projects__wrapper {
            flex-wrap: wrap;
            gap: 20px;

            .projects__card {
                margin: 0;
            }
        }
    }
}